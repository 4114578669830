import "./index.less";
import Presenter from "./presenter";
import { getResourceTypeIndex } from "@/mockData/resource";

const names = {
  10: "自主学习",
  20: "课堂教学",
  30: "课后作业",
};

const FunBar = (props) => {
  const [states, events] = Presenter(props);

  const { btnClicked, chooseActive } = events;

  const { isShow, todoList, active } = states;
  return (
    <div className="funBarGrid">
      <div className="btn btn1" onClick={btnClicked}>
        <i className="ri-menu-4-line"></i>
      </div>
      {isShow ? (
        /**
         * 展示顺序是 先 导学案、再资源、再试题包
         * {
         *    key: 0, 0:导学案,1:资源，2:试题包
         *    val: '', 当前选中的信息
         *    index: 0, 当前是第几个试题或者第几个资源
         * }
         */
        <div className="todoList">
          {(todoList || []).map((v, index) => (
            <div
              key={index}
              className={`item ${active === index ? "active" : ""}`}
              onClick={() => chooseActive(v, index)}
            >
              {/* 试题包 */}
              {v.type === 0 ? (
                <StudyBar v={v} key={index} />
              ) : v.type === 1 ? (
                <ResourceBar v={v} key={index} />
              ) : (
                <ExamBar v={v} key={index} />
              )}
            </div>
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

function ExamBar(props) {
  const { v } = props;
  return (
    <>
      <img
        className="typeIcon"
        src={require("@/assets/courseDesign/type2.png").default}
        alt="icon"
      />
      <span>{v.lessonTeacherExtName || "自主学习"}</span>
    </>
  );
}

function StudyBar(props) {
  const { chooseActive, v } = props;
  return (
    <>
      <span>{v.name}</span>
    </>
  );
}

function ResourceBar(props) {
  const { v } = props;
  const getResourceTypeIndexs = getResourceTypeIndex;

  return (
    <>
      <img
        alt="icon"
        className="typeIcon"
        src={
          require(`@/assets/fileIcons/icon${getResourceTypeIndexs(
            v?.file?.resourceUrl
          )}.png`).default
        }
        v-show="item.type == 0"
      />
      <span>{v?.file?.name || "资源名称"}</span>
    </>
  );
}

export default FunBar;
