import Keyboard from "@/components/keyboard";
import KeyboardNew from "@/components/keyboardNew";
import { useEffect, useRef, useState } from "react";

const CustomKeyboard = () => {

  const [isShow, setIsShow] = useState(false);
  const [keyboardType, setKeyboardType] = useState(1);  // 1普通键盘 2 简易键盘
  const currentInput = useRef(null)

  useEffect(() => {
    const body = document.documentElement;
    const config = { attributes: true, childList: true, subtree: true };

    const focusCallBack = (e) => {
      e.target.setAttribute(`inputmode`, `none`)
      currentInput.current = e.target
      setIsShow(true)
    }

    const callback = function (mutationsList) {
      mutationsList.forEach(function (item, index) {
        if (item.type === 'childList' || item.type === 'attributes') {
          const inputTextareaEle = document.querySelectorAll("input,textarea");
          inputTextareaEle.forEach((el) => {
            el.addEventListener('focus', focusCallBack)
          })
        }
      });
    };

    const observer = new MutationObserver(callback);
    observer.observe(body, config);

    return () => {
      setIsShow(false)
      currentInput.current = null;
      observer.disconnect();
    }
  }, [])

  // 添加页面上 input textarea 的 value 值
  const innerCountVal = (value, type) => {
    const adom = currentInput.current; //获取当前拥有焦点（即处于活动状态）的元素
    const startPos = adom?.selectionStart; //获取当前具有焦点的文本输入元素（如 <input> 或 <textarea>）中所选文本的起始位置的属性； （光标起始位置）
    const endPos = adom?.selectionEnd; //  是用于获取当前具有焦点的文本输入元素（如 <input> 或 <textarea>）中所选文本的结束位置的属性。（光标结束位置）

    if (adom?.tagName === 'INPUT' || adom?.tagName === 'TEXTAREA') {
      adom.focus();

      if (type === 'add') {
        adom.value = adom.value.substring(0, startPos) + value + adom.value.substring(endPos, adom.value.length);
        adom.setSelectionRange(startPos + 1, startPos + 1) //设置光标的位置
      }

      if (type === 'del') {
        adom.value = adom.value.substring(0, startPos - 1) + adom.value.substring(endPos, adom.value.length);
        adom.setSelectionRange(startPos - 1, startPos - 1) //设置光标的位置
      }

    }

  }

  const onSwitch = (type) => {
    setKeyboardType(type);
  }

  const changeInputValue = (val) => {
    innerCountVal(val, 'add');
  }

  const closeKeyBoard = (close) => {
    currentInput.current = null
    setIsShow(close)
  }

  const delInputValue = () => {
    innerCountVal('', 'del')
  }

  const inputOnBlank = (val) => {
    innerCountVal(val, 'add')
  }

  return (<>

    {isShow &&

      <div>
        {

          keyboardType === 1 ?
            <Keyboard
              onSwitch={onSwitch}
              changeInputValue={changeInputValue}
              closeKeyBoard={closeKeyBoard}
              delInputValue={delInputValue}
              inputOnBlank={inputOnBlank}
              complete={closeKeyBoard}
            /> :
            <KeyboardNew
              onSwitch={onSwitch}
              changeInputValue={changeInputValue}
              closeKeyBoard={closeKeyBoard}
              delInputValue={delInputValue}
              inputOnBlank={inputOnBlank}
              complete={closeKeyBoard}
            />
        }
      </div>

    }


  </>)

}

export default CustomKeyboard