/*
 * @Author: your name
 * @Date: 2021-11-09 16:16:01
 * @LastEditTime: 2021-12-30 15:45:55
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \sdzn-student2.0\src\util\configUrl.js
 */
// 配置公共url
export const baseConfigUrl =
  process.env.REACT_APP_ENV === 'production' ? 'http://file.znclass.com/' : 'http://file.znclass.com/';
// 直播的基本路径
export const liveBaseUrl =
  process.env.REACT_APP_ENV === 'production' ? 'https://ai.znclass.com/index.html/#/' : 'https://cs.aiznclass.com/#/';
