import "./index.less"
import presenter from "./presenter"


const calc = (px) => {
    const clientHeight = window.document.querySelector('body').clientHeight / 100
    return px * clientHeight / 12
}


function ErrorsChart(props) {

    const [states, events] = presenter(props)
    const { dataList } = props

    const { subjectActive, subjectList } = states
    const { changeSubjectList } = events

    return (
        <>
            <div className="ErrorsChartWarp">

                <div className="title">
                    <div className="name">错题趋势图</div>
                    <div className="text">近期错题数量统计趋势图</div>
                </div>

                <div className="tabs">
                    <div className="warp" style={{ width: calc(subjectList.length * 100) }}>
                        {
                            subjectList.map((item, index) => {
                                return (
                                    <div className={subjectActive === index ? "subBtn active" : "subBtn"} key={index} onClick={() => changeSubjectList(index)}>{item}</div>
                                )
                            })
                        }
                    </div>
                </div>


                {dataList && dataList.length !== 0 ? <div className="errorsEchartMain" id="ErrorsChartContent"></div>
                    :
                    <div className="empty">
                        <span>
                            <img src={require("@/assets/errorsBook/empty_1.png").default} alt="_" />
                            <div className="text">暂无数据</div>
                        </span>
                    </div>
                }


            </div>
        </>
    )
}

export default ErrorsChart