/*
 * @Author: your name
 * @Date: 2022-03-15 17:19:37
 * @LastEditTime: 2022-03-30 10:47:05
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newGitZnxt2.0\src\pages\wordsRecite\index.jsx
 */

import React from "react";
import "./index.less";

import WordAnswerList from "@/components/wordAnswerList";

import Presenter from "./presenter.js";

import { connect } from "react-redux";

const WordsRecite = (props) => {
  const [states, events] = Presenter(props);
  const { name } = states;

  const { goToStatistics, goBack } = events;

  return (
    <div className="WordsRecitePage">
      <div className="card">
        {/* 学生的作答区域 */}
        <WordAnswerList
          name={name}
          goToStatistics={goToStatistics}
          goBack={goBack}
        />
      </div>
    </div>
  );
};

connect();

export default connect((state) => ({}), {})(WordsRecite);
