/*
 * @Author: your name
 * @Date: 2021-09-29 10:39:51
 * @LastEditTime: 2022-04-27 18:20:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \newGitZnxt2.0\src\components\answerList\components\sevenSelectedFive\presenter.js
 */

import { useState, useEffect } from "react";
import store from "@/redux/store";
import { addCourseAnswer } from "@/api/answer";

const Presenter = (props) => {
  const typeList = [
    { text: "1." },
    { text: "2." },
    { text: "3." },
    { text: "4." },
    { text: "5." },
  ];
  const [numList, setNumList] = useState(typeList);
  const [activeIndex, setActiveIndex] = useState(""); // 学生选中的 - active
  const [typeIndex, setTypeIndex] = useState(0); // 学生选中的题号
  const [rightIndex, setrightIndex] = useState(""); // 正确答案的 - activeRight
  const [errorIndex, seterrorIndex] = useState(""); // 错误答案的 - activeError
  const chioceList = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"];

  const { info, submitAnswerInfo, isSubmit } = props;
  useEffect(() => {
    try {
      let obj = JSON.parse(info?.childrenAnswer || "[]") || [];
      let arr = [...typeList];
      obj.forEach((v, i) => {
        if (v[0]) {
          Object.assign(arr[i], {
            answer: chioceList[Number(v[0]?.a)],
            active: Number(v[0]?.a) - 1,
          });
        }
      });
      if (isSubmit && store.getState().isShowAnswer) {
        if (obj.length > 0) {
          obj.forEach((v, i) => {
            if (v[0]) {
              Object.assign(arr[i], {
                result: v[0]?.r === 2 ? false : true,
                lock: v[0]?.a,
              });
            }
          });
        } else {
          //未作答情况处理
          let data = info?.resource?.exam?.children;
          data.forEach((v, i) => {
            Object.assign(arr[i], {
              result: false,
            });
          });
        }
      }

      setNumList(arr);
      // 如果提交了和公布答案了
      if (isSubmit && store.getState().isShowAnswer) {
        if (typeof arr[0].result === "boolean") {
          errorAndRightActive(0);
        }
      } else {
        if (typeof arr[0].active === "number") {
          maintainActive(0);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // 点击选项
  const changeActiveIndex = (index, v) => {
    if (isSubmit) {
      return;
    }
    // 选项添加选中
    setActiveIndex(index + "");
    // 添加题号和答案
    numList[typeIndex].answer = chioceList[index];
    numList[typeIndex].active = index;
    setNumList(numList);
    submitAnswerInfo(numList);

    let optionList = [];
    numList.forEach((v) => {
      if (typeof v.active === "number") {
        optionList.push([
          {
            examTypeId: 18,
            selectSeq: v.active + 1,
            myAnswer: v.active + 1,
          },
        ]);
      } else {
        optionList.push([]);
      }
    });

    let obj = {
      lessonStudentId: store.getState().courseInfo.id,
      resourceId: info?.resource?.exam?.id,
      resourceType: 2,
      usedTime: store.getState().updateTime,
      optionList: optionList,
      isAdd: activeIndex === "",
      inExamSeq: typeIndex + 1,
    };

    if(props.isMistakes) {
      props.isMistakes(obj)
      return
    }

    addCourseAnswer(obj).then((res) => {});
  };

  // 选项保持选中
  const maintainActive = (index) => {
    setActiveIndex(numList[index].active + "");
  };

  // 公布答案后选项的保持选中
  const errorAndRightActive = (index) => {
    if (numList[index].result) {
      // 正确状态
      let rightStr = "";
      rightStr += numList[index].lock - 1;

      setrightIndex(rightStr);
      seterrorIndex("");
    } else {
      // 错误状态
      seterrorIndex(numList[index].lock - 1 + "");
      let rightStr = "";
      let data = info?.resource?.exam?.children[index]?.examOptions;
      data.forEach((v) => {
        if (v.right) {
          rightStr += v.seq - 1;
        }
      });
      setrightIndex(rightStr);
    }
  };

  // 点击题号
  const changeActiveNum = (index, v) => {
    // 题号选中
    setTypeIndex(index);

    if (isSubmit && store.getState().isShowAnswer) {
      errorAndRightActive(index);
    } else {
      maintainActive(index);
    }
  };

  return [
    {
      numList,
      activeIndex,
      typeIndex,
      chioceList,
      rightIndex,
      errorIndex,
    },
    { changeActiveNum, changeActiveIndex },
  ];
};

export default Presenter;
