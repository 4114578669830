/*
 * @Description: 我的课程
 * @FilePath: \sdzn-student2.0\src\pages\liveList\components\myCourse\presenter.js
 */
import { useState, useEffect, useRef } from "react";
import {
  getOrderList,
  getTodayLiveList,
  getBuyCourseList,
  getCourseItemList,
} from "@/api/live.js";
import { getParams, isApp } from "@/util/util";
import { liveBaseUrl } from "@/util/configUrl";
// import { message } from "antd";
import isMobile from "is-mobile";

const Presenter = (props) => {
  const [tabActiveIndex, setTabActiveIndex] = useState(0);
  const [orderList, setOrderList] = useState([]); // 我的订单
  const [todayLiveList, setTodayLiveList] = useState([]); // 今日直播
  const [buyCourses, setBuyCourses] = useState([]); // 已购课程
  const [buyCourseItems, setBuyCourseItems] = useState([]); // 已购课程-课次
  const [isCourseVisible, setIsCourseVisible] = useState(false); //打开课次

  let urlObj = getParams(window.location.href);

  // 点击tab
  const tabClick = (i) => {
    setTabActiveIndex(i);
  };

  // 今日直播
  const getTodayLives = () => {
    let params = {
      studentId: urlObj.studentId || urlObj.userId,
    };
    getTodayLiveList(params).then((res) => {
      setTodayLiveList(res.data || []);
    });
  };

  // 进入今日直播直播间
  const goLiveRoom = (data) => {
    let token = localStorage.getItem("sdzn-token");
    let userInfo = JSON.parse(localStorage.getItem("sdzn-userInfo") || "{}");
    // account:账号
    // studentName：只有学生有
    // type: 1学生、2老师
    // kpointId：课次id
    // liveName: 直播间名称
    // id:只有老师端添加教师id
    // message.warn('直播还未开始，请耐心等待');
    let params = `?id=${data.lessonTeacherId || data.id}&liveName=${
      data.name || data.lessonTeacherName
    }&account=${urlObj.account}&studentName=${
      urlObj.userName
    }&type=1&kpointId=${data.lessonTeacherId || data.id}&schoolId=${
      data.schoolId
    }&levelId=${userInfo.levelId}&gradeId=${userInfo.gradeId}&className=${
      urlObj.className
    }&classId=${urlObj.classId}&token=${token}&userId=${
      urlObj.studentId
    }&time=${new Date().getTime()}`;
    let url = liveBaseUrl + "liveRoom" + params;
    // 手机
    let isNotMobile = isMobile();
    if (isNotMobile) {
      window.open(liveBaseUrl + "liveRoomh5" + params, "_blank");
      return;
    }
    if (isApp()) {
      let obj = {
        pageUrl: url,
        streamUrls: JSON.stringify(JSON.parse(data.roomInfo)),
      };
      window?.android.goLiveRoom(JSON.stringify(obj));
    } else {
      window.open(liveBaseUrl + "liveRoom" + params, "_blank");
    }
  };

  // 看回放
  const goVideoPlayback = (data) => {
    let dataObj = {
      cameraVod: JSON.parse(data?.roomInfo)?.cameraVod,
      screenVod: JSON.parse(data?.roomInfo)?.screenVod,
    };
    props.history.push("/videoPlayback?videoObj=" + JSON.stringify(dataObj));
  };

  // 已购课程列表
  const getBuyCourses = () => {
    let params = {
      userId: urlObj.userId,
      type: 1,
      page: 1,
      size: 300,
    };
    getBuyCourseList(params).then((res) => {
      setBuyCourses(res || []);
    });
  };

  // 打开课次弹窗
  const openLessonModal = (bool, data) => {
    setBuyCourseItems([]);
    setIsCourseVisible(bool);
    getCourseItems(data);
  };

  // 获取课次
  const getCourseItems = (data) => {
    if (!data?.id) return;
    let params = {
      liveCourseId: data.id,
      types: 70, // 直播课
    };
    getCourseItemList(params).then((res) => {
      setBuyCourseItems(res || []);
    });
  };

  // 订单列表
  const getOrders = () => {
    let params = {
      userId: urlObj.userId,
      type: 1,
      page: 1,
      size: 300,
      // states: '', // 订单状态 (0 未支付,1已支付,2 已取消,3已退款,4退款审核中,5退款审核被拒绝)states多个逗号隔开
    };
    getOrderList(params).then((res) => {
      setOrderList(res || []);
    });
  };

  useEffect(() => {
    if (tabActiveIndex === 0) {
      getTodayLives();
      getBuyCourses();
    }
    if (tabActiveIndex === 2) {
      getOrders();
    }
  }, [tabActiveIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  return [
    {
      tabActiveIndex,
      todayLiveList,
      buyCourses,
      buyCourseItems,
      orderList,
      isCourseVisible,
    },
    { tabClick, goLiveRoom, goVideoPlayback, openLessonModal },
  ];
};
export default Presenter;
