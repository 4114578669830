import React from "react";
import "./index.less";

import PadHeader from "@/components/padHeader";
import Presenter from "./presenter.js";
import { Progress } from "antd";

const PracticeReport = () => {
  const [states, events] = Presenter();
  const { answerList, examInfoList, progressRef, progessWidth, showBtn } =
    states;
  const { timeMethod, handleCheckAnswer } = events;
  return (
    <div className="autonomyPracticeReport">
      {/* pad顶部 */}
      <PadHeader></PadHeader>
      {/* 中间的卡片区域 */}
      <div className="card">
        <div></div>
        <div className="cardTop">
          <div className="cardTopMind"></div>
        </div>
        <div className="cardTopTwo"></div>

        <div className="statistical">
          <div className="leftBox">
            <div className="text">作答统计</div>
            <div>
              {(examInfoList || []).map((v, index) => (
                <div className="statisticalText" key={index}>
                  {/* <div className="showTextBox">
                    <div className="blue">{v.scoreTotal}</div>
                    <div className="showText">我的成绩(得分)</div>
                  </div> */}
                  {/* <div className="showTextBox">
                    <div className="green">{v.avgScoreTotal}</div>
                    <div className="showText">小组平均分</div>
                  </div> */}
                  {/* <div className="showTextBox">
                    <div className="orange">{v.scoreFull}</div>
                    <div className="showText">总分</div>
                  </div> */}
                  {/* <div className="showTextBox">
                    <div className="blue">{v.scoreObjective}</div>
                    <div className="showText">客观题(得分)</div>
                  </div> */}
                  {/* <div className="showTextBox">
                    <div className="blue">{v.scoreSubjective}</div>
                    <div className="showText">主观题(得分)</div>
                  </div> */}
                  <div className="showTextBox">
                    {showBtn ? (
                      <div className="colorText">未统计</div>
                    ) : (
                      <div className="orange">
                        {Math.round(v.accuracy * 100) / 100} %
                      </div>
                    )}

                    <div className="showText">答题正确率</div>
                  </div>
                  <div className="showTextBox">
                    <div className="orange">{timeMethod(v.useTime)}</div>
                    <div className="showText">我的用时</div>
                  </div>
                </div>
              ))}
            </div>
            {showBtn ? (
              <div className="TipsText">请根据解析，对主观题进行自我打分</div>
            ) : (
              <></>
            )}
          </div>
          <div className="ProgressBox" ref={progressRef}>
            <div>
              {(examInfoList || []).map((v, index) => (
                <div key={index}>
                  <Progress
                    width={progessWidth}
                    strokeWidth={12}
                    strokeColor="#fa541c"
                    trailColor="#efefef"
                    type="circle"
                    percent={(v.rightCount / v.countExam) * 100}
                  />
                  <div className="giaoBox">
                    <div className="giao">
                      <span className="giaoTwo">{v.rightCount}</span>/
                      <span>{v.countExam}</span>
                    </div>
                    <div className="aboveboard">正答/总题</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="circleBox">
          <div className="circleLeft"></div>
          <div className="circleRight"></div>
        </div>
        <div className="cardBottom">
          <div className="topBox">
            <div>答题卡</div>
            <div className="rightBox">
              <div className="trueBox"></div>
              <div>正确</div>
              <div className="errorBox"></div>
              <div>错误</div>
              {/* <div className="halfBox"></div> */}
              <div className="halfBox2"></div>
              <div className="halfText">未核对</div>
              <div>共{answerList?.length}题</div>
            </div>
          </div>
          <div className="showBox">
            {(answerList || []).map((v, index) => (
              <div key={v.id}>
                <div
                  className={`item ${v.resultState === 1 ? "color0" : ""} ${v.resultState === 2 ? "color2" : ""
                    } ${v.resultState === 3 ? "color1" : ""}`}
                >
                  <span>{index + 1}</span>
                </div>
              </div>
            ))}
          </div>
          {/* {showBtn ? (
            <div className="checkAnswer" onClick={handleCheckAnswer}>
              核对主观题
            </div>
          ) : (
            <></>
          )} */}
          <div className="checkAnswer" onClick={handleCheckAnswer}>
            核对主观题
          </div>
        </div>
      </div>
    </div>
  );
};

export default PracticeReport;
