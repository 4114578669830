import presenter from "./presenter"
import { RightCircleOutlined, LeftCircleOutlined } from '@ant-design/icons';
import "./index.less"

const arrowsButton = {
    ALLOW_STATE: {
        color: '#155BD4', borderRadius: '50%'
    },
    DISABLED_STATE: {
        color: '#ccc', borderRadius: '50%'
    }
}

const SemesterTrunk = (props) => {

    const [states, events] = presenter(props)

    const { semesterTrunkList, isNext, isPrev, active } = states
    const { cutPages, chengActive } = events

    const { changeTimeQuantum } = props

    return (
        <>
            <div className="SemesterTrunkWrap">
                <div className="section">
                    <div className="left-btn">
                        <div className="icon" onClick={() => cutPages("prev")}>
                            <LeftCircleOutlined style={isPrev ? arrowsButton['ALLOW_STATE'] : arrowsButton['DISABLED_STATE']} />
                        </div>
                    </div>
                    <div className="content">
                        {semesterTrunkList ? semesterTrunkList.map((item, index) => {
                            const { id, createTime, score } = item
                            const date = new Date(parseInt(createTime))

                            return (

                                <div className={active === id ? "timeQuantum active" : "timeQuantum"} key={id} onClick={() => { changeTimeQuantum(id); chengActive(id) }}>
                                    <span className="grad"><em className={(score + '').length >= 5 ? "s" : ""}>{score}</em>分</span>
                                    <span className="month">{`${date.getMonth() + 1}月${date.getDate()}日`}</span>
                                    <span className="year">{`${date.getFullYear()}年`}</span>
                                </div>

                            )
                        }) : null}

                    </div>
                    <div className="right-btn">
                        <div className="icon" onClick={() => cutPages("next")}>
                            <RightCircleOutlined style={isNext ? arrowsButton['ALLOW_STATE'] : arrowsButton['DISABLED_STATE']} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SemesterTrunk