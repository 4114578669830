/*
 * @Author: c1107 chen1997
 * @Date: 2022-06-30 15:46:54
 * @LastEditors: c1107 chen1997
 * @LastEditTime: 2022-07-08 13:56:17
 * @FilePath: \ZNXT-Student2.0\src\pages\pktIndexPage\components\topHead\index.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import react from "react";
import presenter from "./presenter";
import "./index.less";
import { getParams } from "@/util/util";

const PadHeader = (props) => {
  const [states, events] = presenter(props);
  const { typeList } = states;
  const { backHandle, handleType } = events;
  const pageObj = getParams(window.location.href) || {};
  return (
    <div className="topHead">
      {pageObj?.isPad ? (
        <></>
      ) : (
        <div className="iconP btn1">
          <i className="ri-arrow-left-line backIcon" onClick={backHandle}></i>
        </div>
      )}

      <div className="leftTypeBox">
        {typeList.map((v, index) => (
          <div
            style={{
              color: v.active ? "#FA541C" : "#8c8c8c",
            }}
            key={index}
            value={v.type}
            onClick={handleType}
          >
            {v.text}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PadHeader;
