import React from 'react';
import { Select } from 'antd';
import PadHeader from '@/components/padHeader';
import './index.less';
import Presenter from './presenter.js';
const { Option } = Select;

const ElectronicBook = () => {
  const [states, events] = Presenter();
  const { bookList, subjectList, subjectId } = states;
  const { changesubject, activeBook } = events;

  const subjectImgs = ['地理', '化学', '历史', '生物', '数学', '物理', '英语', '思想政治', '语文'];

  return (
    <div className="electronic_book_cont">
      <PadHeader name={'电子课本'}>
        {subjectId ? (
          <div className="subject_cont">
            <div className="select_cont">
              <i className="icon ri-book-mark-fill"></i>
              <Select defaultValue={subjectId} style={{ width: 120 }} onChange={changesubject}>
                {subjectList.map((item) => (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        ) : null}
      </PadHeader>
      {/* 电子课本内容 */}
      <div className="book_wai_cont">
        <div className="book_cont">
          {bookList.map((item, index) => (
            <div className="book" key={index} onClick={() => activeBook(item)}>
              <div
                className="img_cont"
                style={{
                  backgroundImage:
                    'url(' +
                    require('@/assets/subjectImgs/' +
                      (subjectImgs.includes(item.subjectName) ? item.subjectName : '通用') +
                      '.jpg').default +
                    ')',
                }}
              >
                {/* <div className="txt1">
                  {item.levelName} · {item.subjectName}
                </div> */}
                <div className="txt2">{item.bookVolumeName}</div>
                <div className="txt3">{item.bookVersionName}</div>
              </div>
              <div className="text">
                <span>学习进度：</span>
                {item.pageSize && item.pageTotal ? (
                  <span>
                    <span className="left num">{item.pageSize}</span> {' / '}
                    <span className="right num">{item.pageTotal}</span>
                  </span>
                ) : (
                  <span>未看</span>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ElectronicBook;
