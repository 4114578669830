/*
 * @Author: your name
 * @Date: 2021-09-29 10:39:51
 * @LastEditTime: 2022-04-27 18:20:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \newGitZnxt2.0\src\components\answerList\components\sevenSelectedFive\presenter.js
 */

import { useState, useEffect } from "react";
import { addCourseAnswer, updateTimeInRepetition } from "@/api/answer";
import store from "@/redux/store";

const Presenter = (props) => {
    const { info } = props

    const [optionList, setOptionList] = useState([])
    const [timeInRepetition, setTimeInRepetition] = useState(0)



    useEffect(() => {
        let answer = JSON.parse(info.childrenAnswer) || []; // 学生作答

        let initOptionList = info?.resource?.exam?.children.map((v, i) => {
            if (answer[i] && answer[i]?.length !== 0) {
                let v = answer[i][0];
                if (v.qt === 1) return [{ examTypeId: v.qt, selectSeq: Number(v.a) }]
                if (v.qt === 6) return [{ examTypeId: v.qt, myAnswer: v.a }]
            }
            return []
        })

        setOptionList(initOptionList)
        setTimeInRepetition(info?.timeInRepetition)

    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    const updatePlaySoundNumber = () => {
        if (timeInRepetition === 0) return
        updateTimeInRepetition({ lessonStudentAnswerId: info.id }).then(res => {
            setTimeInRepetition(v => v - 1)
        })
    }

    const SubmitResponse = (exam, index, answer) => {
        if (props.isSubmit) return;

        setOptionList(optionList => {

            if (exam.examTypeId === 1) {

                optionList[index][0] = {
                    examTypeId: exam.examTypeId,
                    selectSeq: answer
                }

            } else if (exam.examTypeId === 6) {

                optionList[index][0] = {
                    examTypeId: exam.examTypeId,
                    myAnswer: answer
                }
            }
            return [...optionList]
        })

        console.log(optionList, [[], [], []].every(v => v.length === 0), 'optionList');

        let params = {
            lessonStudentId: store.getState().courseInfo.id,
            resourceId: info?.resource?.exam?.id || info?.id,  // 错题本修改
            resourceType: 2,
            usedTime: store.getState().updateTime,
            optionList: optionList.filter(v => v ? v : []),
            isAdd: info.childrenAnswer && info.childrenAnswer !== "[]" ? false : true,
        };

        addCourseAnswer(params)
    }

    return [
        { optionList, timeInRepetition },
        { SubmitResponse, updatePlaySoundNumber },
    ];
};

export default Presenter;
