import "./index.less"
import Presenter from "./presenter.js";
import TreeExplain from "../../../mistakesRearrange/compoents/treeExplain"




function Tree(props) {
    const { style, treeLevel, bubbleList } = props
    const [states, events] = Presenter(props);

    const { bubbleListDate } = states
    const { treeSuckUp } = events

    // console.log(  , 'fwefwagfe---------------------');

    return (
        <>
            <div className="TreeWarp" style={style}>
                <div className="top">

                    <div className="name">
                        <span>
                            成就树
                        </span>
                        <span className="level">
                            <span className="text">V.{Math.floor(treeLevel)}</span>
                        </span>

                    </div>

                    {/* <TreeExplain /> */}
                </div>
                <div className="sprout" id="Tree_sprout">
                    {/* 幼小树苗 */}
                    {treeLevel > 0 && treeLevel <= 5 ? <img src={require("@/assets/errorsBook/tree_1.png").default} className="treeOne" alt="_" /> : null}
                    {/* 中年树苗 */}
                    {treeLevel > 5 && treeLevel <= 15 ? <img src={require("@/assets/errorsBook/tree_2.png").default} className="treeTwo" alt="_" /> : null}
                    {/* 成年树苗 */}
                    {treeLevel > 15 && treeLevel <= 30 ? <img src={require("@/assets/errorsBook/tree_3.png").default} className="treeThree" alt="_" /> : null}
                    {/* 开花树苗 */}
                    {treeLevel > 30 ? <img src={require("@/assets/errorsBook/tree_4.png").default} className="treeFour" alt="_" /> : null}
                    {/* 土 */}
                    <img src={require("@/assets/errorsBook/soil.png").default} className="soil" alt="_" />

                    <div className="schedule">
                        <span style={{ width: ((treeLevel.toFixed(2)) * 100 + '').match(/.{2}$/) + '%' }}></span>
                    </div>

                    {/* 能量 小球 */}
                    {
                        bubbleList.map((item, index) => <div className="bubble" style={bubbleListDate[index]} key={index} onClick={() => { treeSuckUp(index, item.id) }}>{item.levelScore >= 10 ? item.levelScore : '+' + item.levelScore}</div>)
                    }
                </div>
            </div>
        </>
    )
}

export default Tree