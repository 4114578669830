/*
 * @Author: your name
 * @Date: 2021-08-03 11:11:32
 * @LastEditTime: 2021-08-03 11:22:34
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/util/pubSub.js
 */
import PubSub from "PubSub";

var pubSub;

const createPubSub = () => {
  pubSub = new PubSub();

  return () => pubSub;
};

export default createPubSub()();
