import { useEffect, useMemo, useRef, useState } from "react"
import { isSameDay } from "@/util/util"

/**
 * 有关月度的名称列表
 */
const MONTH_NAME_LIST = [
    {
        chineseFullName: '一月',
        fullName: 'January',
        mmm: 'Jan',
        monthNumber: 0
    },
    {
        chineseFullName: '二月',
        fullName: 'February',
        mmm: 'Feb',
        monthNumber: 1
    },
    {
        chineseFullName: '三月',
        fullName: 'March',
        mmm: 'Mar',
        monthNumber: 2
    },
    {
        chineseFullName: '四月',
        fullName: 'April',
        mmm: 'Apr',
        monthNumber: 3
    },
    {
        chineseFullName: '五月',
        fullName: 'May',
        mmm: 'May',
        monthNumber: 4
    },
    {
        chineseFullName: '六月',
        fullName: 'June',
        mmm: 'Jun',
        monthNumber: 5
    },
    {
        chineseFullName: '七月',
        fullName: 'July',
        mmm: 'Jul',
        monthNumber: 6
    },
    {
        chineseFullName: '八月',
        fullName: 'August',
        mmm: 'Aug',
        monthNumber: 7
    },
    {
        chineseFullName: '九月',
        fullName: 'September',
        mmm: 'Sep',
        monthNumber: 8
    },
    {
        chineseFullName: '十月',
        fullName: 'October',
        mmm: 'Oct',
        monthNumber: 9
    },
    {
        chineseFullName: '十一月',
        fullName: 'November',
        mmm: 'Nov',
        monthNumber: 10
    },
    {
        chineseFullName: '十二月',
        fullName: 'December',
        mmm: 'Dec',
        monthNumber: 11
    }
]

/**
 * 有关周几的名称列表
 */
const DAY_NAME_LIST = [
    {
        chineseFullName: "周日",
        chineseShortName: "日",
        fullName: "Sunday",
        shortName: "SUN",
        dayNumber: 0,
    },
    {
        chineseFullName: "周一",
        chineseShortName: "一",
        fullName: "Monday",
        shortName: "MON",
        dayNumber: 1,
    },
    {
        chineseFullName: "周二",
        chineseShortName: "二",
        fullName: "Tuesday",
        shortName: "TUE",
        dayNumber: 2,
    },
    {
        chineseFullName: "周三",
        chineseShortName: "三",
        fullName: "Wednesday",
        shortName: "WED",
        dayNumber: 3,
    },
    {
        chineseFullName: "周四",
        chineseShortName: "四",
        fullName: "Thursday",
        shortName: "THU",
        dayNumber: 4,
    },
    {
        chineseFullName: "周五",
        chineseShortName: "五",
        fullName: "Friday",
        shortName: "FRI",
        dayNumber: 5,
    },
    {
        chineseFullName: "周六",
        chineseShortName: "六",
        fullName: "Saturday",
        shortName: "SAT",
        dayNumber: 6,
    },
];

/**
 * 日历的各项属性
 */
const calendarInitConfig = {
    target: {
        year: null,
        month: null,
        date: null,
        day: null,
        monthShortName: null,
        monthFullName: null,
        monthChineseFullName: null,
        firstDay: null,
        firstDayIndex: null,
        totalDays: null,
    },
    previous: {
        totalDays: null,
    },
}

const Presenter = (props) => {
    // 最小年份
    const MIN_YEAR = 1900;
    // 最大年份
    const MAX_YEAR = 9999;
    // 当前时间
    const TODAY = new Date();


    const [calendarConfig, setCalendarConfig] = useState(calendarInitConfig)
    const [calendarData, setCalendarData] = useState([])
    const [activeBehavior, setActiveBehavior] = useState(TODAY)
    const headerDateDOM = useRef(null)

    const processCanlendarStyleData = useMemo(() => {

        return calendarData.map((obj) => {

            const matching = props?.processList.find(v => isSameDay(v.date, obj.date))
            const isActive = isSameDay(obj.date, activeBehavior)

            if (matching) {
                obj = { ...obj, ...matching }
            }

            if (isActive) {
                obj.active = true
            } else {
                obj.active = undefined
            }

            return obj
        })

    }, [calendarData, activeBehavior, props?.processList])

    /**
     *  时间对象
     * @param date
     */
    function loadCalendarConfig(date) {
        const targetDate = date || props?.targetDate || TODAY;
        if (targetDate.getFullYear() < MIN_YEAR || targetDate.getFullYear() > MAX_YEAR) return

        // 获取 年 月 日 星期
        calendarInitConfig.target.year = targetDate.getFullYear();
        calendarInitConfig.target.month = targetDate.getMonth();
        calendarInitConfig.target.date = targetDate.getDate();
        calendarInitConfig.target.day = targetDate.getDay();

        // 获取到目标日期的月份【中文】名称
        let dateString = targetDate.toString().split(" ");
        /* [
            0: "Thu"
            1: "Jul"
            2: "20"
            3: "2023"
            4: "10:21:42"
            5: "GMT+0800"
            6: "(中国标准时间)"
        ] */
        calendarInitConfig.target.monthShortName = dateString[1];
        calendarInitConfig.target.monthFullName = MONTH_NAME_LIST[calendarInitConfig.target.month]['fullName'];
        calendarInitConfig.target.monthChineseFullName = MONTH_NAME_LIST[calendarInitConfig.target.month]['chineseFullName'];

        // 获取 月份的第一天 是 星期几 
        const targetMonthFirstDay = new Date(
            calendarInitConfig.target.year,
            calendarInitConfig.target.month,
            1
        );
        calendarInitConfig.target.firstDay = targetMonthFirstDay.getDay();
        calendarInitConfig.target.firstDayIndex = DAY_NAME_LIST.findIndex(day => day.dayNumber === calendarInitConfig.target.firstDay);

        // 获取月份总共多少天
        const targetMonthLastDay = new Date(
            calendarInitConfig.target.year,
            calendarInitConfig.target.month + 1,
            0
        )
        calendarInitConfig.target.totalDays = targetMonthLastDay.getDate();

        // 获取目标月份的上个月总共多少天
        const previousMonthLastDay = new Date(
            calendarInitConfig.target.year,
            calendarInitConfig.target.month,
            0
        );

        calendarInitConfig.previous.totalDays = previousMonthLastDay.getDate();

        setCalendarConfig({ ...calendarInitConfig })

        // console.log(calendarInitConfig, 'loadEnd: calendarInitConfig');
    }

    function loadCalendarData() {
        let originData = [];
        let index = 0;

        let describeMap = [
            [
                /**
                 * 今日
                 */
                () =>
                    TODAY.getFullYear() === calendarInitConfig.target.year &&
                    TODAY.getMonth() === calendarConfig.target.month &&
                    index === calendarInitConfig.target.firstDayIndex + TODAY.getDate() - 1,
                () => {
                    let today = TODAY.getDate()
                    return {
                        type: 'today',
                        day: today,
                        date: new Date(
                            calendarInitConfig.target.year,
                            calendarInitConfig.target.month,
                            today
                        )
                    }
                }
            ],
            [
                /**
                 * 上一个月
                 */
                () => index < calendarInitConfig.target.firstDayIndex,
                () => {
                    let previousDay = calendarInitConfig.previous.totalDays - calendarInitConfig.target.firstDayIndex + index + 1
                    return {
                        type: 'previous',
                        day: previousDay,
                        date: new Date(
                            calendarInitConfig.target.year,
                            calendarInitConfig.target.month - 1,
                            previousDay
                        )
                    }
                }
            ],
            [
                /**
                 * 下一个月
                 */
                () => index >= (calendarInitConfig.target.totalDays + calendarInitConfig.target.firstDayIndex),
                () => {
                    let nextDay = index - calendarInitConfig.target.totalDays - calendarInitConfig.target.firstDayIndex + 1
                    return {
                        type: 'next',
                        day: nextDay,
                        date: new Date(
                            calendarInitConfig.target.year,
                            calendarInitConfig.target.month + 1,
                            nextDay
                        )
                    }
                }
            ],
            [
                /**
                 * 当前月
                 */
                () => true,
                () => {
                    let currentDay = index - calendarInitConfig.target.firstDayIndex + 1
                    return {
                        type: 'current',
                        day: currentDay,
                        date: new Date(
                            calendarInitConfig.target.year,
                            calendarInitConfig.target.month,
                            currentDay
                        )
                    }
                }
            ]
        ]

        for (let i = 0; i <= 5; i++) { // 多少行
            for (let j = 0; j <= 6; j++) {  // 多少列
                let getDescribeMap = describeMap.find(item => item[0]())
                originData.push(getDescribeMap[1]())
                index++
            }
        }
        setCalendarData(originData)

        // console.log(originData, 'loadEnd: calendarData');
    }

    function sliderScrollCenter(i, isAnimation = true) {
        const listElement = headerDateDOM.current;
        const containerWidth = listElement.offsetWidth;
        const element = listElement.children[0].children[i]
        const elementWidth = element.offsetWidth;
        const scrollLeft = element.offsetLeft - ((containerWidth - elementWidth) / 2);
        listElement.scrollTo({
            left: scrollLeft,
            behavior: isAnimation ? "smooth" : "auto"
        })
    }

    function switchDateChange(goDate) {
        let max = 11;
        let min = 0;
        let year = goDate?.year !== undefined ? goDate?.year : calendarInitConfig.target.year;
        let month = goDate?.month !== undefined ? goDate?.month : calendarInitConfig.target.month;
        let day = goDate?.day !== undefined ? goDate?.day : 1;
        let date = new Date(
            year,
            month,
            day
        );

        if (Number(month) < min) {
            year = year - 1
            month = max
            sliderScrollCenter(month, false)
        } else if (Number(month) > max) {
            year = year + 1
            month = min
            sliderScrollCenter(month, false)
        } else {
            sliderScrollCenter(month)
        }

        loadCalendarConfig(date)
        loadCalendarData()
        props?.onMonthChange(date)
    }

    function changeActiveDate({ date, type }, index) {
        let YMD = {
            year: date.getFullYear(),
            month: date.getMonth(),
            day: date.getDate()
        }
        setActiveBehavior(date)
        type !== 'current' && type !== 'today' && switchDateChange(YMD)
        props?.onDayChange(date, index)
    }

    useEffect(() => {
        loadCalendarConfig()
        loadCalendarData()
        sliderScrollCenter(calendarInitConfig.target.month, false)
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps


    return [
        { MONTH_NAME_LIST, DAY_NAME_LIST, calendarConfig, calendarData, headerDateDOM, processCanlendarStyleData },
        { sliderScrollCenter, switchDateChange, changeActiveDate }
    ]
}

export default Presenter