/*
 * @Author: your name
 * @Date: 2021-09-17 14:40:59
 * @LastEditTime: 2022-07-07 16:48:36
 * @LastEditors: c1107 chen1997
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newGitZnxt2.0\src\pages\studentAnswer\index.jsx
 */
import React from "react";
import "./index.less";

import PadHeader from "@/components/padHeader";
import AnswerList from "@/components/answerList";
import FunBar from "./components/funBar";
import Presenter from "./presenter.js";
import TemplateArea from "@/components/templateArea";
import ResourcePreview from "@/components/resourcePreview";
import ExamFeature from "./components/examFeature";

import { connect } from "react-redux";

// import Background from "@/assets/bk.jpeg";

const AnswerWrapper = () => {
  const [states, events] = Presenter();
  const { courseDetail, activeInfo, name, weekEnFiles } = states;
  const { changeComponents } = events;

  //定义背景样式

  // var sectionStyle = {
  //   // makesure here is String确保这里是一个字符串，以下是es6写法
  //   // backgroundImage: `url(${Background}) no-repeat`,
  //   backgroundImage: `url(${Background})`,
  //   backgroundSize: "100% 100%",
  // };

  return (
    <div className="AnswerWrapper">
      {/* pad顶部 */}
      <PadHeader name={name}>
        {activeInfo.key === 2 ? (
          <ExamFeature weekEnFiles={weekEnFiles}></ExamFeature>
        ) : (
          ""
        )}
      </PadHeader>
      {/* 中间的卡片区域 */}
      {courseDetail && (
        <div className="card">
          {
            activeInfo.key === 0 ?

              // 导学案展示效果
              <TemplateArea courseDetail={courseDetail} /> :

              activeInfo.key === 1 ?

                // 资源预览
                <ResourcePreview info={activeInfo?.val} /> :

                // 学生的作答区域
                <AnswerList courseDetail={courseDetail} />
          }
        </div>
      )}
      {courseDetail === undefined && <div className="no_data">暂无数据</div>}
      {/* 任务详情目录 */}
      {courseDetail && (
        <FunBar
          courseDetail={courseDetail}
          changeComponents={changeComponents}
        />
      )}
    </div>
  );
};

connect();

export default connect((state) => ({}), {})(AnswerWrapper);
