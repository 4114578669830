import "./index.less"
import presenter from "./presenter"
import { Button } from "antd"

const SubjectOperation = (props) => {
    const [states, events] = presenter(props)

    const { timeBucket, timeBucketActive, subjectList, subjectActive } = states;
    const { changeTimeBucket, changeSubject } = events

    const { exhibitionArea } = props

    return (
        <>
            <div className="SubjectOperationWrap">

                <div className="action">

                    <div className="timeBucket">
                        {timeBucket.map((item, index) => {
                            return (
                                <div  key={item.id} >
                                    <button className={timeBucketActive === index ? 'active' : ''} onClick={() => changeTimeBucket(index)}>{item.text}</button>
                                    <em className={timeBucket[index + 1] && timeBucketActive !== index && timeBucketActive !== (index + 1) ? "a" : "b"}>|</em>
                                </div>
                            )
                        })}
                    </div>

                    <div className="subjectNav">
                        <div className="hint">可点击切换学科</div>
                        <div className="nav">
                            {subjectList.map((item, index) => {
                                return (
                                    <div className={subjectActive === item.subjectId ? "subjectItem active" : "subjectItem"} key={item.subjectId} onClick={() => changeSubject(item.subjectId)}>
                                        {item.subjectName}{subjectList[index + 1] ? (<em>/</em>) : null}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>


                {exhibitionArea.length === 0 ? (
                    <div className="subjectNull">
                        <span className="icon">
                            <svg t="1679647133426" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="28951" width="20" height="20"><path d="M920.5 333.2c-22.6-53.4-55-101.5-96.2-142.7-41.2-41.2-89.2-73.6-142.7-96.2C626.2 70.9 567.5 59 506.8 59 446.2 59 387.4 70.9 332 94.3c-53.4 22.6-101.5 54.9-142.7 96.2-41.2 41.2-73.6 89.2-96.2 142.7C69.9 388.6 58 447.4 58 508c0 60.6 11.9 119.4 35.3 174.8 22.6 53.4 54.9 101.5 96.2 142.7 41.2 41.2 89.2 73.6 142.7 96.2C387.6 945.1 446.3 957 507 957c60.6 0 119.4-11.9 174.8-35.3 53.4-22.6 101.5-55 142.7-96.2 41.2-41.2 73.6-89.2 96.2-142.7C944.1 627.4 956 568.7 956 508c0-60.7-12.1-119.4-35.5-174.8zM506.9 892.1c-211.8 0-384.1-172.3-384.1-384.1s172.3-384.1 384.1-384.1S891 296.2 891 508 718.7 892.1 506.9 892.1z" p-id="28952"></path><path d="M227.3 333.5c5.1-4.6 11.8-7.3 19.1-7.3H378c15.7 0 28.5 12.8 28.5 28.5s-12.8 28.5-28.5 28.5h-66.9c-0.4 30.6-25.4 55.3-56.2 55.3-31 0-56.2-25.1-56.2-56.2 0.2-20.9 11.6-39.2 28.6-48.8zM551.6 327.3c2.5-0.7 5.2-1.1 8-1.1h131.7c15.7 0 28.5 12.8 28.5 28.5s-12.8 28.5-28.5 28.5h-72.2c-0.4 30.6-25.4 55.3-56.2 55.3-31 0-56.2-25.1-56.2-56.2 0.1-27.2 19.4-49.8 44.9-55zM600.1 637h-36 36zM595.5 615h-261c-17.4 0-31.5-14.1-31.5-31.5s14.1-31.5 31.5-31.5h261c17.4 0 31.5 14.1 31.5 31.5S612.9 615 595.5 615zM806.5 314.6c-5.4-6.7-15.5-6.7-20.9 0C764.7 340.5 720 399.8 720 432c0 42 34 76 76 76s76-34 76-76c0-32.2-44.7-91.5-65.5-117.4zM796 461c-18.7 0-33.9-15.2-33.9-33.9 0-11.7 13.3-31.5 23.2-44.7 5.4-7.1 16-7.1 21.4 0 9.9 13.2 23.2 33 23.2 44.7 0 18.7-15.2 33.9-33.9 33.9z" p-id="28953"></path></svg>
                        </span>
                        “{timeBucket[timeBucketActive].text}”内未有考试
                    </div>
                ) : null}
            </div>
        </>
    )
}

export default SubjectOperation