import react from "react";
import presenter from "./presenter";
import "./index.less";

const PadHeader = (props) => {
  const [states, events] = presenter(props);
  const { hideHead } = states;
  const { backHandle } = events;

  return (
    <div className="padHeader">
      {!hideHead && (
        <div className="iconP btn1">
          <i className="ri-arrow-left-line backIcon" onClick={backHandle}></i>
        </div>
      )}
      {!hideHead && <span className="pageName">{props.name || ""}</span>}
      <div className="rightFun">{props.children}</div>
    </div>
  );
};

export default PadHeader;
