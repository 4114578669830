import "./index.less";
import Presenter from "./presenter";
import { Image } from "antd";
import { Select } from "antd";
import MyPdfViewer from "./pdf.jsx";
import { isApp } from "@/util/util";
import AudioPlayer from "@/components/memorizeWords/audioPlayer"
import VideoPlayer from "@/components/memorizeWords/videoPlayer"

const ResourcePreview = (props) => {
  const [
    {
      collectState,
      file,
      pageNumber,
      numPages,
      isUsePdfPlugin,
      scalingNum,
      rotateNum,
      docWidth,
      docHeight,
      docRef,
    },
    {
      getOfficeI,
      handleCollect,
      previewImageClicked,
      onDocumentLoadSuccess,
      onDocumentLoadError,
      upPage,
      downPage,
      onChangeInput,
      switchPdfType,
      scalingPage,
      rotatePage,
      getBase64Url,
      look365File,
      onChangePage,
      downLoadResource,
      checkUrl,
      audioError,
      onDocumentLoadFail,
    },
  ] = Presenter(props);
  // 通过判断展示不同文件
  const resourcesType = () => {
    let url = checkUrl(file.convertResourceUrl || file.resourceUrl);
    if (url && url.toLowerCase().indexOf(".mp4") > -1) {
      // mp4
      return (
        <VideoPlayer url={url} onError={audioError} className="videoContent" />
      );
    } else if (url && url.toLowerCase().indexOf(".mp3") > -1) {
      // mp3
      return (
        <div className="audio-cont">
          <AudioPlayer controls url={url} style={{ width: '94%', marginTop: 'calc(20vh / 12)' }} sourceName={file.name} error={audioError} />
        </div>
      );
    } else if (
      url &&
      url.toLowerCase().indexOf(".pdf") > -1 &&
      isUsePdfPlugin
    ) {
      // pdf
      return (
        <div className="audio-cont" ref={docRef}>
          <MyPdfViewer
            pageIndex={file.pageIndex || 1}
            url={url}
            look365File={look365File}
            onChangePage={onChangePage}
            onDocumentLoadFail={onDocumentLoadFail}
          ></MyPdfViewer>
        </div>
      );
    } else if (file.resourceType === 10) {
      // html片段
      return (
        <div
          className="remarks"
          dangerouslySetInnerHTML={{ __html: url }}
        ></div>
      );
    } else if (url && /\.(gif|jpg|jpeg|png|GIF|JPEG|JPG|PNG)$/.test(url)) {
      // 图片
      return (
        <div className="img-cont" onClick={() => previewImageClicked(file)}>
          <Image className="image" src={url} preview={false} />
        </div>
      );
    } else if (url) {
      // word ppt
      return (
        <div className="ow365_view" style={{ width: "100%", height: "100%" }}>
          {/* <div className="cover"></div> */}
          <iframe
            title="office"
            style={{ width: "100%", height: "100%" }}
            src={getBase64Url(file.resourceUrl)}
            frameBorder="0"
          ></iframe>
        </div>
      );
    }
  };

  return (
    <div className="resource-info-cont">
      {/* 收藏 */}
      {
        props?.collect !== false &&
        <div className="funbtn" onClick={handleCollect}>
          <i className={`ri-heart-3-${collectState ? 'fill' : 'line'}`} />
          <span>收藏</span>
        </div>
      }

      {/* 下载 */}
      {!isApp() && props?.download !== false && 
        <div className="funbtn downResource" onClick={downLoadResource}>
          <i className="ri-download-2-line"></i>
          <span>下载</span>
        </div>
      }

      {resourcesType()}
      <p className="file_name">
        {decodeURIComponent(file?.name?.replace(/%/g, "") || "")}
      </p>
    </div>
  );
};

export default ResourcePreview;
