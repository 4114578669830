import "./index.less"


function studyCard(props) {

    return (
        <div className={props.className ?`studyCardComponent ${props.className}` : `studyCardComponent`} style={props.style}>
            {props?.children}
        </div>
    )
}

export default studyCard