import Presenter from "./presenter.js";
import "./index.less";
import PadHeader from "@/components/padHeader";
import Control from "./compoents/control"
import ExamItem from "@/components/examItem"
import { getParams } from "@/util/util"

const calc = (px) => {
    const clientHeight = window.document.querySelector('body').clientHeight / 100
    return px * clientHeight / 12
}

const controlStyle = {
    position: 'absolute',
    right: calc(30),
    bottom: calc(30)
}

function MistakesRearrange(props) {
    const [states, events] = Presenter(props);

    const { wrongQuestionList, isSubmit, number, mistakesType, type, showAction, isNextButton, evaluate } = states
    const { onChangeNext, onChangeSubmit, mistakesChange, onChangeJudge, onChangePrev, isCollectChange } = events

    return (
        <>
            <div className="StudentMistakesRearrangeWrap">
                <PadHeader>
                    <div className="header">
                        <div className="title">错题重做</div>
                    </div>
                </PadHeader>

                <div className="contentBox">
                    {
                        // 错题提交后作答状态  1 正确   2 半对   3 错误
                        isSubmit &&
                        <div className="evaluate">
                            {evaluate === 1 && <img src={require("@/assets/errorsBook/check_1.png").default} alt="_" />}
                            {evaluate === 2 && <img src={require("@/assets/errorsBook/check_2.png").default} alt="_" />}
                            {evaluate === 3 && <img src={require("@/assets/errorsBook/check_3.png").default} alt="_" />}
                        </div>
                    }

                    {/* 题目整体内容板块区分 */}
                    {
                        wrongQuestionList ?
                            <ExamItem
                                index={number}
                                examTypeId={wrongQuestionList.resource?.exam?.examTypeId}
                                exam={wrongQuestionList}
                                isSubmit={isSubmit}
                                isCorrect={isSubmit}
                                isNotCollect={isSubmit}
                                iscollect={wrongQuestionList.collection}
                                isCollectChange={isCollectChange}
                                isMistakes={mistakesChange}
                                wrongRate={wrongQuestionList.wrongRate} />
                            :
                            <div className="loding"></div>
                    }

                    {/* 操作 */}
                    <Control
                        index={number}
                        style={controlStyle}
                        onChangeSubmit={onChangeSubmit}
                        onChangeNext={onChangeNext}
                        onChangePrev={onChangePrev}
                        onChangeJudge={onChangeJudge}
                        isAnswer={getParams(window.location.href).isAnswer === '0'}
                        isSubmit={isSubmit}
                        type={type}
                        mistakesType={mistakesType}
                        showAction={showAction}
                        isNextButton={isNextButton}
                    />
                </div>
            </div>
        </>
    )
}

export default MistakesRearrange