/*
 * @Author: your name
 * @Date: 2021-07-22 22:59:21
 * @LastEditTime: 2022-09-18 10:02:52
 * @LastEditors: c1107 chen1997
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/pages/studentAnswer/components/examFeature/presenter.js
 */

import { useEffect, useState, useRef } from "react";
import { setTimeFormater } from "@/util/util";
import { message } from "antd";
import { courseAnswerSituation } from "@/api/answer";
import { selfLessonSubmit } from "@/api/autonomyPractice";
import store from "@/redux/store";

import { submitCourseAnswer } from "@/api/answer";
import { getParams } from "@/util/util";
import { androidHandle } from "@/util/android";
import pubsub from "@/util/pubSub";
import { selfLearnCorrect } from "@/api/autonomyPractice";
import { withSuccess } from "antd/lib/modal/confirm";

const Presenter = (props) => {
  const [countExam, setcountExam] = useState(0);
  // const [answerCount, setanswerCount] = useState(0);
  const [isShow, setisShow] = useState(false);
  const [isSubmit, setisSubmit] = useState(false); // 是否提交
  const [isShowCheck, setIsShowCheck] = useState(false); // 是否显示批改按钮
  const [examList, setExamList] = useState([]); //答题卡列表
  const [num, setNum] = useState(0);
  useEffect(() => {
    const { countExam } = store.getState().courseInfo;

    setisSubmit(true);
    setcountExam(countExam);
    // setanswerCount(answerCount);
  }, [props.courseInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // 第一次进来
    if (!store.getState().answerStates.length) {
      setTimeout(() => {
        getAnswerCard(); //1表示第一次进来
      }, 0);
      setisShow(!isShow);
    } else {
      // isShowBtnApi();
      getAnswerCardGIao();
    }
  }, [store.getState().nowExamIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getAnswerCardGIao();
  }, [num]); // eslint-disable-line react-hooks/exhaustive-deps

  const isShowBtnApi = () => {
    // let num = store.getState().nowExamIndex;
    let type =
      store.getState().answerStates[store.getState().nowExamIndex]
        .correctState === 0
        ? true
        : false;
    console.log(store.getState().answerStates[store.getState().nowExamIndex]);
    if (store.getState().answerStates.length) {
      if (type) {
        setIsShowCheck(true);
        // alert("B我草");
      } else {
        setIsShowCheck(false);
      }
    }
  };

  const getAnswerCard = () => {
    let obj = getParams(window.location.href);
    courseAnswerSituation({
      id: store.getState().courseInfo.id || obj.id,
    }).then((res) => {
      store.dispatch({
        type: "updateAnswerStates",
        data: res,
      });
      setExamList(res);
      if (res.length && store.getState().nowExamIndex === 0) {
        if (res[0]?.correctState === 0) {
          // alert("A我草");
          setIsShowCheck(true);
        }
      }
    });
  };

  const getAnswerCardGIao = () => {
    let obj = getParams(window.location.href);
    courseAnswerSituation({
      id: store.getState().courseInfo.id || obj.id,
    }).then((res) => {
      store.dispatch({
        type: "updateAnswerStates",
        data: res,
      });
      setExamList(res);
      if (res.length) {
        if (res[store.getState().nowExamIndex].correctState === 0) {
          setIsShowCheck(true);
          // alert("B我草");
        } else {
          setIsShowCheck(false);
        }
      }
    });
  };

  const checkExam = (type) => {
    let a = num + 1;
    setNum(a);
    let obj = getParams(window.location.href);
    selfLearnCorrect({
      lessonStudentId: store.getState().courseInfo.id || obj.id,
      seq: store.getState().nowExamIndex + 1,
      state: type,
    }).then((res) => {
      message.success("核对成功！");
    });
  };

  const changeCardStatus = () => {
    setisShow(!isShow);
  };

  return [
    {
      countExam,
      isShow,
      isSubmit,
      isShowCheck,
    },
    {
      changeCardStatus,
      checkExam,
    },
  ];
};

export default Presenter;
