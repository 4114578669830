import "./index.less"
import PadHeader from "@/components/padHeader";
import Presenter from "./presenter.js";
import ScrollView from "@/components/memorizeWords/scrollView"
import { getParams } from "@/util/util"
import Empty from "@/components/memorizeWords/empty"
import { useHistory } from 'react-router-dom'


function WordEnglishCorner(props) {
    const history = useHistory()
    const query = getParams(window.location.href);
    const [states, events] = Presenter(props)
    const { wordStudyRank, studentStaticData, learnDynamicsList, text } = states
    const { wordLearnLikeChange, loadMoreLearnDynamicsList } = events

    return (
        <div className="WordEnglishCornerPage">
            <PadHeader>
                <div className="pageHeaderBar">
                    <div className="title">英语角</div>
                    <div className="right">{text}</div>
                </div>
            </PadHeader>
            <div className="substanceBox">
                <div className="left">
                    <div className="top">

                        <div className="studyCard">
                            <div className="title">学习榜</div>
                            <ScrollView className="list" height="calc(100% - (40vh / 12))">
                                {
                                    (wordStudyRank?.learnRanks || []).map((v, i) =>
                                        <div className="item" key={i}>
                                            <div className="rank">
                                                {
                                                    i === 0 ? <img src={require(`@/assets/memorizeWords/NO.1.svg`).default} alt="_" /> :
                                                        i === 1 ? <img src={require(`@/assets/memorizeWords/NO.2.svg`).default} alt="_" /> :
                                                            i === 2 ? <img src={require(`@/assets/memorizeWords/NO.3.svg`).default} alt="_" /> :
                                                                i + 1
                                                }
                                            </div>
                                            <div className="name">{v?.studentName || '--'}</div>
                                            <div className="count">{v?.count}词</div>
                                        </div>
                                    )
                                }
                                <Empty visible={!wordStudyRank?.learnRanks || wordStudyRank?.learnRanks?.length === 0}>
                                    <img src={require(`@/assets/memorizeWords/empty-2.png`).default} style={{ width: "calc(114vh / 12)" }} alt="_" />
                                </Empty>
                            </ScrollView>
                        </div>
                        <div className="studyCard">
                            <div className="title">正确率榜</div>
                            <ScrollView className="list" height="calc(100% - (40vh / 12))">
                                {
                                    (wordStudyRank?.correctRanks || []).map((v, i) =>
                                        <div className="item" key={i}>
                                            <div className="rank">
                                                {
                                                    i === 0 ? <img src={require(`@/assets/memorizeWords/NO.1.svg`).default} alt="_" /> :
                                                        i === 1 ? <img src={require(`@/assets/memorizeWords/NO.2.svg`).default} alt="_" /> :
                                                            i === 2 ? <img src={require(`@/assets/memorizeWords/NO.3.svg`).default} alt="_" /> :
                                                                i + 1
                                                }
                                            </div>
                                            <div className="name">{v?.studentName || '--'}</div>
                                            <div className="count">{(v?.correctRate * 100).toFixed(0)}%</div>
                                        </div>
                                    )
                                }
                                <Empty visible={!wordStudyRank?.correctRanks || wordStudyRank?.correctRanks?.length === 0}>
                                    <img src={require(`@/assets/memorizeWords/empty-2.png`).default} style={{ width: "calc(114vh / 12)" }} alt="_" />
                                </Empty>
                            </ScrollView>
                        </div>
                        <div className="studyCard">
                            <div className="title">复习榜</div>
                            <ScrollView className="list" height="calc(100% - (40vh / 12))">
                                {
                                    (wordStudyRank?.reviewRanks || []).map((v, i) =>
                                        <div className="item" key={i}>
                                            <div className="rank">
                                                {
                                                    i === 0 ? <img src={require(`@/assets/memorizeWords/NO.1.svg`).default} alt="_" /> :
                                                        i === 1 ? <img src={require(`@/assets/memorizeWords/NO.2.svg`).default} alt="_" /> :
                                                            i === 2 ? <img src={require(`@/assets/memorizeWords/NO.3.svg`).default} alt="_" /> :
                                                                i + 1
                                                }
                                            </div>
                                            <div className="name">{v?.studentName || '--'}</div>
                                            <div className="count">{v?.reviewCount}词</div>
                                        </div>
                                    )
                                }
                                <Empty visible={!wordStudyRank?.reviewRanks || wordStudyRank?.reviewRanks?.length === 0}>
                                    <img src={require(`@/assets/memorizeWords/empty-2.png`).default} style={{ width: "calc(114vh / 12)" }} alt="_" />
                                </Empty>
                            </ScrollView>
                        </div>
                        <div className="studyCard">
                            <div className="title">点赞榜</div>
                            <ScrollView className="list" height="calc(100% - (40vh / 12))">
                                {
                                    (wordStudyRank?.likeRanks || []).map((v, i) =>
                                        <div className="item" key={i}>
                                            <div className="rank">
                                                {
                                                    i === 0 ? <img src={require(`@/assets/memorizeWords/NO.1.svg`).default} alt="_" /> :
                                                        i === 1 ? <img src={require(`@/assets/memorizeWords/NO.2.svg`).default} alt="_" /> :
                                                            i === 2 ? <img src={require(`@/assets/memorizeWords/NO.3.svg`).default} alt="_" /> :
                                                                i + 1
                                                }
                                            </div>
                                            <div className="name">{v?.toStudentName || '--'}</div>
                                            <div className="count">{v?.count}赞</div>
                                        </div>
                                    )
                                }
                                <Empty visible={!wordStudyRank?.likeRanks || wordStudyRank?.likeRanks?.length === 0}>
                                    <img src={require(`@/assets/memorizeWords/empty-2.png`).default} style={{ width: "calc(114vh / 12)" }} alt="_" />
                                </Empty>
                            </ScrollView>
                        </div>

                    </div>
                    <div className="bottom">
                        <div className="personalLog">
                            <div className="title">
                                个人数据  <span>(每小时更新一次数据)</span>
                            </div>
                            <div className="content">
                                <div className="block">
                                    <span>累计学习 <em>{studentStaticData?.learnCount || 0}</em> 词</span>
                                    <span>学习榜第 <em>{studentStaticData?.learningRanking || 0}</em> 名</span>
                                    <span>累计获赞 <em>{studentStaticData?.likeCount || 0}</em> 个</span>
                                    <span>点赞榜第 <em>{studentStaticData?.likeRanking || 0}</em> 名</span>
                                    <span>正确率榜第 <em>{studentStaticData?.correctRanking || 0}</em> 名</span>
                                </div>
                                <div className="block">
                                    <span>累计复习 <em>{studentStaticData?.reviewCount || 0}</em> 词</span>
                                    <span>复习榜第 <em>{studentStaticData?.reviewRanking || 0}</em> 名</span>
                                    <span>最多一日学习 <em>{studentStaticData?.dayMaxWord || 0}</em> 词</span>
                                    <span>平均每天学习 <em>{studentStaticData?.avgWordCount || 0}</em> 词</span>
                                    <span>累计打卡 <em>{studentStaticData?.clockInDay || 0}</em> 次</span>
                                </div>
                                <div className="line" />

                                <div className="block">
                                    {
                                        studentStaticData?.likeTotal.slice(0, 6).map((v, i) =>
                                            <span key={i}>{v}</span>
                                        )
                                    }
                                    <Empty visible={!studentStaticData?.likeTotal || studentStaticData?.likeTotal?.length === 0}>
                                        <img src={require(`@/assets/memorizeWords/empty-3.png`).default} style={{ width: "calc(500vh / 12)", transform: 'translateY(-10%)' }} alt="_" />
                                    </Empty>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right">
                    <div className="studynamics">
                        <div className="title">
                            学习动态
                        </div>
                        <ScrollView height="calc(100% - (50vh / 12))" className="studyDynamics" onScrollBottom={loadMoreLearnDynamicsList} bottomDistance="10">
                            {
                                (learnDynamicsList || []).map((v, i) =>
                                    <div className="item" key={v.id}>
                                        <span className={`text ${v.studentId === Number(query?.studentId) ? 'me' : ''}`}>{v.content}</span>
                                        {
                                            v.studentId === Number(query?.studentId) ? null : <i className={v.count === 0 ? "mark" : "markActive"} onClick={() => wordLearnLikeChange(v, i)}></i>
                                        }
                                    </div>
                                )
                            }
                            <Empty visible={!learnDynamicsList || learnDynamicsList?.length === 0} >
                                <img src={require(`@/assets/memorizeWords/empty-1.png`).default} style={{ width: "calc(300vh / 12)", transform: 'translateY(-10%)' }} alt="_" />
                                <p className="empty-text">数据正在赶来中...</p>
                            </Empty>
                        </ScrollView>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default WordEnglishCorner