/*
 * @Author: your name
 * @Date: 2021-08-02 10:13:48
 * @LastEditTime: 2021-08-02 10:14:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/redux/reducers/answerStates.js
 */
import { UPDATEANSWERSTATES } from "../constant";

const initState = [];
export default function courseInfoReducer(preState = initState, action) {
  const { type, data } = action;
  switch (type) {
    case UPDATEANSWERSTATES:
      return data;
    default:
      return preState;
  }
}
