/*
 * @Author: your name
 * @Date: 2021-11-16 15:11:20
 * @LastEditTime: 2021-12-16 10:59:27
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \sdzn-student2.0\src\api\groupScoring.js
 */
import request from '../util/request';
// 任务详情接口
export function getGroupsRank(params) {
  return request({
    url: '/class/v2/teacher/student/place',
    method: 'get',
    params,
  });
}

// 小组排行页面
export function groupScoreRankList(params) {
  return request({
    url: '/class/v2/teacher/SchoolGroupScore/rank/list',
    method: 'get',
    params,
  });
}

// 学生所在 行政/学科组
export function getStudentGroup(params) {
  return request({
    url: '/class/v2/teacher/student/group',
    method: 'get',
    params,
  });
}

/*******************电子教材********************/
export function getElectronicBooks(params) {
  return request({
    url: '/class/v2/teacher/ebook/relation/list',
    method: 'get',
    params,
  });
}

export function updataBook(params) {
  return request({
    url: '/class/v2/relation/ebook/add',
    method: 'get',
    params,
  });
}
