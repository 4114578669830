/*
 * @Author: your name
 * @Date: 2021-06-25 13:45:56
 * @LastEditTime: 2022-09-22 11:12:43
 * @LastEditors: c1107 chen1997
 * @Description: In User Settings Edit
 * @FilePath: \project_manage\src\pages\editText\presenter.js
 */
import { useEffect, useState, useRef } from "react";
import { updateMark } from "@/api/answer";
import Range from "./unit/Range";
import store from "@/redux/store";

const EditText = (props) => {
  const { info } = props;

  const [titleStem, setTitleStem] = useState("");
  const examSteam = useRef(null);


  /**
   * 添加下划线
   */
  const addUnderline = () => {
    // replaceSelectedStrByEle("custom-underline"); // eslint-disable-line
  };

  /**
   * 启用荧光笔
   */
  const enableNiteWriterPen = () => {
    replaceSelectedStrByEle("nite-writer-pen"); // eslint-disable-line
  };

  /**
   * 用元素替换被选中的文本
   */
  const replaceSelectedStrByEle = (className) => {
    let getRange = () => {
      let me = window;
      let range = new Range(me.document);

      let sel = window.getSelection();
      if (sel && sel.rangeCount) {
        let firstRange = sel.getRangeAt(0);
        let lastRange = sel.getRangeAt(sel.rangeCount - 1);
        range
          .setStart(firstRange.startContainer, firstRange.startOffset)
          .setEnd(lastRange.endContainer, lastRange.endOffset);
        let domSteam = document.querySelector(".examStemCom");
        if (domSteam) {
          let val = domSteam.innerHTML;

          // if (
          //   val.indexOf("custom-underline") > -1 &&
          //   store.getState().examItem?.resource?.exam?.examTypeId !== 6
          // ) {
          //   updateMark({
          //     mark: val,
          //     lessonStudentId: store.getState().courseInfo.id,
          //     resourceId: store.getState().examItem?.resource?.exam?.id,
          //   });
          // }

        }
      }
      return range;
    };
    let range = getRange();
    range.applyInlineStyle("i", {
      class: className,
    });
    range.select();
  };

  // 监听鼠标抬起事件
  const touchendFn = (e) => {
    if (window.getSelection().toString().length > 0) {
      addUnderline();
    }
    setTitleStem(e.target.innerHTML);
  };

  // examSteam.current &&
  //   document.addEventListener('mousedown', () => {
  //     touchendFn({ target: examSteam });
  //   });
  if (examSteam.current) {
    let doms = document.querySelectorAll(".examInsideWrapper");
    for (let i = 0; i < doms.length; i++) {
      doms[i].addEventListener("mousedown", () => {
        touchendFn({ target: examSteam });
      });
    }
  }

  useEffect(() => {

    // 子传父，把改变的题干html导出
    if (titleStem && props.onChangeHtml) {
      props.onChangeHtml(titleStem);
    }
  }, [titleStem, props]);

  return [{ examSteam }, { addUnderline, enableNiteWriterPen, touchendFn }];
};

export default EditText;
