/*
 * @Description: 录音
 * @FilePath: \newGitZnxt2.0\src\pages\keyboardPage\presenter.js
 */

import { useState, useEffect } from 'react';

const Presenter = (props) => {
  const [recordType, setRecordType] = useState(1);
  const [mp3Time, setMp3Time] = useState('00:00');
  const { onTiggerRecord, onClose } = props;

  // let obj = getParams(window.location.href);

  // 改变录音dom样式
  const changeRecordType = (num) => {
    setRecordType(num);
  };

  // 录音时间
  let mp3TimeOut = null;
  let initTime = new Date().getTime();
  const setMp3TimeFn = () => {
    mp3TimeOut = setTimeout(() => {
      let newTime = new Date().getTime();
      let time = Math.floor((newTime - initTime) / 1000);
      let m = Math.floor(time / 60);
      let s = time % 60;
      if (s < 10) {
        s = '0' + s;
      }
      setMp3Time(m + ':' + s);
      setMp3TimeFn();
    }, 1000);
  };

  // 清楚时间增加
  const clearMp3Time = () => {
    clearTimeout(mp3TimeOut);
    mp3TimeOut = null;
  };

  useEffect(() => {
    if (recordType === 2) {
      // 开录音
      onTiggerRecord(1);
      setTimeout(() => {
        setMp3TimeFn();
      }, 500);
    } else if (recordType === 3) {
      onTiggerRecord(0);
      clearMp3Time();
      onClose(false);
    }
  }, [recordType]); // eslint-disable-line react-hooks/exhaustive-deps

  return [{ recordType, mp3Time }, { changeRecordType }];
};

export default Presenter;
