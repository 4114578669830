import "./index.less";
import Presenter from "./presenter.js";
import Solution from "../solution";
import ScrollView from "@/components/memorizeWords/scrollView"

import {
    DownOutlined,
    UpOutlined
} from '@ant-design/icons';

import { Modal } from 'antd';



const calc = (px) => {
    const clientHeight = window.document.querySelector('body').clientHeight / 100
    return px * clientHeight / 12
}

const ABC = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'G', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
function TopicItem(props) {
    const { info, exam, index, showWitchOperate, showSolution, addMistakeBook } = props
    const [states, events] = Presenter(props);

    const { isShowAll, isShowRemoveModal, isShowChangeLabelModal, isShowEditLabelInput, createLabelInputDom, allLabelList, searchLabelInputDom, checkedLabelList } = states
    const { changeIsShowAll, setRemoveInfoChange, setRemoveInfoOK, setRemoveCancel, changeIsShowLabel, changeIsShowLabelInput, createNewLabelHandle, getWantLabelList, changeCheckedLabelHandle } = events

    return (
        <>
            <div className="TopicItem">
                <div className="serial">
                    <span>{index + 1}.</span>
                </div>
                <div className="container">
                    <div className="text" style={isShowAll ? { minHeight: calc(280) } : { height: calc(280) }}>
                        {
                            // 题目
                            exam?.examStem ? <span dangerouslySetInnerHTML={{ __html: exam?.examStem }}></span> : null
                        }
                        {
                            // 判断是否是填空题
                            exam?.examTypeId === 6 ? null :
                                // 选项
                                exam?.examOptions ? exam?.examOptions.map((subItem, index) => (
                                    <div style={{ display: "flex", marginTop: calc(20) }} key={index}>
                                        <span>{ABC[subItem.seq - 1]}. </span>
                                        <span key={index} dangerouslySetInnerHTML={{ __html: subItem.content }}></span>
                                    </div>
                                )) : null
                        }
                        {
                            // 判断是否还有小题
                            exam?.children ? exam?.children.map((subItem, index) => {
                                return (<div key={index} style={{ marginTop: calc(20) }}>
                                    <span style={{ display: "flex" }}> <span> {index + 1}.</span> <span dangerouslySetInnerHTML={{ __html: subItem.examStem }}></span> </span>
                                    {
                                        exam?.examTypeId === 6 ? null :
                                            subItem.examOptions ? subItem.examOptions.map((sunItem, index) => (
                                                <div style={{ display: "flex" }} key={index}>
                                                    <span>{ABC[sunItem.seq - 1]}. </span>
                                                    <span key={index} dangerouslySetInnerHTML={{ __html: sunItem.content }}></span>
                                                </div>
                                            )) : null
                                    }
                                </div>)
                            }) : null
                        }
                        {
                            // 答案和解析
                            showSolution ? (<div className="solution">
                                <Solution examAnswer={exam?.examAnswer} examAnalysis={exam?.examAnalysis} />
                            </div>) : null
                        }
                    </div>

                    {
                        showWitchOperate === 1 ? (<>
                            {/* 已清 */}
                            <div className="operate">
                                <div className="left">
                                    <span>{info?.examTypeName}</span><em>/</em>
                                    <span>{info?.createTime}</span>
                                    {/* <span>已清</span> */}
                                    {
                                        checkedLabelList.map((v, i) => <span key={i}><em>/</em><span>{v.labelName}</span></span>)
                                    }
                                </div>
                                <div className="right">
                                    <span style={{ color: '#0043f3' }} onClick={() => changeIsShowLabel(true)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="calc(15vh / 12)" height="calc(18vh / 12)" viewBox="0 0 15 18" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M13.9924 17.8882C14.1011 17.9611 14.2295 18 14.3579 18C14.4567 18 14.5604 17.9757 14.6543 17.9271C14.8666 17.8201 15 17.6063 15 17.3681V2.47907C15 1.11315 13.8689 0 12.4811 0H2.51893C1.13105 0 0 1.11315 0 2.47907V17.2368C0 17.4702 0.133355 17.684 0.345736 17.7958C0.558117 17.9028 0.81001 17.8882 1.00757 17.7521L7.39875 13.3724L13.9924 17.8882ZM3.58579 6.41421C3.21071 6.03914 3 5.53043 3 5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3C5.53043 3 6.03914 3.21071 6.41421 3.58579C6.78929 3.96086 7 4.46957 7 5C7 5.53043 6.78929 6.03914 6.41421 6.41421C6.03914 6.78929 5.53043 7 5 7C4.46957 7 3.96086 6.78929 3.58579 6.41421Z" fill="#0043F2" />
                                        </svg>&nbsp;打标签
                                    </span>
                                    <span onClick={() => setRemoveInfoChange(info)}>
                                        <svg t="1681974931834" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4344" width={calc(25)} height={calc(25)}><path d="M847.743 223.953H640.639c-3.132-68.921-60.177-124.029-129.858-124.029s-126.726 55.108-129.858 124.029H173.256c-17.673 0-32 14.327-32 32s14.327 32 32 32h674.487c17.673 0 32-14.327 32-32s-14.327-32-32-32z m-336.962-60.03c34.379 0 62.689 26.426 65.718 60.029H445.064c3.029-33.603 31.338-60.029 65.717-60.029zM767.743 351.79c-17.673 0-32 14.327-32 32v478.173H288.256V383.79c0-17.673-14.327-32-32-32s-32 14.327-32 32v510.173c0 17.673 14.327 32 32 32h511.487c17.673 0 32-14.327 32-32V383.79c0-17.673-14.327-32-32-32z" fill="#707070" p-id="4345"></path><path d="M449.306 732.802V448.208c0-17.673-14.327-32-32-32s-32 14.327-32 32v284.593c0 17.673 14.327 32 32 32s32-14.326 32-31.999zM640.84 732.802V448.208c0-17.673-14.327-32-32-32s-32 14.327-32 32v284.593c0 17.673 14.327 32 32 32s32-14.326 32-31.999z" fill="#707070" p-id="4346"></path></svg>
                                        删除此题
                                    </span>
                                    {/* <span>
                                        <svg t="1681974570787" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3264" width={calc(25)} height={calc(25)}><path d="M627.2 171.264a25.6 25.6 0 0 1 16.768 6.2464l319.3088 276.736a25.6 25.6 0 0 1 0 38.7072l-319.3088 276.736a25.6 25.6 0 0 1-42.368-19.3536V614.4H435.2c-167.9872 0-310.784 107.8784-362.9056 258.1248A408.9344 408.9344 0 0 1 51.2 742.4c0-226.2272 183.3728-409.6 409.6-409.6h140.8V196.864a25.6 25.6 0 0 1 25.6-25.6z" p-id="3265" fill="#8a8a8a"></path></svg>
                                        重做此题
                                    </span> */}
                                    {
                                        isShowAll ?
                                            <span style={{ color: "#FA541C" }} onClick={() => changeIsShowAll(false)}>收起解析 <UpOutlined /></span> :
                                            <span style={{ color: "#FA541C" }} onClick={() => changeIsShowAll(true)}>查看解析 <DownOutlined /></span>
                                    }
                                </div>
                            </div>
                        </>) : null
                    }

                    {
                        showWitchOperate === 2 ? (
                            <>
                                {/* 未清 */}
                                <div className="operate">
                                    <div className="left">
                                        <span>{info.examTypeName}</span><em>/</em>
                                        <span>{info.createTime}</span>
                                        {
                                            checkedLabelList.map((v, i) => <span key={i}><em>/</em><span>{v.labelName}</span></span>)
                                        }
                                    </div>
                                    <div className="right">
                                        <span style={{ color: '#0043f3' }} onClick={() => changeIsShowLabel(true)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="calc(15vh / 12)" height="calc(18vh / 12)" viewBox="0 0 15 18" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M13.9924 17.8882C14.1011 17.9611 14.2295 18 14.3579 18C14.4567 18 14.5604 17.9757 14.6543 17.9271C14.8666 17.8201 15 17.6063 15 17.3681V2.47907C15 1.11315 13.8689 0 12.4811 0H2.51893C1.13105 0 0 1.11315 0 2.47907V17.2368C0 17.4702 0.133355 17.684 0.345736 17.7958C0.558117 17.9028 0.81001 17.8882 1.00757 17.7521L7.39875 13.3724L13.9924 17.8882ZM3.58579 6.41421C3.21071 6.03914 3 5.53043 3 5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3C5.53043 3 6.03914 3.21071 6.41421 3.58579C6.78929 3.96086 7 4.46957 7 5C7 5.53043 6.78929 6.03914 6.41421 6.41421C6.03914 6.78929 5.53043 7 5 7C4.46957 7 3.96086 6.78929 3.58579 6.41421Z" fill="#0043F2" />
                                            </svg>&nbsp;打标签
                                        </span>
                                        {
                                            isShowAll ?
                                                <span style={{ color: "#FA541C" }} onClick={() => changeIsShowAll(false)}>部分收起 <UpOutlined /></span> :
                                                <span style={{ color: "#FA541C" }} onClick={() => changeIsShowAll(true)}>全部展开 <DownOutlined /></span>
                                        }
                                    </div>
                                </div>
                            </>
                        ) : null
                    }

                    {
                        showWitchOperate === 3 ? (
                            <>
                                {/* 我的试卷 查看试卷 */}
                                <div className="operate">
                                    <div className="left"><span>{exam?.examTypeId === 4 || exam?.examTypeId === 16 ? '主观题' : '客观题'}</span><em>/</em><span>{info?.score + '分'}</span>
                                    </div>
                                    <div className="right">
                                        {
                                            isShowAll ?
                                                <span onClick={() => changeIsShowAll(false)}>
                                                    <svg t="1684739233812" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="16813" width={calc(30)} height={calc(30)}><path d="M832.522014 669.951405c0-8.782014-3.235694-17.565051-9.708106-24.26873L535.638373 348.198354c-12.932544-13.408381-33.9226-13.408381-46.855144 0l-287.172465 297.485345c-12.9438 13.408381-12.9438 35.130102 0 48.53746 12.932544 13.408381 33.9226 13.408381 46.855144 0l263.744893-273.215592 263.747963 273.215592c12.932544 13.408381 33.9226 13.408381 46.855144 0C829.28632 687.516456 832.522014 678.734442 832.522014 669.951405z" p-id="16814" fill="#969799"></path></svg>
                                                    收起解析
                                                </span> :
                                                <span onClick={() => changeIsShowAll(true)}>
                                                    <svg t="1684739150573" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="15792" width={calc(30)} height={calc(30)}><path d="M507.8 727.728a30.016 30.016 0 0 1-21.288-8.824L231.104 463.496a30.088 30.088 0 0 1 0-42.568 30.088 30.088 0 0 1 42.568 0l234.128 234.128 234.16-234.128a30.088 30.088 0 0 1 42.568 0 30.088 30.088 0 0 1 0 42.568L529.08 718.904a30 30 0 0 1-21.28 8.824z" fill="#969799" p-id="15793"></path></svg>
                                                    展开全部
                                                </span>
                                        }
                                        <span className={info.errorType === 0 ? "addMistakesBookBtn" : "removeMistakesBookBtn"} onClick={() => addMistakeBook(info)}>{info.errorType === 0 ? '加入错题本' : '移出错题本'}</span>
                                    </div>
                                </div>
                            </>
                        ) : null
                    }
                </div>

                <Modal visible={isShowRemoveModal} wrapClassName="submitModal" closable={false} closeIcon={null} centered maskClosable footer={null}>
                    <div className="SubmitModalText" >删除后无法撤回，确定删除此错题？</div>
                    <div className="btn">
                        <div className="goOn" onClick={setRemoveCancel}>取消</div>
                        <div className="over" onClick={setRemoveInfoOK}>确认删除</div>
                    </div>
                </Modal>

                <Modal visible={isShowChangeLabelModal} wrapClassName="labelModal" centered width="calc(760vh / 12)" title={null} footer={null} closeIcon={null} onCancel={() => changeIsShowLabel(false)}>
                    <div className="title">打标签</div>
                    <div className="content">
                        <input type="text" className="labelInput" placeholder="输入关键字搜索标签" ref={searchLabelInputDom} onChange={(e) => getWantLabelList(e.target.value)} />
                        <ScrollView className="labelList">
                            {
                                allLabelList.map(v =>
                                    <div className={`labelBtn${checkedLabelList.some(s => s.id === v.id) ? ' active' : ''}`} key={v.id} onClick={() => changeCheckedLabelHandle(v)}>{v.labelName}</div>
                                )
                            }
                        </ScrollView>
                    </div>
                    <div className="footer">
                        <div className="createLabel">
                            {
                                isShowEditLabelInput ?
                                    <div className="edit">
                                        <input type="text" placeholder="请输入标签名" onBlur={() => setTimeout(() => changeIsShowLabelInput(false), 200)} ref={createLabelInputDom} />
                                        <div className="cancel">取消</div>
                                        <div className="ok" onClick={createNewLabelHandle}>确认</div>
                                    </div> :
                                    <div className="face" onClick={() => changeIsShowLabelInput(true)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="calc(28vh /12)" height="calc(28vh / 12)" viewBox="0 0 28 28" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM14 7C13.2268 7 12.6 7.6268 12.6 8.4V12.6H8.4C7.6268 12.6 7 13.2268 7 14C7 14.7732 7.6268 15.4 8.4 15.4H12.6V19.6C12.6 20.3732 13.2268 21 14 21C14.7732 21 15.4 20.3732 15.4 19.6V15.4H19.6C20.3732 15.4 21 14.7732 21 14C21 13.2268 20.3732 12.6 19.6 12.6H15.4V8.4C15.4 7.6268 14.7732 7 14 7Z" fill="#0043F2" />
                                        </svg>
                                        新建标签
                                    </div>
                            }
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    )
}

export default TopicItem