import { useEffect, useRef, useState } from "react";

const Presenter = (props) => {
    const {semesterTrunk} = props

    let list = useRef([])
    let [pages, setPages] = useState(0)
    let [size, setSize] = useState(10)
    const [semesterTrunkList, setSemesterTrunkList] = useState([])
    const [isNext, setIsNext] = useState(false)
    const [isPrev, setIsPrev] = useState(false)

    const [active, setActive] = useState(0)

    const setPagesList = () => {
        setSemesterTrunkList(list.current.slice(pages * size, (pages + 1) * size))
        setIsNext(list.current[(pages + 1) * size] ? true : false)
        setIsPrev(pages === 0 ? false : true)
    }



    useEffect(() => {
        list.current = semesterTrunk
        if (list.current) {
            setSemesterTrunkList(list.current.slice(pages * size, (pages + 1) * size))
            setIsNext(list.current[(pages + 1) * size] ? true : false)
            setIsPrev(pages === 0 ? false : true)
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pages, size])

    useEffect(() => {
        setActive(semesterTrunk[0].id) 
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const cutPages = (dire) => {
        if (dire === "prev") {
            if (isPrev) {
                setPages(pages - 1)
                setPagesList()
            }
        } else if (dire === "next") {
            if (isNext) {
                setPages(pages + 1)
                setPagesList()
            }
        }
    }

    const chengActive = (index) => {
        setActive(index)
    }


    return [
        { semesterTrunkList, isNext, isPrev, active },
        { cutPages, chengActive }
    ]
}

export default Presenter