/*
 * @Author: your name
 * @Date: 2021-08-11 09:40:17
 * @LastEditTime: 2021-08-24 20:15:11
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \znxt-student2.0\src\pages\cooperativeInquiry\presenter.js
 */
import { cooperativeInquiry, upDataImgs, allStudents } from '@/api/cooperativeInquiry';
import { useState, useEffect } from 'react';
import { androidHandle } from '@/util/android';
import { getParams } from '@/util/util';
import { message } from 'antd';

const Presenter = () => {
  const [exploreData, setExploreData] = useState({});
  const [imgsData, setImgsData] = useState([]);
  const [uploadImgsData, setUploadImgsData] = useState([]);
  const [students, setStudents] = useState([]);
  const [showGroupsStudents, setShowGroupsStudents] = useState(false);
  const [subTimeOut, setSubTimeOut] = useState('00:00');
  let queryObj = getParams(window.location.href);

  // 获取详情
  const exploreDetails = () => {
    let params = {
      id: queryObj?.id,
      groupId: queryObj?.groupId,
    };
    cooperativeInquiry(params).then((res) => {
      setExploreData(res);

      const imgs = res.groupTaskResultRpcDtos?.map((item) => {
        return item.ext;
      });
      const imgsArr = imgs.join(',')?.split(',');
      if (imgs?.length > 0) setImgsData(imgsArr);
    });
  };

  // 上传图片
  const upLoadImgs = () => {
    const upload = 4 - imgsData?.length;
    if (upload < 1) {
      message.error('同一小组最多上传4张图片');
      return;
    }
    androidHandle('chooseImgs', upload);
  };

  // 安卓返回图片
  window.sendImgs = (data) => {
    let imgKeys = data?.split(',') || [];
    setUploadImgsData(imgKeys);
  };

  useEffect(() => {
    uploadImgsData?.length > 0 && saveImgs();
  }, [uploadImgsData]); // eslint-disable-line react-hooks/exhaustive-deps

  // 实时保存接口
  const saveImgs = () => {
    let params = {
      groupTaskPublishId: queryObj?.groupTaskPublishId,
      groupTaskId: queryObj?.groupTaskId,
      ext: uploadImgsData.join(','),
      type: 1,
      groupId: queryObj?.groupId,
      groupName: decodeURIComponent(queryObj?.groupName),
      studentName: decodeURIComponent(queryObj?.studentName),
      studentId: queryObj?.studentId,
    };
    upDataImgs(params).then(() => {
      exploreDetails();
    });
  };
  // 获取小组人员
  const getAllStudents = () => {
    let params = {
      groupId: queryObj?.groupId,
      page: 1,
      limit: 300,
    };
    allStudents(params).then((res) => {
      setStudents(res.records);
    });
  };

  // 显示隐藏学生
  const changeShowGroupsStudents = () => {
    setShowGroupsStudents(!showGroupsStudents);
  };

  // 定时器
  let timer = null;
  // 可用于教师主动停止讨论
  const clearTime = () => {
    clearTimeout(timer);
    timer = null;
  };
  const submitTimeout = () => {
    let now = new Date().getTime();
    let end = new Date(exploreData?.endTime);
    let leftTime = end - now; //时间差
    if (leftTime >= 0) {
      let hour = Math.floor(leftTime / 1000 / 60 / 60);
      let minute = Math.floor((leftTime / 1000 / 60) % 60);
      let second = Math.floor((leftTime / 1000) % 60);
      if (second < 10) {
        second = '0' + second;
      }
      if (minute < 10) {
        minute = '0' + minute;
      }
      if (hour < 10) {
        hour = '0' + hour;
      }
      setSubTimeOut(minute + ':' + second);
      timer = setTimeout(submitTimeout, 1000);
    } else {
      clearTimeout(timer);
      timer = null;
      setSubTimeOut(null);
    }
  };

  useEffect(() => {
    submitTimeout();
  }, [exploreData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    exploreDetails();
    getAllStudents();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return [
    { exploreData, imgsData, students, showGroupsStudents, subTimeOut, queryObj },
    { upLoadImgs, saveImgs, changeShowGroupsStudents },
  ];
};

export default Presenter;
