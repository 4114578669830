/*
 * @Author: your name
 * @Date: 2021-08-05 18:37:00
 * @LastEditTime: 2021-08-16 20:57:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \znxt2.0\src\components\subjectSelect\presenter.js
 */

import React from "react";

import { useState, useEffect } from "react";

const Presenter = () => {
  let typeListTwo = [
    {
      text: "题干有误",
      type: 1,
    },
    {
      text: "题干有歧义",
      type: 2,
    },
    {
      text: "题干缺失不全",
      type: 3,
    },
    {
      text: "题干展示错乱",
      type: 4,
    },
    {
      text: "答案错误",
      type: 5,
    },
    {
      text: "其他",
      type: 6,
    },
  ];
  const [typeList, setType] = useState(typeListTwo);

  useEffect(() => {}, []);
  return [{ typeList }, {}];
};

export default Presenter;
