import React, { useState, useEffect, useRef } from "react";
import { usePdf } from "@mikecousins/react-pdf";
import "./index.less";
import { Select } from "antd";
const { Option } = Select;

const MyPdfViewer = (props) => {
  const [page, setPage] = useState(props.pageIndex || 1);
  const [rotate, setrotates] = useState(0);
  const [scale, setscale] = useState(1);
  const canvasRef = useRef(null);

  const { pdfDocument, pdfPage } = usePdf({
    file: props.url,
    page,
    canvasRef,
    scale,
    rotate,
    onDocumentLoadFail: () => {
      props.onDocumentLoadFail();
    },
    // workerSrc: "http://file.znclass.com/pdf.worker.js",
    workerSrc:
      process.env.REACT_APP_ENV === "production"
        ? `./pdf.worker.js`
        : `/pdf.worker.js`,
  });

  useEffect(() => {
    setPage(props.pageIndex);
  }, [props.url]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    props?.onChangePage && props?.onChangePage(page, pdfDocument);
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeInput = (val) => {
    console.log(val);
    setPage(val);
  };
  const scalingPage = (flag) => {
    let n;
    if (flag) {
      n = scale + 0.3;
    } else {
      n = scale - 0.3;
    }
    setscale(n);
  };

  const resetPahe = (val) => {
    if (val) {
      setPage(
        page + 1 <= pdfDocument.numPages ? page + 1 : pdfDocument.numPages
      );
    } else {
      setPage(page - 1 >= 1 ? page - 1 : 1);
    }
  };

  const rotatePage = () => {
    let n = rotate + 90;
    setrotates(n);
  };

  return (
    <div className="pdfWrapper">
      {!pdfDocument && <span>Loading...</span>}
      <canvas ref={canvasRef} width={2000} height={2000} />
      {Boolean(pdfDocument && pdfDocument.numPages) && (
        <div>
          <div className="pdfControl1">
            <span className="nowPage">
              {/* 当前为:&nbsp;{pdfDocument.numPages} */}
              当前为:&nbsp;{page}
              <Select
                defaultValue={1}
                value={page}
                style={{ width: 70 }}
                onChange={onChangeInput}
              >
                {new Array(pdfDocument.numPages).fill(1).map((v, i) => (
                  <Option key={i} value={i + 1}>
                    {i + 1}
                  </Option>
                ))}
              </Select>
              &nbsp; /&nbsp;{pdfDocument.numPages}
            </span>
          </div>

          <div className="pdfControl">
            <span className="btnItem" onClick={props.look365File}>
              查看原文件
            </span>
            &nbsp; &nbsp;
            <span className="btnItem" onClick={() => rotatePage()}>
              旋转
            </span>
            &nbsp; &nbsp;
            <span className="btnItem" onClick={() => scalingPage(true)}>
              放大
            </span>
            &nbsp; &nbsp;
            <span className="btnItem" onClick={() => scalingPage(false)}>
              缩小
            </span>
            &nbsp; &nbsp;
            <span className="btnItem" onClick={() => resetPahe(0)}>
              上一页
            </span>
            &nbsp; &nbsp;
            <span className="btnItem" onClick={() => resetPahe(1)}>
              下一页
            </span>
          </div>
        </div>
      )}
    </div>
    // <div className="pdfWrapper">
    //   <iframe
    //     frameborder="0"
    //     scrolling="auto"
    //     src="http://file.znclass.com/1665631364261带电粒子在匀强磁场中的运动强化训练.pdf"
    //     type="application/x-google-chrome-pdf"
    //     width="100%"
    //     height="100%"
    //   />
    // </div>
  );
};
export default MyPdfViewer;
