/*
 * @Author: your name
 * @Date: 2021-12-03 09:53:09
 * @LastEditTime: 2022-04-27 18:20:48
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https:github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newGitZnxt2.0\src\pages\wordPK\presenter.js
 */

import { useState, useEffect, useRef } from "react";
import { getPKList } from "@/api/word";
import { getParams } from "@/util/util";
import data from "./data.json";
let redTimer = null;
let blueTimer = null;

const Presenter = (props) => {
  // 开始PK
  const [playGame, setPlayGame] = useState(false);
  // PK结束
  const [gameOver, setGameOver] = useState(false);

  // 红、篮当前的作答的题目序号
  const [redIndex, setredIndex] = useState(0);
  const [blueIndex, setblueIndex] = useState(0);

  // 整理后的试题列表
  const [examList, setexamList] = useState([]);
  // const dataList = useRef([]);

  // 得分
  const [redScore, setredScore] = useState(0);
  const [blueScore, setblueScore] = useState(0);

  // 时间
  const [redAnswerTime, setRedAnswerTime] = useState(0);
  const [blueAnswerTime, setBlueAnswerTime] = useState(0);

  // 输赢展示
  const [win, setWin] = useState("");

  // 存储本次PK的数据
  const lessonPartInfo = useRef({});

  const [visibleL, setVisibleL] = useState(false);
  const [visibleR, setVisibleR] = useState(false);

  useEffect(() => {
    getPKData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setVisibleL(false);
  }, [redIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setVisibleR(false);
  }, [blueIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  // 接口请求
  const getPKData = () => {
    let pageObj = getParams(window.location.href);

    // let params = {
    //   id: 3941, //pageObj.id, 3757,
    //   type: 20, //pageObj.type, //20,
    //   subjectId: 8, //pageObj.subjectId, //8,
    //   createUserId: 2879, //pageObj.createUserId, //2879,
    //   wordName: "单词计分赛-中文选词", //decodeURI(pageObj.wordName) ||  // pageObj.wordName, //"主题PK-中文选词",
    // };

    let params = {
      id: pageObj.id,
      type: pageObj.type,
      subjectId: pageObj.subjectId,
      createUserId: pageObj.createUserId,
      wordName: decodeURI(pageObj.wordName),
    };

    // let res = data.data;
    // let arr = res?.partInfo[0]?.exams;
    // lessonPartInfo.current = res?.partInfo[0]?.lessonPartInfo;
    // arr.length && setRedAndBlue(arr || []);

    getPKList(params).then((res) => {
      let arr = res?.partInfo[0]?.exams;
      lessonPartInfo.current = res?.partInfo[0]?.lessonPartInfo;
      arr.length && setRedAndBlue(arr || []);
    });
  };

  // 处理红蓝两队数据
  const setRedAndBlue = (arr) => {
    // 初始化红、蓝的初始index
    setblueIndex(arr.length - 1);
    setredIndex(0);

    // 直接把红、篮双方的数据一次性整理好
    arr.forEach((v) => {
      v.redIsTrue = "";
      v.blueIsTrue = "";
      let trueArr = v.exam.examOptions.filter((v) => v.right);
      let flaseArr = v.exam.examOptions.filter((v) => !v.right);
      v.exam.blueArr = [...flaseArr.slice(0, 5)];
      let i = Math.floor(Math.random() * 6);
      v.exam.blueArr.splice(i, 0, trueArr[0]);
      v.answerData = trueArr[0].content;
      v.exam.redArr = [...flaseArr.slice(5, 10)];
      let i1 = Math.floor(Math.random() * 6);
      v.exam.redArr.splice(i1, 0, trueArr[0]);
    });
    setexamList(arr);
    // dataList.current = arr;
  };

  var redTime = 0;
  var blueTime = 0;

  // 开始PK
  const handlePlay = () => {
    moveFist();

    redTimer = setInterval(() => {
      redTime += 1;
      setRedAnswerTime(redTime);
    }, 1000);
    blueTimer = setInterval(() => {
      blueTime += 1;
      setBlueAnswerTime(blueTime);
    }, 1000);
    setTimeout(() => {
      setPlayGame(true);
      let qSmall = document.querySelector(".leftFist");
      let qSmall2 = document.querySelector(".rightFist");
      qSmall.style.opacity = 1;
      qSmall2.style.opacity = 1;
      setOptionFontSize();
    }, 600);
  };

  // 获取选项长度如果选项过长设置小字号
  const setOptionFontSize = () => {
    let arr = examList?.[redIndex]?.exam.redArr;
    for (let i = 0; i < arr.length; i++) {
      let leftOptionBox = document.getElementById(`lOption${i}`);
      let rightOptionBox = document.getElementById(`rOption${i}`);

      leftOptionBox.style.fontSize = "calc(48vh / 12)";
      rightOptionBox.style.fontSize = "calc(48vh / 12)";

      if (leftOptionBox.innerText.length > 8) {
        leftOptionBox.style.fontSize = "calc(25vh / 12)";
      }
      if (rightOptionBox.innerText.length > 8) {
        rightOptionBox.style.fontSize = "calc(25vh / 12)";
      }
    }
  };

  // 点击开始移动拳头
  const moveFist = () => {
    // 移动拳头q1左拳头q2右拳头
    let q1 = document.querySelector(".redFist");
    let q2 = document.querySelector(".blueFist");

    let qSmall = document.querySelector(".leftFist");
    let qSmall2 = document.querySelector(".rightFist");

    q1.classList.remove("shake");
    q2.classList.remove("shake");
    let p = document.querySelector(".leftTop");
    // 获取小拳头大小
    let w = qSmall.clientWidth;
    let h = qSmall.clientHeight;

    let wHalf = w / 2;

    q1.style.left = `${p.offsetLeft + p.clientWidth - wHalf}px`;
    q1.style.top = `${p.offsetTop - 10}px`;

    q1.style.width = `${w}px`;
    q1.style.height = `${h}px`;

    // let p2 = document.querySelector(".leftTop");

    let w2 = qSmall.clientWidth;
    let h2 = qSmall.clientHeight;

    q2.style.right = `${p.offsetLeft + p.clientWidth - wHalf}px`;
    q2.style.top = `${p.offsetTop - 10}px`;

    q2.style.width = `${w2}px`;
    q2.style.height = `${h2}px`;
  };

  // 去除选中className
  const clearBtnCLassName = (e) => {
    e.target.classList.remove("isAnswer");
    e.target.classList.remove("trueActive");
    e.target.classList.remove("falseActive");
  };
  // 作答
  const answer = (type, info, e, index) => {
    var dataList = examList;
    // 1.答案比对 动态添加class
    // 2.加分
    var dom1 = document
      .querySelector(".testBoxLeft")
      .querySelector(".isAnswer");
    var dom2 = document
      .querySelector(".testBoxRight")
      .querySelector(".isAnswer");

    if (info.right) {
      if (type === 0) {
        if (dom1) return;
        dataList[index].redIsTrue = true;
        setredScore(redScore + 10);
      } else {
        if (dom2) return;
        dataList[dataList.length - index - 1].blueIsTrue = true;
        setblueScore(blueScore + 10);
      }
      e.target.classList.add("trueActive");
      e.target.classList.add("isAnswer");
    } else {
      if (type === 0) {
        if (dom1) return;
        dataList[index].redIsTrue = false;
      } else {
        if (dom2) return;
        dataList[dataList.length - index - 1].blueIsTrue = false;
      }
      e.target.classList.add("falseActive");
      e.target.classList.add("isAnswer");
    }
    setexamList(dataList);

    // 检查是否都已经做完
    answerOver(type);

    setTimeout(() => {
      // 清空按钮选中状态
      if (type === 0 && redIndex !== examList.length - 1) {
        clearBtnCLassName(e);
      } else if (type === 1 && blueIndex !== 0) {
        clearBtnCLassName(e);
      }
      // 切换试题顺序
      switchExamSort(type);
    }, 500);
  };

  // 切题
  const switchExamSort = (type) => {
    if (type === 0) {
      let i = redIndex;
      if (i === examList.length - 1) return;
      i++;
      setredIndex(i);
    } else {
      let i = blueIndex;
      if (i === 0) return;
      i--;
      setblueIndex(i);
    }

    setOptionFontSize();
  };

  // 作答完成
  const answerOver = (type) => {
    // 单队作答完成清除定时器
    if (type === 0) {
      if (
        redIndex === examList.length - 1 &&
        document.querySelector(".testBoxLeft").querySelector(".isAnswer")
      ) {
        clearInterval(redTimer);
        redTimer = null;
        let redTimeText = setTimeFormat(redAnswerTime);
        setRedAnswerTime(redTimeText);
      }
    } else {
      if (
        blueIndex === 0 &&
        document.querySelector(".testBoxRight").querySelector(".isAnswer")
      ) {
        clearInterval(blueTimer);
        blueTimer = null;
        let blueTimeText = setTimeFormat(blueAnswerTime);
        setBlueAnswerTime(blueTimeText);
      }
    }

    // 全部作答完成
    setTimeout(() => {
      if (
        redIndex === examList.length - 1 &&
        blueIndex === 0 &&
        document.querySelector(".testBoxLeft").querySelector(".isAnswer") &&
        document.querySelector(".testBoxRight").querySelector(".isAnswer")
      ) {
        // 存本地
        localStorage.setItem("PKList", JSON.stringify(examList));

        // 判断谁赢了添加图标
        if (redScore === blueScore) {
          if (redAnswerTime === blueAnswerTime) {
            // 得分一样时间一样
          } else {
            if (redAnswerTime > blueAnswerTime) {
              setWin("blue");
            } else {
              setWin("red");
            }
          }
        } else {
          if (redScore > blueScore) {
            setWin("red");
          } else {
            setWin("blue");
          }
        }

        // 打开结束弹窗
        setGameOver(true);
      }
    }, 0);
  };

  // 处理时间格式
  const setTimeFormat = (time) => {
    let m = Math.floor(time / 60);
    let s = time % 60;
    if (s < 10) {
      s = "0" + s;
    }
    return `${m}'${s}`;
  };

  // 查看答案
  const lookAnswerDetails = () => {
    props.history.push(`/wordPkDetails`);
  };

  // 左右气泡
  const handleVisibleChangeL = () => {
    setVisibleL(true);
  };

  const handleVisibleChangeR = () => {
    setVisibleR(true);
  };

  return [
    {
      playGame,
      examList,
      redIndex,
      blueIndex,
      redScore,
      blueScore,
      gameOver,
      redAnswerTime,
      blueAnswerTime,
      win,
      visibleL,
      visibleR,
    },
    {
      handlePlay,
      answer,
      lookAnswerDetails,
      handleVisibleChangeL,
      handleVisibleChangeR,
    },
  ];
};

export default Presenter;
