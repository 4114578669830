/*
 * @Author: your name
 * @Date: 2021-07-23 16:24:56
 * @LastEditTime: 2022-07-08 13:56:10
 * @LastEditors: c1107 chen1997
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/util/android.js
 */
export const androidHandle = (val, data) => {
  return window?.android?.[val](data);
};
