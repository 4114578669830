import request from "../util/request";

export function comprehensiveList(params) {
    return request({
        url: "/class/v2/task/student/comprehensive/list",
        method: "get",
        params
    });
}

export function comprehensiveCondition(params) {
    return request({
        url: "/class/v2/task/student/comprehensive/detail",
        method: "get",
        params
    });
}

export function subjectTendency(params) {
    return request({
        url:"/class/v2/task/student/subject",
        method: "get",
        params
    })
}

export function subjectPerformance(params) {
    return request({
        url:"/class/v2/task/student/subject/data",
        method:"get",
        params
    })
}
