import "./index.less"
import ScrollView from "@/components/memorizeWords/scrollView"
import messageHint from "@/pages/mistakesRearrange/compoents/messageHint"
import { createNewGroup } from "@/api/morality"
import { useState, useEffect, useRef } from "react";
import { Modal } from 'antd'
import { getParams } from "@/util/util";
import { getMoralityEnterGroup, getGroupStudents, deleteCommentGroup, addStudentToGroup } from "@/api/morality"



const MoralityEditGroupComponent = ({ subjectId, ...props }) => {
    const paramsObj = getParams(window.location.href)
    const [createGroupName, setCreateGroupName] = useState('')
    const [groupList, setGroupList] = useState([])
    const [currentGroup, setCurrentGroup] = useState(-1)
    const [studentList, setStudentList] = useState([])
    const [isShowDelGroupBtn, setIsShowDelGroupBtn] = useState(false)

    useEffect(() => {
        if (props.visible) {
            if (subjectId === undefined) return messageHint('请先选择学科')
            getCommentGroup()
            getCommentStudent()
        }

    }, [props.visible]) // eslint-disable-line react-hooks/exhaustive-deps

    const getCommentGroup = () => {
        let params = {
            createId: paramsObj.studentId,
            classId: paramsObj.classId,
            subjectId: subjectId
        }

        getMoralityEnterGroup(params).then(res => {
            setGroupList(res || [])
        })
    }

    const getCommentStudent = () => {
        let params = {
            classId: paramsObj.classId,
        }

        getGroupStudents(params).then(res => {
            setStudentList(res || [])
        })
    }

    const createGroupOk = () => {
        if (createGroupName.trim().length === 0) return messageHint('小组名称不能为空');

        let params = {
            name: createGroupName,
            classId: paramsObj.classId,
            className: decodeURIComponent(paramsObj?.className),
            createUserId: paramsObj.studentId
        }

        createNewGroup(params).then(res => {
            messageHint('创建小组成功')
            getCommentGroup()
            setCreateGroupName('')
            setCurrentGroup(0)
        })
    }

    const delCurrentGroup = (e, info, index) => {
        e.stopPropagation();
        deleteCommentGroup({ id: info.id }).then(res => {
            setGroupList(groupList.filter(v => v.id !== info.id))
            messageHint('已删除该小组')
            setIsShowDelGroupBtn(false)
            if (currentGroup > index) {
                setCurrentGroup(currentGroup - 1)
            }

            if (currentGroup === index) {
                setCurrentGroup(-1)
            }
        })
    }

    const currentGroupChange = (index) => {
        setCurrentGroup(index)
    }

    const addStudentToCurrentGroup = (info) => {
        let params = {
            classId: paramsObj.classId,
            studentId: info.studentId,
            studentName: info.studentName,
            groupId: groupList?.[currentGroup]?.id,
            groupName: groupList?.[currentGroup]?.name,
        }
        addStudentToGroup(params).then(res => {
            setStudentList(list => {
                return list.map(v => {
                    if (v.studentId === info.studentId) {
                        return {
                            ...v,
                            groupId: groupList?.[currentGroup]?.id,
                            groupName: groupList?.[currentGroup]?.name
                        }
                    } else {
                        return v
                    }
                })
            })
        })
    }


    return (
        <Modal wrapClassName="moralityEditGroupComponentModal" centered width="calc(1258vh / 12)" title={null} footer={null} {...props}>
            <div className="title">标签管理 <span className="subTitle">（点击未分组及其他小组学生即可移入已选小组内）</span></div>
            <div className="content">
                <div className="group">
                    <div className="tabs">
                        <div className={`item${currentGroup === -1 ? ' active' : ''}`} onClick={() => currentGroupChange(-1)}>全部学生</div>
                        <div className="warp">
                            {
                                groupList.map((v, i) =>
                                    <div className={`item${currentGroup === i ? ' active' : ''}`} key={v.id} onClick={() => currentGroupChange(i)}>
                                        {v.name}
                                        {isShowDelGroupBtn && <i className="remove" onClick={(e) => delCurrentGroup(e, v, i)} />}
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="control" onClick={() => setIsShowDelGroupBtn(!isShowDelGroupBtn)}>{isShowDelGroupBtn ? '取消' : '删除小组'}</div>
                </div>

                <ScrollView className="labelList">
                    {
                        studentList.map(v =>
                            <div className={`item${v.groupId === groupList?.[currentGroup]?.id ? ' checked' : ''}`} key={v.studentId}>
                                <span className="name">{v.studentName}</span>
                                <span className="group">({v?.groupName || '未分组'})</span>
                                {currentGroup !== -1 && <i className="add" onClick={() => addStudentToCurrentGroup(v)} />}
                            </div>
                        )
                    }
                </ScrollView>
            </div>
            <div className="footer">
                <div className="createLabel">
                    <div className="text">新建小组:</div>
                    <div className="edit">
                        <input type="text" placeholder="请输入小组名称" value={createGroupName} onChange={(e) => setCreateGroupName(e.target.value)} />
                        <div className="cancel" onClick={() => setCreateGroupName('')}>取消</div>
                        <div className="ok" onClick={createGroupOk}>确认</div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default MoralityEditGroupComponent