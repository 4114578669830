/*
 * @Author: your name
 * @Date: 2021-07-21 15:50:07
 * @LastEditTime: 2022-09-16 14:47:40
 * @LastEditors: c1107 chen1997
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/components/answerList/presenter.js
 */
import { useState, useEffect, useRef } from "react";
import {
  getExamSignDetail,
  addCourseAnswer,
  courseAnswerSituation,
} from "@/api/answer.js";
import { getParams } from "@/util/util";
import store from "@/redux/store";
import { message } from "antd";
import mathJax from "@/util/globalVariable";
import { getEduselflearn } from "@/api/autonomyPractice";

const Presenter = (props) => {
  const inputEl = useRef(null);
  const [exam, setexam] = useState({
    examTypeId: 1,
  });
  const [nowIndex, setNowIndex] = useState(1);
  const [timer, settimer] = useState(true);

  const [nextExam, setnextExam] = useState({
    seq: -1,
    val: null,
  });

  const errorList = useRef([]);
  const timer1 = useRef(null);
  const urlObj = getParams(window.location.href);

  useEffect(() => {
    // 只看错题
    if (props.errorExamState) {
      setNowIndex(0);
      // let obj = getParams(window.location.href);
      courseAnswerSituation({
        id: store.getState().courseInfo.id || urlObj.pid,
        onlyWrong: 1,
      }).then((res) => {
        if (res.length === 0) {
          message.info("你当前没有错题，继续保持哦！");
        } else {
          errorList.current = res;
          store.dispatch({
            type: "updateAnswerStates", // answerStates
            data: res,
          });
          if (res?.length > 0) {
            setNowIndex(res[0].seq);
          } else {
            setexam({});
          }
        }
      });
    } else {
      setNomalExamList();
    }
  }, [props.errorExamState]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.rewriteState) {
      let res = props.answerStates.filter((v) => v.redo === 1);

      // if(res.length === 0) {
      //   message.info("已订正完毕，可以提交了");
      //   return
      // }

      store.dispatch({
        type: "updateAnswerStates", // answerStates
        data: res,
      });

      if (res?.length > 0) {
        setNowIndex(res[0].seq);
      } else {
        setexam({});
      }
    } else {
      setNomalExamList();
    }
  }, [props.rewriteState]); // eslint-disable-line react-hooks/exhaustive-deps

  const setNomalExamList = () => {
    if (store.getState()?.courseInfo?.id) {
      courseAnswerSituation({
        id: store.getState().courseInfo.id,
      }).then((res) => {
        store.dispatch({
          type: "updateAnswerStates", // answerStates
          data: res,
        });
        setNowIndex(1);
      });
    }
  };

  const updateExamBefore = () => {
    // 先清空试题数据
    store.dispatch({ type: "updateExam", data: {} });
    // 本文件的清空
    setexam({});
  };

  // 获取试题接口
  const getExamRequest = (query) => {
    return new Promise((resolve, reject) => {
      getExamSignDetail(query)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // 下一题的请求
  const getNextExamInfo = async (seq) => {
    try {
      let res = await getExamInfo(seq);
      // 数据存储起来下次调用
      setnextExam({
        seq: seq,
        val: res,
      });
    } catch (error) { }
  };

  const getExamInfo = async (seq) => {
    // let obj = getParams(window.location.href);
    try {
      if (!urlObj?.isAutonomyPractice) {
        let query = {
          id: store.getState().courseInfo.id,
          seq: seq,
          studentId: urlObj.studentId,
        };
        let res = await getExamRequest(query);


        // 综合题数据处理
        let copyExamAnswer = JSON.parse(res.childrenAnswer || "[]");
        res?.resource?.exam?.children?.forEach((element, index) => {
          element.resource = {};
          element.resource.exam = {};
          element.resource.exam = element;
          element.resource.exam.answer =
            JSON.stringify(copyExamAnswer[index]) || "";
        });


        console.log(res?.resource?.exam?.examStem, 'res---------------------');

        // 填空题数据处理
        res = setFillbackInput(res);
        return res;
      } else {
        // 自主练习
        let queryZZLX = {
          id: props?.courseDetail?.lessonStudentId,
          studentId: props?.courseDetail?.studentId || urlObj.studentId,
          seq: seq,
        };
        let res = await getExamRequest(queryZZLX);
        // 综合题数据处理
        let copyExamAnswer = JSON.parse(res.childrenAnswer || "[]");
        res?.resource?.exam?.children?.forEach((element, index) => {
          element.resource = {};
          element.resource.exam = {};
          element.resource.exam = element;
          element.resource.exam.answer =
            JSON.stringify(copyExamAnswer[index]) || "";
        });

        // 填空题数据处理
        res = setFillbackInput(res);
        return res;
      }
    } catch (error) {
      setTimeout(() => {
        settimer(true);
      }, 0);
    }
  };

  const initExam = (isInit) => {
    // let obj = getParams(window.location.href);

    // 节流
    clearTimeout(timer1.current);
    timer1.current = setTimeout(async () => {
      // 加载试题前处理
      updateExamBefore();

      // 非空判断
      if (!urlObj?.isAutonomyPractice) {
        if (!store.getState().courseInfo.id) return;
      }

      // 获取当前试题的seq
      let seq = nowIndex + (store.getState()?.courseInfo?.examIndexStart || 0);
      if (seq === 0) return;

      // 加载试题数据,如果没有预加载就请求，如果预加载了就直接展示即可
      let res = null;
      if (nextExam.seq === seq) {
        res = nextExam.val;
      } else {
        res = await getExamInfo(seq);
      }
      // 获取到试题数据后的处理

      // 更新当前作答的试题信息状态

      setexam(res);

      // 每次切换试题-更新试题作答时间
      store.dispatch({
        type: "updateTime",
        data: res?.useTime || 0,
      });

      // redux 存储的数据，其他功能需要使用到这些数据
      store.dispatch({ type: "updateExam", data: res });
      store.dispatch({ type: "updateNowExamIndex", data: nowIndex - 1 }); // 这里是需要更新数据的

      // 更新作答进度
      if (nowIndex === 1) {
        let courseInfo = { ...store.getState().courseInfo };
        courseInfo.answerCount = res.answerCount;
        store.dispatch({
          type: "updateCourseInfo",
          data: courseInfo,
        });
      }

      // 防止重复加载
      setTimeout(() => {
        // 节流
        settimer(true);
        // 公式
        mathJax();
      }, 0);

      // 预加载下一题数据
      if (!urlObj?.isAutonomyPractice) {
        if (seq < props?.courseInfo?.countExam) {
          getNextExamInfo(seq + 1);
        }
      } else {
        // console.log(res?.countExam);

        if (seq < res?.countExam) {
          getNextExamInfo(seq + 1);
        }
      }
    }, 10);
  };

  useEffect(() => {
    settimer(false);
    // 每次切换试题的时候还需要考虑作答时间引起的更新
    // 拿到当前的数据和当前的时间进行比对
    let newTime = store.getState().updateTime;
    let oldTime = store.getState()?.examItem?.useTime;
    // 暂定为时间间隔为5秒 ，认定学生是产生了思考的,需要更新作答时间
    if ((oldTime || oldTime === 0) && newTime - oldTime >= 5000) {
      addCourseAnswer({
        lessonStudentId: store.getState().courseInfo.id,
        resourceId: store.getState()?.examItem?.resource?.exam?.id,
        resourceType: 2,
        usedTime: newTime,
      });
    }
    initExam();
    if (urlObj?.isAutonomyPractice) {
      store.dispatch({ type: "updateNowExamIndex", data: nowIndex - 1 });
    }
  }, [nowIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    settimer(false);
    setNowIndex(1);
    initExam();
  }, [props.courseInfo.id]); // eslint-disable-line react-hooks/exhaustive-deps

  // 填空题的回填
  const setFillbackInput = (data) => {
    const text = "点击作答";
    let isSubmit = store.getState().courseInfo.status !== 0;
    // 去除 input的只读
    if (data?.answerType === 1 && data?.resource?.exam?.examTypeId === 6) {
      /**
       * <input index="1" class=\"cus-com\" readonly=\"readonly\" type=\"text\" value=\"(1)\"/>
       * 为了适应新的填空题，前端需要把一些下划线替换为上面的input标签
       * 1.下划线全部处理成标记
       * 2.index、value的值是动态的
       * 3.逐个替换需要将index和value的值赋上
       */
      // 将_下划线替换为<u>&nbsp;</u>
      let steamRegGiao = new RegExp("(_){1,}");
      let aaa = steamRegGiao.test(data.resource.exam.examStem);

      if (aaa) {
        data.resource.exam.examStem = data.resource.exam.examStem.replace(
          /(_){2,}/g,
          `<u>&nbsp;</u>`
        );
      }

      let steamIndex = 1;
      let steamIndex2 = 1;
      let steamReg = new RegExp("(<u>&nbsp;</u>){1,}");
      let steamReg2 = new RegExp("(_){1,}");
      let isLive = steamReg.test(data.resource.exam.examStem),
        isLive2 = steamReg2.test(data?.mark);
      while (isLive || isLive2) {
        isLive = steamReg.test(data.resource.exam.examStem);
        isLive2 = steamReg2.test(data?.mark);
        // let isLive3 = steamReg2.test(data.resource.exam.examStem);

        if (isLive) {
          data.resource.exam.examStem = data.resource.exam.examStem.replace(
            /(<u>&nbsp;<\/u>){1,}/,
            `<input index="${steamIndex}" class="cus-com" readonly="readonly" type="text" value="(${steamIndex})"/>`
          );
          steamIndex += 1;
        }

        if (isLive2) {
          data.mark = data.mark.replace(
            /(_){2,}/g,
            `<input index="${steamIndex2}" class="cus-com" readonly="readonly" type="text" value="(${steamIndex2})"/>`
          );
          steamIndex2 += 1;
        }

        // if (isLive3) {
        //   data.resource.exam.examStem = data.resource.exam.examStem.replace(
        //     /(_){1,}/g,
        //     `<input index="${steamIndex}" class="cus-com" readonly="readonly" type="text" value="(${steamIndex})"/>`
        //   );
        //   steamIndex++;
        // } else {
        //   flag = false;
        // }
      }

      let allEnglishIds = [7, 8, 9];

      // 还要判断是否已经提交，已经提价的就不能再提交了
      if (
        !isSubmit &&
        allEnglishIds.indexOf(data?.resource?.exam?.baseSubjectId) === -1
      ) {
        data.resource.exam.examStem = data.resource.exam.examStem.replace(
          /readonly/g,
          "a"
        );
        if (data.mark) data.mark = data?.mark.replace(/readonly/g, "a");
      }

      // 给input 回填数据
      let answerObj = JSON.parse(data.answer || "[]");
      data.resource.exam.examOptions.forEach((element, index) => {
        var reg = new RegExp('value="\\(' + (index + 1) + '\\)"', "g");
        var reg1 = new RegExp(
          'index="' + (index + 1) + '" class="cus-com"',
          "g"
        );

        if (isSubmit) {
          let isRight = false;
          if (answerObj?.[index]?.r === 1) {
            isRight = true;
          }
          data.resource.exam.examStem = data.resource.exam.examStem.replace(
            reg1,
            `index="${index + 1}" class="cus-com ${isRight ? "right" : "error"
            }"`
          );

          if (data.mark)
            data.mark = data.mark?.replace(
              reg1,
              `index="${index + 1}" class="cus-com ${isRight ? "right" : "error"
              }"`
            );
        }
        //给input宽度使其展示全
        let num = 22 * (answerObj?.[index]?.a.length > 10 ? answerObj?.[index]?.a.length : 10);
        // 答案回填
        data.resource.exam.examStem = data.resource.exam.examStem.replace(
          reg,
          `value="${answerObj?.[index]?.a || `${text}`
          }" style="width:${num}px;padding:0 10px; box-sizing: border-box;"`
        );
        // 答案回填
        if (data.mark) {
          let reg2 = new RegExp('value="\\' + text + '\\"');
          if (reg2.test(data.mark)) {
            data.mark = data.mark?.replace(
              reg2,
              `value="${answerObj?.[index]?.a || `${text}`
              }"  style="width:${num}px;padding:0 10px; box-sizing: border-box;"`
            );
          } else {
            data.mark = data.mark?.replace(
              reg,
              `value="${answerObj?.[index]?.a || `${text}`
              }"  style="width:${num}px;padding:0 10px; box-sizing: border-box;"`
            );
          }
        }
      });
    }
    return data;
  };

  // 跳转到指定的内容
  const switchExam = (type) => {
    let now = 0;
    if (props.errorExamState || props.rewriteState) {
      let index = props.answerStates.findIndex((v) => v.seq === nowIndex);
      if (type === 0) {
        if (props.answerStates[index - 1]) {
          now = props.answerStates?.[index - 1]?.seq;
        } else {
          return;
        }
      } else {
        if (props.answerStates[index + 1]) {
          now = props.answerStates?.[index + 1]?.seq;
        } else {
          return;
        }
      }
    } else {
      if (type === 0) {
        now = exam?.seq - 1;
        if (now <= 0) return;
      } else {
        now = exam?.seq + 1;
        if (now > props?.courseInfo?.countExam) return;
      }
    }
    if (timer) {
      // passiveUpdateAnswe();
      setNowIndex(now);
    }
  };

  useEffect(() => {
    onChange(null, store.getState().nowExamIndex);
    // inputEl.current.goTo(store.getState().nowExamIndex, true);
  }, [props.nowExamIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  //  切换时需要考虑填空题
  const passiveUpdateAnswe = () => {
    // 填空题键盘作答需要主动提交
    let examInfo = exam?.resource?.exam;
    if (examInfo?.examTypeId === 6 && exam?.answerType === 1) {
      let info = exam?.resource?.exam;
      let optionList = [];
      let doms = document
        ?.querySelector(".examStemCom")
        ?.querySelectorAll("input");
      if (!doms) return;
      let flag = false;
      doms.forEach((element, index) => {
        let reg = new RegExp("\\(" + (index + 1) + "\\)", "g");
        let val = doms[index].value.replace(reg, "");
        if (val !== "" && val !== "点击作答") flag = true;
        optionList.push({
          examTypeId: info.examTypeId,
          answerType: exam.answerType || 3,
          myAnswer: val,
        });
      });
      if (flag) {
        let obj = getParams(window.location.href);
        addCourseAnswer({
          lessonStudentId: store.getState().courseInfo.id,
          resourceId: info?.id,
          resourceType: 2,
          usedTime: store.getState().updateTime,
          optionList,
          isAdd: exam?.answer === "",
        }).then((res) => { });
      }
    }
  };

  const onChange = (last, now) => {
    if (timer) {
      passiveUpdateAnswe();
      setNowIndex(now + 1);
    }
  };

  return [
    {
      exam,
      inputEl,
      nowIndex,
    },
    {
      onChange,
      switchExam,
    },
  ];
};

export default Presenter;

/**
 * 1.确定好相关方法和数据
 *    包含：（1）get试题详情的接口调用
 *          （2）通过redux更新examInfo的方法
 *          （3）预加载的试题信息应该存到哪里
 *
 * 2.
 *
 */

