/*
 * @Author: your name
 * @Date: 2021-07-22 11:20:47
 * @LastEditTime: 2022-08-17 10:23:44
 * @LastEditors: c1107 chen1997
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/components/resourcePreview/presenter.js
 */
import { useEffect, useState, useRef } from "react";

import { download } from "@/util/downloadFile";
import {
  cacheServerIpPool,
  collectionAdd,
  collectionDel,
  getIpBySchoolId,
} from "@/api/answer";
import { androidHandle } from "@/util/android";
import { getParams } from "@/util/util";
import axios from "axios";
import { message } from "antd";
import qs from "qs";

const EditText = (props) => {
  const { info } = props;
  const [numPages, setNumPages] = useState(null);

  const [isUsePdfPlugin, setisUsePdfPlugin] = useState(true);

  const [scalingNum, setScalingNum] = useState(1);

  const [rotateNum, setRotateNum] = useState(0);

  const [showBigImage, setShowBigImage] = useState(false);

  const [pageNumber, setpageNumber] = useState(1);

  const [collectState, setcollectState] = useState(false);
  const [file, setfile] = useState({});
  const [docWidth, setDocWidth] = useState(0);
  const [docHeight, setDocHeight] = useState(0);
  const docRef = useRef();

  const timer = useRef(null);

  useEffect(() => {
    if (getParams(window.location.href).isPc) {
      setisUsePdfPlugin(false);
    } else {
      setisUsePdfPlugin(true);
    }
    setcollectState(props?.info?.collection || false);
    // setfile(info.file);
    setInfo();
    setpageNumber(1);
  }, [info]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangePage = (page, pdfDocument) => {
    info?.onChangePage?.(page, pdfDocument);
  };

  const setInfo = async () => {
    let pageObj = getParams(window.location.href);

    // 获取ip池来进行匹配 取缓存 -> 掉接口
    // let IP_POOLS = [];
    let IP_POOL = "";
    try {
      // 获取随机 ip
      IP_POOL = await cacheServerIpPool({
        schoolId: pageObj.schoolId,
        gradeId: pageObj.gradeId,
      });

      // <S>
      // 检查ip是否能使用
      let ipNum = judgeIp(IP_POOL) || false;
      let isUseIpTF = ipNum ? await ping(ipNum) : false;
      // if (IP_POOL) {
      // <E>

      if (isUseIpTF) {
        let obj = { ...info.file };
        let url = obj.convertResourceUrl || obj.resourceUrl;

        let isLiveResource = await requestResource(IP_POOL, url);
        if (isLiveResource) {
          url = encodeURIComponent(url);
          obj.convertResourceUrl = `${IP_POOL}download?name=${url}`;
          setfile(obj);
        } else {
          throw new Error(
            `缓存盒子无该资源:${obj.convertResourceUrl || obj.resourceUrl}`
          );
        }
      } else {
        throw new Error(`该学校无盒子缓存服务,学校id：${pageObj.schoolId}`);
      }
    } catch (error) {
      setfile(info.file);
    }
  };

  const judgeIp = (ip) => {
    if (ip) {
      let str = ip;
      var reg = new RegExp(/\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}/);
      let ipData = str.match(reg);
      // 检查ip是否能使用
      // http://192.168.107.237:8888/resource/
      // http://49.4.1.81:8080/#/login
      let numIp = ipData[0];
      return numIp;
    } else {
      return false;
    }
  };

  const ping = (ip, port) => {
    return new Promise((resolve, reject) => {
      var img = new Image();
      var start = new Date().getTime();
      var flag = false;
      var isCloseWifi = true;
      var hasFinish = false;
      img.onload = () => {
        if (!hasFinish) {
          flag = true;
          hasFinish = true;
          img.src = "X:\\";
          console.log("Ping " + ip + " 成功. ");
          resolve(true);
        }
      };
      img.onerror = () => {
        if (!hasFinish) {
          hasFinish = true;
          if (!isCloseWifi) {
            flag = true;
            img.src = "X:\\";
            console.log("Ping " + ip + " 成功. ");
            resolve(true);
          } else {
            console.log("网络不工作!");
            resolve(false);
          }
        }
      };
      setTimeout(() => {
        isCloseWifi = false;
      }, 2);
      img.src = "http://" + ip + "/" + start;
      var timer = setTimeout(() => {
        if (!flag) {
          hasFinish = true;
          img.src = "X://";
          flag = false;
          console.log("Ping " + ip + " 失败. ");
          resolve(false);
        }
      }, 1000);
    });
  };

  const requestResource = (ip, url) => {
    // let str = `${ip}exsit?name=${url}`;
    console.log(ip);
    let str = `${ip}exsit`;
    console.log(ip);
    return new Promise((reslove, reject) => {
      axios({
        method: "post",
        url: str,
        data: {
          name: url,
        },
        transformRequest: [
          function (data) {
            let ret = "";
            for (let it in data) {
              ret +=
                encodeURIComponent(it) +
                "=" +
                encodeURIComponent(data[it]) +
                "&";
            }
            ret = ret.substring(0, ret.lastIndexOf("&"));
            return ret;
          },
        ],
      })
        .then(function (res) {
          if (res.status !== 404) {
            reslove(res);
          } else {
            reject(-1);
          }
        })
        .catch(function (err) {
          reject(-1);
        });
    });
  };

  // 显示大图
  const getOfficeI = (url) => {
    if (url?.indexOf("file.fuzhuxian") > -1) {
      return 17965;
    } else {
      return 23129;
    }
  };
  const handleCollect = () => {
    let pageObj = getParams(window.location.href);
    if (collectState) {
      // 资源取消收藏;
      collectionDel({
        studentId: pageObj.studentId, //207975,
        subjectId: info?.file?.subjectId, //store.getState().courseInfo.subjectId,
        type: 1,
        examId: info?.file?.id,
      }).then((res) => {
        let f = collectState ? false : true;
        setcollectState(f);
      });
    } else {
      collectionAdd({
        studentId: pageObj.studentId, //207975,
        subjectId: info?.file?.subjectId, //store.getState().courseInfo.subjectId,
        type: 1,
        examId: info?.file?.id,
        chapterId: info?.file?.chapterId, //章节id
        // resourceType: info?.file?.resourceSuffix, //资源类型
        resourceName: info?.file?.name, //资源名称
      }).then((res) => {
        let f = collectState ? false : true;
        setcollectState(f);
      });
    }
  };

  const previewImageClicked = (file) => {
    androidHandle("previewImageClicked", file.resourceKey);
  };

  const upPage = () => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (pageNumber > 1) {
        setpageNumber(pageNumber - 1);
      }
    }, 0);
  };

  const downPage = () => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (pageNumber < numPages) {
        setpageNumber(pageNumber + 1);
      }
    }, 0);
  };

  const scalingPage = (scaling, num) => {
    let sizeNum = num;
    if (scaling) {
      if (num >= 2) {
        message.warning("不能再大了哦");
      } else {
        sizeNum = sizeNum + 0.1;
      }
    } else {
      if (num <= 0.5) {
        message.warning("不能再小了哦");
      } else {
        sizeNum = sizeNum - 0.1;
      }
    }
    setScalingNum(sizeNum);
  };

  const rotatePage = (num) => {
    let list = [0, 90, 180, 270];
    let index = list.findIndex((v) => v === num);
    if (index === 3) {
      setRotateNum(0);
    } else {
      index = index + 1;
      setRotateNum(list[index]);
    }
  };

  const look365File = () => {
    setisUsePdfPlugin(false);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    const rect = docRef.current?.getBoundingClientRect();

    if (rect) {
      setDocWidth(rect.width);
      setDocHeight(rect.height);
    }
  };

  const onDocumentLoadError = () => {
    setisUsePdfPlugin(false);
  };

  const onChangeInput = (val) => {
    setpageNumber(val);
  };

  const switchPdfType = () => {
    setisUsePdfPlugin(!isUsePdfPlugin);
  };



  const getBase64Url = (url) => {
    if (url?.indexOf(".pdf") > -1) {
      url = `https://vip.ow365.cn/?i=${getOfficeI(url)}&n=5&furl=${url}`;
    } else if (url?.indexOf(".doc") > -1 || url?.indexOf(".docx") > -1) {
      // if (
      //   navigator.userAgent.match(/Android/i) ||
      //   navigator.userAgent.match(/webOS/i) ||
      //   navigator.userAgent.match(/iPhone/i) ||
      //   navigator.userAgent.match(/iPad/i) ||
      //   navigator.userAgent.match(/iPod/i) ||
      //   navigator.userAgent.match(/BlackBerry/i) ||
      //   navigator.userAgent.match(/Windows Phone/i)
      // ) {
      //   url = `https://vip.ow365.cn/?i=${getOfficeI(url)}&n=1&furl=${url}`;
      // } else {
      //   url = `https://vip.ow365.cn/?i=${getOfficeI(url)}&furl=${url}`;
      // }
      url = `https://vip.ow365.cn/?i=${getOfficeI(url)}&n=3&furl=${url}`;

    } else {
      url = `https://vip.ow365.cn/?i=${getOfficeI(url)}&n=5&furl=${url}`;
    }

    if (getParams(window.location.href).isPc) {
      let arr = url.split("?");
      arr[0] += "?info=3&";
      url = arr.join("");
    }
    return url;
  };

  const downLoadResource = () => {
    download(checkUrl(file.resourceUrl), file.name);
  };

  const checkUrl = (url) => {
    // :8888 规避掉 所有的盒子访问链接
    if (url?.substr(0, 5) !== "https" && url?.indexOf(":8888") === -1) {
      url = "https:" + url?.substring(5);
    }
    return url;
  };

  // MP3，MP4路径报错自动切换下个路径
  const audioError = () => {
    let fileObj = Object.assign({}, file);
    fileObj.convertResourceUrl = fileObj.resourceUrl;
    setfile(fileObj);
  };

  const onDocumentLoadFail = () => {
    console.error("加载失败");
    let obj = { ...info.file };
    obj.convertResourceUrl = obj.convertResourceUrl || obj.resourceUrl;
    // 当盒子的资源加载失败，重新修改资源地址去重新加载
    setfile(obj);
  };

  return [
    {
      showBigImage,
      collectState,
      file,
      pageNumber,
      numPages,
      isUsePdfPlugin,
      scalingNum,
      rotateNum,
      docWidth,
      docHeight,
      docRef,
    },
    {
      onDocumentLoadFail,
      getBase64Url,
      getOfficeI,
      handleCollect,
      previewImageClicked,
      onDocumentLoadSuccess,
      onDocumentLoadError,
      upPage,
      downPage,
      onChangeInput,
      switchPdfType,
      scalingPage,
      rotatePage,
      look365File,
      onChangePage,
      downLoadResource,
      checkUrl,
      audioError,
    },
  ];
};

export default EditText;
