import { getWrongQuestionSubject, getLabelList, removeWorngTopic, getWrongLabelList } from "@/api/mistakesBooks"
import { useEffect, useState } from "react"
import { getParams } from "@/util/util"
import messageHint from "../mistakesRearrange/compoents/messageHint"

const subjectSort = (arr, active) => {
    let first = ["语文", "数学", "英语"]
    let arrs = []
    first.forEach((item = [], index) => {
        let obj = arr.find(x => x[active] === item);
        if (obj) {
            arrs.push(...arr.splice(arr.indexOf(obj), 1))
        }
    })

    return [...arrs, ...arr]
}

const Presenter = (props) => {
    const { studentId } = getParams(window.location.href);
    // 学科
    const [subjectListData, setSubjectListData] = useState([])
    // 选中学科
    const [subjectListDataActive, setSubjectListDataActive] = useState(-1)
    // 选中时间
    const [timerActive, setTimerActive] = useState(1)
    // 选中日期
    const [dateActive, setDateActive] = useState(['', ''])
    // 题库
    const [questionBank, setQuestionBank] = useState(2)
    // 题型
    const [questionTypes, setQuestionTypes] = useState(0)
    // 标签名称
    const [labelName, setLabelName] = useState('')
    // 错题列表
    const [wrongQuestionList, setWrongQuestionList] = useState([])
    // 错题数量
    const [wrongQuestionCount, setWrongQuestionCount] = useState(0)
    // 常用标签
    const [useLabelList, setUseLabelList] = useState([])
    // 请求参数传递到错题重做页面
    const [MistakesBookParamsObj, setMistakesBookParamsObj] = useState({})
    // 请求条数
    const size = 5
    // 请求id
    const [lastId, setLastId] = useState(0)


    useEffect(() => {
        ; (async () => {
            const subjectNameList = await getWrongQuestionSubject({
                userStudentId: studentId
            })
            setSubjectListData(x => subjectSort(subjectNameList.data, "baseSubjectName"))
        })();
        refReshUseLabelList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const refReshUseLabelList = () => {
        getLabelList({
            studentId
        }).then(res => {
            if (res) {
                let arr = res?.sort((a, b) => b.count - a.count);
                setUseLabelList([{ id: 0, labelName: '', studentId }, ...arr?.slice(0, 5)]);
            }
        })
    }

    const init = (type) => {
        if (subjectListData.length !== 0) {
            const params = {
                userStudentId: studentId,
                baseSubjectId: subjectListDataActive === -1 ? subjectListData[0].baseSubjectId : subjectListDataActive,
                dateType: timerActive === -1 ? undefined : timerActive,
                startTime: dateActive[0],
                endTime: dateActive[1],
                clear: questionBank,
                examType: questionTypes,
                labelName: labelName,
                size: size,
                lastId: type === 'switch' ? 0 : lastId
            }

            setMistakesBookParamsObj(params);

            (async () => {
                const { data, meta } = await getWrongLabelList(params);
                if (type === 'switch') {
                    document.querySelector('#topListContent').scrollTop = 0
                    setWrongQuestionList(data ? data : [])
                    setWrongQuestionCount(x => {
                        if (meta) {
                            return meta.count
                        } else {
                            return 0
                        }
                    })
                } else if (type === 'addList') {
                    if (!data) messageHint("没有更多了")
                    setWrongQuestionList([...wrongQuestionList, ...(data || [])])
                }
            })();
        }
    }

    useEffect(() => {
        init('switch')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subjectListData, subjectListDataActive, timerActive, dateActive, questionBank, questionTypes, labelName])

    useEffect(() => {
        if (lastId !== 0) {
            init('addList')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastId])

    // 学科切换
    const onChangeSubject = (id) => {
        // console.log(id, '学科Id');
        setSubjectListDataActive(x => id)
    }

    // 时间
    const onChangeTimer = (dataType) => {
        // console.log(dataType,'时间 本周 本月 全部');
        setTimerActive(x => dataType)
    }

    // 日期
    const onChangeDate = (dateValue) => {
        // console.log(JSON.stringify(dateValue),'日期时间');
        setDateActive(x => dateValue)
    }

    // 题库
    const onChangeBank = (value) => {
        setQuestionBank(value)
        // console.log(value);
    }

    // 题型
    const onChangeType = (value) => {
        setQuestionTypes(value)
        // console.log(value);
    }

    // 列表滚动到底部
    const scrollBottomChange = () => {
        if (wrongQuestionList[wrongQuestionList.length - 1]) {
            setLastId(wrongQuestionList[wrongQuestionList.length - 1].id)
            // init('addList')
        }
    }

    // 删除此题
    const removePresentTopic = async (info) => {
        removeWorngTopic({
            id: info.id
        }).then((res) => {
            setWrongQuestionList(x => {
                return x.filter((val) => val.id !== info.id)
            })
            setWrongQuestionCount(x => x - 1)
        })
    }

    const onChangeLabelName = (value) => {
        setLabelName(value)
    }


    return [
        { subjectListData, wrongQuestionList, questionBank, MistakesBookParamsObj, wrongQuestionCount, useLabelList },
        { onChangeSubject, onChangeTimer, onChangeDate, onChangeBank, onChangeType, scrollBottomChange, removePresentTopic, onChangeLabelName, refReshUseLabelList }
    ]
}

export default Presenter