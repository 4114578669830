/*
 * @Author: c1107 chen1997
 * @Date: 2022-08-09 15:48:26
 * @LastEditors: c1107 chen1997
 * @LastEditTime: 2022-08-16 16:11:55
 * @FilePath: \ZNXT-Student2.0\src\pages\glossary\index.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState } from "react";
import "./index.less";
import PadHeader from "@/components/padHeader";
import FirstTab from "./components/firstTab"
import SecondTab from "./components/secondTab"
import { useHistory } from 'react-router-dom'

const ElectronicBook = () => {
  const history = useHistory()
  const [tabActive, setTabActive] = useState(0)

  const tabItemConfig = [
    {
      name: '词汇表',
      key: 0
    },
    {
      name: '单词收藏',
      key: 1
    }
  ]

  return (
    <div className="wordSummaryTable">
      <PadHeader>
        {
          tabItemConfig.map(v =>
            <div className={`tabItem${v.key === tabActive ? ' active' : ''}`} key={v.key} onClick={() => setTabActive(v.key)}>{v.name}</div>
          )
        }
        <div onClick={() => history.push(`/morality`)}>德育统计</div>
      </PadHeader>
      {
        // 词汇表
        tabActive === 0 && <FirstTab />
      }
      {
        // 单词收藏
        tabActive === 1 && <SecondTab />
      }
    </div>
  );
};

export default ElectronicBook;
