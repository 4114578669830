import "./index.less";
import PadHeader from "@/components/padHeader";
import Presenter from "./presenter.js";
import StudyCard from "./components/studyCard";
import { CaretRightOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom'
import TaskModal from "@/components/memorizeWords/taskModal"
import { DatePicker, InputNumber } from 'antd';
import ScrollView from "@/components/memorizeWords/scrollView"
import Empty from "@/components/memorizeWords/empty"
import { connect } from "react-redux";

const formatNumber = (number) => {
    if (number > 10) {
        var tens = Math.floor(number / 10) * 10
        return tens + '+'
    } else {
        return String(number)
    }
}

// 错题本
function MemorizeWords(props) {
    const history = useHistory()
    const [states, events] = Presenter(props)
    const { info, query, planModalVisible, planDate, text, datePickerRef } = states
    const { planModalVisibleChange, planModalOk, planDateChange } = events

    // 所需参数
    const needQuery = `studentId=${query.studentId}&studentName=${query.studentName}&gradeId=${query.gradeId}&classId=${query.classId}&schoolId=${query.schoolId}`;
    // 章节列表
    const chapterWordUrl = `/wordsChapterExerise?${needQuery}&chapterId=${info?.chapterId}&bookVolumeId=${info?.bookVolumeId}`;
    // 章节学习
    const chapterWordStudyUrl = `/studyWordsWay?${needQuery}&type=${1}&chapterId=${info?.chapterId}&chapterName=${encodeURIComponent(info?.chapterName)}&total=${info?.chapterTotalCount || 0}`;
    // 高频学习
    const manyWordUrl = `/studyWordsWay?${needQuery}&type=${2}&chapterId=${0}&total=${info?.highTotalCount || 0}`;
    // 单词复习
    const reviewAllWordUrl = `/wordReview?${needQuery}&dayTime=${new Date().setHours(0, 0, 0, 0)}&chapterId=${0}&count=${info?.reviewTodayCount || 0}&keyCount=${info?.keyReviewTodayCount || 0}`;
    // 重点单词复习
    const reviewKeyWordUrl = `/wordReview?${needQuery}&resultState=${1}&dayTime=${new Date().setHours(0, 0, 0, 0)}&chapterId=${0}&keyCount=${info?.keyReviewTodayCount || 0}`;
    // 学习记录
    const studyRecordUrl = `/wordLearningRecord?${needQuery}&chapterId=${info?.chapterId}&chapterName=${encodeURIComponent(info?.chapterName)}&chapterTotalCount=${info?.chapterTotalCount}&bookVolumeId=${info?.bookVolumeId}&highTotalCount=${info?.highTotalCount || 0}&reviewTodayCount=${info?.reviewTodayCount || 0}`
    // 英语角
    const enCornerUrl = `/wordEnglishCorner?${needQuery}`

    return (
        <>
            <div className="StudentMemorizeWordsPage">
                <PadHeader>
                    <div className="pageHeaderBar">
                        <div className="title">记单词</div>
                        <div className="right">{text}</div>
                    </div>
                </PadHeader>
                <div className="substanceBox">
                    <div className="left">
                        <StudyCard className="card">
                            <div className="title">
                                <div>
                                    <span className="icon">
                                        <img src={require(`@/assets/memorizeWords/menu.svg`).default} alt="_" />
                                    </span>
                                    <span className="name">章节同步练习</span>
                                </div>
                                <div>
                                    <span className="execute" onClick={() => history.push(chapterWordUrl)}>
                                        查看章节
                                        <CaretRightOutlined className="rightIcon" />
                                    </span>
                                </div>
                            </div>
                            <div className="content">
                                <span className="item">
                                    <span className="text">章节单词进度：</span>
                                    <span className="number">
                                        <span className="activeTextNumber" style={{ textDecorationLine: "none" }}>{info?.chapterCount || 0}</span>
                                        <span>/{info?.chapterTotalCount || 0}</span>
                                    </span>
                                </span>
                                <span className="item">
                                    <span className="text">已学单词总数：</span>
                                    <span className="number">
                                        <span>{info?.chapterAllCount || 0}</span>
                                    </span>
                                </span>
                                <span className="item">
                                    <span className="text">今日已学单词：</span>
                                    <span className="number">
                                        <span>{info?.chapterTodayCount || 0}</span>
                                    </span>
                                </span>
                            </div>
                            <div className="footer">
                                {
                                    info?.chapterId ?
                                        <div className="left">
                                            <div className="studyCard">正在学习</div>
                                            <div className="text">{info?.chapterName}</div>
                                        </div>
                                        : <div className="left"></div>
                                }
                                {
                                    info?.chapterId && info?.chapterCount !== info?.chapterTotalCount ?
                                        <div className="button" onClick={() => history.push(chapterWordStudyUrl)}>去练习</div> :
                                        <div className="button" onClick={() => history.push(chapterWordUrl)}>选择章节</div>
                                }
                            </div>
                        </StudyCard>

                        <StudyCard className={`card ${info?.highCount >= info?.highTodayCount && info?.highCount && info?.highCount > 0 ? 'complete' : ''}`}>
                            <div className="title">
                                <div>
                                    <span className="icon">
                                        <img src={require(`@/assets/memorizeWords/Aword.svg`).default} alt="_" />
                                    </span>
                                    <span className="name">高频单词练习</span>
                                </div>
                                <div>
                                    <span className="execute" onClick={() => planModalVisibleChange(true)}>
                                        调整计划
                                        <CaretRightOutlined className="rightIcon" />
                                    </span>
                                </div>
                            </div>
                            <div className="content">
                                <span className="item">
                                    <span className="text">单词进度：</span>
                                    <span className="number">
                                        <span>{info?.highFreqCount || 0}</span>
                                        <span>/{info?.highTotalCount || 0}</span>
                                    </span>
                                </span>
                                <span className="item">
                                    <span className="text">打卡天数：</span>
                                    <span className="number">
                                        <span>{info?.clockInDay || 0}</span>
                                        <span>天</span>
                                    </span>
                                </span>
                                <span className="item">
                                    <span className="text">剩余天数：</span>
                                    <span className="number">
                                        <span>{info?.residueDay || 0}</span>
                                        <span>天</span>
                                    </span>
                                </span>
                            </div>
                            <div className="footer">
                                <div className="left">
                                    <div className="text">依据Deadine，推荐今日学习<span className="numberActive">{info?.highTodayCount || 0}</span>词，今日已学<span className="numberActive">{info?.highCount || 0}</span>词</div>
                                </div>
                                <div className="button"
                                    onClick={() => {
                                        if (info?.clockInDay === undefined || info?.residueDay === undefined || (info?.clockInDay === 0 && info?.residueDay === 0)) return planModalVisibleChange(true);
                                        history.push(manyWordUrl)
                                    }}
                                >去练习</div>
                            </div>
                        </StudyCard>

                        <StudyCard className={`card ${info?.reviewAllCount > 0 && info?.reviewTodayCount === 0 ? 'complete' : ''}`} style={{ background: '#fff' }}>
                            <div className="title">
                                <div>
                                    <span className="name">学习记录</span>
                                    <span className="subName">(依据艾宾浩斯记忆法建议复习，点击蓝色数字开始复习)</span>
                                </div>
                            </div>
                            <div className="content">
                                <span className="item">
                                    <span className="text">今日需复习：</span>
                                    <span className="number"
                                        onClick={() => {
                                            if (!info?.reviewTodayCount || info?.reviewTodayCount === 0) return
                                            history.push(reviewAllWordUrl)
                                        }}
                                    >
                                        <span className={!info?.reviewTodayCount || info?.reviewTodayCount === 0 ? `` : "activeTextNumber"}>{info?.reviewTodayCount || 0}</span>
                                    </span>
                                </span>
                                <span className="item">
                                    <span className="text">今日需重点复习：</span>
                                    <span className="number"
                                        onClick={() => {
                                            if (!info?.keyReviewTodayCount || info?.keyReviewTodayCount === 0) return
                                            history.push(reviewKeyWordUrl)
                                        }}
                                    >
                                        <span className={!info?.keyReviewTodayCount || info?.keyReviewTodayCount === 0 ? `` : "activeTextNumber"}>{info?.keyReviewTodayCount || 0}</span>
                                    </span>
                                </span>
                            </div>
                            <div className="footer">
                                <div className="left">
                                    <div className="word">
                                        <span>已记单词：</span>
                                        <span>{info?.reviewCount || 0}词</span>
                                    </div>
                                    <div className="line"></div>
                                    <div className="word">
                                        <span>重点单词：</span>
                                        <span>{info?.keyReviewCount || 0}词</span>
                                    </div>
                                </div>
                                <div className="button" onClick={() => history.push(studyRecordUrl)}>查看记录</div>
                            </div>
                        </StudyCard>
                    </div>
                    <div className="right">
                        <StudyCard className="card enCard">
                            <div className="title">
                                <div>
                                    <span className="name">英语角</span>
                                </div>
                                <div>
                                    <span className="execute" style={{ color: "#8C92A4" }} onClick={() => history.push(enCornerUrl)} >
                                        前往 <CaretRightOutlined className="rightIcon" />
                                    </span>
                                </div>
                            </div>
                            <div className="trends">
                                <img src={require(`@/assets/memorizeWords/goodActive.svg`).default} className="icon" alt="_" />
                                <div className="text">你的学习动态被点赞了，累计获赞<em> {info?.likeCount || 0} </em>次！</div>
                            </div>
                            <div className="content">
                                <span className="item">
                                    <div className="text">学习排名</div>
                                    <div className="number">
                                        <span>{formatNumber(info?.learningRanking || '--')}</span>
                                    </div>
                                </span>
                                <span className="item">
                                    <div className="text">正确率排名</div>
                                    <div className="number">
                                        <span>{formatNumber(info?.correctRanking || '--')}</span>
                                    </div>
                                </span>
                                <span className="item">
                                    <div className="text">被点赞数</div>
                                    <div className="number">
                                        <span>{info?.likeCount || '--'}</span>
                                    </div>
                                </span>
                            </div>
                        </StudyCard>
                        <div className="ranking">
                            <StudyCard className="card">
                                <div className="title">
                                    <div>
                                        <span className="name">学习周榜</span>
                                    </div>
                                </div>
                                <ScrollView className="list" height="calc(100% - (40vh / 12))">
                                    {
                                        (info?.learnRanks || []).map((v, i) =>
                                            <div className="item" key={i}>
                                                <div className="rank">
                                                    {
                                                        i === 0 ? <img src={require(`@/assets/memorizeWords/NO.1.svg`).default} alt="_" /> :
                                                            i === 1 ? <img src={require(`@/assets/memorizeWords/NO.2.svg`).default} alt="_" /> :
                                                                i === 2 ? <img src={require(`@/assets/memorizeWords/NO.3.svg`).default} alt="_" /> :
                                                                    i + 1
                                                    }
                                                </div>
                                                <div className="name">{v.studentName}</div>
                                                <div className="count">{v.count}词</div>
                                            </div>
                                        )
                                    }

                                    <Empty visible={!info?.learnRanks || info?.learnRanks.length === 0}>
                                        <img src={require(`@/assets/memorizeWords/empty-2.png`).default} style={{ width: "calc(114vh / 12)" }} alt="_" />
                                    </Empty>
                                </ScrollView>
                            </StudyCard>
                            <StudyCard className="card">
                                <div className="title">
                                    <div>
                                        <span className="name">正确率周榜</span>
                                    </div>
                                </div>
                                <ScrollView className="list" height="calc(100% - (40vh / 12))">
                                    {
                                        (info?.correctRanks || []).map((v, i) =>
                                            <div className="item" key={i}>
                                                <div className="rank">
                                                    {
                                                        i === 0 ? <img src={require(`@/assets/memorizeWords/NO.1.svg`).default} alt="_" /> :
                                                            i === 1 ? <img src={require(`@/assets/memorizeWords/NO.2.svg`).default} alt="_" /> :
                                                                i === 2 ? <img src={require(`@/assets/memorizeWords/NO.3.svg`).default} alt="_" /> :
                                                                    i + 1
                                                    }
                                                </div>
                                                <div className="name">{v.studentName}</div>
                                                <div className="count">{(v.correctRate * 100).toFixed(0)}%</div>
                                            </div>)
                                    }
                                    <Empty visible={!info?.correctRanks || info?.correctRanks.length === 0}>
                                        <img src={require(`@/assets/memorizeWords/empty-2.png`).default} style={{ width: "calc(114vh / 12)" }} alt="_" />
                                    </Empty>
                                </ScrollView>
                            </StudyCard>
                        </div>
                    </div>
                </div>

                <TaskModal
                    title="调整计划"
                    icon={(<img src={require(`@/assets/memorizeWords/plan.png`).default} alt="_" />)}
                    className="planModal"
                    visible={planModalVisible}
                    cancel={() => planModalVisibleChange(false)}
                    ok={planModalOk}
                >
                    <DatePicker size="middle"
                        style={{ width: '100%', marginBottom: 'calc(24vh / 12)' }}
                        disabledInput
                        placeholder="选择截止时间"
                        value={planDate}
                        onChange={planDateChange}
                        ref={datePickerRef} />
                    <div className="completion-degree">
                        <span>剩余学习天数：<em>{info?.residueDay || 0}</em></span>
                        <span>每日学习单词数：<em>{info?.highTodayCount || 0}</em></span>
                    </div>
                </TaskModal>
            </div>
        </>
    )
}

export default connect(({ studyWords }) => ({
    studyWords,
}))(MemorizeWords);
