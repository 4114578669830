/*
 * @Author: your name
 * @Date: 2021-12-03 10:05:25
 * @LastEditTime: 2021-12-03 11:41:02
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newGitZnxt2.0\src\api\login.js
 */

import request from "../util/request";
// 任务详情接口
export function getLogin(data) {
  return request({
    url: "/student/login/token",
    method: "post",
    data,
    meta: {
      isSerialize: true,
    },
  });
}

export function getTaskList(params) {
  return request({
    url: "/class/v2/stu/lesson/all/task",
    method: "get",
    params,
  });
}
