/*
 * @Author: your name
 * @Date: 2021-08-23 16:43:11
 * @LastEditTime: 2021-08-23 18:28:22
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/redux/reducers/backState.js
 */
import { UPDATEBACKSTATE } from "../constant";

const initState = 0;
export default function backStateReducer(preState = initState, action) {
  const { type, data } = action;
  switch (type) {
    case UPDATEBACKSTATE:
      return data;
    default:
      return preState;
  }
}
