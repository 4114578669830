import "./index.less"
import SearchInput from "../searchInput"
import DictionaryBriefCard from "@/components/memorizeWords/dictionaryBriefCard"
import DictionaryModal from "@/components/memorizeWords/dictionaryModal"
import { getCollectWordsList, addCollectWord, delCollectWord } from "@/api/memorizeWords";
import ScrollView from "@/components/memorizeWords/scrollView"
import { getParams } from "@/util/util";
import { useEffect, useRef, useState } from "react"

const SecondTab = ({ ...props }) => {
    const [text, setText] = useState("");
    const [collectWordList, setCollectWordList] = useState([]);
    const [collectWordDetail, setCollectWordDetail] = useState(null)
    const [wordModalVisible, setWordModalVisible] = useState(false)
    const [lastId, setLastId] = useState(0)
    const scrollListRef = useRef(null)

    useEffect(() => {
        handleTranslate('')
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    const inputChange = (val) => {
        setText(val.trim())
        if (!val.trim()) handleTranslate(val, 0)
    };

    const handleTranslate = async (val, lastId) => {
        let paramsObj = getParams(window.location.href)
        getCollectWordsList({
            type: 3,
            keyWord: val,
            subjectId: 0,
            studentId: paramsObj.studentId,
            lastId
        }).then((res) => {
            if (lastId !== 0) {
                setCollectWordList([...collectWordList, ...res] || [])
            } else {
                scrollListRef.current.scrollTop = 0
                setCollectWordList(res || [])
            }
            res?.length !== 0 && setLastId(res[res.length - 1]?.id)
        })
    };

    // 单词收藏事件处理
    const onCollectHandle = (flage, info, callBack) => {
        let paramsObj = getParams(window.location.href)

        let params = {
            studentId: paramsObj.studentId,
            subjectId: 0,
            type: 3,
            examId: info?.word || info?.examId
        }

        if (flage) {
            addCollectWord(params).then(res => {
                callBack && callBack(flage, info)
            })
        } else {
            delCollectWord(params).then(res => {
                callBack && callBack(flage, info)
            })
        }
    }

    const setCollectWordListHandle = (flage, index) => {
        setCollectWordList(collectWordList.map((v, i) => i === index ? { ...v, collection: flage } : v))
        // 判断是否是通过单词详情modal 组件触发的
        if (index === collectWordDetail?.index) {
            setCollectWordDetail({ ...collectWordDetail, collection: flage })
        }
    }

    const openWordModal = (info, index) => {
        setCollectWordDetail({ ...info, index })
        setWordModalVisible(true)
    }

    const offWordModal = () => {
        setWordModalVisible(false)
    }


    return (
        <div className="substanceBox secondTabComponent">
            <SearchInput
                className="searchInputMain"
                placeholder="请输入要查找的单词"
                allowClear
                value={text}
                storeKey="collect_search_history_list"
                onChange={(e) => inputChange(e.target.value)}
                onSearch={(val) => handleTranslate(val, 0)}
            />
            <ScrollView className="collectList" onScrollBottom={() => handleTranslate(text, lastId)}>
                {
                    collectWordList.map((v, i) =>
                        <DictionaryBriefCard
                            key={i}
                            wordName={v?.resourceDto?.esWordDto?.word}
                            comment={v?.resourceDto?.esWordDto?.cn}
                            phEn={v?.resourceDto?.esWordDto?.phEn}
                            phEnMp3={v?.resourceDto?.esWordDto?.phEnMp3}
                            phAm={v?.resourceDto?.esWordDto?.phAm}
                            phAmMp3={v?.resourceDto?.esWordDto?.phAmMp3}
                            isCollect={v?.collection === undefined || v?.collection === true}
                            onCollectHandle={() => onCollectHandle(v?.collection === false, v, (flage) => setCollectWordListHandle(flage, i))}
                            bottom="20"
                            className="wordCard"
                            onClick={() => openWordModal(v, i)}
                        />
                    )
                }
            </ScrollView>

            <DictionaryModal
                visible={wordModalVisible}
                onCancel={offWordModal}
                translateList={collectWordDetail?.resourceDto?.esWordDto?.cn?.split(';') || []}
                word={collectWordDetail?.resourceDto?.esWordDto?.word}
                phEn={collectWordDetail?.resourceDto?.esWordDto?.phEn}
                phEnMp3={collectWordDetail?.resourceDto?.esWordDto?.phEnMp3}
                phAm={collectWordDetail?.resourceDto?.esWordDto?.phAm}
                phAmMp3={collectWordDetail?.resourceDto?.esWordDto?.phAmMp3}
                list={collectWordDetail?.resourceDto?.esWordDto?.newSentence}
                extWord={collectWordDetail?.resourceDto?.esWordDto?.extWord?.split(';')}
                isCollect={collectWordDetail?.collection === undefined || collectWordDetail?.collection === true}
                onCollectHandle={() => onCollectHandle(collectWordDetail?.collection === false, collectWordDetail, (flage) => setCollectWordListHandle(flage, collectWordDetail.index))}
            />
        </div>
    )
}


export default SecondTab