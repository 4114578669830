import { useState, useEffect, useRef } from "react";
import { getMoralityHome, getMoralityRecord } from "@/api/morality"
import { getParams, getTodayTimeRange, getThisWeekTimeRange, getThisMonthTimeRange, getThisAcademicYearRange } from "@/util/util";

const timeTabList = [
    {
        name: '今日',
        key: 0,
        params: { ...getTodayTimeRange() }
    },
    {
        name: '本周',
        key: 1,
        params: { ...getThisWeekTimeRange() }
    },
    {
        name: '本月',
        key: 2,
        params: { ...getThisMonthTimeRange() }
    },
    {
        name: '本学年',
        key: 3,
        params: { ...getThisAcademicYearRange() }
    }
]

const Presenter = (props) => {
    const paramsObj = getParams(window.location.href)
    const [timeConfig, setTimeConfig] = useState(timeTabList)
    const [activeTime, setActiveTime] = useState(0)
    const [adminData, setAdminData] = useState({})
    const [recordList, setRecordList] = useState([])
    const [lastId, setLastId] = useState(0)

    useEffect(() => {
        let params = {
            studentId: paramsObj?.studentId,
            ...timeConfig[activeTime].params
        }

        getMoralityHome(params).then(res => {
            setAdminData(res || {})
        })

    }, [activeTime])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let params = {
            studentId: paramsObj?.studentId,
            pageSize:'15',
            lastId
        }

        getMoralityRecord(params).then(res => {
            if (lastId === 0) {
                setRecordList(res || [])
            } else {
                setRecordList([...recordList, ...(res || [])])
            }
        })

    }, [lastId])  // eslint-disable-line react-hooks/exhaustive-deps


    const changeActiveTime = (key) => {
        setActiveTime(key)
    }

    const loadRecordList = () => {
        setLastId(recordList[recordList.length - 1].id)
    }


    return [
        { timeConfig, activeTime, adminData, recordList },
        { changeActiveTime, loadRecordList }
    ]
};

export default Presenter;
