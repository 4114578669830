import React from 'react';
import Presenter from './presenter.js';
import PadHeader from '@/components/padHeader';
import { Image } from 'antd';
import timeImg from '@/assets/time.svg';
import peopleImg from '@/assets/people.svg';
import stu_0 from '@/assets/avatar/stu_0.png';
import stu_1 from '@/assets/avatar/stu_1.png';
import stu_2 from '@/assets/avatar/stu_2.png';
import stu_3 from '@/assets/avatar/stu_3.png';
import stu_4 from '@/assets/avatar/stu_4.png';
import { CloseOutlined } from '@ant-design/icons';
import { dateFormat } from '@/util/util';
import { baseConfigUrl } from '@/util/configUrl';
import './index.less';

const CooperativeInquiry = (props) => {
  const [states, events] = Presenter();
  const { exploreData, imgsData, students, showGroupsStudents, subTimeOut, queryObj } = states;
  const { upLoadImgs, saveImgs, changeShowGroupsStudents } = events;
  const stuImgs = [stu_0, stu_1, stu_2, stu_3, stu_4];

  return (
    <div className="cooperative_inquiry">
      <PadHeader name={'合作探究讨论'}>
        {subTimeOut && (
          <div className="btns">
            <div className="time">
              <img src={timeImg} alt="icon" /> {subTimeOut}
            </div>
            <div className="groups" onClick={changeShowGroupsStudents}>
              <img src={peopleImg} alt="icon" /> 小组成员
            </div>
            <div className="btn" onClick={upLoadImgs}>
              提交结果
            </div>
          </div>
        )}
      </PadHeader>
      <div className="explore_content">
        <div className="content_view">
          <div className="title">
            <span className="text_icon">{exploreData?.subjectName?.slice(0, 1)}</span>
            <span>{exploreData?.title || ' -'}</span>
          </div>
          <div className="time_view">
            <p className="chapter">探究章节：{exploreData?.chapterName}</p>
            <p className="end_time">
              结束时间：<span className="color">{dateFormat(exploreData?.endTime)}</span>
            </p>
          </div>
          <div className="content">
            <div className="h2">探究内容</div>
            <div className="info_view">
              <p className="info">1、{exploreData?.content && JSON.parse(exploreData?.content || '')?.content}</p>
            </div>
            <div className="imgs_view">
              {exploreData?.content &&
                (JSON.parse(exploreData?.content) || [])?.imgs.map((item, index) => (
                  <Image className="img" src={`${baseConfigUrl}${item}-.jpg`} key={index} />
                ))}
            </div>
          </div>
        </div>

        <div className="result_view content">
          <div className="h2">探究内容</div>
          <div className="imgs_view">
            {imgsData.map((item, index) => (
              <Image className="img" src={`${baseConfigUrl}${item}-.jpg`} key={index} />
            ))}
          </div>
        </div>
      </div>
      {showGroupsStudents && (
        <div className="students">
          <div className="title">
            {encodeURIComponent(queryObj.groupName)} <CloseOutlined onClick={changeShowGroupsStudents} />
          </div>
          <div className="stu_view">
            {students.map((stu, index) => (
              <div className="stu" key={index}>
                <img src={stuImgs[index % 5]} alt="头像" />
                <p className="name" title={stu.name}>
                  {stu.name}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CooperativeInquiry;
