/*
 * @Author: slw 3289360550@qq.com
 * @Date: 2021-10-12 17:57:30
 * @LastEditors: slw 3289360550@qq.com
 * @LastEditTime: 2022-05-17 11:40:00
 * @FilePath: /znxt-student2.0/src/pages/studentAnswer/components/answerCard/index.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import "./index.less";
import Presenter from "./presenter";
import store from "@/redux/store";
import { connect } from "react-redux";
import RewritePop from "@/components/rewritePop";

const AnswerType = (props) => {
  const { isSubmit } = props;

  const [states, events] = Presenter(props);

  const { answerData } = states;
  const { changeDialogStatus, goTo, cardClicked } = events;

  const typeList = ["type", "type1", "type2", "type3"];

  return (
    <div className="AnswerType" onClick={cardClicked}>
      <div className="header">
        <div className="leftArea">
          <span className="title">答题卡</span>
          <div className="rewriteBox">
            (订正:&nbsp;<RewritePop/>&nbsp;)
          </div>
        </div>
        <i className="ri-close-line" onClick={changeDialogStatus}></i>
      </div>
      <div className="examList">
        {(props.answerStates || []).map((v, index) => (
          <div
            onClick={() => goTo(event, v.seq)}
            className={`${
              (v.answerState === 1 && !isSubmit) ||
              (v.answerState === 1 && isSubmit && !props.isShowAnswer)
                ? "active"
                : ""
            } ${
              isSubmit && props.isShowAnswer
                ? typeList[Number(v.resultState)]
                : ""
            } seqItem`}
            key={index}
          >
            {v.seq}
            {
              v.redo === 1 ?
              <div className="positionRewrite">
                <RewritePop/>
              </div>
              : ''
            }
          </div>
        ))}
      </div>
    </div>
  );
};

connect();

export default connect(
  (state) => ({
    answerStates: state.answerStates,
    isShowAnswer: state.isShowAnswer,
  }),
  {}
)(AnswerType);
