import './index.less'
import { initTheme } from "@/assets/js/theme";
import { useState } from 'react';
const Test = () => {

  const [first, setfirst] = useState("smallTheme")

  const click =() => {
    initTheme(first === 'smallTheme' ? 'bigTheme' : 'smallTheme' );
    setfirst(first === 'smallTheme' ? 'bigTheme' : 'smallTheme')
  }

  return (
    <div>
      <button onClick={click}>切换</button>
      <p className="aaa">asdadsadas</p>
    </div>
  )
}

export default Test;