import { useEffect, useState } from "react"
import { getParams } from "@/util/util"
import { getStudentWordRank, getStudentWordStatistics, getLearnDynamics, getStudentLearnLike } from "@/api/memorizeWords"
import { topRightText, randomArr } from "@/components/memorizeWords/topRightText"

const Presenter = (props) => {
    const query = getParams(window.location.href);
    const [wordStudyRank, setWordStudyRank] = useState([])
    const [studentStaticData, setStudentStaticData] = useState(null)
    const [learnListLastId, setlearnListLastId] = useState(0)
    const [learnDynamicsList, setLearnDynamicsList] = useState([])
    const [text, setText] = useState('')

    useEffect(() => {
        // 获取榜单信息
        getStudentWordRank(query).then(res => {
            setWordStudyRank(res)
        })
        // 个人数据
        getStudentWordStatistics({ ...query, size: 10 }).then(res => {
            setStudentStaticData(res)
        })

        setText(randomArr(topRightText))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        // 学习动态
        getLearnDynamics({ ...query, size: 15, lastId: learnListLastId }).then(res => {
            if (learnListLastId === 0) {
                setLearnDynamicsList(res)
            } else {
                setLearnDynamicsList(list => [...list, ...(res || [])])
            }
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [learnListLastId])

    const wordLearnLikeChange = (info, index) => {
        if (info.count !== 0) return
        // classId  gradeId learningSpaceId  fromStudentId fromStudentName schoolId
        let params = {
            classId: query.classId,
            gradeId: query.gradeId,
            schoolId: query.schoolId,
            fromStudentId: query.studentId,
            fromStudentName: decodeURIComponent(query.studentName),
            learningSpaceId: info.id,
            toStudentId: info.studentId,
            toStudentName: info.studentName
        }
        getStudentLearnLike(params)
        setLearnDynamicsList(list => {
            list[index]['count'] = 1;
            return [...list]
        })
    }

    const loadMoreLearnDynamicsList = () => {
        setlearnListLastId(learnDynamicsList[learnDynamicsList.length - 1]['id'])
    }

    return [
        { wordStudyRank, studentStaticData, learnDynamicsList, text },
        { wordLearnLikeChange, loadMoreLearnDynamicsList }
    ]
}

export default Presenter