/*
 * @Author: your name
 * @Date: 2021-07-26 10:46:22
 * @LastEditTime: 2022-02-21 19:34:32
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/components/answerList/components/judgeExam/presenter.js
 */

import { useState, useEffect } from "react";
import { addCourseAnswer } from "@/api/answer";
import store from "@/redux/store";

const Presenter = (props) => {
  const { info, isSubmit, inExamSeq, submitAnswerInfo, isMistakes } = props;

  const [activeIndex, setActiveIndex] = useState(-1);

  useEffect(() => {

    if(isMistakes) {
      if(!isSubmit) {
        setActiveIndex(-1)
      }
    }

    // 初始化答案
    // eslint
    let answer = JSON.parse(info.answer || "[]");
    if (answer.length > 0) {
      setActiveIndex(Number(answer[0].a));
    }
    // console.log(props,'触发---------------------------');
  }, [props.examItem, props.isSubmit,props.info.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeActiveIndex = (index, v, info) => {
    if (isSubmit) return;
    setActiveIndex(v.seq);
    let optionList = [
      {
        examTypeId: info?.resource?.exam?.examTypeId,
        selectSeq: v.seq, // 判断题，存放0、1分别表示错误、正确
      },
    ];

    let obj = {
      lessonStudentId: store.getState().courseInfo.id,
      resourceId: info?.resource?.exam?.id,
      resourceType: 2,
      usedTime: store.getState().updateTime,
      optionList,
      inExamSeq: inExamSeq,
      isAdd: activeIndex === -1,
    };

    if (inExamSeq || inExamSeq === 0) {
      submitAnswerInfo(inExamSeq, obj);
    } else {
      if (isMistakes) {
        isMistakes(obj)
        return
      }
      addCourseAnswer(obj).then((res) => { });
    }
  };
  return [
    {
      activeIndex,
    },
    {
      changeActiveIndex,
    },
  ];
};

export default Presenter;
