import { Input } from "antd";
import "./index.less"
import { useState, useEffect } from "react";


const SearchInput = ({ className, onSearch, onChange, onFocus, onBlur, storeKey, historySize, value, ...props }) => {

    const [isShowHistory, setIsShowHistory] = useState(false);
    const [historyList, setHistoryList] = useState([]);

    useEffect(() => {
        if (isShowHistory && storeKey) {
            setHistoryList(JSON.parse(localStorage.getItem(storeKey) || "[]"))
        }
    }, [isShowHistory, storeKey])

    const onChangeHandle = (e) => {
        setIsShowHistory(e.target.value.length === 0)
        onChange && onChange(e)
    }

    const onFocusHandle = (e) => {
        e.target.value.length === 0 && setIsShowHistory(true);
        onFocus && onFocus(e)
    }

    const onBlurHandle = (e) => {
        setTimeout(() => setIsShowHistory(false), 150)
        onBlur && onBlur(e)
    }

    const onSearchHandle = (value) => {
        let newList = [...new Set([value?.trim(), ...historyList])]

        if (storeKey) {
            setHistoryList(newList)
            // 存储历史搜索列表
            localStorage.setItem(storeKey, JSON.stringify(newList.filter(v => !!v).splice(0, historySize || 20)))
        }

        onSearch && onSearch(value)
    }

    // 点击历史记录触发
    const onHistoryItemHandle = (value) => {
        onChange({ target: { value } })
        onSearchHandle(value)
    }

    return (
        <div className={`searchInputComponent${' ' + className || ''}`}>

            <div className="inputAdmin">
                <Input
                    size="large"
                    className="input"
                    onChange={onChangeHandle}
                    onFocus={onFocusHandle}
                    onBlur={onBlurHandle}
                    onPressEnter={() => onSearchHandle(value)}
                    value={value || ''}
                    {...props}
                />

                <div className="warp" style={{ maxHeight: isShowHistory ? 'calc(390vh / 12)' : '0' }}>
                    <div className="historyList" >
                        <div className="title">最近搜索</div>
                        <div className="list">
                            {historyList.map((v, i) => v && <span key={i} onClick={() => onHistoryItemHandle(v)}>{v}</span>)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="search" onClick={() => onSearchHandle(value)}>查询</div>
        </div >
    )
}


export default SearchInput