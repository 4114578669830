import { useEffect, useState } from "react"
import { getDatabankRelationDetail, getWrongQuestionAdd } from '@/api/answer'
import { getParams } from "@/util/util"
import messageHint from "@/pages/mistakesRearrange/compoents/messageHint"


const Presenter = (props) => {
    let queryObj = getParams(window.location.href);
    const [examList, setExamList] = useState([])

    useEffect(() => {
        getExamDetailList()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const addMistakeBook = async ({resourceId,score,errorType}) => {
        const { studentId, gradeId, classId, subjectNameId } = queryObj
        const params = {
            type:errorType,
            classId,
            examId:resourceId,
            score,
            baseGradeId: gradeId,
            baseSubjectId: subjectNameId,
            userStudentId: studentId
        }

        await getWrongQuestionAdd(params)
        getExamDetailList(() => {
            messageHint(errorType === 0 ? '已将该试题加入错题本' : '已将该错题移出')
        })
    }

    const getExamDetailList = (callBack) => {
        const { studentId, id } = queryObj
            ; (async () => {
                const list = await getDatabankRelationDetail({
                    id,
                    studentId
                })
                setExamList(list || [])
                callBack && callBack()
            })();
    }


    return [
        { examList },
        { addMistakeBook }
    ]
}

export default Presenter