/*
 * @Author: your name
 * @Date: 2022-03-16 08:51:52
 * @LastEditTime: 2022-08-10 16:45:09
 * @LastEditors: c1107 chen1997
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newGitZnxt2.0\src\api\word.js
 */

import request from "../util/request";

// 记单词任务接口
export function getWordExamDetail(params) {
  return request({
    url: "/class/v2/stu/lesson/word/exam/detail",
    method: "get",
    params,
  });
}

// 记单词任务统计接口
export function getWordExamStatistics(params) {
  return request({
    url: "/class/v2/stu/lesson/word/exams/detail",
    method: "get",
    params,
  });
}
// 统计头部
export function getTopStatistics(params) {
  return request({
    url: "/class/v2/stu/lesson/word/statistics",
    method: "get",
    params,
  });
}

// 今日复习
export function getTodayReviewWord(params) {
  return request({
    url: "/class/v2/stu/lesson/getTodayReviewWord",
    method: "get",
    params,
  });
}

// PK1V1
export function getPKList(params) {
  return request({
    url: "/class/v2/teacher/course/pk/1v1",
    method: "get",
    params,
  });
}

// 单词详情
export function getWordDetail(params) {
  return request({
    url: "/class/v2/stu/lesson/word/detail",
    method: "get",
    params,
  });
}

// 词汇表
export function wordTranslate(params) {
  return request({
    url: "/class/v2/teacher/course/word/Translate",
    method: "get",
    params,
  });
}

export function wordJapanese(params) {
  return request({
    url:"/class/v2/teacher/course/word/japanese/Translate",
    method: "get",
    params,
  })
}