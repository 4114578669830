import "./index.less"
import Presenter from "./presenter"
import PadHeader from "@/components/padHeader";
import TopRightBox from "@/components/memorizeWords/topRightBox"
import ScrollView from "@/components/memorizeWords/scrollView"
import { useHistory } from 'react-router-dom'
import { getParams, formatDate } from "@/util/util"


const Morality = (props) => {
    const paramsObj = getParams(window.location.href)
    const history = useHistory()
    const [states, events] = Presenter(props)
    const { timeConfig, activeTime, adminData, recordList } = states
    const { changeActiveTime, loadRecordList } = events

    return (
        <div className="moralityPage">
            <PadHeader>
                <div className="pageHeaderBar">
                    <div className="title">德育评价</div>
                    <div className="right">
                        {
                            adminData?.state === 1 &&
                            <TopRightBox className="manner" onClick={() => history.push(`/moralityEnter?studentId=${paramsObj.studentId}&levelId=${paramsObj.levelId}&classId=${paramsObj.classId}&studentName=${paramsObj.studentName}&className=${paramsObj.className}&schoolId=${paramsObj.schoolId}&gradeId=${paramsObj.gradeId}`)}>
                                <img src={require(`@/assets/icon/edit.png`).default} alt="_" />
                                <span>录入本班点评</span>
                            </TopRightBox>
                        }

                    </div>
                </div>
            </PadHeader>
            <div className="substanceBox">
                <div className="left">
                    <div className="timeBar">
                        {timeConfig.map(v => <div className={`item${activeTime === v.key ? ' active' : ''}`} key={v.key} onClick={() => changeActiveTime(v.key)}>{v.name}</div>)}
                    </div>
                    <div className="user card">
                        <div className="title">
                            <i className="icon" />
                            <div className="text">个人德育评分</div>
                        </div>
                        <div className="fraction">
                            <div className="item">
                                <div className="number">{adminData?.allScore || '-'}</div>
                                <div className="text">总评分</div>
                            </div>
                            <div className="item">
                                <div className="number">{adminData?.perGoodScore || '-'}</div>
                                <div className="text">表扬得分</div>
                            </div>
                            <div className="item">
                                <div className="number">{adminData?.perBadScore || '-'}</div>
                                <div className="text">待改进</div>
                            </div>
                            <div className="item">
                                <div className="number">{adminData?.preRank || '-'}</div>
                                <div className="text">班级排名</div>
                            </div>
                        </div>
                        <div className="table">
                            {
                                adminData?.commentStudentRecordRpcDtos?.map(v =>
                                    <div className={`td ${v.classLabelType === 1 ? 'right' : 'error'}`} key={v.id}>
                                        <div className="text">{v?.classLabelName}</div>
                                        <div className="count">{v?.score}</div>
                                    </div>)
                            }
                        </div>
                    </div>
                    <div className="group card">
                        <div className="title">
                            <i className="icon" />
                            <div className="text">小组德育评分</div>
                        </div>
                        <div className="groupName">
                            <span className="name">{adminData?.classeGroupStudentRpcDtos?.[0]?.groupName}</span>
                            <span className="subName">（{adminData?.classeGroupStudentRpcDtos?.map(v => v.studentName)?.join('、')}）</span>
                        </div>
                        <div className="fraction">
                            <div className="item">
                                <div className="number">{adminData?.groupAllScore || '-'}</div>
                                <div className="text">总评分</div>
                            </div>
                            <div className="item">
                                <div className="number">{adminData?.groGoodScore || '-'}</div>
                                <div className="text">表扬得分</div>
                            </div>
                            <div className="item">
                                <div className="number">{adminData?.groBadScore || '-'}</div>
                                <div className="text">待改进</div>
                            </div>
                            <div className="item">
                                <div className="number">{adminData?.groRank || '-'}</div>
                                <div className="text">班级排名</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right">
                    <div className="record card">
                        <div className="title">
                            <div className="text">点评记录</div>
                        </div>
                        <ScrollView className="list" onScrollBottom={loadRecordList}>
                            {
                                recordList?.map(v =>
                                    <div className="item" key={v.id}>
                                        <div className="t">
                                            {`${v?.classLabelType === 1 ? '表扬' : ''}${v?.studentName}，${v?.classLabelName}`}
                                            {v?.classLabelType === 1 ? <span className="add">+{v?.score}</span> : <span className="dec">{v?.score}</span>}分
                                            {v?.classLabelType === 1 ? <div className="label r">表扬</div> : <div className="label e">批评</div>}
                                        </div>
                                        <div className="st">{formatDate(new Date(v?.createTime))} 由 {v?.createName} 点评</div>
                                    </div>)
                            }
                        </ScrollView>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Morality