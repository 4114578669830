/*
 * @Author: your name
 * @Date: 2022-03-15 09:26:56
 * @LastEditTime: 2022-04-27 18:20:49
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newGitZnxt2.0\src\pages\wordStatistics\presenter.js
 */

import { useState, useEffect, useRef } from "react";
import { timeMethod, getParams, isApp } from "@/util/util";
import { androidHandle } from "@/util/android";
import store from "@/redux/store";
import { PAD_HEADER_HREF } from "@/redux/constant.js";
import {
  getWordExamStatistics,
  getTopStatistics,
  getWordDetail,
} from "@/api/word";

const Presenter = (props) => {
  const [pageTopData, setPageTopData] = useState({}); //头部数据
  const [errWordList, setErrWordList] = useState([]); //错误单词数据
  const [trueWordList, setTrueWordList] = useState([]); //正确单词数据
  const [isModalVisible, setIsModalVisible] = useState(false); //详情弹窗
  const [wordDetails, setWordDetails] = useState({});

  // const activeIndex = useRef(0);

  useEffect(() => {
    initData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const initData = () => {
    let obj = getParams(window.location.href);
    // 单词word
    getWordExamStatistics({
      id: obj.id,
    }).then((res) => {
      let errList = res.filter((v) => {
        return v.resultState !== 1;
      });
      setErrWordList(errList);
      let trueList = res.filter((v) => {
        return v.resultState === 1;
      });
      setTrueWordList(trueList);
    });
    // 头部数据
    getTopStatistics({
      id: obj.id,
    }).then((res) => {
      setPageTopData(res);
    });
  };

  const handleOk = (id) => {
    getWordDetail({
      wordId: id,
    }).then((res) => {
      setWordDetails(res);
      setIsModalVisible(true);
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  // 右上角返回
  const goBack = () => {
    store.dispatch({
      type: PAD_HEADER_HREF,
      data: { href: "" }
    });
    if (isApp()) {
      androidHandle("nativeBackClick");
    } else {
      props.history.push(`/indexPage`);
    }
  };
  return [
    { pageTopData, errWordList, trueWordList, isModalVisible, wordDetails },
    { timeMethod, handleCancel, handleOk, goBack },
  ];
};

export default Presenter;
