/*
 * @Author: your name
 * @Date: 2021-08-12 14:15:46
 * @LastEditTime: 2022-09-16 17:14:50
 * @LastEditors: c1107 chen1997
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/pages/errorBook/presenter.js
 */

import { useState, useEffect, useRef } from "react";
import {
  getSelfLearnList,
  bookversionList,
  getBookVolumesList,
} from "@/api/autonomyPractice";
import { getParams } from "@/util/util";
import store from "@/redux/store";

import { message } from "antd";
const Presenter = () => {
  const [active, setactive] = useState(0);
  const [nowIndex, setnowIndex] = useState(0);
  const [todoList, settodoList] = useState([]);
  const [subjectId, setsubjectId] = useState(0);
  const [subjectName, setsubjectName] = useState("");
  const modalRef = useRef(null);
  const subId = useRef(0);
  const subjectSelectRef = useRef(null);
  const [bookList, setBookList] = useState([]); //教材版本
  const [bookValue, setBookValue] = useState(undefined);
  const [bookVolumesList, setBookVolumesList] = useState([]); //教材版本
  const [bookVolumesVal, setBookVolumesVal] = useState(undefined);

  const obj = getParams(window.location.href);
 
  // useEffect(() => {
  //   if (active === 0) {
  //   } else {
  //   }
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (bookVolumesVal) {
      getSynchroList(bookVolumesVal);
      store.dispatch({
        type: "updateAnswerStates",
        data: [],
      });
    }
  }, [bookVolumesVal]); // eslint-disable-line react-hooks/exhaustive-deps

  const getList = (isInit) => {
    if (!subjectId) return;
    let pageObj = getParams(window.location.href);
  };

  const onChange = (flag) => {
    setnowIndex(flag);
  };

  const changeSubject = (val, name) => {
    let pageObj = getParams(window.location.href);
    setsubjectName(name || decodeURIComponent(pageObj.subjectName));
    setsubjectId(val);
    subId.current = val;
    getBookversionList(val);
  };

  const getBookversionList = (subjectId) => {
    bookversionList({
      levelId: obj.levelId,
      subjectId: subjectId,
    }).then((res) => {
      if (res.length > 0) {
        setBookList(res);
        setBookValue(res[0].id || undefined);
        getBookVolumesListApi(res[0].id);
      } else {
        console.log("学科下没有版本");
        setBookList([]);
        setBookValue(undefined);
        getBookVolumesListApi(undefined);
        settodoList([]);
      }
    });
  };
  const changeBook = (val) => {
    setBookValue(val);
    getBookVolumesListApi(val);
  };

  const getBookVolumesListApi = (baseVersionId) => {
    if (baseVersionId) {
      getBookVolumesList({
        baseVersionId: baseVersionId,
        subjectId: subId.current,
      }).then((res) => {
        if (res.length > 0) {
          setBookVolumesVal(res[0].id);
          console.log(res[0].id);
        }
        setBookVolumesList(res);
      });
    } else {
      setBookVolumesVal(undefined);
      setBookVolumesList([]);
    }
  };

  const changeBookVolumes = (val) => {
    setBookVolumesVal(val);
  };

  const getSynchroList = (bookId) => {
    getSelfLearnList({
      studentId: obj.studentId,
      type: 1,
      volumeIdChapterId: bookId,
    }).then((res) => {
      settodoList(res || []);
    });
  };

  const changeActive = (a) => {
    setactive(a);
    if (a === 1) {
      getqueryHomeworkList();
    } else {
      getList();
    }
  };

  return [
    {
      active,
      modalRef,
      nowIndex,
      todoList,
      subjectSelectRef,
      bookValue,
      bookList,
      bookVolumesVal,
      bookVolumesList,
    },
    {
      onChange,
      changeActive,
      changeSubject,
      changeBook,
      changeBookVolumes,
    },
  ];
};

export default Presenter;
