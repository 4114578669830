import "./index.less";
import PadHeader from "@/components/padHeader";
import Presenter from "./presenter.js";
import DictionaryModal from "@/components/memorizeWords/dictionaryModal"
import TaskModal from "@/components/memorizeWords/taskModal"
import DictionaryBriefCard from "@/components/memorizeWords/dictionaryBriefCard"
import ToneGenerator from "@/components/memorizeWords/toneGenerator"
import DictionaryComment from "@/components/memorizeWords/dictionaryComment"
import DictionarySentence from "@/components/memorizeWords/dictionarySentence"
import TopRightBox from "@/components/memorizeWords/topRightBox"
import ScrollView from "@/components/memorizeWords/scrollView"
import { getParams } from "@/util/util"
import { useHistory } from 'react-router-dom'
import { connect } from "react-redux";
import { Switch } from "antd";


function StudyWordsWay(props) {
    const [states, events] = Presenter(props)
    const query = getParams(window.location.href);
    const history = useHistory()

    const { wordMenuList, wordMenuActive, wordDetail, translateList, wordModalVisible, examIds, studyModalVisible, answerList, completeModalVisible, schedule, studySecondRefDOM } = states
    const { wordMenuChange, switchStudyWay, openWordModal, offWordModal, exitStudy, offStudyModal, goBack, goRecords, loadMoreLearnedWordList, switchTestWay, onCollectHandle, setCollectWordListHandle } = events

    return (
        <>
            <div className="StudentStudyWordsWayPage">
                <PadHeader word goBack={exitStudy}>
                    <div className="pageHeaderBar">
                        <div className="title">单词学习</div>
                        <div className="right">
                            {
                                query.total &&
                                <>
                                    <TopRightBox className="pace">
                                        <div className="bar">
                                            <span style={{ width: (schedule / query.total * 100) + '%' }} />
                                        </div>
                                        <span>{schedule}/{query.total}</span>
                                    </TopRightBox>
                                    <TopRightBox className="manner" onClick={switchTestWay}>
                                        <Switch checked={props.studyWords.model} />
                                        <span style={{ marginLeft: 'calc(15vh / 12)' }}>{props.studyWords.model === 0 ? '普通模式' : '困难模式'}</span>
                                    </TopRightBox>
                                </>
                            }
                            <TopRightBox className="manner" onClick={switchStudyWay}>
                                <img src={require(`@/assets/memorizeWords/manner.png`).default} alt="_" />
                                <span>切换学习方式</span>
                            </TopRightBox>
                        </div>
                    </div>
                </PadHeader>

                <div className="substanceBox">
                    {
                        query?.type &&
                        <div className="inlet-btn" onClick={() => history.push(`/wordDetection?examIds=${encodeURIComponent(examIds)}&studentId=${query.studentId}&studentName=${query.studentName}&gradeId=${query.gradeId}&classId=${query.classId}&schoolId=${query.schoolId}&type=${query.type}&chapterId=${query.chapterId}&chapterName=${query.chapterName}`)}>
                            开始检测
                            <i className="next"></i>
                        </div>
                    }

                    {
                        props.studyWords.style === 1 &&
                        <ScrollView className="studyFirst" onScrollBottom={loadMoreLearnedWordList} bottomDistance="100">
                            {
                                wordMenuList.map((v, i) => (
                                    <DictionaryBriefCard
                                        key={i}
                                        wordName={v.word}
                                        comment={v.cn}
                                        phEn={v.phEn}
                                        phEnMp3={v.phEnMp3}
                                        phAm={v.phAm}
                                        phAmMp3={v.phAmMp3}
                                        bottom="20"
                                        isCollect={v.collection}
                                        onCollectHandle={(flage) => onCollectHandle(flage, v, (flage) => setCollectWordListHandle(flage, i))}
                                        onClick={() => openWordModal(i)}
                                    />
                                ))
                            }
                            <div className="box" style={{ height: 'calc(80vh / 12)' }}></div>
                        </ScrollView>
                    }
                    {
                        props.studyWords.style === 2 &&
                        <div className="studySecond" >
                            <ScrollView className="leftWordMenu" onScrollBottom={loadMoreLearnedWordList}>
                                {
                                    wordMenuList.map((v, i) => (
                                        <div className={i !== wordMenuActive ? "warp" : "warp selecte"} onClick={(e) => wordMenuChange(i, e)} key={v.id}>
                                            <div className="inner">
                                                {v.word}
                                            </div>
                                        </div>
                                    ))
                                }
                            </ScrollView>
                            <div className="rightWordDetail" ref={studySecondRefDOM}>
                                <section>
                                    {wordDetail?.phEn ? <ToneGenerator diction={`英`} symbol={wordDetail?.phEn} style={{ backgroundColor: '#F6F7FC' }} mp3Url={wordDetail?.phEnMp3} /> : null}
                                    {wordDetail?.phAm ? <ToneGenerator diction={`美`} symbol={wordDetail?.phAm} style={{ backgroundColor: '#F6F7FC' }} mp3Url={wordDetail?.phAmMp3} /> : null}
                                    <span className="collect" onClick={() => onCollectHandle(!wordDetail?.collection, wordDetail, (flage) => setCollectWordListHandle(flage, wordMenuActive))}>
                                        {wordDetail?.collection ? <><i className="ri-heart-3-fill" />取消收藏</> : <><i className="ri-heart-3-line" />收藏</>}
                                    </span>
                                </section>
                                {
                                    wordDetail?.extWord &&
                                    <section>
                                        <div className="navName key">新东方绿皮书释义：</div>
                                        {
                                            (wordDetail?.extWord?.split(';') || []).map((v, i) => {
                                                return (
                                                    v &&
                                                    <DictionaryComment
                                                        style={{ backgroundColor: '#FFF2F5' }}
                                                        key={i}
                                                        comment={v}
                                                        bottom="20" />
                                                )
                                            })
                                        }
                                    </section>
                                }
                                <section>
                                    <div className="navName">常用释义：</div>
                                    {
                                        (translateList || []).map((v, i) => {
                                            return (
                                                v &&
                                                <DictionaryComment
                                                    key={i}
                                                    comment={v}
                                                    bottom="20" />
                                            )
                                        })
                                    }
                                </section>
                                <section>
                                    <DictionarySentence list={wordDetail?.newSentence} word={wordDetail?.word} />
                                    <div className="box" style={{ height: 'calc(80vh / 12)' }}></div>
                                </section>
                            </div>
                        </div>
                    }
                </div>

                <DictionaryModal
                    visible={wordModalVisible}
                    onCancel={offWordModal}
                    translateList={translateList}
                    word={wordDetail?.word}
                    phEn={wordDetail?.phEn}
                    phEnMp3={wordDetail?.phEnMp3}
                    phAm={wordDetail?.phAm}
                    phAmMp3={wordDetail?.phAmMp3}
                    list={wordDetail?.newSentence}
                    extWord={wordDetail?.extWord?.split(';')}
                    isCollect={wordDetail?.collection}
                    onCollectHandle={(flage) => onCollectHandle(flage, wordDetail, (flage) => setCollectWordListHandle(flage, wordMenuActive))}
                />

                <TaskModal
                    title="提示"
                    visible={studyModalVisible}
                    className="studyRecorder"
                    cancelText="休息一下"
                    okText="继续学习"
                    cancel={goBack}
                    ok={offStudyModal}
                >
                    <p>本次共记忆单词：<span>{answerList.length}词</span> ，首次检测正确率 <span>{(answerList.filter(v => v.isRight).length / answerList.length * 100).toFixed()}%</span>；</p>
                    <p>需重点复习单词：<span>{answerList.filter(v => !v.isRight).length}词</span> ，可在学习记录模块查看。</p>
                </TaskModal>

                <TaskModal
                    visible={completeModalVisible}
                    centrality={<img src={require(`@/assets/memorizeWords/complete.png`).default} alt="_" />}
                    className="completeModal"
                    cancelText="查看记录"
                    okText="返回首页"
                    ok={goBack}
                    cancel={goRecords}
                >
                    <p>你已完成本模块<span>{query?.total}</span>单词学习，perfect！</p>
                </TaskModal>
            </div >
        </>
    )
}

export default connect(({ studyWords }) => ({
    studyWords,
}))(StudyWordsWay);


// export default StudyWordsWay