import "./index.less";
import Presenter from "./presenter";
import ExamItem from "@/components/examItem";
import UploadFile from "@/components/answerList/components/uploadFile";
import PadHeader from "@/components/padHeader";
import HeadHandle from "./components/headHandle";

import { dateFormat } from "@/util/util";
import { Empty } from "antd";

const ListQuestionSheet = () => {
  const [states, events] = Presenter();

  const { examList } = states;
  const { changeType, changeSubject, changeSwitch } = events;
  return (
    <div className="ListQuestionSheet">
      <PadHeader name={"问题记录"}>
        <HeadHandle
          changeSwitch={changeSwitch}
          changeSubject={changeSubject}
          changeType={changeType}
        ></HeadHandle>
      </PadHeader>
      {(examList || []).map((v, index) => (
        <div key={index} className="quesitionItem">
          <ExamItem
            isNotCollect={true}
            isNotQuesition={true}
            index={index}
            examTypeId={v.resourceDto.exam.examTypeId}
            exam={v}
            isSubmit={true}
            isCorrect={true}
          ></ExamItem>
          <QuestionCard info={v.questionSheet} />
        </div>
      ))}

      {examList.length === 0 ? (
        <div className="nodata">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="暂无数据" />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

const QuestionCard = (props) => {
  const { info } = props;

  return (
    <div className="questionCard">
      <div className="head">
        <span>问题类型：{info.askType || "-"}</span>
        <span>提交时间：{dateFormat(info.askTime) || "-"}</span>
      </div>
      {(info?.ask || []).map((v, i) =>
        v?.type === 1 ? (
          <div className="content" key={i}>
            {v.content || "-"}
          </div>
        ) : (
          <UploadFile key={i} imgList={v?.content} isDisable={true} />
        )
      )}
      {info?.reply && info?.reply.length > 0 ? (
        <>
          <div className="head">
            <span>回复：</span>
          </div>

          {(info?.reply || []).map((v, i) =>
            v?.type === 1 ? (
              <div className="content" key={i}>
                {v.content || "-"}
              </div>
            ) : (
              <UploadFile
                key={i}
                // imgList={JSON.parse(v?.content || "[]")}
                imgList={v?.content || []}
                isDisable={true}
              />
            )
          )}
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default ListQuestionSheet;
