/*
 * @Author: your name
 * @Date: 2021-06-17 14:45:39
 * @LastEditTime: 2022-05-17 09:04:06
 * @LastEditors: slw 3289360550@qq.com
 * @Description: In User Settings Edit
 * @FilePath: /react-demo/src/redux/constant.js
 */

/**
 * 该模块适用于定义action对象中type类型的常量值，目的只有一个，便于管理的同时防止程序员单词写错
 */

export const UPDATETIME = "updateTime"; // 作答时间
export const UPDATEWORDTIME = "updateWordTime"; // 单词作答总时间
export const UPDATEEXAM = "updateExam"; // 作答试题信息
export const UPDATECOURSEINFO = "updateCourseInfo"; // 作答试题信息
export const INCREMENTTIME = "incrementTime"; // 增加时间
export const UPDATENOWEXAMINDEX = "updateNowExamIndex"; // 当前作答的试题index
export const UPDATEANSWERSTATES = "updateAnswerStates"; // 作答状态列表
export const UPDATA_REWRITE_STATE = "updateRewriteState"; // 作答状态列表
export const UPDATEANSWERSHOWSTATE = "updateRightAnswerShowState"; // 更新正确答案是否可以查看
export const UPDATEERROREXAMSTATE = "updateErrorExamState"; // 更新正确答案是否可以查看
export const UPDATESUBJECTID = "updateSubjectId"; // 更新subjectId
export const UPDATEBACKSTATE = "updateBackState"; // 更新返回事件的状态时间戳
export const SET_STUDY_RECORDER = "setStudyRecorder"; //记单词 学习单词，检测单词 记录
export const CLEAR_STUDY_RECORDER = "clearStudyRecorder"; //记单词 学习单词，检测单词 记录
export const SET_STUDY_STYLE = "setStudyStyle" //记录学习方式状态
export const PAD_HEADER_HREF = "padHeaderHref" //记录当前页面href 处理pad端返回问题
