import "./index.less"
import { Navigate } from "react-router-dom"
import { KeepAlive } from "react-activation"
import presenter from "./presenter"
import PadHeader from "@/components/padHeader"
import HeadOperation from "./components/headOperation"
import SemesterTrunk from "./components/semesterTrunk"
import RemarkableGrad from "./components/remarkableGrad"
import PercentageList from "./components/percentageList"
import SubjectOperation from "./components/subjectOperation"
import ExhibitionArea from "./components/exhibitionArea"


const StudentAnalysis = (props) => {

    const [states, events] = presenter(props)
    const { flageTabs, semesterTrunkList, remarkable, exhibitionArea } = states
    const { changeFlageTabs, changeExhibitionArea, changeTimeQuantum } = events


    return (
        <>
            <div className="StudentAnalysisWrap">

                <PadHeader>
                    <HeadOperation changeFlageTabs={changeFlageTabs}></HeadOperation>
                </PadHeader>

                <div className={flageTabs ? "block" : "none"}>
                    {semesterTrunkList.length !== 0 ? <SemesterTrunk semesterTrunk={semesterTrunkList} changeTimeQuantum={changeTimeQuantum}></SemesterTrunk> : null}
                    {remarkable ? <RemarkableGrad remarkable={remarkable}></RemarkableGrad> : null}
                    {remarkable ? <PercentageList remarkable={remarkable}></PercentageList> : null}
                </div>

                {
                    flageTabs ? null : (
                        <div>
                            <SubjectOperation exhibitionArea={exhibitionArea} changeExhibitionArea={changeExhibitionArea}></SubjectOperation>
                            {exhibitionArea.length !== 0 ? <ExhibitionArea exhibitionArea={exhibitionArea} flageTabs={flageTabs}></ExhibitionArea> : null}
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default StudentAnalysis