import "./index.less";
import imgIcon from "@/assets/Vector.png";
import Presenter from "./presenter";
import Questionnaire from "@/components/questionnaire";
import { Modal } from "antd";
import { UserOutlined } from '@ant-design/icons';

import AudioPlayer from "@/components/memorizeWords/audioPlayer"
import VideoPlayer from "@/components/memorizeWords/videoPlayer"

const difficulty = {
  0: "容易",
  1: "容易",
  2: "较易",
  3: "中等",
  4: "较难",
  5: "困难",
};

const correctType = {
  1: "优秀解答",
  2: "典型错误",
  3: "粗糙界面",
};

const newExamTypeEnum = {
  1: "单选题",
  2: "多选题",
  3: "判断题",
  4: "简答题",
  6: "填空题",
  12: "阅读理解",
  14: "完形填空",
  16: "综合题",
  17: "填空题",
  18: "7选5",
  19: "综合客观题",
  20: "不定项选择",
  21: "单选题组",
  29: "听读音写单词",
  30: "听读音写句子"
};

const ExamHeader = (props) => {
  const [states, events] = Presenter(props);
  const { dialogRef, isCollect, isGoodAnswerModalOpen, pictureActive, isPictureActiveOpen } = states;
  const { submitQuestion, collectExam, handleGoodAnswerModal, handleGoodAnswerCancel, viewAnswerPicture, handleViewAnswerPicture } = events;
  const { sort, info, isNotCollect, isNotQuesition, isMistakes, wrongRate, isSubmit } = props;
  const { correctList } = info;
  return (
    <>
      <div className="examHeader">
        <div className="left">
          <span>{sort}</span>
          <span>{info?.resource?.exam?.desc || newExamTypeEnum[info?.resource?.exam?.examTypeId]}</span>
          <span>{difficulty?.[info?.resource?.exam?.difficulty]}</span>
          <span>（{info?.scoreFull}分）</span>
        </div>
        <div className="right">
          {/* 批改结果类型 */}
          {
            info?.correctList && info?.correctList.length !== 0 && isSubmit &&
            <div className="correctType" onClick={handleGoodAnswerModal}>
              查看优秀解答
            </div>
          }

          {
            !isNotCollect &&
            <div onClick={collectExam} className="collectExam funbtn">
              {!isCollect ? (
                <div>
                  <i className="ri-heart-3-line"></i>
                  <span>收藏</span>
                </div>
              ) : (
                <div>
                  <i className="ri-heart-3-fill"></i>
                  <span>已收藏</span>
                </div>
              )}
            </div>
          }

          {/* 问题反馈 */}
          {!isNotQuesition ? (
            <div onClick={submitQuestion} className="submitQuestion funbtn">
              <img src={imgIcon} alt="" />
              <span>问题反馈</span>
            </div>
          ) : (
            ""
          )}
        </div>

      </div>
      <Questionnaire ref={dialogRef} info={info} />
      {/* 查看优秀解答modal框 */}
      <Modal visible={isGoodAnswerModalOpen} title="优秀作答" footer={false} onCancel={handleGoodAnswerCancel} width="90vw" destroyOnClose={true} wrapClassName={'GoodAnswerModalbox'}>
        {correctList ? correctList.map((item, index) => {
          return (
            <div className="studentRespondenceContent" key={index}>
              {
                item?.answerType === 4 && item.answer &&
                JSON.parse(item.answer).map((subItem, index) => <AudioPlayer controls url={subItem?.c || subItem?.a} sourceName={item.studentName} key={index} />)
              }
              {
                item?.answerType === 5 && item.answer &&
                <>
                  <div className="title">
                    <div className="icon">
                      <UserOutlined />
                    </div>
                    <div className="studentName">{item.studentName}</div>
                  </div>
                  <div className="imgList">
                    {item.answer ? (
                      <>
                        {JSON.parse(item.answer).map((subItem, index) => {
                          return (
                            <VideoPlayer url={subItem?.c || subItem?.a} key={index} className="videoView" />
                          )
                        })}
                      </>
                    ) : ''}
                  </div>
                </>
              }
              {
                item?.answerType === 3 &&
                <>
                  <div className="title">
                    <div className="icon">
                      <UserOutlined />
                    </div>
                    <div className="studentName">{item.studentName}</div>
                  </div>
                  <div className="imgList">
                    {item.answer ? (
                      <>
                        {JSON.parse(item.answer).map((subItem, index) => {
                          return (
                            <div key={index} className="parcel">
                              <img src={subItem?.c || subItem?.a} alt="_" onClick={() => viewAnswerPicture({ studentName: item.studentName, img: subItem?.c || subItem?.a })} />
                            </div>
                          )
                        })}
                      </>
                    ) : ''}
                  </div>
                </>
              }
            </div>
          )
        }) : null}
      </Modal>
      {/* 查看优秀作答大图 */}
      <Modal title={pictureActive.studentName + "的优秀作答"} visible={isPictureActiveOpen} footer={false} width="90vw" onCancel={handleViewAnswerPicture} key={Math.random()}>
        <img src={pictureActive.img} alt="" style={{ width: "100%" }} />
      </Modal>
    </>
  );
};

export default ExamHeader;
