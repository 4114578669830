/*
 * @Author: your name
 * @Date: 2022-03-11 11:15:34
 * @LastEditTime: 2022-04-02 14:29:24
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newGitZnxt2.0\src\components\answerList\components\wordStem\presenter.js
 */

import { useEffect, useState, useRef } from "react";

const EditText = (props) => {
  const { info, type, examTypeId } = props;
  const url = "https://file.aiznclass.com/word";
  useEffect(() => {
    // 自动播放
    // if (info?.phAmMp3) {
    //   if (examTypeId === 22 && (type === null || type === 3)) {
    //     const audios = document.getElementById("musicUSABox");
    //     if (audios) {
    //       playMp3("USA");
    //     }
    //   }
    // }
  }, [info, type, examTypeId]);

  const playMp3 = (type) => {
    if (type === "UK") {
      const audios = document.getElementById("musicUKBox");
      audios.play();
    } else {
      const audios = document.getElementById("musicUSABox");
      audios.play();
    }
  };
  return [{}, { playMp3 }];
};

export default EditText;
