import "./index.less";

import PadHeader from "@/components/padHeader";
import TimeAnt from "@/components/timeAnt";
import SubjectSelect from "@/components/subjectSelect";
import ExamListSelect from "@/components/examListSelect";
import CarouselGenerator from "@/components/carouselGenerator";
import { Progress, Modal, Empty } from "antd";
import { dateFormat } from "@/util/util";
import listPng from "@/assets/newSence/list.png";
import InfiniteScroll from "react-infinite-scroller";
import Presenter from "./presenter";
const ErrorBook = () => {
  const [states, events] = Presenter();
  const {
    active,
    examInfo,
    nowIndex,
    todoList,
    isModal,
    generatorErrorList,
    modalRef,
    subjectSelectRef,
    isDefault,
  } = states;
  const {
    onChange,
    switchExam,
    changeActive,
    goAnswer,
    changeSubject,
    handleOk,
    openModal,
    changemodalActive,
    handleCancel,
    handleChangTime,
    changeExamTemplateId,
    handleInfiniteOnLoad,
  } = events;

  const tabs = [
    {
      desc: "错题列表",
      val: 0,
    },
    {
      desc: "错题练习",
      val: 1,
    },
  ];

  return (
    <div ref={modalRef} className="ErrorBook">
      <Modal
        getContainer={modalRef.current}
        title="错题重做"
        footer={null}
        visible={isModal}
        width="720px"
        onCancel={handleCancel}
      >
        <div className="addModal">
          {(generatorErrorList || []).map((v, i) => (
            <div className="modalCenter" key={i}>
              <div className="title">{v.desc}</div>
              <div className="itemList">
                {(v.list || []).map((el, ii) => (
                  <div
                    onClick={() => changemodalActive(i, ii)}
                    key={ii}
                    className={`item ${ii === v.val ? "active" : ""}`}
                  >
                    {el.desc}
                  </div>
                ))}
              </div>
            </div>
          ))}
          <div className="bottom" onClick={handleOk}>
            <div>生成错题</div>
          </div>
        </div>
      </Modal>

      <PadHeader>
        <div className="errorHead">
          <div className="left">
            {tabs.map((v, index) => (
              <p
                className={`tabItem ${active === index ? "active" : ""}`}
                key={index}
                onClick={() => changeActive(index)}
              >
                {v.desc}
              </p>
            ))}
          </div>
          <div className="right">
            {/* 接口暂时不支持时间筛选 */}
            {active === 0 ? (
              <>
                <TimeAnt handleChangTime={handleChangTime} />
                <div className="bar"></div>
              </>
            ) : (
              ""
            )}

            <SubjectSelect
              ref={subjectSelectRef}
              changeSubject={changeSubject}
              isDefault={isDefault}
            />
            {/* {active === 0 ? (
              <>
                <div className="bar"></div>
                <ExamListSelect changeExamTemplateId={changeExamTemplateId} />
              </>
            ) : (
              ""
            )} */}
            <div className="bar"></div>
            <div className="reDo btn1" onClick={openModal}>
              错题重做
            </div>
          </div>
        </div>
      </PadHeader>
      {active === 0 ? (
        <div className="examList">
          <div className="examListGrid">
            <CarouselGenerator
              nowIndex={nowIndex}
              examInfo={examInfo}
              onChange={onChange}
              switchExam={switchExam}
            />
          </div>
        </div>
      ) : (
        <div className="errorExamPractice">
          <div className="todoCard">
            <InfiniteScroll
              initialLoad={false}
              pageStart={0}
              loadMore={handleInfiniteOnLoad}
              hasMore={true}
              useWindow={false}
            >
              {(todoList || []).map((v) => (
                <div className="card" key={v.id}>
                  <div className="left">
                    <span>{v.subjectName.substring(0, 1)}</span>
                    <span>{v.lessonTeacherName}</span>
                  </div>
                  <div className="cardRight">
                    <div></div>
                    <div className="rightcenter">
                      <div className="between">
                        <div className="top">
                          {/* <span>{v.status}</span> */}
                          <span
                            className={`finishState ${
                              v.status === 0 ? "finish1" : "finish2"
                            }`}
                          >
                            {v.status === 0 ? "未完成" : "已完成"}
                          </span>
                          <span className="examcount">
                            试题：<span className="num">{v.countExam}</span>
                          </span>
                        </div>
                        <div className="bottom">
                          <span>
                            {dateFormat(v.createTime, "yyyy-MM-dd hh:mm")} ~{" "}
                            {dateFormat(v.endTime, "yyyy-MM-dd hh:mm")}
                          </span>
                        </div>
                      </div>
                      <div className="right">
                        <div className="answer">
                          <span>作答进度</span>
                          <Progress
                            strokeColor="rgba(250, 84, 28, 1)"
                            percent={
                              (v.answerCount / Number(v.countExam)) * 100
                            }
                          />
                          <span>
                            {v.answerCount}/{v.countExam}
                          </span>
                        </div>
                        <div className="correct">
                          <span>批改进度</span>
                          <Progress
                            strokeColor="rgba(250, 84, 28, 1)"
                            percent={
                              (v.correctCount / Number(v.answerCount)) * 100
                            }
                          />
                          <span>
                            {v.correctCount}/{v.answerCount}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="goBtn" onClick={() => goAnswer(v)}>
                      {v.status === 0 ? "去作答" : "查看"}
                    </div>
                  </div>
                </div>
              ))}
            </InfiniteScroll>
            {todoList.length === 0 ? (
              <Empty
                image={listPng}
                description={""}
                imageStyle={{
                  height: 300,
                }}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ErrorBook;
