import "./index.less"
import PadHeader from "@/components/padHeader";
import Presenter from "./presenter.js";
import PlanningCalendar from "@/components/memorizeWords/planningCalendar"
import EmptyBg from "@/components/memorizeWords/empty"
import DictionaryBriefCard from "@/components/memorizeWords/dictionaryBriefCard"
import DictionaryModal from "@/components/memorizeWords/dictionaryModal"
import ScrollView from "@/components/memorizeWords/scrollView"
import { getParams, isSameDay } from "@/util/util"
import { useHistory } from 'react-router-dom'

const FastEntryButton = ({ text, url, className, ...props }) => {
    const history = useHistory()
    return (<div className={`btn ${className || ''}`} onClick={() => history.push(url)} {...props}>{text}</div>)
}

function WordLearningRecord(props) {
    const history = useHistory()
    const query = getParams(window.location.href);
    const [states, events] = Presenter(props)
    const { calendar, wordMenuList, dateSolt, currentDayInfo, wordModalVisible, wordDetail, wordActive } = states
    const { getRangeStudyRecord, addWordMenuList, dayActiveChange, openWordModal, offWordModal, onCollectHandle, setCollectWordListHandle } = events

    const needQuery = `studentId=${query.studentId}&studentName=${query.studentName}&gradeId=${query.gradeId}&classId=${query.classId}&schoolId=${query.schoolId}`;
    const reviewAllWordUrl = `/wordDetection?${needQuery}&type=${3}&dayTime=${new Date().setHours(0, 0, 0, 0)}&chapterId=${0}`;
    const manyWordUrl = `/studyWordsWay?${needQuery}&type=${2}&chapterId=${0}&total=${query?.highTotalCount}`;
    const chapterWordUrl = `/wordsChapterExerise?${needQuery}&chapterId=${query?.chapterId}&bookVolumeId=${query?.bookVolumeId}`
    const reviewCurrentWordUrl = `/wordDetection?${needQuery}&startTime=${new Date(dateSolt?.getTime()).setHours(0, 0, 0, 0)}&endTime=${new Date(dateSolt?.getTime()).setHours(23, 59, 59, 999)}&type=${3}&chapterId=${0}`
    const reviewCurrentKeyWordUrl = `/wordDetection?${needQuery}&startTime=${new Date(dateSolt?.getTime()).setHours(0, 0, 0, 0)}&endTime=${new Date(dateSolt?.getTime()).setHours(23, 59, 59, 999)}&resultState=${1}&type=${3}&chapterId=${0}`

    return (
        <div className="WordLearningRecordPage">
            <PadHeader>
                <div className="pageHeaderBar">
                    <div className="title">学习记录</div>
                    <div className="right">注：根据艾宾浩斯记忆曲线，绿色标记日期下的单词需要复习，点击右侧按钮开始复习</div>
                    {
                        query?.reviewTodayCount !== "0" && <FastEntryButton text="复习全部单词" url={reviewAllWordUrl} />
                    }
                </div>
            </PadHeader>
            <div className="substanceBox">
                <div className="left">
                    <PlanningCalendar processList={calendar} onDayChange={(date) => dayActiveChange(date)} onMonthChange={getRangeStudyRecord} className="study-record-calendar" />
                </div>

                <div className="right">
                    <EmptyBg visible={wordMenuList?.length === 0} className="empty-not-study">
                        <img src={require(`@/assets/memorizeWords/empty-1.png`).default} alt="_" />
                        <p className="text">
                            {isSameDay(new Date(), dateSolt) ? '今' : (dateSolt?.getMonth() + 1 + '月' + dateSolt?.getDate())}日没有学习记录
                        </p>
                        <div className="bottom">
                            <FastEntryButton text="高频单词学习" url={manyWordUrl} />
                            <FastEntryButton text="章节同步练习" url={chapterWordUrl} />
                        </div>
                    </EmptyBg>
                    {
                        wordMenuList?.length !== 0 ? <div className="record">
                            <div className="bottom">
                                {
                                    currentDayInfo?.className === "type-today" ?
                                        <>
                                            <FastEntryButton text="高频单词学习" url={manyWordUrl} />
                                            <FastEntryButton text="章节同步练习" url={chapterWordUrl} />
                                        </>
                                        :
                                        currentDayInfo?.className === "type-review" && currentDayInfo?.keyCount !== 0 ?
                                            <>
                                                <FastEntryButton text="复习该日单词" url={reviewCurrentWordUrl} />
                                                <FastEntryButton text="复习重点单词" url={reviewCurrentKeyWordUrl} />
                                            </>
                                            :
                                            currentDayInfo?.className === "type-review" && currentDayInfo?.keyCount === 0 ?
                                                <FastEntryButton text="复习该日单词" url={reviewCurrentWordUrl} />
                                                : null
                                }
                            </div>
                            <div className="title">
                                {isSameDay(new Date(), dateSolt) ? '今' : (dateSolt?.getMonth() + 1 + '月' + dateSolt?.getDate())}日记单词<span>{currentDayInfo?.memorizedCount}词</span>，错误<span>{currentDayInfo?.keyCount}词</span>，点击单词查看详情
                            </div>
                            <ScrollView height="calc(100vh - (265vh / 12))" onScrollBottom={addWordMenuList}>
                                {
                                    wordMenuList.map((v, i) => {

                                        return <DictionaryBriefCard
                                            key={v.id}
                                            point={v.resultState === 2 ? true : false}
                                            bottom="20"
                                            wordName={v?.esWordDto?.word}
                                            comment={v?.esWordDto?.cn}
                                            phEn={v?.esWordDto?.phEn}
                                            phEnMp3={v?.esWordDto?.phAmMp3}
                                            phAm={v?.esWordDto?.phAm}
                                            phAmMp3={v?.esWordDto?.phAmMp3}
                                            direction="vertical"
                                            onClick={() => openWordModal(i)}
                                            isCollect={v?.esWordDto?.collection}
                                            onCollectHandle={(flage) => onCollectHandle(flage, v?.esWordDto, (flage) => setCollectWordListHandle(flage, i))}
                                            uhf={v?.type === 2} //是否是高频
                                        />
                                    })
                                }
                                <div className="box" style={{ height: 'calc(60vh / 12)' }}></div>
                            </ScrollView>
                        </div> : null
                    }
                </div>
            </div>

            <DictionaryModal
                visible={wordModalVisible}
                onCancel={offWordModal}
                translateList={wordDetail?.cn?.split(';')}
                extWord={wordDetail?.extWord?.split(';')}
                word={wordDetail?.word}
                phEn={wordDetail?.phEn}
                phEnMp3={wordDetail?.phEnMp3}
                phAm={wordDetail?.phAm}
                phAmMp3={wordDetail?.phAmMp3}
                list={wordDetail?.newSentence}
                isCollect={wordDetail?.collection}
                onCollectHandle={(flage) => onCollectHandle(flage, wordDetail, (flage) => setCollectWordListHandle(flage, wordActive))}
            />
        </div>
    )
}

export default WordLearningRecord

