import request from "../util/request";
// 学生错题本
export function getSubmitReading(data) {
    return request({
        url: "/class/v2/morningReadingScore/submit",
        method: "post",
        data,
    });
}

export function getAllReadingData(params) {
    return request({
        url: "/class/v2/morningReadingScore/list",
        method: "get",
        params,
    });
}

export function getSubjectList(params) {
    return request({
        url: "/class/v2/morningReadingScore/subject/list",
        method: "get",
        params,
    });
}
