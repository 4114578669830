/*
 * @Author: your name
 * @Date: 2021-07-21 17:35:05
 * @LastEditTime: 2023-02-24 13:50:05
 * @LastEditTime: 2021-09-26 16:00:26
 * @LastEditors: c1107 chen1997
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/api/answer.js
 */
import request from "../util/request";
import store from "@/redux/store";

var updateMarkTimer = null;

// 任务详情接口
export function getCourseDetail(params) {
  return request({
    url: "/class/v2/stu/lesson/detail",
    method: "get",
    params,
  });
}

export function getCourseList(params) {
  return request({
    url: "/class/v2/teacher/course/part/list",
    method: "get",
    params,
  });
}

export function updateMark(data) {
  clearTimeout(updateMarkTimer);
  updateMarkTimer = setTimeout(() => {
    return request({
      url: "/class/v2/stu/lesson/update/mark",
      method: "post",
      data,
    });
  }, 50);
}

// 提交作答
export function addCourseAnswer(data) {
  if (data.optionList && !(data?.optionList?.[0] instanceof Array)) {
    data.optionList = [data.optionList];
  }
  if (data?.isAdd) {
    // 更新作答进度
    let obj = { ...store.getState().courseInfo };
    obj.answerCount += 1;
   console.log(obj);
    store.dispatch({
      type: "updateCourseInfo",
      data: obj,
    });
    delete data.isAdd;
    // 更新作答状态
    let arr = [...store.getState().answerStates];

    if (arr.length) {
      arr[store.getState().nowExamIndex].answerState = 1;
      store.dispatch({
        type: "updateAnswerStates",
        data: arr,
      });
      console.log(arr,'arr');
    }
  }

  return request({
    url: "/class/v2/stu/lesson/answer",
    method: "post",
    data,
  });
}


// 单题详情
export function getExamSignDetail(params) {
  return request({
    url: "/class/v2/stu/lesson/exam/detail",
    method: "get",
    params,
  });
}

// 单题详情
export function submitCourseAnswer(params) {
  return request({
    url: "/class/v2/stu/lesson/submit",
    method: "get",
    params,
  });
}

// 作答情况
export function courseAnswerSituation(params) {
  return request({
    url: "/class/v2/stu/lesson/exam/answer",
    method: "get",
    params,
  });
}

// 作答情况
export function answerModel(data) {
  return request({
    url: "/class/v2/stu/lesson/model/answer",
    method: "post",
    data,
    meta: {
      isSerialize: true,
    },
  });
}

// 提交问题单
export function createQuestionSheet(data) {
  return request({
    url: "/class/v2/stu/lesson/createQuestionSheet",
    method: "post",
    data,
    meta: {
      // isSerialize: true,
    },
  });
}

//练习报告
export function getExamStatistics(params) {
  return request({
    url: "/class/v2/stu/lesson/exam/statistics",
    method: "get",
    params,
  });
}

// 收藏
export function insertLessonTaskStudentCon(data) {
  return request({
    url: "/course/api/student/insertLessonTaskStudentCon",
    method: "post",
    data,
  });
}

// 新收藏删除
export function collectionDel(params) {
  return request({
    url: "/class/v2/stu/lesson/student/collection/del",
    method: "get",
    params,
  });
}

// 新收藏添加
export function collectionAdd(params) {
  return request({
    url: "/class/v2/stu/lesson/student/collection/add",
    method: "get",
    params,
  });
}

export function listQuestionSheet(params) {
  return request({
    url: "/class/v2/stu/lesson/listQuestionSheet",
    method: "get",
    params,
  });
}

//全部学科
export function getBaseSubjectListAll(params) {
  return request({
    url: "/knowledgecenter/baseSubject/listAll",
    method: "get",
    params,
  });
}

// // 我的收藏列表
export function myCollectList(data) {
  return request({
    url: "/course/lessonTaskStudentCollection/list",
    method: "post",
    data,
  });
}

// 新我的收藏列表
export function newMyCollectList(params) {
  return request({
    url: "/class/v2/stu/lesson/student/collection/list",
    method: "get",
    params,
  });
}

// 获取IP池
export function cacheServerIpPool(params) {
  return request({
    url: "/class/v2/config/getIpBySchoolId/v2",
    method: "get",
    params,
    meta: {
      notNeedErrorMessage: true,
    },
  });
}

// 收藏章节筛选接口
export function getVolumeList(params) {
  return request({
    url: "/class/v2/stu/lesson/student/Volume/list",
    method: "get",
    params,
  });
}

// 我的资料库接口
export function myDatabaseList(params) {
  return request({
    url: "/class/v2/stu/resource/list",
    method: "get",
    params,
  });
}

// 我的试卷 - 列表
export function getDataBankList(params) {
  return request({
    url: '/class/v2/student/dataBank/list',
    method: 'get',
    params
  })
}

// 我的试卷 - 学科
export function getDatabankSubjectList(params) {
  return request({
    url: '/class/v2/student/databank/subject/list',
    method: 'get',
    params
  })
}

// 我的试卷 - 查看 - 详情
export function getDatabankRelationDetail(params) {
  return request({
    url: '/class/v2/teacher/databank/relation/detail',
    method: 'get',
    params
  })
}

// 我的试卷 - 添加做题本
export function getWrongQuestionAdd(params) {
  return request({
    url: '/class/v2/student/wrong/question/add',
    method: 'get',
    params
  })
}


export function updateTimeInRepetition(params) {
  return request({
    url: '/class/v2/teacher/timeInRepetition',
    method: 'get',
    params
  })

}




