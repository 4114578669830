import React from "react"
import "./index.less"
import PadHeader from "@/components/padHeader";
import Presenter from "./presenter.js"
import ScrollView from "@/components/memorizeWords/scrollView"
import { dateFormat } from "@/util/util"

const EarlyReading = () => {
    const [states, events] = Presenter();
    const { ReadingScoreList, subjectReadingDetails, todayIsSubmitReading, isOpenCard, otherDayReadingList, subjectConfigList } = states;
    const { changeSubjectReadingRate, submitReadingDetails, changeIsOpen } = events;
    const otherColor = 'grey';

    const subjectScoreClassNameDir = (isSubmit, buttonScore, studentScore, teacherScore) => {
        let currentClassName = '';

        if (isSubmit) {
            buttonScore === teacherScore && (currentClassName = ' teacherScore')
            buttonScore === studentScore && (currentClassName = ' studentScore')
        } else {
            buttonScore === studentScore && (currentClassName = ' active')
        }

        return currentClassName
    }

    return (
        <>
            <div className="EarlyReadingPage">
                <PadHeader>
                    <div className="pageHeaderBar">
                        <div className="title">早读上报</div>
                    </div>
                </PadHeader>
                <div className="substanceBox">
                    <ScrollView>
                        <div
                            className={`todayReadigCard${todayIsSubmitReading ? ' afterSubmit' : ''}`}
                            style={{
                                height: todayIsSubmitReading ? (isOpenCard ? 'calc(580vh / 12)' : 'calc(110vh / 12)') : 'calc(550vh / 12)'
                            }}
                        >
                            <div className="top">
                                <span className="datetime">今日({dateFormat(new Date(), 'yyyy-MM-dd')})</span>
                                {
                                    todayIsSubmitReading &&
                                    <>
                                        <div className="switchKey" onClick={changeIsOpen}>
                                            <i className={`arrow_icon${isOpenCard ? ' open' : ''}`} />
                                            {isOpenCard ? '收起' : '展开'}
                                        </div>
                                        <div className="subjectScoreList">
                                            {
                                                subjectReadingDetails.map((v, i) =>
                                                    <span
                                                        key={i}
                                                        className={`subjectScore ${v?.score ? v.color : otherColor}`}
                                                    >{v.subjectName}: {v?.score || '--'}</span>
                                                )
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="section">
                                <div className="subjectMain">
                                    {
                                        subjectReadingDetails.map((v, i) =>
                                            <div className="subjectItem" key={i}>
                                                <div className="title">
                                                    <img src={require(`@/assets/errorsBook/${v.icon}_0.png`).default} alt="_" />
                                                    {v.subjectName}
                                                </div>
                                                {
                                                    ReadingScoreList.map((buttonScore, j) =>
                                                        <div
                                                            key={j}
                                                            className={`partBtn${subjectScoreClassNameDir(v.isSubmit, buttonScore, v.score, v.teacherScore)}`}
                                                            onClick={() => changeSubjectReadingRate(i, buttonScore)}
                                                        >
                                                            {buttonScore}分
                                                            {subjectScoreClassNameDir(v.isSubmit, buttonScore, v.score, v.teacherScore) === ' teacherScore' ? '(教师)' : ''}
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="operate">
                                    <span className="prompt">注：提交后不可修改，请慎重评分</span>
                                    <span className="submitBtn" onClick={submitReadingDetails}>提交</span>
                                </div>
                            </div>
                        </div>
                        {
                            otherDayReadingList.map((v, i) =>
                                <div className="otherDayReadigCard" key={i}>
                                    <span className="datetime">{dateFormat(new Date(v?.createTime), 'yyyy-MM-dd')}</span>
                                    {
                                        subjectConfigList.map((s, j) =>
                                            <span
                                                key={j}
                                                className={`subjectScore ${v?.morningReadingScoreRpcDtoList.find(c => c.subjectId === s.subjectId)?.score ? s.color : otherColor}`}
                                            >
                                                {s.subjectName}:
                                                {
                                                    v?.morningReadingScoreRpcDtoList.find(c => c.subjectId === s.subjectId)?.teacherScore ||
                                                    v?.morningReadingScoreRpcDtoList.find(c => c.subjectId === s.subjectId)?.score ||
                                                    '--'
                                                }
                                            </span>
                                        )
                                    }
                                </div>
                            )
                        }
                    </ScrollView>
                </div>
            </div>
        </>
    )
}

export default EarlyReading