/*
 * @Author: your name
 * @Date: 2022-03-21 17:04:42
 * @LastEditTime: 2022-04-02 19:57:09
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newGitZnxt2.0\src\pages\wordStatistics\components\wordExamCard\idnex.jsx
 */
import React from "react";

import "./index.less";
import Presenter from "./presenter.js";

import { Popover } from "antd";
const WordExamCard = (props) => {
  const { info, index, type } = props;
  const [states, events] = Presenter(props);
  const url = "https://file.aiznclass.com/word";
  const examType = {
    22: "英译汉",
    23: "汉译英",
    24: "英译汉-pk",
    25: "汉译英-pk",
    26: "英语填空题",
  };

  const ABC = {
    A: 0,
    B: 1,
    C: 2,
    D: 3,
    E: 4,
    F: 5,
  };
  // const {} = states;
  const { playMp3, openDetails } = events;

  return (
    <div className="studentAnswerWordDetailCard">
      <div className="examType">{examType[info.resource.exam.examTypeId]}</div>
      <i
        className="ri-file-list-3-line detailIcon"
        onClick={() => openDetails(info?.wordId)}
      ></i>
      <div className="line1">
        <p className="index">{index + 1}</p>
        <p className="word">{info.resource.esWordDto.word}</p>
        {info.resource.esWordDto.phAm.length ? (
          <>
            <p
              className="voice"
              onClick={() => playMp3("USA", info.resource.esWordDto.phAmMp3)}
            >
              <span>美</span>
              <span>[{info.resource.esWordDto.phAm}]</span>
              <i className="ri-volume-down-line"></i>
              <audio
                id="audio1"
                src={url + info.resource.esWordDto.phAmMp3}
              ></audio>
            </p>
          </>
        ) : (
          ""
        )}

        {info.resource.esWordDto.phEn ? (
          <>
            <p
              className="voice"
              onClick={() => playMp3("UK", info.resource.esWordDto.phEnMp3)}
            >
              <span>英</span>
              <span>[{info.resource.esWordDto.phEn}]</span>
              <i className="ri-volume-down-line"></i>
              <audio
                id="audio2"
                src={url + info.resource.esWordDto.phEnMp3}
              ></audio>
            </p>
          </>
        ) : (
          ""
        )}
      </div>
      <p className="line2">
        <Popover
          placement="topLeft"
          content={info.resource.esWordDto.cn}
          trigger="click"
        >
          释义：{info.resource.esWordDto.cn}
        </Popover>
      </p>
      <p className="line3">
        <Popover
          placement="topLeft"
          content={info.resource.exam.examStem}
          trigger="click"
        >
          题干：{info.resource.exam.examStem}
        </Popover>
      </p>
      {info.resource.exam.examTypeId === 26 ? (
        <>
          <div className="line4">
            作答：
            <span className={type ? "green" : "red"}>
              {JSON.parse(info.answer || "[]").reduce((a, b) => a + b.a, "") ||
                "未作答"}
            </span>
          </div>
          <div className="line5">答案：{info.resource.exam.examAnswer}</div>
        </>
      ) : (
        <>
          <div className="line4">
            作答：
            <span className={type ? "green" : "red"}>
              {(JSON.parse(info.answer || "[]") &&
                JSON.parse(info.answer || "[]")[0] &&
                JSON.parse(info.answer || "[]")[0].a &&
                info.resource.exam.examOptions[
                  JSON.parse(info.answer || "[]")[0].a - 1
                ].content) ||
                "未作答"}
            </span>
          </div>
          <div className="line5">
            答案：
            {
              info.resource.exam.examOptions[ABC[info.resource.exam.examAnswer]]
                .content
            }
          </div>
        </>
      )}
    </div>
  );
};

export default WordExamCard;
