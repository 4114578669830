/*
 * @Author: c1107 chen1997
 * @Date: 2022-05-24 17:09:22
 * @LastEditors: c1107 chen1997
 * @LastEditTime: 2023-02-24 11:05:06
 * @FilePath: \ZNXT-Student2.0\src\pages\myCollect\components\headOperation\index.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from "react";
import "./index.less";
import TimeAnt from "@/components/timeAnt";
import { Input, Select, Checkbox } from "antd";
import SubjectSelect from "@/components/subjectSelect";
import Presenter from "./presenter.js";

const calc = (px) => {
  const clientHeight = window.document.querySelector('body').clientHeight / 100
  return (px * clientHeight / 12) + ''
}
const HeadOperation = (props) => {
  const [states, events] = Presenter(props);

  const { isDefault, typeList, resourcesTypeList, value, checkedVal, activeType } = states;
  const { handleType, changeType, changeInput, changeCheckBox, searchExamName } = events;
  const { Option } = Select;

  return (
    <div className="headOperation">
      <div className="leftTypeBox">
        {typeList.map((v, index) => (
          <div
            style={{
              color: activeType === index + 1 ? "#0043F2" : "#8c8c8c",
            }}
            key={index}
            value={v.type}
            onClick={(e) => handleType(e, v.type)}
          >
            {v.text}
          </div>
        ))}
      </div>
      <div className="rightBox">

        <div>
          {activeType === 1 ? (
            <>
              <Checkbox
                className="cheeckBoxGiao"
                checked={checkedVal}
                onChange={changeCheckBox}
              >
                只看收藏
              </Checkbox>
              <Input
                className="inputBox"
                placeholder="请输入资源名称"
                style={{ width: 200 }}
                onChange={changeInput}
                adjust-position={false}
                always-embed
                hold-keyboard
              />
            </>
          ) : (
            ""
          )}
        </div>


        {activeType === 2 ? (
          <>
            <div>
              <TimeAnt handleChangTime={props.handleChangTime} />
            </div>

            <div className="rightMinBox">
              <SubjectSelect
                isDefault={isDefault}
                changeSubject={props.changeSubject}
              />
            </div>
          </>
        ) : (
          ""
        )}

        {activeType === 3 ? (
          <div className="examSearch">
            <div className="icon">
              <svg t="1684721006047" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2380" width={calc(26)} height={calc(26)}><path d="M756.565333 697.258667c2.133333 1.493333 4.224 3.157333 6.101334 5.12l241.664 241.621333c16.256 16.256 16.512 43.52-0.128 60.16a42.453333 42.453333 0 0 1-60.202667 0.170667l-241.664-241.664a41.429333 41.429333 0 0 1-5.034667-6.101334A424.917333 424.917333 0 0 1 426.666667 853.333333C191.018667 853.333333 0 662.314667 0 426.666667S191.018667 0 426.666667 0s426.666667 191.018667 426.666666 426.666667c0 102.698667-36.266667 196.949333-96.768 270.592zM426.666667 768a341.333333 341.333333 0 1 0 0-682.666667 341.333333 341.333333 0 0 0 0 682.666667z" fill="#3D3D3D" p-id="2381"></path></svg>
            </div>
            <input type="text" placeholder="搜索" onInput={(e) => searchExamName(e)}
              adjust-position={false}
              always-embed
              hold-keyboard />
          </div>
        ) : (
          ""
        )}

      </div>
    </div>
  );
};

export default HeadOperation;
