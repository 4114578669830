import Presenter from "./presenter";
import { Input } from "antd";
import "./index.less";
const { TextArea } = Input;

const TemplateArea = (props) => {
  const { courseDetail } = props;
  const jsonData = JSON.parse(courseDetail?.examInfo?.[0]?.info || "{}");

  const [states, events] = Presenter(props);
  // const {} = states;
  const { onChange } = events;

  console.log(courseDetail);



  return (
    <div className="templateArea">
      {jsonData.name ? <p className="title">{jsonData.name}</p> : ""}
      {(courseDetail?.models?.filter(v => v?.c) || []).map((v, index) => (
        <div className="item" key={index}>
          <p className="itemTitle">{v.n}</p>
          <TextArea
            defaultValue={v.c}
            disabled={v.t !== 2}
            onChange={(e) => onChange(e, index)}
            placeholder="请输入内容"
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </div>
      ))}
      <div className=""></div>
    </div>
  );
};

export default TemplateArea;
