import { useEffect, useRef, useState } from "react"
import React from "react"
import { getParams } from "@/util/util"
import { getWrongQuestionList } from "@/api/mistakesBooks"
import messageHint from "./compoents/messageHint"
import { getWrongQuestionSubmit, getWrongSubjectiveJudge, addCollectMistakeExam, delCollectMistakeExam } from "@/api/mistakesBooks"
import mathJax from "@/util/globalVariable";

const Presenter = (props) => {
    const [wrongQuestionList, setWrongQuestionList] = useState(null)
    const [mistakesType, setMistakesType] = useState()  // 题目类型
    const [lastId, setLastId] = useState(0)
    const [prevId, setPrevId] = useState(0)
    const [number, setNumber] = useState(-1)
    const [direction, setDirection] = useState(1)
    const [answerOptions, setAnswerOptions] = useState([]) //作答的记录 提交时的参数
    const [isSubmit, setIsSubmit] = useState(getParams(window.location.href).isAnswer === '1')
    const [showAction, setShowAction] = useState(true)
    const [isNextButton, setNextButton] = useState(true)
    const [evaluate, setEvaluate] = useState(0)  // 1 正确   2 半对   3 错误


    // 判断是否显示拍照 判断正误  true 拍照需要自己判断正误   false 提交后自动判断正误
    const type = (wrongQuestionList?.resource?.exam?.answerType === 3) ||
        (wrongQuestionList?.resource?.exam?.examTypeId === 6 && wrongQuestionList?.resource?.exam?.answerType === 0) ||
        (wrongQuestionList?.resource?.exam?.examTypeId === 4) ||
        (wrongQuestionList?.resource?.exam?.examTypeId === 16);

    useEffect(() => {
        let paramsObj = getParams(window.location.href)
        mathJax()
            ; (async () => {
                const result = await getWrongQuestionList({
                    ...getParams(window.location.href),
                    size: 1,
                    lastId: lastId
                })

                if (result.data) {
                    let res = result.data[0]
                    setShowAction(true) //如果有下一题 的话 就显示 没有 不做任何操作
                    setNextButton(true) //如果有下一题 的话 下一题按钮是可用状态 没有就是禁用状态

                    // 判断如果是错题重做 则可以改变题的提交状态  如果是查看已清 不根据返回的提交状态改变 一直是提交状态
                    if (paramsObj.clear === '2') {
                        setIsSubmit(res.states === 0 ? false : true)
                    }

                    // 判断是否是已清重做，如果是则需要清除掉作答的记录
                    if (paramsObj.clear === "1" && paramsObj.isAnswer === "0" && !isSubmit) {
                        res.childrenAnswer = "[]"
                        res.answer = "[]"
                    }

                    // 综合题数据处理
                    let copyExamAnswer = JSON.parse(res.childrenAnswer || "[]");
                    res?.resource?.exam?.children?.forEach((element, index) => {
                        element.resource = {};
                        element.resource.exam = {};
                        element.resource.exam = element;
                        element.resource.exam.answer = JSON.stringify(copyExamAnswer[index]) || "";
                    });

                    // 填空题数据处理
                    let data = setFillbackInput(res);

                    setMistakesType(data.resource?.exam?.examTypeId)   // 题型id
                    setWrongQuestionList(data || [])  // 题内容
                    setAnswerOptions([])  // 作答内容 每次请求过来题 将作答内容清空
                    setEvaluate(0)
                    setNumber(number + direction)

                } else {
                    messageHint('最后一道题了哦~')
                    setNextButton(false)
                }

            })();

    }, [lastId]) // eslint-disable-line react-hooks/exhaustive-deps


    const setFillbackInput = (data) => {

        let text = "点击作答"
        if (data?.answerType === 1 && data?.resource?.exam?.examTypeId === 6) {
            /**
             * <input index="1" class=\"cus-com\" readonly=\"readonly\" type=\"text\" value=\"(1)\"/>
             * 为了适应新的填空题，前端需要把一些下划线替换为上面的input标签
             * 1.下划线全部处理成标记
             * 2.index、value的值是动态的
             * 3.逐个替换需要将index和value的值赋上
             */
            // 将_下划线替换为<u>&nbsp;</u>
            let steamRegGiao = new RegExp("(_){1,}");
            let aaa = steamRegGiao.test(data.resource.exam.examStem);

            if (aaa) {
                data.resource.exam.examStem = data.resource.exam.examStem.replace(
                    /(_){2,}/g,
                    `<u>&nbsp;</u>`
                );
            }

            let steamIndex = 1;
            let steamIndex2 = 1;
            let steamReg = new RegExp("(<u>&nbsp;</u>){1,}");
            let steamReg2 = new RegExp("(_){1,}");
            let isLive = steamReg.test(data.resource.exam.examStem),
                isLive2 = steamReg2.test(data?.mark);
            while (isLive || isLive2) {
                isLive = steamReg.test(data.resource.exam.examStem);
                isLive2 = steamReg2.test(data?.mark);
                // let isLive3 = steamReg2.test(data.resource.exam.examStem);

                if (isLive) {
                    data.resource.exam.examStem = data.resource.exam.examStem.replace(
                        /(<u>&nbsp;<\/u>){1,}/,
                        `<input index="${steamIndex}" class="cus-com" readonly="readonly" type="text" value="(${steamIndex})"/>`
                    );
                    steamIndex += 1;
                }

                if (isLive2) {
                    data.mark = data.mark.replace(
                        /(_){2,}/g,
                        `<input index="${steamIndex2}" class="cus-com" readonly="readonly" type="text" value="(${steamIndex2})"/>`
                    );
                    steamIndex2 += 1;
                }
            }

            let allEnglishIds = [7, 8, 9];

            // 还要判断是否已经提交，已经提价的就不能再提交了
            if (
                !(data.states === 0 ? false : true) &&
                allEnglishIds.indexOf(data?.resource?.exam?.baseSubjectId) === -1
            ) {
                data.resource.exam.examStem = data.resource.exam.examStem.replace(
                    /readonly/g,
                    "a"
                );
                if (data.mark) data.mark = data?.mark.replace(/readonly/g, "a");
            }

            // 给input 回填数据
            let answerObj = JSON.parse(data.answer || "[]");
            data.resource.exam.examOptions.forEach((element, index) => {
                var reg = new RegExp('value="\\(' + (index + 1) + '\\)"', "g");
                var reg1 = new RegExp(
                    'index="' + (index + 1) + '" class="cus-com"',
                    "g"
                );

                if (data.states === 0 ? false : true) {
                    let isRight = false;
                    if (answerObj?.[index]?.r === 1) {
                        isRight = true;
                    }
                    data.resource.exam.examStem = data.resource.exam.examStem.replace(
                        reg1,
                        `index="${index + 1}" class="cus-com ${isRight ? "right" : "error"
                        }"`
                    );

                    if (data.mark)
                        data.mark = data.mark?.replace(
                            reg1,
                            `index="${index + 1}" class="cus-com ${isRight ? "right" : "error"
                            }"`
                        );
                }
                //给input宽度使其展示全
                let num = 24 * answerObj?.[index]?.a.length + 20;
                // 答案回填
                data.resource.exam.examStem = data.resource.exam.examStem.replace(
                    reg,
                    `value="${answerObj?.[index]?.a || `${text}`
                    }" style="width:${num}px;padding:0 10px; box-sizing: border-box;"`
                );
                // 答案回填
                if (data.mark) {
                    let reg2 = new RegExp('value="\\' + text + '\\"');
                    if (reg2.test(data.mark)) {
                        data.mark = data.mark?.replace(
                            reg2,
                            `value="${answerObj?.[index]?.a || `${text}`
                            }"  style="width:${num}px;padding:0 10px; box-sizing: border-box;"`
                        );
                    } else {
                        data.mark = data.mark?.replace(
                            reg,
                            `value="${answerObj?.[index]?.a || `${text}`
                            }"  style="width:${num}px;padding:0 10px; box-sizing: border-box;"`
                        );
                    }
                }
            });
        }

        return data
    }

    // 下一题
    const onChangeNext = (message) => {
        if (!isNextButton) return
        let paramsObj = getParams(window.location.href)
        if (paramsObj.clear === "1" && paramsObj.isAnswer === "0") setIsSubmit(false)
        setPrevId(x => lastId)
        setLastId(x => wrongQuestionList.id)
        setDirection(1)

    }

    const onChangePrev = () => {
        setLastId(prevId)
        setDirection(-1)
    }

    // 提交
    const onChangeSubmit = async () => {
        let paramsObj = getParams(window.location.href)
        if (answerOptions.length === 0) {
            messageHint('请作答后再提交')
            return
        }
        getWrongQuestionSubmit({
            id: wrongQuestionList.id,
            optionList: answerOptions,
            lessonStudentAnswerId: wrongQuestionList.lessonStudentAnswerId,
            type: Number(getParams(window.location.href).clear),
            updateTime: +new Date()
        }).then((res) => {
            setIsSubmit(true)

            if (!type) {
                if (res.resultState === 1) {
                    setEvaluate(1)
                    paramsObj.clear === '1' && messageHint('恭喜你答对了')
                    paramsObj.clear === '2' && messageHint('恭喜你答对了，成就树成长值 +1')
                } else {
                    setEvaluate(3)
                    messageHint('答错了，请再接再厉哦~')
                }
            }
        }).catch(err => {
            messageHint('提交失败')
        })
    }

    // 拍照上传题判断正误
    const onChangeJudge = async (verify) => {
        setShowAction(false)  // 判断状态提交后隐藏
        await getWrongSubjectiveJudge({
            id: wrongQuestionList.id,
            type: verify === "right" ? 2 : 0,
            lessonStudentAnswerId: wrongQuestionList.lessonStudentAnswerId
        }).then(() => {
            messageHint(`已将当前作答批改为${verify === "right" ? "正确" : "错误"}`)  //提示
            setEvaluate(verify === "right" ? 1 : 3)  //评价显示 正确错误
        })
    }

    // 作答
    const mistakesChange = (info) => {
        console.log(mistakesType, 'mistakes');
        if ([1, 4, 6, 20, 2, 3].includes(mistakesType)) {
            setAnswerOptions([info.optionList])
        } else {
            setAnswerOptions(info.optionList)
        }
    }

    // 错题收藏
    const isCollectChange = (value) => {
        let paramsObj = getParams(window.location.href)

        let params = {
            studentId: paramsObj.userStudentId,
            subjectId: paramsObj.baseSubjectId,
            type: 2,
            examId: wrongQuestionList.examId,
            lessonStudentId: wrongQuestionList.lessonStudentId
        }
        if (value) {
            addCollectMistakeExam(params).then(res => {
                setWrongQuestionList({ ...wrongQuestionList, collection: value })
            })
        } else {
            delCollectMistakeExam(params).then(res => {
                setWrongQuestionList({ ...wrongQuestionList, collection: value })
            })
        }
    }

    return [
        { wrongQuestionList, isSubmit, number, mistakesType, type, showAction, isNextButton, evaluate },
        { onChangeNext, onChangeSubmit, mistakesChange, onChangeJudge, onChangePrev, isCollectChange }
    ]
}

export default Presenter