import { useEffect, useState } from "react"
import mathJax from "@/util/globalVariable";

const isHttpUrl = /(http|https):\/\/([\w.]+\/?)\S*/;  //判断是否有 url链接


const Presenter = (props) => {

    const { answerHistory } = props

    const [historyData, setHistoryData] = useState([])
    const [imgList, setImgList] = useState([])
    const [switchState, setSwitchState] = useState(false)
    const [imgUrl, setImgUrl] = useState("")
    const [isShowModal, setIsShowModal] = useState(false)

    useEffect(() => {
        if (answerHistory) {
            setImgList([])
            setHistoryData([])
            JSON.parse(answerHistory).forEach((item, index) => {

                if (isHttpUrl.test(item.a)) {  //判断历史作答是否是图片或者文字
                    setImgList(x => {
                        return [...x, { a: item.a, c: item.c }]
                    })
                } else {
                    setHistoryData(x => {
                        return [...x, item.a]
                    })
                }

            });
            mathJax()
        }
    }, [props.answerHistory]) // eslint-disable-line react-hooks/exhaustive-deps

    // 查看历史作答按钮切换
    const onChange = (checked) => {
        setSwitchState(checked)
    };

    // 点击显示历史作答大图
    const showImgModal = (url) => {
        console.log(url, "url---------------------------------");
        setIsShowModal(true)
        setImgUrl(url)
    }

    // 关闭对话框
    const cancelModal = () => {
        setIsShowModal(false)
    }


    return [
        { historyData, imgList, switchState, imgUrl, isShowModal },
        { onChange, showImgModal, cancelModal }
    ]
}

export default Presenter