/*
 * @Author: your name
 * @Date: 2022-03-15 08:58:16
 * @LastEditTime: 2022-04-02 15:29:03
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newGitZnxt2.0\src\components\answerList\components\wordCompletion\presenter.js
 */

import { useState, useRef, useEffect } from "react";
import { Input } from "antd";

const Presenter = (props) => {
  const { info, seq, examTotal } = props;
  const [examWordType, setExamWordType] = useState(1); // 题目类型 1拼写2补全句子
  const [wordLength, setWordLength] = useState([]); //单词长度
  const [sentence, setSentence] = useState(""); // 补全句子题干
  const [chinese, setChinese] = useState(""); // 补全句子中午
  const [isShowKeyboard, setisShowKeyboard] = useState(true); // 默认显示键盘
  const [keyboardType, setKeyboardType] = useState(1); //1正常键盘0简单键盘
  const activeIndex = useRef(0);
  const inputVal = useRef([]);

  useEffect(() => {
    setExamWordType(1);
    setisShowKeyboard(true);
    setWordLength([]);
    activeIndex.current = 0;
    inputVal.current = [];
    if (info?.examOptions) {
      let word = info?.examOptions[0]?.content;
      let newArr = [];
      for (let a = 0; a < word.length; a++) {
        if (word[a] === " ") {
        } else {
          newArr.push(word[a]);
        }
      }
      setWordLength(newArr);
    }

    if (info?.examStem.indexOf("<br/>") !== -1) {
      let arr = info?.examStem.split("<br/>");
      setFillbackInput(arr?.[0]);
      setChinese(arr?.[1]);
      setExamWordType(2);
    }
  }, [info, seq]); // eslint-disable-line react-hooks/exhaustive-deps

  // 设置填空题
  /**
   * 1. 使用p标签进行内容填充（p标签伪类添加光标动画）
   * 2. 默认第一个index对应的p标签闪烁
   * 3. 默认 吊起自定义键盘
   *
   */
  const setFillbackInput = (data) => {
    console.log(data,'data---------------------------');
    let text = data;
    let steamRegGiao = new RegExp("(_){1,}");
    let aaa = steamRegGiao.test(text);
    if (aaa) {
      let word = info?.examOptions[0]?.content;
      let arr = word?.split("");
      let newArr = [];
      let spaceNum = [];
      for (let a = 0; a < arr.length; a++) {
        if (arr[a] === " ") {
          spaceNum.push(a);
        } else {
          newArr.push(arr[a]);
        }
      }
      setWordLength(newArr);

      let index = 0;
      for (let i = 0; i < arr.length; i++) {
        if (spaceNum.indexOf(i) > -1) {
          // 替换空格
          text = text.replace("_", `<span class='Space'></span>`);
        } else {
          // 处理_改为span标签
          text = text.replace(
            "_",
            `<span
              id="active${index}"
              class="inputBox active${index} ${index === 0 ? "active" : ""}"
              ></span>`
          );
          index++;
        }
      }
    }

    let div = `<div class="sentence"> ${text} </div>`;
    setSentence(div);
  };

  // 键盘赋值
  const changeInputValue = (val) => {
    // 删除上次的光标
    let dom = document.querySelector(".sentence").querySelector(".active");
    if (!dom) return;
    // 累加 activeIndex
    dom.innerHTML = val;
    dom.classList.remove("active");
    activeIndex.current++;
    // 给要选中的p标签加className
    let dom1 = document
      .querySelector(".sentence")
      .querySelector(`.active${activeIndex.current}`);
    dom1 && dom1.classList.add("active");

    if (activeIndex.current === wordLength.length) {
      setWordIsTrue();
    }
  };

  // 补全句子判断正误 && 提交
  const setWordIsTrue = () => {
    let answerWord = [];
    for (let i = 0; i < wordLength.length; i++) {
      answerWord.push(document.getElementById(`active${i}`).innerText);
    }

    // 提交
    let answer = answerWord.map((item) => item).join("");
    props.wordAnswer(answer);
    // 显示错误字母
    for (let a = 0; a < wordLength.length; a++) {
      if (
        wordLength[a].toLocaleLowerCase() !== answerWord[a].toLocaleLowerCase()
      ) {
        document.getElementById(`active${a}`).classList.add("errorText");
      } else {
        document.getElementById(`active${a}`).classList.add("trueText");
      }
    }
    // 关闭键盘
    setisShowKeyboard(false);

    setTimeout(() => {
      props.showModal();
    }, 600);
  };

  // 键盘删除操作
  const delInputValue = () => {
    // 递减 activeIndex
    // 1.删除上一个光标2.找到当前的p标签，3.删除内容，4.添加光标
    if (activeIndex.current === 0) return;
    let dom = document.querySelector(".sentence").querySelector(".active");
    if (dom) dom.classList.remove("active");

    activeIndex.current--;
    let dom1 = document
      .querySelector(".sentence")
      .querySelector(`.active${activeIndex.current}`);
    dom1.innerHTML = "";
    dom1.classList.add("active");
  };

  // 键盘点击空格
  const inputOnBlank = (val) => {};

  // 键盘点击对号
  const inputOnTrue = () => {};

  // 键盘隐藏
  const closeKeyBoard = (val) => {
    setisShowKeyboard(false);
  };
  // 键盘打开
  const openKeyBoard = () => {
    setisShowKeyboard(true);
  };

  // 切换键盘
  const onSwitch = (num) => {
    setKeyboardType(num);
  };

  return [
    {
      wordLength,
      inputVal,
      examWordType,
      chinese,
      sentence,
      isShowKeyboard,
      keyboardType,
    },
    {
      changeInputValue,
      closeKeyBoard,
      delInputValue,
      inputOnBlank,
      inputOnTrue,
      onSwitch,
      openKeyBoard,
    },
  ];
};

export default Presenter;
