/*
 * @Author: your name
 * @Date: 2022-03-15 08:57:56
 * @LastEditTime: 2022-04-01 17:17:35
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newGitZnxt2.0\src\components\answerList\components\wordCompletion\index.jsx
 */

import "./index.less";
import WordStem from "../wordStem";
import Presenter from "./presenter";
import Keyboard from "@/components/keyboard";
import KeyboardNew from "@/components/keyboardNew";

import React, { useState } from "react";
import { Input } from "antd";

const WordCompletion = (props) => {
  const { info } = props;
  const [states, events] = Presenter(props);
  const {
    wordLength,
    examWordType,
    chinese,
    sentence,
    isShowKeyboard,
    keyboardType,
  } = states;

  const {
    changeInputValue,
    closeKeyBoard,
    delInputValue,
    inputOnBlank,
    inputOnTrue,
    onSwitch,
    openKeyBoard,
  } = events;
  return (
    <div className="wordCompletion">
      <WordStem info={info} type={examWordType}></WordStem>
      <div className="stemBox">
        {examWordType === 1 ? (
          <>
            <div className="sentence">
              {wordLength.map((v, i) => (
                // <input
                //   type="text"
                //   id={`active${i}`}
                //   className={`inputBox active${i}`}
                //   maxLength={1}
                //   key={i}
                //   onInput={changeInputBox}
                // />
                <span
                  key={i}
                  id={`active${i}`}
                  className={`inputBox active${i} ${i === 0 ? "active" : ""}`}
                  onClick={openKeyBoard}
                ></span>
              ))}
            </div>
          </>
        ) : (
          <>
            <div
              onClick={openKeyBoard}
              dangerouslySetInnerHTML={{ __html: sentence }}
            ></div>
            <div className="chineseTitle">{chinese}</div>
          </>
        )}
      </div>

      {/* 键盘 */}
      {isShowKeyboard ? (
        <div>
          {keyboardType === 1 ? (
            <Keyboard
              onSwitch={onSwitch}
              changeInputValue={changeInputValue}
              closeKeyBoard={closeKeyBoard}
              delInputValue={delInputValue}
              inputOnBlank={inputOnBlank}
              inputOnTrue={inputOnTrue}
            ></Keyboard>
          ) : (
            <KeyboardNew
              onSwitch={onSwitch}
              changeInputValue={changeInputValue}
              closeKeyBoard={closeKeyBoard}
              delInputValue={delInputValue}
              inputOnBlank={inputOnBlank}
              inputOnTrue={inputOnTrue}
            ></KeyboardNew>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default WordCompletion;
