/*
 * @Author: your name
 * @Date: 2021-09-02 15:50:57
 * @LastEditTime: 2022-05-17 14:09:56
 * @LastEditors: slw 3289360550@qq.com
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/components/answerList/components/comprehensive/presenter.js
 */

import { addCourseAnswer } from "@/api/answer";
import { useState, useEffect } from "react";
import store from "@/redux/store";

const Presenter = (props) => {
  const { exam, isSubmit, isMistakes } = props;
  const [imgList, setimgList] = useState([]);
  const [defaultChecked, setdefaultChecked] = useState(isSubmit ? true : false);

  useEffect(() => {
    getImgList(isSubmit ? true : false);
  }, [exam]); // eslint-disable-line react-hooks/exhaustive-deps

  const getImgList = (flag = true, callback) => {
    // 答案回填

    let arr = JSON.parse(exam?.childrenAnswer || exam?.answer || "[]");
    arr =
      arr[
      exam.resource.exam.children.findIndex(
        (v, i) => v.examTypeId !== 1 && v.examTypeId !== 2
      )
      ];
    let newArr = [];
    (arr || []).forEach((element, i) => {
      if (element[flag ? "c" : "a"] || element?.myAnswer) {
        newArr.push({
          uid: i + 1,
          status: "done",
          name: "作答图片",
          url: element[flag ? "c" : "a"] || element?.myAnswer,
        });
      }
    });
    if (callback) callback(newArr);
    setimgList(newArr);
  };

  const onChange = (flag) => {
    setdefaultChecked(flag);
    getImgList(flag);
  };

  // 是否有主观题
  const isHaveSubjective = (arr) => {
    let flag = arr.findIndex((v) => v.examTypeId !== 1 && v.examTypeId !== 2);
    return flag === -1 ? false : true;
  };

  /**
   * @description: 综合题目的集中提交方法， 由子组件触发父组件的事件来触发
   * @param {*}
   * @return {*}
   */
  const submitAnswerInfo = (examSeq, answerInfo, allExams) => {
    console.log(examSeq, answerInfo, allExams);
    // 先检查是否更新作答进度
    let isAddState =
      exam.childrenAnswer && exam.childrenAnswer !== "[]" ? false : true;
    let answerInfoCopy = { ...answerInfo };
    let submitData = [...answerInfo.optionList];
    // 子组件提交的需要维护的是单题详情的answer字段

    if (JSON.parse(exam.childrenAnswer || "[]").length > 0) {
      // console.log(submitData,'submitData-------------------');
      let arr = showbackToAnswer();

      arr[examSeq - 1] = [...answerInfo.optionList];
      answerInfo.optionList = [...arr];
    } else {

      // 第一次提交
      let arr = [];
      exam?.resource?.exam?.children.forEach((element, index) => {
        if (index === examSeq - 1) {
          arr[index] = answerInfo.optionList;
        } else {
          arr[index] = [];
        }
      });

      answerInfo.optionList = arr;
    }
    // 将新的添加到试题详情对象中，方便下次直接获取
    answerInfo.resourceId = exam?.resource?.exam?.id;
    let data = answerInfo.optionList.filter((v) => v.length > 0);
    // 进行图片遍历
    if (allExams) {
      allExams.forEach((v, i) => {
        answerInfo.optionList[v - 1] = submitData;
      });
    }

    if (isMistakes) {
      isMistakes(answerInfo)
      isMistakesAnswerToShowback(examSeq - 1, answerInfoCopy);
      // getWrongQuestionSubmit({
      //   id:,
      //   optionList: answerInfo.optionList,
      //   updateTime: +new Date()
      // }).then(() => {

      // })
     
      return;
    }
    addCourseAnswer({
      ...answerInfo,
      isAdd: isAddState,
    }).then(() => {
      // 成功之后需要转换一下数据到 childrenAnswer中
      answerToShowback(examSeq - 1, answerInfoCopy);
    });
  };

  const showbackToAnswer = () => {
    let defaultArr = new Array(exam?.resource?.exam?.children.length).fill([]);
    let arr = JSON.parse(
      isEmpty(exam?.childrenAnswer)
        ? exam?.childrenAnswer
        : JSON.stringify(defaultArr)
    ); // 获取历史作答数据

    let info = exam?.resource?.exam;
    let resArr = [];
    arr.forEach((element) => {
      let copyArr = [...(element || [])];
      let arr1 = [];
      copyArr.forEach((v, i) => {
        let str = (v.selectSeq || v.a || "") + "";
        let id = v.examTypeId || info.children[i]?.examTypeId;
        arr1.push({
          selectSeq: str.indexOf("http") > -1 ? undefined : str,
          examTypeId: str.indexOf("http") > -1 ? 4 : id,
          // selectSeq: v.selectSeq || v.a,
          // examTypeId: v.examTypeId || info.children[i]?.examTypeId,
          answerType: v.answerType || info.children[i]?.answerType,
          myAnswer: v.myAnswer || v.a,
        });
      });
      resArr.push(arr1 || []);
    });
    return resArr;
  };

  // 图片作答时的回调
  // 主动提交
  const updateAnswer = (imgList, isAdd) => {
    let info = exam;
    let optionList = [];
    imgList.forEach((element) => {
      optionList.push({
        examTypeId:
          info?.resource?.exam?.examTypeId === 4 ||
            info?.resource?.exam?.examTypeId === 6
            ? info?.resource?.exam?.examTypeId
            : 4,
        answerType: 3 || info?.resource?.exam?.answerType,
        myAnswer: element.url,
      });
    });

    let obj = {
      lessonStudentId: store.getState().courseInfo.id,
      resourceId: info?.resource?.exam?.id,
      resourceType: 2,
      usedTime: store.getState().updateTime,
      optionList,
      isAdd: isAdd && info?.answerState === 0,
    };

    // 找到所有主观题小题题号
    let allExams = info?.resource?.exam?.children
      .map((v, i) => {
        if (v.examTypeId !== 1 && v.examTypeId !== 2) {
          return i + 1;
        } else {
          return undefined;
        }
      })
      .filter((v) => v);
    submitAnswerInfo(allExams[0], obj, allExams);
    // addCourseAnswer(obj).then((res) => {});
  };

  // 7选5
  const submitAnswerData = (data) => {
  };

  const isEmpty = (str) => {
    if (str) {
      try {
        let arr = JSON.parse(str);
        if (arr.length > 0) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    } else {
      return false;
    }
  };

  // 将作答的数据整理到 childrenAnswer中
  const answerToShowback = (index, answerInfo) => {
    let originAnswerInfo = { ...answerInfo };
    let cpExam = { ...exam }; // 拷贝试题信息

    cpExam.useTime = store.getState().updateTime
    let defaultArr = new Array(cpExam?.resource?.exam?.children.length).fill(
      []
    );
    let arr = JSON.parse(
      isEmpty(cpExam?.childrenAnswer)
        ? cpExam?.childrenAnswer
        : JSON.stringify(defaultArr)
    ); // 获取历史作答数据
    let answerToSmall = []; // 将作答数据格式转换为回填数据格式
    let newAnswer = [];
    originAnswerInfo.optionList.forEach((element, i) => {
      answerToSmall.push({
        selectSeq: element.selectSeq,
        examTypeId: element.examTypeId,
        answerType: element.answerType,
        myAnswer: element.myAnswer,
      });
      newAnswer.push({
        a: element.selectSeq || element.myAnswer,
        r: 1,
        s: "0.00",
      });
    });
    arr[index] = answerToSmall; // 将新的作答记录填充到 childrenAnswer 中
    // 还需要更新每个小题的答案
    if (cpExam?.resource?.exam?.children?.[index]) {
      cpExam.resource.exam.children[index].answer = JSON.stringify(newAnswer);
    }

    cpExam.childrenAnswer = JSON.stringify(arr || []);
    store.dispatch({ type: "updateExam", data: cpExam });
    console.log(cpExam.childrenAnswer, ' cpExam.childrenAnswer-------------------');
  };

  // 错题本·  // 将作答的数据整理到 childrenAnswer中
  const isMistakesAnswerToShowback = (index, answerInfo) => {
    let originAnswerInfo = { ...answerInfo };
    let cpExam = exam  // 拷贝试题信息 ------------错题本

    cpExam.useTime = store.getState().updateTime
    let defaultArr = new Array(cpExam?.resource?.exam?.children.length).fill(
      []
    );
    let arr = JSON.parse(
      isEmpty(cpExam?.childrenAnswer)
        ? cpExam?.childrenAnswer
        : JSON.stringify(defaultArr)
    ); // 获取历史作答数据
    let answerToSmall = []; // 将作答数据格式转换为回填数据格式
    let newAnswer = [];
    originAnswerInfo.optionList.forEach((element, i) => {
      answerToSmall.push({
        selectSeq: element.selectSeq,
        examTypeId: element.examTypeId,
        answerType: element.answerType,
        myAnswer: element.myAnswer,
      });
      newAnswer.push({
        a: element.selectSeq || element.myAnswer,
        r: 1,
        s: "0.00",
      });
    });
    arr[index] = answerToSmall; // 将新的作答记录填充到 childrenAnswer 中
    // 还需要更新每个小题的答案
    if (cpExam?.resource?.exam?.children?.[index]) {
      cpExam.resource.exam.children[index].answer = JSON.stringify(newAnswer);
    }

    cpExam.childrenAnswer = JSON.stringify(arr || []);
    store.dispatch({ type: "updateExam", data: cpExam });
  };


  return [
    { imgList, defaultChecked },
    {
      isHaveSubjective,
      submitAnswerInfo,
      updateAnswer,
      submitAnswerData,
      onChange,
    },
  ];
};

export default Presenter;
