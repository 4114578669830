/*
 * @Author: c1107 chen1997
 * @Date: 2022-08-31 14:39:29
 * @LastEditors: c1107 chen1997
 * @LastEditTime: 2022-09-16 17:39:10
 * @FilePath: \ZNXT-Student2.0\src\pages\autonomyPractice\components\itemCard\presenter.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from "react";
import { getParams } from "@/util/util";
import { getSelfLearnList, getEduselflearn } from "@/api/autonomyPractice";
import { useState, useEffect, useRef } from "react";
import { message } from "antd";
import store from "@/redux/store";

const Presenter = (props) => {
  const [childrenShow, setChildrenShow] = useState(false);
  const [childrenList, setChildrenList] = useState([]);

  const obj = getParams(window.location.href);

  useEffect(() => {
    setChildrenShow(false);
  }, [props.bookVolumesVal]); // eslint-disable-line react-hooks/exhaustive-deps
  const openChildren = (item, show) => {
    setChildrenShow(show);
    if (show) {
      getChildrenList(item);
    }
  };

  const getChildrenList = (item) => {
    getSelfLearnList({
      studentId: obj.studentId,
      type: 2,
      volumeIdChapterId: item.id,
    }).then((res) => {
      setChildrenList(res);
    });
  };
  const goAnswer = (item, id) => {
    getEduselflearn({ chapterId: item.id, studentId: obj.studentId }).then(
      (res) => {
        if (res) {
          let data =
            {
              lessonStudentId: res?.id,
              id: res?.id,
              levelId: obj.levelId,
              name: item.name + "同步练习",
              studentId: obj?.studentId || res?.studentId,
              studentName: res?.studentName,
              chapterId: item.id,
              countExam: res?.countExam,
              correctState: 1,
              status: res?.status || 0,
            } || {};
          store.dispatch({
            type: "updateCourseInfo",
            data: data,
          });
          window.location.hash = `#/autonomyAnswer?chapterId=${item.id}&studentName=${res?.studentName}&studentId=${obj.studentId}&name=${data.name}&levelId=${obj.levelId}&lessonStudentId=${res?.id}&countExam=${res?.countExam}&isAutonomyPractice=true`;
        } else {
          message.error("该单元下暂无题目！");
        }
      }
    );
  };

  const goCardDetail = (item, dataObj) => {
    if (item.status === 0) {
      let data =
        {
          lessonStudentId: item?.lessonStudentId,
          id: item?.lessonStudentId,
          levelId: obj.levelId,
          name: dataObj.name + "同步练习",
          studentId: obj?.studentId || res?.studentId,
          studentName: "",
          chapterId: dataObj.id,
          countExam: item?.examCount,
          correctState: 1,
          status: item?.status || 0,
        } || {};
      store.dispatch({
        type: "updateCourseInfo",
        data: data,
      });
      window.location.hash = `#/autonomyAnswer?chapterId=${dataObj.id}&studentName=${data?.studentName}&studentId=${obj.studentId}&name=${data.name}&levelId=${obj.levelId}&lessonStudentId=${data?.id}&countExam=${data?.countExam}&chapterId=${item.id}&isAutonomyPractice=true`;
    } else {
      // store.dispatch({
      //   type: "updateAnswerStates",
      //   data: [],
      // });
      window.location.hash = `#/autonomyCheck?id=${
        item.lessonStudentId
      }&studentId=${obj.studentId}&levelId=${obj.levelId}&seq=${
        item.examCount
      }&isAutonomyPractice=true&name=${dataObj.name + "同步练习"}`;
    }
  };

  return [
    { childrenShow, childrenList },
    { goAnswer, openChildren, goCardDetail },
  ];
};

export default Presenter;
