/*
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \sdzn-student2.0\src\pages\liveList\components\shopCart\presenter.js
 * @Description: 观看回放
 */

import { useState, useEffect } from "react";
import { getParams } from "@/util/util";

const Presenter = (props) => {
  const [videosObj, setVideosObj] = useState({ cameraVod: [], screenVod: [] }); // 购买的课程列表

  let urlObj = getParams(window.location.href);
  const getVideosObj = () => {
    let data = JSON.parse(decodeURIComponent(urlObj?.videoObj));
    let videos = {
      cameraVod: data?.cameraVod ? data?.cameraVod.split("|") : [],
      screenVod: data?.screenVod ? data?.screenVod.split("|") : [],
    };
    setVideosObj(videos);
  };

  useEffect(() => {
    getVideosObj();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return [{ videosObj }, {}];
};

export default Presenter;
