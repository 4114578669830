/*
 * @Author: your name
 * @Date: 2022-04-08 14:28:48
 * @LastEditTime: 2022-04-08 16:18:46
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newGitZnxt2.0\src\pages\studentAnswer\components\audioBtn\index.jsx
 */
import "./index.less";
import Presenter from "./presenter";
import { Button } from "antd";
import { SoundOutlined } from "@ant-design/icons";
const AudioBtn = (props) => {
  const [states, events] = Presenter(props);
  const { url } = props;

  const { playENMp3 } = events;

  const { isOver } = states;
  return (
    <div className="playAudioBox">
      <span className="text">播放听力:</span>
      {/* <Button
       
        type="ghost"
        shape="circle"
        icon={<SoundOutlined />}
      /> */}
      <div className="audioBox" onClick={playENMp3}>
        {isOver ? (
          <img
            className="png"
            src={require("@/assets/MP3.png").default}
            alt="icon"
          />
        ) : (
          <img
            className="gif"
            src={require("@/assets/MP3.gif").default}
            alt="icon"
          />
        )}
      </div>
      <audio id="ENMp3" src={url}></audio>
    </div>
  );
};

export default AudioBtn;
