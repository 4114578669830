/*
 * @Author: your name
 * @Date: 2022-03-21 17:05:06
 * @LastEditTime: 2022-03-30 09:12:26
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newGitZnxt2.0\src\pages\wordStatistics\components\wordExamCard\presenter.js
 */

import { useState, useEffect, useRef } from "react";
import { message } from "antd";
import { getParams } from "@/util/util";

const Presenter = (props) => {
  const baseUrl = "https://file.aiznclass.com/word";

  useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps
  const playMp3 = (type, url) => {
    if (type === "UK") {
      const audios = document.getElementById("audio1");
      audios.src = baseUrl + url;
      audios.play();
    } else {
      const audios = document.getElementById("audio2");
      audios.src = baseUrl + url;
      audios.play();
    }
  };

  const openDetails = (id) => {
    props.handleOk(id);
  };

  return [{}, { playMp3, openDetails }];
};

export default Presenter;
