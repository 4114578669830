import "./index.less"
import { Modal } from "antd"

function TaskModal({ className, height, title, titleCenter, centrality, icon, children, cancel, ok, cancelText, okText, ...props }) {

    return (<>
        <Modal wrapClassName={"task-Modal-Component " + className} centered width="calc(600vh / 12)" closable={false} title={null} footer={null} {...props}>
            <div className="content" style={{ height: `calc(${height}vh / 12)` }}>
                {
                    title === null || centrality ? null :
                        <div className={`title ${titleCenter ? 'textCenter' : ""}`}>
                            <div className="l">{title}</div>
                            {icon ? <div className="r icon">{icon}</div> : null}
                        </div>
                }
                {
                    centrality ?
                        <div className="centrality">
                            {centrality}
                        </div> :
                        null
                }
                {children}
                <div className={cancel === false || ok === false ? "footer c" : "footer"}>
                    {cancel === false ? null : <div className="button cancel" onClick={cancel}>{cancelText || '取消'}</div>}
                    {ok === false ? null : <div className="button ok" onClick={ok}>{okText || '确定'}</div>}
                </div>
            </div>
        </Modal>
    </>)
}

export default TaskModal