import React, { useEffect, useState } from "react"

const calc = (px) => {
    const clientHeight = window.document.querySelector('body').clientHeight / 100
    return px * clientHeight / 12
}

// 小树旁边能量球信息
const bubbleObjList = [
    { width: calc(35), height: calc(35), top: calc(205), left: calc(25), opacity: 1, lineHeight: calc(35) + 'px',fontSize: calc(18)+'px' },
    { width: calc(30), height: calc(30), top: calc(90), left: calc(50), opacity: 1, lineHeight: calc(30) + 'px',fontSize: calc(15)+'px' },
    { width: calc(40), height: calc(40), top: calc(140), left: calc(145), opacity: 1, lineHeight: calc(40) + 'px' ,fontSize: calc(20)+'px'},
    { width: calc(38), height: calc(38), top: calc(185), left: calc(210), opacity: 1, lineHeight: calc(38) + 'px' ,fontSize: calc(18.5)+'px'},
    { width: calc(25), height: calc(25), top: calc(65), left: calc(180), opacity: 1, lineHeight: calc(25) + 'px',fontSize: calc(12)+'px' }
]

const Presenter = (props) => {

    const { suckUpChange } = props

    const [bubbleListDate, setBubbleListData] = useState([])

    useEffect(() => {
        setBubbleListData([...bubbleObjList])
    }, [])

    const treeSuckUp = (index, id) => {
        suckUpChange(id)
        setBubbleListData(x => {
            x[index] = { width: 0, height: 0, top: calc(305), left: calc(140), opacity: 0 }
            return [...x]
        })
    }

    return [
        { bubbleListDate },
        { treeSuckUp }
    ]
}

export default Presenter