/*
 * @Author: your name
 * @Date: 2021-06-17 14:54:28
 * @LastEditTime: 2021-08-20 09:53:20
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /react-demo/src/redux/reducers/count.js
 */

import { UPDATECOURSEINFO } from "../constant";

import store from "@/redux/store";

const initState = {};
export default function courseInfoReducer(preState = initState, action) {
  const { type, data } = action;
  switch (type) {
    case UPDATECOURSEINFO:
      return data;
    default:
      return preState;
  }
}
