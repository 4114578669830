/*
 * @Author: c1107 chen1997
 * @Date: 2022-09-15 16:40:58
 * @LastEditors: c1107 chen1997
 * @LastEditTime: 2022-09-15 19:56:39
 * @FilePath: \ZNXT-Student2.0\src\pages\autonomyCheck\components\examFeature\index.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import "./index.less";
import Presenter from "./presenter";
import AnswerCard from "../answerCard";
import { Modal, Switch } from "antd";
import { Link } from "react-router-dom";
import store from "@/redux/store";
import { connect } from "react-redux";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Radio } from "antd";
import AudioBtn from "../audioBtn";
let userInfo = localStorage.getItem("sdzn-userInfo");
const ExamFeature = (props) => {
  const [states, events] = Presenter(props);

  const { weekEnFiles } = props;

  const { isShow, isSubmit, isShowCheck } = states;
  const { changeCardStatus, checkExam } = events;

  return (
    <div className="examFeature">
      <>
        {weekEnFiles?.file?.resourceUrl ? (
          <AudioBtn url={weekEnFiles.file.resourceUrl}></AudioBtn>
        ) : (
          <></>
        )}
      </>
      <>
        {isShowCheck ? (
          <div className="checkBtnBox">
            <Button
              onClick={() => checkExam(1)}
              className="btn btnT"
              type="primary"
              icon={<CheckOutlined />}
              size="large"
            >
              正确
            </Button>
            <Button
              onClick={() => checkExam(2)}
              className="btn btnF"
              type="primary"
              icon={<CloseOutlined />}
              size="large"
            >
              错误
            </Button>
          </div>
        ) : (
          ""
        )}
      </>
      <>
        {/* 答题卡 */}
        <div className="answerCard btn1" onClick={changeCardStatus}>
          <i className="ri-file-edit-fill"></i>
          <span>答题卡</span>
        </div>
      </>
      {/* 答题卡 */}
      {isShow ? (
        <AnswerCard
          isCheck={true}
          isSubmit={isSubmit}
          changeCardStatus={changeCardStatus}
        ></AnswerCard>
      ) : (
        ""
      )}
    </div>
  );
};

connect();

export default connect(
  (state) => ({
    courseInfo: state.courseInfo,
    examItem: state.examItem,
    updateTime: state.updateTime,
    answerStates: state.answerStates,
  }),
  {}
)(ExamFeature);
