import React from "react";
import "./index.less";
import Presenter from "./presenter.js";
import { Image } from "antd";
import { dateFormat } from "@/util/util";
import TopPead from "./components/topHead";

const IndexPage = (props) => {
  const [states, events] = Presenter(props);

  const { indexData, showMore } = states;
  const { bindCard, getSubjectName, moreLearn, getActiveType } = events;

  return (
    <div className="index-page-first-cont-pkt">
      <TopPead getActiveType={getActiveType}></TopPead>
      <div className="tools-body-cont">
        <div className="learn-cont">
          <div className="learn">
            <div className="item-cont">
              {/* <div className="title">
                <span className="name">
                  全部任务
                  <span className="on-finish"></span>
                </span>
              </div> */}
              <div>
                {indexData.map((item, index) => (
                  <div
                    className="item"
                    onClick={() => bindCard(item)}
                    key={index}
                  >
                    <span className="subjectName">
                      {getSubjectName(item.subjectName)}
                    </span>
                    <div className="text">
                      {item.resourceType === 5 && item?.ext > 1 ? (
                        <>
                          <div className="name">
                            {item.lessonTeacherName} - 第{item.ext - 1}次复习
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="name">{item.lessonTeacherName}</div>
                        </>
                      )}

                      <div className="info">
                        {item.teacherName.substring(0, 1)}
                        老师布置的{item.chapterName}
                      </div>
                    </div>
                    <div>
                      <span className="time">
                        {dateFormat(item.createTime)}--
                        {dateFormat(item.endTime)}
                      </span>
                    </div>
                  </div>
                ))}
                {showMore ? (
                  <div className="more_learn" onClick={() => moreLearn()}>
                    加载更多
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndexPage;
