import { useEffect, useState } from "react"


const Presenter = (props) => {
    const { onChangeSubject, onChangeTimer, onChangeDate, onChangeBank, onChangeType } = props

    // 当前学科
    const [subjectActive, setSubjectActive] = useState(0)
    // 时间段
    const [timerList, setTimerList] = useState(['7天', '30天', '全部'])
    const [timerActive, setTimerActive] = useState(0)
    // 时间
    const [timerDate, setTimerDate] = useState(['', ''])
    // 题库
    const [questionBank, setQuestionBank] = useState(2)
    // 题型
    const [questionTypes, setQuestionTypes] = useState(0)
    // 标签
    const [labelName, setLabelName] = useState('')

    useEffect(() => {
        // 阻止antd 日期框在pad上唤醒键盘
        const inputDOM = document.querySelectorAll('.ant-picker-input input');
        inputDOM.forEach(dom => {
            dom.addEventListener('focus', function (event) {
                dom.setAttribute(`readonly`, `readonly`)
                setTimeout(() => {
                    dom.setAttribute(`readonly`, `readonly`)
                }, 0);
            });
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // 学科切换触发
    const subjectListChange = (i, id) => {
        setSubjectActive(x => i)
        onChangeSubject(id)
    }

    // 时间切换触发
    const timerListChange = (i) => {
        setTimerActive(x => i)
        onChangeTimer(i + 1)
        setTimerDate(['', ''])
        onChangeDate(['', ''])
    }

    // 日期切换触发
    const rangePickerChange = (value) => {
        setTimerDate([...value])
        let start = new Date(new Date(value[0]).setHours(0, 0, 0, 0)).getTime()
        let end = new Date(new Date(value[1]).setHours(0, 0, 0, 0)).getTime() * 1 + 24 * 60 * 60 * 1000

        onChangeDate([start, end])
        setTimerActive(-1)
        onChangeTimer(-1)
    }

    // 题库切换触发
    const onChangeQuestionBank = (e) => {
        setQuestionBank(x => e.target.value)
        onChangeBank(e.target.value)
    }

    // 题型切换触发
    const onChangeQuestionType = (e) => {
        setQuestionTypes(x => e.target.value)
        onChangeType(e.target.value)
    }

    // 标签搜索
    const updateSearchLabelName = (value) => {
        setLabelName(value)
        props.onChangeLabelName(value)
    }

    return [
        { subjectActive, timerList, timerActive, timerDate, questionBank, questionTypes, labelName },
        { subjectListChange, timerListChange, rangePickerChange, onChangeQuestionBank, onChangeQuestionType, updateSearchLabelName }
    ]
}

export default Presenter