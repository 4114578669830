import React, { useEffect } from "react";
import "./index.less";

// import Presenter from "./presenter.js";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;
const TimeAnt = (props) => {
  useEffect(() => {
    // 阻止antd 日期框在pad上唤醒键盘
    const inputDOM = document.querySelectorAll('.ant-picker-input input');
    inputDOM.forEach(dom => {
      dom.addEventListener('focus', function (event) {
        dom.setAttribute(`readonly`, `readonly`)
        setTimeout(() => {
          dom.setAttribute(`readonly`, `readonly`)
        }, 0);
      });
    })
  }, []);

  return (
    <RangePicker
      className="timeAnt"
      format="YYYY-MM-DD"
      onChange={props.handleChangTime}
      placeholder={["开始日期", "结束日期"]}
    />
  );
};

export default TimeAnt;
