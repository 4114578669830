/*
 * @Author: your name
 * @Date: 2021-07-22 10:33:19
 * @LastEditTime: 2022-04-08 16:26:45
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/pages/studentAnswer/components/audioBtn/presenter.js
 */
import { useRef, useState, useEffect } from "react";
import { getParams } from "@/util/util";

const Presenter = (props) => {
  // const {  } = props;

  const [isOver, setIsOver] = useState(true);

  const playENMp3 = () => {
    setIsOver(false);
    const audios = document.getElementById("ENMp3");
    audios.play();
    audios.loop = false; //loop 属性规定当音频结束后将重新开始播放
    audios.addEventListener(
      "ended",
      () => {
        setIsOver(true);
      },
      false
    );
  };

  return [
    { isOver },
    {
      playENMp3,
    },
  ];
};

export default Presenter;
