/*
 * @Author: your name
 * @Date: 2022-03-28 14:41:07
 * @LastEditTime: 2022-04-02 16:03:00
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newGitZnxt2.0\src\pages\wordPK\components\index.jsx
 */

import React from "react";
import "./index.less";
import Presenter from "./presenter.js";
import { Popover } from "antd";
const WordPkDetails = (props) => {
  const [states, events] = Presenter(props);

  const { wordlist, oneOrTwo, oneTable, twoTable } = states;
  // const { } = events;

  return (
    <div className="WordPkDetailsPage">
      <img
        className="pkBg"
        src={require("@/assets/wordPk/pkBg.png").default}
        alt="aperture"
      />
      {/* 单行的表 */}

      {oneOrTwo === 1 ? (
        <div className="oneTable">
          <div className="tableOneTop">
            <div className="wordText">单词</div>
            <div className="red">红队</div>
            <div className="blue">蓝队</div>
          </div>
          <div className="aaaa">
            {wordlist.map((v, i) => (
              <div className="tableOneContent" key={i}>
                <div className="wordText">
                  <span className="word">{v.exam.examStem}</span>
                  <Popover
                    placement="topLeft"
                    content={v.answerData}
                    trigger="click"
                  >
                    <span className="cnText">{v.answerData}</span>
                  </Popover>
                </div>
                <div className="red">
                  {v.redIsTrue ? (
                    <img
                      className="tImg"
                      src={require("@/assets/wordPk/trueImg.png").default}
                      alt="trueImg"
                    />
                  ) : (
                    <img
                      className="fImg"
                      src={require("@/assets/wordPk/falseImg.png").default}
                      alt="falseImg"
                    />
                  )}
                </div>
                <div className="blue">
                  {v.blueIsTrue ? (
                    <img
                      className="tImg"
                      src={require("@/assets/wordPk/trueImg.png").default}
                      alt="trueImg"
                    />
                  ) : (
                    <img
                      className="fImg"
                      src={require("@/assets/wordPk/falseImg.png").default}
                      alt="falseImg"
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="TwoTable">
          <div className="leftTable">
            <div className="tableTop">
              <div className="wordTop">单词</div>
              <div>红队</div>
              <div>蓝队</div>
            </div>
            <div className="aaaa">
              {oneTable.map((v, i) => (
                <div className="contentTable" key={i}>
                  <div className="wordTextT">
                    <span className="wordT">{v.exam.examStem}</span>
                    <Popover
                      placement="topLeft"
                      content={v.answerData}
                      trigger="click"
                    >
                      <span className="cnTextT">{v.answerData}</span>
                    </Popover>
                  </div>
                  <div>
                    {v.redIsTrue ? (
                      <img
                        className="tImg"
                        src={require("@/assets/wordPk/trueImg.png").default}
                        alt="trueImg"
                      />
                    ) : (
                      <img
                        className="fImg"
                        src={require("@/assets/wordPk/falseImg.png").default}
                        alt="falseImg"
                      />
                    )}
                  </div>
                  <div>
                    {v.blueIsTrue ? (
                      <img
                        className="tImg"
                        src={require("@/assets/wordPk/trueImg.png").default}
                        alt="trueImg"
                      />
                    ) : (
                      <img
                        className="fImg"
                        src={require("@/assets/wordPk/falseImg.png").default}
                        alt="falseImg"
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="rightTable">
            <div className="tableTop">
              <div className="wordTop">单词</div>
              <div>红队</div>
              <div>蓝队</div>
            </div>
            <div className="aaaa">
              {twoTable.map((v, i) => (
                <div className="contentTable" key={i}>
                  <div className="wordTextT">
                    <span className="wordT">{v.exam.examStem}</span>
                    <Popover
                      placement="topLeft"
                      content={v.answerData}
                      trigger="click"
                    >
                      <span className="cnTextT">{v.answerData}</span>
                    </Popover>
                  </div>
                  <div>
                    {v.redIsTrue ? (
                      <img
                        className="tImg"
                        src={require("@/assets/wordPk/trueImg.png").default}
                        alt="trueImg"
                      />
                    ) : (
                      <img
                        className="fImg"
                        src={require("@/assets/wordPk/falseImg.png").default}
                        alt="falseImg"
                      />
                    )}
                  </div>
                  <div>
                    {v.blueIsTrue ? (
                      <img
                        className="tImg"
                        src={require("@/assets/wordPk/trueImg.png").default}
                        alt="trueImg"
                      />
                    ) : (
                      <img
                        className="fImg"
                        src={require("@/assets/wordPk/falseImg.png").default}
                        alt="falseImg"
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WordPkDetails;
