import "./index.less"
import Presenter from "./presenter"
import PadHeader from "@/components/padHeader";
import TopRightBox from "@/components/memorizeWords/topRightBox"
import ScrollView from "@/components/memorizeWords/scrollView"
import EditGroupModal from "./components/editGroupModal"
import EditScoreModal from "./components/editScoreModal"
import { Select } from 'antd';
import { getParams, formatDate } from "@/util/util"


const MoralityEnter = (props) => {
    const paramsObj = getParams(window.location.href)
    const [states, events] = Presenter(props)
    const { timeConfig, activeTime, isScoreVisible, isGroupVisible, subjectData, currentSubject, groupCommentList, studentCommentList, labelCommentList, commentRecordList } = states
    const { changeActiveTime, changeScoreVisible, changeGroupVisible, changeCurrentSubject, loadRecordList, revokeCommentRecordHandle, selectScoringHandle, commentScoringChange } = events

    return (
        <div className="moralityEnterPage">
            <PadHeader>
                <div className="pageHeaderBar">
                    <div className="title">录入本班点评</div>
                    <div className="right">
                        <TopRightBox className="subjectSelectBox">
                            <Select placeholder="选择学科" bordered={false} options={subjectData} value={currentSubject} onChange={changeCurrentSubject} />
                        </TopRightBox>
                        <TopRightBox className="manner" onClick={() => changeScoreVisible(true)}>
                            <img src={require(`@/assets/icon/edit.png`).default} alt="_" />
                            <span>编辑打分点</span>
                        </TopRightBox>
                        <TopRightBox className="manner" onClick={() => changeGroupVisible(true)}>
                            <img src={require(`@/assets/icon/edit.png`).default} alt="_" />
                            <span>管理小组</span>
                        </TopRightBox>
                    </div>
                </div>
            </PadHeader>
            <div className="substanceBox">
                <div className="left">
                    <div className="timeBar">
                        {timeConfig.map(v => <div className={`item${activeTime === v.key ? ' active' : ''}`} key={v.key} onClick={() => changeActiveTime(v.key)}>{v.name}</div>)}
                    </div>
                    {
                        activeTime === 0 &&
                        <div className="user card">
                            {
                                studentCommentList?.map(v =>
                                    <div className={`useItem${v?.checked ? ' checked' : ''}`} key={v.studentId} onClick={() => selectScoringHandle(v)}>
                                        <div className="top">{v?.studentName}</div>
                                        <div className="bottom">
                                            分数：<span className="s">{v?.score}</span>
                                            {
                                                (v?.adjScore && v?.adjScore > 0) ? <span className="r">(+{v?.adjScore})</span> : null
                                            }
                                            {
                                                (v?.adjScore && v?.adjScore < 0) ? <span className="e">({v?.adjScore})</span> : null
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    }
                    {
                        activeTime === 1 &&
                        <div className="group card">
                            {
                                groupCommentList?.map(v =>
                                    <div className={`groupItem${v?.checked ? ' checked' : ''}`} key={v.id} onClick={() => selectScoringHandle(v)}>
                                        <div className="top">
                                            {v?.name} &nbsp; &nbsp;
                                            <span className="s">分数：</span>
                                            <span className="c">{v?.score}</span>
                                            {
                                                (v?.adjScore && v?.adjScore > 0) ? <span className="r">(+{v?.adjScore})</span> : null
                                            }
                                            {
                                                (v?.adjScore && v?.adjScore < 0) ? <span className="e">({v?.adjScore})</span> : null
                                            }
                                        </div>
                                        <div className="bottom">{v?.studentNames?.join('、')}</div>
                                    </div>)
                            }
                        </div>
                    }
                </div>
                <div className="right">
                    <div className="verify card">
                        <div className="title"> 表扬 <span>（选择左侧学生后单击下列选项即可进行加分）</span> </div>
                        <div className="labels r">
                            {
                                labelCommentList?.filter(v => v.type === 1).map(v =>
                                    <div className="i" key={v.id} onClick={() => commentScoringChange(v)}>{v.name} <span>({'+' + v.score})</span></div>)
                            }
                        </div>
                        <div className="title"> 待改进 <span>（选择左侧学生后单击下列选项即可进行减分）</span> </div>
                        <div className="labels e">
                            {
                                labelCommentList?.filter(v => v.type === 2).map(v =>
                                    <div className="i" key={v.id} onClick={() => commentScoringChange(v)}>{v.name} <span>({v.score})</span></div>)
                            }
                        </div>
                    </div>
                    <div className="record card">
                        <div className="title"> 点评记录</div>
                        <ScrollView className="list" onScrollBottom={loadRecordList}>
                            {
                                commentRecordList?.map(v =>
                                    v.classLabelType === 1 ?
                                        <div className="item" key={v.id}>
                                            <div className="t">{`表扬${v.type === 0 ? v.studentName : v.groupName}${v.classLabelName}`}<span className="add">+{v.score}</span>分 </div>
                                            <div className="st">{formatDate(new Date(v?.createTime))} 由{v.createName}点评</div>
                                            {Number(paramsObj.studentId) === v.createId && <div className="label r" onClick={() => revokeCommentRecordHandle(v)}>撤回</div>}
                                        </div> :
                                        <div className="item" key={v.id}>
                                            <div className="t">{v.type === 0 ? v.studentName : v.groupName},{v.classLabelName}<span className="dec">{v.score}</span>分 </div>
                                            <div className="st">{formatDate(new Date(v?.createTime))} 由{v.createName}点评</div>
                                            {Number(paramsObj.studentId) === v.createId && <div className="label r" onClick={() => revokeCommentRecordHandle(v)}>撤回</div>}
                                        </div>
                                )
                            }
                        </ScrollView>
                    </div>
                </div>
            </div>

            <EditScoreModal visible={isScoreVisible} onCancel={() => changeScoreVisible(false)} />
            <EditGroupModal visible={isGroupVisible} onCancel={() => changeGroupVisible(false)} subjectId={currentSubject} />
        </div>
    )
}

export default MoralityEnter