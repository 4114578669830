import "./index.less"
import mathJax from "@/util/globalVariable";

function Solution(props) {
    const { examAnswer, examAnalysis } = props
    mathJax()

    return (
        <>
            <div className="SolutionWarps">
                <div className="section">
                    <div className="sign">答案：</div>
                    <div className="details" dangerouslySetInnerHTML={{ __html: examAnswer }}></div>
                </div>
                {
                    examAnalysis ? <div className="section">
                        <div className="sign">解析：</div>
                        <div className="details" dangerouslySetInnerHTML={{ __html: examAnalysis }}></div>
                    </div> : null
                }
            </div>
        </>
    )
}

export default Solution