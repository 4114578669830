import Presenter from "./presenter.js";
import "./index.less";
import ShopCart from "./components/shopCart";
import { Button, Modal } from "antd";
import { dateFormat } from "@/util/util";

const LiveList = () => {
  const [states, events] = Presenter();
  const {
    liveList,
    show,
    childShopCartRef,
    shopCartNum,
    shopCartList,
    buyCourseItems,
    isCourseVisible,
  } = states;
  const { showShopCart, addShopCart, getShopCartNum, openLessonModal } = events;
  const deleteds = ["处理中", "未上架", "已上架", "删除"];
  let gradeList = [
    "",
    "一年级",
    "二年级",
    "三年级",
    "四年级",
    "五年级",
    "六年级",
    "七年级",
    "八年级",
    "九年级",
    "高一",
    "高二",
    "高三",
  ];

  let shopCartIdList = shopCartList.map((item) => item.goodsId);

  return (
    <div className="liveListContainer">
      {liveList.map((v, index) => (
        <div
          className="course_card"
          key={index}
          onClick={() => openLessonModal(true, v)}
        >
          <div
            className="img_bg"
            style={{
              backgroundImage: "url(" + v.logo + ")",
            }}
          >
            {/* <div className="num">共{v.lessonNum}课次</div> */}
          </div>
          <div className="title">{v.title}</div>
          {/* <div className="teacher_name">
            <span className="name">孙小龙</span>
            <span className="name">王小丽</span>
            <span className="name">张大友</span>
          </div> */}
          <div className="subject">
            <span className="txt">{gradeList[v.gradeId]}</span>
            {v.subjectName && <span className="txt">{v.subjectName}</span>}
            <span className="txt">共{v.lessonNum}课次</span>
          </div>
          <div className="btns_cont">
            <div>
              <span className="price">￥{v.currentPrice}元</span>
              {/* {Number(v.currentPrice) === 0 ? (
                <span className="free">免费</span>
              ) : (
                <span className="current_price">￥{v.currentPrice}</span>
              )} */}
            </div>
            <div className="buy_btn">
              <div
                className={`btn ${shopCartIdList.includes(v.id) ? "has" : ""}`}
                onClick={(e) => addShopCart(e, v)}
              >
                加入购物车
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="shopCar">
        <i
          className="ri-shopping-cart-2-line"
          onClick={() => showShopCart()}
        ></i>
        <span className="num" onClick={() => showShopCart()}>
          {shopCartNum || ""}
        </span>
        <div
          className="shopCar_cont"
          style={{ display: show ? "block" : "none" }}
        >
          <ShopCart
            ref={childShopCartRef}
            getShopCartNum={getShopCartNum}
            showShopCart={showShopCart}
          ></ShopCart>
        </div>
      </div>
      {liveList.length === 0 && <div className="no_data">暂无课程</div>}
      <Modal
        title="课次"
        visible={isCourseVisible}
        width="100%"
        onCancel={() => openLessonModal(false)}
        footer={null}
      >
        <div className="course_list_cont">
          {buyCourseItems.map((item, i) => (
            <div className="course" key={i}>
              <div className="logo_data">
                {/* <div className="logo"></div> */}
                <div className="data">
                  <div className="h1">{item.name}</div>
                  <div className="chapter_name">{item.chapterName}</div>
                  {/* <div className="tags">
                    <div className="subject">{item.name}</div>
                    <div className="name">李连杰</div>
                  </div> */}
                </div>
              </div>
              <div className="btn_cont">
                {/* <Button size="large" onClick={() => goLiveRoom(item)}>
                  进入直播
                </Button> */}
                <div className="time">
                  开始时间：
                  {dateFormat(
                    item.attendClassTime - 0,
                    "yyyy-MM-dd hh:mm"
                  )}{" "}
                  <br />
                  结束时间：
                  {dateFormat(item.endClassTime - 0, "yyyy-MM-dd hh:mm")}
                </div>
              </div>
            </div>
          ))}
          {buyCourseItems.length === 0 && (
            <div className="no_data">暂无课次</div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default LiveList;
