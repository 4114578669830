/*
 * @Author: slw
 * @Date: 2020-08-14 09:44:53
 * @LastEditTime: 2022-06-28 16:02:32
 * @LastEditors: c1107 chen1997
 * @Description: In User Settings Edit
 * @FilePath: /sdzn-assist-line/src/util/request.js
 */
/**
 * 全站http配置
 *
 * axios参数说明
 * isSerialize是否开启form表单提交
 * isToken是否需要token
 */
import axios from "axios";
import { message } from "antd";
import qs from "qs";
import { getParams } from "@/util/util";
import baseUrl from "@/util/baseURL";
// 正式：http://doc.znclass.com
// 测试：http://49.4.1.81:8998

// axios.defaults.baseURL = "https://doc.znclass.com";
axios.defaults.baseURL = baseUrl;
axios.defaults.timeout = 2000;
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500; // 默认的
};
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  async (config) => {
    const meta = config.meta || {};
    let obj = getParams(window.location.href);

    let token = obj.token || "";
    // if (token) config.headers["Authorization"] = `Bearer ${token || ""}`;
    if (config.data) config.data.updateTime = new Date().getTime();
    if (config.params) config.params.updateTime = new Date().getTime();

    if (config.method === "post" && meta.isSerialize) {
      config.data = qs.stringify({ ...config.data });
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
//HTTPresponse拦截
axios.interceptors.response.use(
  (res) => {
    const status = res?.data?.code;
    const msg = res?.data?.message || "未知错误";

    // 这里要区分文件流
    if (typeof res.data === "string") {
      return res;
    }

    // 如果请求为非0否者默认统一处理
    if (status !== 200 && status !== 0) {
      if (status !== undefined) {
        if (res?.config?.meta?.notNeedErrorMessage) {
          // 不报错
        } else {
          message.error(msg);
        }
      }
      return Promise.reject(msg);
    }

    if (res?.config?.meta?.notNeedSplit) {
      return res.data
    } else {
      return res.data.result || res.data.data;
    }

  },
  (error) => {
    return Promise.reject(error);
  }
);
export default axios;
