import React from 'react';
import './index.less';
import Presenter from './presenter.js';
const KeyboardPage = () => {
  const [states, events] = Presenter();

  const { userName, passWord } = states;
  const { loginBtn, onChangeUserName, onChangePassWord } = events;

  return (
    <div className="login_cont">
      <div className="loginContainer">
        <div className="funWrapper hoverShadowBk">
          <img className="logoImg" src={require('@/assets/logo.png').default} alt="logo" />
          <div className="loginFormWrapper">
            <div>
              <p className="inputTitle">账号/手机号</p>
              <div>
                <input
                  className="inputBox"
                  placeholder="请输入账号 / 手机号"
                  type="input"
                  name="username"
                  onChange={(e) => onChangeUserName(e)}
                ></input>
              </div>
              <div className="titleBox">
                <p className="inputTitle">密码</p>
                {/* <p className="forgetPwd">忘记密码？</p> */}
              </div>
              <div>
                <input
                  className="inputBox"
                  placeholder="请输入密码"
                  type="password"
                  name="password"
                  onChange={(e) => onChangePassWord(e)}
                ></input>
              </div>
            </div>
          </div>
          <button className="submitBtn hoverShadow" onClick={loginBtn}>
            登录
          </button>
          <p className="agreement">
            登录即同意<span>AI智囊学堂用户协议</span>和<span>隐私协议</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default KeyboardPage;
