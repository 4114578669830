/*
 * @Author: c1107 chen1997
 * @Date: 2022-09-06 09:17:53
 * @LastEditors: c1107 chen1997
 * @LastEditTime: 2022-09-15 20:00:45
 * @FilePath: \ZNXT-Student2.0\src\api\autonomyPractice.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from "../util/request";

// 获取版本
export function bookversionList(params) {
  return request({
    url: "/knowledgecenter/bookversion/listBySubject",
    method: "get",
    params,
  });
}
// 获取册别
export function getBookVolumesList(params) {
  return request({
    url: "/knowledgecenter/bookVolume/bookVolumes",
    method: "get",
    params,
  });
}

// 同步练习列表
export function getSelfLearnList(params) {
  return request({
    url: "/class/v2/stu/self/learn/list",
    method: "get",
    params,
  });
}

// 去练习
export function getEduselflearn(params) {
  return request({
    url: "/class/v2/student/eduselflearn",
    method: "get",
    params,
  });
}

// 提交
export function selfLessonSubmit(params) {
  return request({
    url: "/class/v2/stu/self/lesson/submit",
    method: "get",
    params,
  });
}

// 批改
export function selfLearnCorrect(params) {
  return request({
    url: "/class/v2/student/updateSelfLearn",
    method: "get",
    params,
  });
}
