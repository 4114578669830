import Presenter from "./presenter.js";
import "./index.less";
import PadHeader from "@/components/padHeader";
import Ranking from "./components/ranking";
import Tree from "./components/tree"
import SubjectEntry from "./components/subjectEntry"
import ErrorsChart from "./components/errorsChart"

// 错题本
function mistakesBooks(props) {
    const [states, events] = Presenter(props);

    const { honorList, clearList, weeklyWrong, userLevel, levelScore, wrongTrend, obj, hot } = states
    const { changeRankingList, suckUpChange } = events


    return (
        <>
            <div className="StudentMiskesBooksWrap">
                <PadHeader>
                    <div className="header">
                        <div className="title">错题本</div>
                        <div className="saying">{hot}</div>
                    </div>
                </PadHeader>

                <div className="contentBox">
                    <div className="leftBox">
                        <div className="topBox">
                            {/* 荣誉榜 */}
                            <Ranking style={{ width: '35%'}} title="荣誉榜" list={honorList} text="分" onChange={changeRankingList} />
                            {/* 清题榜 */}
                            <Ranking style={{ width:'35%' }} title="清题榜" list={clearList} text="分" onChange={changeRankingList} />
                            {/* 成长树 */}
                            <Tree style={{ width: '27%' }} treeLevel={userLevel} bubbleList={levelScore} suckUpChange={suckUpChange} />
                        </div>
                        <div className="bottomBox">
                            {/* 错题本学科入口 */}
                            <SubjectEntry list={weeklyWrong} obj={obj} />
                        </div>
                    </div>
                    <div className="rightBox">
                        {/* 错题趋势图 */}
                        <ErrorsChart dataList={wrongTrend} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default mistakesBooks