import { useState, useEffect, useRef } from "react";
import messageHint from "@/pages/mistakesRearrange/compoents/messageHint"
import { getMoralityEnterGroup, getSubjectShould, getMoralityEnterStudent, getCommentLabel, getMoralityEnterRecord, revokeCommentRecord, commentScoringAdd } from "@/api/morality"
import { getParams } from "@/util/util";


const timeConfig = [
    {
        name: '按学生点评',
        key: 0
    },
    {
        name: '按小组点评',
        key: 1
    }
]

const Presenter = (props) => {
    const paramsObj = getParams(window.location.href)
    const [activeTime, setActiveTime] = useState(0)
    const [isScoreVisible, setIsScoreVisible] = useState(false)
    const [isGroupVisible, setIsGroupVisible] = useState(false)
    const [subjectData, setSubjectData] = useState(false)
    const [currentSubject, setCurrentSubject] = useState(undefined)
    const [groupCommentList, setGroupCommentList] = useState([])
    const [studentCommentList, setStudentCommentList] = useState([])
    const [labelCommentList, setLabelCommentList] = useState([])
    const [commentRecordList, setCommentRecordList] = useState([])
    const [lastId, setLastId] = useState(0)
    const [updateBack, setUpdateBack] = useState(false);


    useEffect(() => {
        getSubjectShould({ levelId: paramsObj.levelId }).then(res => {
            setCurrentSubject(res[0]?.id || undefined)
            setSubjectData(res.map(v => ({ label: v.name, value: v.id })))
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isScoreVisible) {
            getCommentLabel({ classId: paramsObj.classId }).then(res => {
                setLabelCommentList(res)
            })
        }
    }, [isScoreVisible]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let params = {
            classId: paramsObj.classId,
            pageSize: '10',
            lastId: lastId
        }
        getMoralityEnterRecord(params).then(res => {
            if (lastId === 0) {
                setCommentRecordList(res || [])
            } else {
                setCommentRecordList([...commentRecordList, ...(res || [])])
            }
        })
    }, [lastId]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!currentSubject) return

        let params = {
            createId: paramsObj.studentId,
            classId: paramsObj.classId,
            subjectId: currentSubject,
        }

        if (activeTime === 0) {
            getMoralityEnterStudent(params).then(res => {
                setStudentCommentList(res)
            })

        } else if (activeTime === 1) {
            getMoralityEnterGroup(params).then(res => {
                setGroupCommentList(res)
            })
        }

    }, [currentSubject, activeTime, updateBack]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let params = {
            createId: paramsObj.studentId,
            classId: paramsObj.classId,
            subjectId: currentSubject,
        }

        if (!isGroupVisible) {
            getMoralityEnterGroup(params).then(res => {
                setGroupCommentList(res)
            })
        }

    }, [isGroupVisible]) // eslint-disable-line react-hooks/exhaustive-deps



    const changeActiveTime = (key) => {
        setActiveTime(key)
    }

    const changeScoreVisible = (flage) => {
        setIsScoreVisible(flage)
    }

    const changeGroupVisible = (flage) => {
        setIsGroupVisible(flage)
    }

    const changeCurrentSubject = (value) => {
        setCurrentSubject(value)
    }

    const loadRecordList = () => {
        setLastId(commentRecordList?.[commentRecordList.length - 1]?.id || 0)
    }

    // 撤回打分
    const revokeCommentRecordHandle = (info) => {
        let params = {
            createId: paramsObj.studentId,
            id: info.id,
            type: info.type
        }
        revokeCommentRecord(params).then(res => {
            setCommentRecordList(commentRecordList.filter(v => v.id !== info.id))
            setUpdateBack(!updateBack)
        })
    }

    // 选中或取消打分项
    const selectScoringHandle = (info) => {
        // 学生
        if (activeTime === 0) {
            setStudentCommentList(list => {
                return list.map(v => {
                    if (v.studentId === info.studentId) {
                        return {
                            ...v,
                            checked: !(!!v?.checked)
                        }
                    } else {
                        return v
                    }
                })
            })
        }

        //小组
        if (activeTime === 1) {
            setGroupCommentList(list => {
                return list.map(v => {
                    if (v.id === info.id) {
                        return {
                            ...v,
                            checked: !(!!v?.checked)
                        }
                    } else {
                        return v
                    }
                })
            })
        }
    }

    // 打分
    const commentScoringChange = (info) => {

        let obj = {
            classLabelId: info.classLabelId,
            classLabelType: info.type,
            classLabelName: info.name,
            score: info.score,
            classId: paramsObj.classId,
            type: activeTime,
            createId: paramsObj.studentId,
            createName: decodeURIComponent(paramsObj?.studentName),
        }

        if (activeTime === 0) {
            let checkedList = studentCommentList.filter(v => v.checked).map(v => ({ id: v.studentId, name: v.studentName }))
            if (!checkedList.length) return messageHint('请先选择要打分的学生')
            let list = studentCommentList.map(v => {
                if (v?.checked) {
                    return {
                        ...v,
                        score: v.score + info.score,
                        adjScore: Number((v?.adjScore || 0) + info.score)
                    }
                } else {
                    return v
                }
            })

            let params = {
                infoArray: JSON.stringify(checkedList),
                ...obj
            }

            commentScoringAdd(params).then(res => {
                setStudentCommentList(list)
                updateRecord()
            })
        }

        if (activeTime === 1) {
            let checkedList = groupCommentList.filter(v => v.checked).map(v => ({ id: v.id, name: v.name }))
            if (!checkedList.length) return messageHint('请先选择要打分的小组')
            let list = groupCommentList.map(v => {
                if (v?.checked) {
                    return {
                        ...v,
                        score: v.score + info.score,
                        adjScore: Number((v?.adjScore || 0) + info.score)
                    }
                } else {
                    return v
                }
            })

            let params = {
                infoArray: JSON.stringify(checkedList),
                ...obj
            }

            commentScoringAdd(params).then(res => {
                setGroupCommentList(list)
                updateRecord()
            })
        }

        // 更新点评记录
        function updateRecord() {
            if (lastId === 0) {
                let params = {
                    classId: paramsObj.classId,
                    pageSize: '10',
                    lastId: 0
                }
                getMoralityEnterRecord(params).then(res => setCommentRecordList(res || []))
            } else {
                setLastId(0)
            }
        }
    }


    return [
        { timeConfig, activeTime, isScoreVisible, isGroupVisible, subjectData, currentSubject, groupCommentList, studentCommentList, labelCommentList, commentRecordList },
        { changeActiveTime, changeScoreVisible, changeGroupVisible, changeCurrentSubject, loadRecordList, revokeCommentRecordHandle, selectScoringHandle, commentScoringChange }
    ]
};

export default Presenter;
