/*
 * @Author: your name
 * @Date: 2021-07-21 15:35:25
 * @LastEditTime: 2022-07-11 14:13:01
 * @LastEditors: c1107 chen1997
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/components/padHeader/presenter.js
 */
import { androidHandle } from "@/util/android";
import { useEffect, useState } from "react";

import pubsub from "@/util/pubSub";
import store from "@/redux/store";
import { getParams } from "@/util/util";

const Presenter = (props) => {
  let typeListTwo = [
    {
      text: "全部",
      type: "",
      active: true,
    },
    {
      text: "已作答",
      type: 1,
      active: false,
    },
    {
      text: "未作答",
      type: 0,
      active: false,
    },
  ];

  // const [hideHead, setHideHead] = useState();
  const [typeList, setType] = useState(typeListTwo);

  let obj = getParams(window.location.href);

  const handleType = (event) => {
    typeListTwo.forEach((v, i) => {
      v.active = false;
      if (v.text === event.target.innerHTML) {
        typeListTwo[i].active = true;
        props.getActiveType(v);
      }
    });
    setType(typeListTwo);
  };

  const backHandle = () => {
    let url = localStorage.getItem("goBackPktUrl") || "";
    if (navigator.userAgent.indexOf("sdzn-android-gr") > -1) {
      androidHandle("nativeBackClick");
    } else if (url) {
      window.open(`${url}`, "_self");
    } else {
      window.history.back();
    }
  };

  useEffect(() => {
    window.bottomBackEvent = () => {
      backHandle();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return [
    { typeList },
    {
      backHandle,
      handleType,
    },
  ];
};

export default Presenter;
