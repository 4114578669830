import { PAD_HEADER_HREF } from "../constant";

const initState = {
    href:""
};
export default function padHeaderReducer(preState = initState, action) {
  const { type, data } = action;
  switch (type) {
    case  PAD_HEADER_HREF:
      return data;
    default:
      return preState;
  }
}
