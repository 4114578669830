import './index.less';
import Presenter from './presenter';

const ExamSteam = (props) => {
  const { info, isMistakes } = props;
  const [{ examSteam }, { touchendFn }] = Presenter(props);

  return (
    <>
      <div
        className={isMistakes ? "examStemCom examStemCom-mistakes" : "examStemCom"}
        ref={examSteam}
        // onClick={(e) => touchendFn(e)}
        onTouchEnd={(e) => touchendFn(e)}
        dangerouslySetInnerHTML={{ __html: info }}
      ></div>
    </>
  );
};

export default ExamSteam;
