import "./index.less"
import Presenter from "./presenter.js";

function PreviousArrow() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="calc(10vh / 12)" height="calc(16vh / 12)" viewBox="0 0 10 16" fill="none">
            <path d="M1.41421 9.41421C0.633163 8.63316 0.633165 7.36683 1.41421 6.58578L6.58579 1.41421C7.84572 0.154279 10 1.04662 10 2.82843L10 13.1716C10 14.9534 7.84572 15.8457 6.58579 14.5858L1.41421 9.41421Z" fill="#0043F2" />
        </svg>
    )
}
function NextArrow() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="calc(10vh / 12)" height="calc(16vh / 12)" viewBox="0 0 10 16" fill="none">
            <path d="M8.58579 9.41421C9.36684 8.63316 9.36684 7.36683 8.58579 6.58578L3.41421 1.41421C2.15428 0.154279 -2.12618e-07 1.04662 -2.90504e-07 2.82843L-7.42617e-07 13.1716C-8.20502e-07 14.9534 2.15428 15.8457 3.41421 14.5858L8.58579 9.41421Z" fill="#0043F2" />
        </svg>
    )
}

function planningCalendar(props) {

    const [states, events] = Presenter(props)
    const { MONTH_NAME_LIST, DAY_NAME_LIST, calendarConfig, calendarData, headerDateDOM, processCanlendarStyleData } = states
    const { sliderScrollCenter, switchDateChange, changeActiveDate } = events


    return (
        <div className={`planningCalendarComponent ${props?.className || ''}`}>
            <div className="planningCalendar-header">
                <div className="pre-btn" onClick={() => switchDateChange({
                    month: calendarConfig.target.month - 1
                })}>
                    <PreviousArrow />
                </div>
                <div className="content">
                    <div className="slider" ref={headerDateDOM}>
                        <div className="warp">
                            {
                                MONTH_NAME_LIST.map((v, i) =>
                                    <div className="center-title-date" onClick={() => {
                                        sliderScrollCenter(i);
                                        switchDateChange({
                                            month: i
                                        })
                                    }} key={i}>
                                        {
                                            calendarConfig.target.month === v.monthNumber ?
                                                <>
                                                    <div className="current">
                                                        <div className="month">{v.mmm}</div>
                                                        <div className="year">{calendarConfig.target.year}</div>
                                                    </div>
                                                </> :
                                                <>
                                                    {/* chineseFullName | fullName | mmm | monthNumber */}
                                                    <div className="other">{v.mmm}</div>
                                                </>
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="next-btn" onClick={() => switchDateChange({
                    month: calendarConfig.target.month + 1
                })}>
                    <NextArrow />
                </div>
            </div>
            <div className="planningCalendar-main">
                {/* 星期栏 */}
                <div className="table-header-row">
                    {
                        DAY_NAME_LIST.map((v, i) =>
                            <span className="table-header-th" key={i}>
                                {v.shortName}
                            </span>
                        )
                    }
                </div>
                {/* 日历主体 */}
                <div className="table-body-cell">
                    {
                        processCanlendarStyleData.map((v, i) =>
                            <span
                                className={`table-body-td ${v.type} ${v?.className || ''}`}
                                data-active={v?.active}
                                key={i}
                                onClick={() => changeActiveDate(v,i)}
                            >
                                <span className="day">{v.day}</span>
                                {v?.children}
                            </span>
                        )
                    }
                </div>
            </div>
        </div >
    )
}


export default planningCalendar