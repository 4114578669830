import Presenter from "./presenter";

import "./index.less";
import { connect } from "react-redux";

const SevenSelectedFive = (props) => {
  const { info } = props;
  const [states, events] = Presenter(props);

  const {
    numList,
    activeIndex,
    chioceList,
    typeIndex,
    rightIndex,
    errorIndex,
  } = states;
  const { changeActiveNum, changeActiveIndex } = events;
  // 七选五
  return (
    <div className="sevenSelectedFive">
      {/* 七选五选项 */}
      <div className="allChildren">
        <div className="numTopBox">
          {(numList || []).map((v, index) => (
            <p
              key={index}
              className={`${typeIndex === index ? "activeNum" : ""}`}
              onClick={() => changeActiveNum(index, v)}
            >
              {v.text}
            </p>
          ))}
        </div>
        <div>
          <ul>
            {(info?.resource?.exam?.children[0].examOptions || []).map(
              (v, index) => (
                <li
                  key={index}
                  className={`${
                    activeIndex.indexOf(index + "") > -1 ? "active" : ""
                  }
                   ${
                     rightIndex.indexOf(index + "") > -1 && props.isShowAnswer
                       ? "activeRight"
                       : ""
                   } ${
                    errorIndex.indexOf(index + "") > -1 && props.isShowAnswer
                      ? "activeError"
                      : ""
                  }`}
                  onClick={() => changeActiveIndex(index, v)}
                >
                  <p className="item">{chioceList[index]}</p>
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{ __html: v.content }}
                  ></div>
                </li>
              )
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

connect();

export default connect(
  (state) => ({
    examItem: state.examItem,
    isShowAnswer: state.isShowAnswer,
  }),
  {}
)(SevenSelectedFive);
