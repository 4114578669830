import { useEffect, useMemo, useRef, useState, forwardRef, useImperativeHandle } from "react"
import { message } from "antd";
import { Howl } from "howler"
import { millisecondToDate, PlayingButton, ProgressBar, CredibilityKey } from "@/components/memorizeWords/controls"
import "./index.less"

const baseUrl = "https://file.aiznclass.com/word";

// 判断播放来源是否是完整的链接地址
export function isFullLink(url) {
    const regex = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/; //eslint-disable-line
    return regex.test(url);
}

// 播放控件
const AudioPlayer = function ({ controls, disabled, error, url, sourceName, className, beforePlaying, ...props }, onRef) {
    const audioRef = useRef(null);
    const processUrl = useMemo(() => isFullLink(url) ? url : baseUrl + url, [url])
    const [isPlaying, setIsPlaying] = useState(false);
    const [speed, setSpeed] = useState('1.0')
    const [totalTime, setTotalTime] = useState(0)
    const [currentTime, setCurrentTime] = useState(0)

    // 向外暴露 （父组件可通过ref来获取）
    useImperativeHandle(onRef, () => (audioRef?.current))

    useEffect(() => {
        if (controls && url) {
            audioRef.current = new Howl({
                src: [`${processUrl}`],
                preload: 'auto',
                html5: true,
                onload: function () {
                    setTotalTime(audioRef.current?.duration())
                },
                onplay: () => {
                    setInterval(() => {
                        if (audioRef.current && audioRef.current.playing()) {
                            setCurrentTime(audioRef.current.seek())
                        }
                    }, 800);
                },
                onpause: () => {
                    setIsPlaying(false)
                },
                onend: () => {
                    setCurrentTime(0)
                    setIsPlaying(false)
                },
                onplayerror: () => {
                    message.error('音频播放错误！');
                    error && error(err)
                },
                onloaderror: () => {
                    message.error('音频加载失败！');
                }
            })
        }

        return () => {
            if (controls) audioRef?.current?.unload();
            setIsPlaying(false);
            setTotalTime(0)
            setCurrentTime(0)
            setSpeed("1.0")
        }
    }, [url]) // eslint-disable-line react-hooks/exhaustive-deps

    // 播放切换方法
    const handlePlayingChange = (event) => {
        if (disabled) return
        event.stopPropagation();
        document.querySelectorAll('audio').forEach(el => {
            el.pause()
        });

        if (isPlaying) {
            audioRef.current.pause();
        } else {
            Howler?.stop();
            audioRef.current.seek(currentTime);
            beforePlaying && beforePlaying();
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying)
    }

    // 设置播放倍速
    const handleSpeedChange = (speed) => {
        audioRef.current.rate(Number(speed))
        setSpeed(speed)
    }

    // 跳转到某个时长位置
    const handleProgressChange = (currentTime) => {
        setCurrentTime(currentTime / 100 * totalTime)
        audioRef.current.seek(currentTime / 100 * totalTime);
    }

    // 音频无控件播放规则
    const toggleAudio = (event) => {
        if (disabled) return
        event.stopPropagation();
        document.querySelectorAll('audio').forEach(el => {
            el.pause()
        });
        if (isPlaying) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        } else {
            beforePlaying && beforePlaying();
            audioRef.current.play().catch(err => {
                message.error('暂无播放来源~');
                error && error(err)
            })
        }
        setIsPlaying(!isPlaying)
    }

    if (controls) {
        return (
            <div className={`AudioPlayerAnyComponent ${className || ''}`} {...props}>
                <div className="controls">
                    <PlayingButton isPlay={isPlaying} onClick={(e) => handlePlayingChange(e)} />
                    <span className="sourceName" dangerouslySetInnerHTML={{ __html: sourceName }}></span>
                    <CredibilityKey onChange={handleSpeedChange} speed={speed} className="speed" />
                </div>
                <div className="progress">
                    <ProgressBar progress={Math.floor(currentTime / totalTime * 100) || 0} onChange={handleProgressChange} />
                    <span className="currentTime">{millisecondToDate(currentTime)}</span>
                    <span className="totalTime">{millisecondToDate(totalTime)}</span>
                </div>
            </div>
        )
    } else {
        return (
            <span onClick={toggleAudio}>
                {props?.children}
                <audio ref={audioRef} src={processUrl} onEnded={() => setIsPlaying(false)}>
                    <source src={processUrl} type="audio/mpeg" />
                    <source src={processUrl} type="audio/ogg" />
                </audio>
            </span>
        )
    }
}

export default forwardRef(AudioPlayer)

