/*
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \sdzn-student2.0\config\baseURL.js
 */
var baseUrl = ""; // pad打包这里默认空 var baseUrl = ""
if (!baseUrl) {
  if (process.env.REACT_APP_ENV === "test") {
    // 测试  2.3
    baseUrl = "https://csdoc.aiznclass.com/";
  } else if (process.env.REACT_APP_ENV === "development") {
    // 开发
    baseUrl = "http://49.4.1.81:8998";
    // baseUrl = "https://doc.znclass.com";
  } else if (process.env.REACT_APP_ENV === "production") {
    // 正式地址 这里不允许改动
    baseUrl = "https://doc.znclass.com";
  }
}

export default baseUrl;
