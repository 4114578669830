import { useEffect, useRef, useState } from "react";
import "./index.less"


// 节流函数，可以单独抽取成一个工具函数
const throttle = (func, limit) => {
    let inThrottle;
    return function () {
        const args = arguments;
        const context = this;
        if (!inThrottle) {
            func.apply(context, args);
            inThrottle = true;
            setTimeout(() => (inThrottle = false), limit);
        }
    };
};

function ScrollView({ height, className, onScrollBottom, bottomDistance, scrollTop, onScroll, ...props }) {

    const ScrollViewRef = useRef(null)

    const handleScroll = (e) => {
        onScroll && onScroll(e)
        const { scrollTop, clientHeight, scrollHeight } = e.target;
        if (Math.ceil(scrollTop + clientHeight) >= (scrollHeight - 10)) {
            onScrollBottom && onScrollBottom(e);
        }
    }

    return (
        <div ref={ScrollViewRef} className={`scrollViewComponent ${className || ''}`} style={{ height: (height || undefined) }} {...props} onScroll={throttle(handleScroll, 0)}>
            {props?.children}
        </div>
    )
}

export default ScrollView