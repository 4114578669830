import React from "react";
import "./index.less";

import PadHeader from "@/components/padHeader";
import HeadOperation from "./components/headOperation";
import ExamItem from "@/components/examItem";
import ResourcePreview from "@/components/resourcePreview";

import { getResourceTypeIndex } from "@/mockData/resource";
import InfiniteScroll from "react-infinite-scroller";

import Presenter from "./presenter.js";
import { Button, Carousel, Empty, Modal } from "antd";
import listPng from "@/assets/newSence/list.png";
import { dateFormat } from "@/util/util";
import { useHistory } from "react-router-dom";
import { getParams } from "@/util/util";

const PracticeReport = () => {
  let queryObj = getParams(window.location.href);
  const [states, events] = Presenter();
  const dom = document.querySelector("#root");
  const history = useHistory()
  const {
    isModalVisible,
    examList,
    activeType,
    examInfo,
    nowIndex,
    activeInfo,
    volumeListGiaoList,
    checkVal,
    subjectListData,
    activeSubject,
    term,
    whereGrade,
    subjectNameList,
    subjectNameActive,
    examTestPaperList,
    subjectNameId,
    examDataListRef
  } = states;
  const {
    handleChangTime,
    changeSubject,
    handleCancel,
    getActiveType,
    onChange,
    switchExam,
    examine,
    collectExam,
    getSubjectName,
    handleInfiniteOnLoad,
    handleTypeList,
    changeInput,
    handleCheck,
    handleSubject,
    handleVolume,
    subjectNameBtnChange,
    testPaperListScrollBottom,
    searchValueChange
  } = events;
  const getResourceTypeIndexs = getResourceTypeIndex;
  return (
    <div className="myCollect">
      {/* pad顶部 */}
      <PadHeader>
        <HeadOperation
          changeInput={changeInput}
          handleTypeList={handleTypeList}
          handleChangTime={handleChangTime}
          changeSubject={changeSubject}
          getActiveType={getActiveType}
          handleCheck={handleCheck}
          searchValueChange={searchValueChange}
        ></HeadOperation>
      </PadHeader>
      {activeType === 1 ? (
        <div className="subjectBox">
          {subjectListData.map((v, i) => (
            <span key={v.id}>
              <span
                className={v.id === activeSubject ? "activeSub" : ""}
                onClick={() => handleSubject(v)}
              >
                {v.name}
              </span>
              <span className="fgx">
                {subjectListData.length > i + 1 ? "/" : ""}
              </span>
            </span>
          ))}
        </div>
      ) : (
        ""
      )}

      {/* 中间的卡片区域 */}
      <div className="card" id="bodyWrapper">
        {

          activeType === 1 ? (
            <div className="scrollWrapper">
              <div className="volume">
                {(volumeListGiaoList?.list || []).map((v, i) => (
                  <div
                    className={`${v.id === whereGrade && v.type === term
                      ? "activeCard"
                      : "volumeCard"
                      }`}
                    key={i}
                    onClick={() => handleVolume(v)}
                  >
                    {v.name}
                  </div>
                ))}
              </div>
              {examList.length > 0 ? (
                <div className="ScrollBox">
                  <InfiniteScroll
                    initialLoad={false}
                    pageStart={0}
                    loadMore={handleInfiniteOnLoad}
                    useWindow={false}
                    hasMore={true}
                  >
                    {(examList || []).map((v, i) => (
                      <div className="collectCard" key={i}>
                        <div className="collectCardLeft">
                          <img
                            className="typeIcon"
                            src={
                              require(`@/assets/fileIcons/icon${getResourceTypeIndexs(
                                v?.resource?.resourceUrl
                              )}.png`).default
                            }
                            alt="icon"
                          />
                          <div>
                            <div className="titleText">
                              {getSubjectName(v?.resource?.subjectId) ? (
                                <div className="iconText">
                                  {getSubjectName(v?.resource?.subjectId)}
                                </div>
                              ) : (
                                ""
                              )}

                              <div className="text"> {v.resource.name}</div>
                            </div>
                            <div className="subhead">
                              {v.resource.chapterName}
                            </div>
                          </div>
                        </div>
                        <div className="collectCardRight">
                          <div>
                            <div className="topText">分类</div>
                            <div className="bottomText">教案</div>
                          </div>
                          <div className="rightThree">
                            <div className="topText">收藏时间</div>
                            <div className="bottomText">
                              {dateFormat(
                                v.resource.createTime,
                                "yyyy-MM-dd hh:mm"
                              )}
                            </div>
                          </div>
                          <div>
                            <Button
                              className="rightTwo"
                              onClick={() => collectExam(v, i)}
                            >
                              {v.collected ? (
                                <div className="rightBtnGrid">
                                  <i className="ri-heart-3-fill"></i>
                                  <span>已收藏</span>
                                </div>
                              ) : (
                                <div className="rightBtnGrid">
                                  <i className="ri-heart-3-line"></i>
                                  <span>收藏</span>
                                </div>
                              )}
                            </Button>
                          </div>
                          <div>
                            <Button
                              className="rightOne"
                              onClick={() => {
                                examine(v);
                              }}
                            >
                              查看
                            </Button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </InfiniteScroll>
                </div>
              ) : (
                <div
                  className="nodata"
                  style={{ height: checkVal ? "94%" : "94%" }}
                >
                  <Empty image={listPng} description="" />
                </div>
              )}
            </div>
          )
            :
            activeType === 2 ? (
              <div className="examCard">
                {examInfo ? (
                  <Carousel beforeChange={onChange}>
                    {(new Array(1).fill(1) || []).map((el, index) => (
                      <div className="itemGridWrapper" key={index}>
                        <ExamItem
                          isNotQuesition={true}
                          index={nowIndex}
                          examTypeId={examInfo?.exam?.examTypeId || examInfo?.resource?.exam?.examTypeId}
                          exam={examInfo}
                          isSubmit={true}
                          isCorrect={true}
                        ></ExamItem>
                      </div>
                    ))}
                  </Carousel>
                ) : (
                  <div className="nodata">
                    <Empty image={listPng} description="" />
                  </div>
                )}
                {/* 切换按钮 */}
                <div className="switchExam">
                  <div className="lastExam" onClick={() => switchExam(0)}>
                    <i className="ri-arrow-left-line"></i>
                  </div>
                  <div className="nextExam" onClick={() => switchExam(1)}>
                    <i className="ri-arrow-right-line"></i>
                  </div>
                </div>
              </div>
            )
              :
              activeType === 3 ? (
                <>
                  <div className="subjectSelect">
                    {subjectNameList && subjectNameList.length !== 0 ? subjectNameList.map((item, index) => {
                      return (
                        <div className={index === subjectNameActive ? "subjectNameBtn active" : "subjectNameBtn"} key={index} onClick={() => subjectNameBtnChange(index, item.subjectId)}>{item.subjectName}</div>
                      )
                    }) : null}
                  </div>
                  <div className="examList" ref={examDataListRef} onScroll={(e) => testPaperListScrollBottom(e)}>
                    {
                      examTestPaperList && examTestPaperList.length !== 0 ? examTestPaperList.map(item => {
                        return (
                          <div className="examItem" key={item.id}>
                            {/* <div className="subIcon">语</div> */}
                            <div className="title"><div className="text">{item.name}</div></div>
                            <div className="uploadTime">上传时间：<em>{dateFormat(item.createTime, "yyyy-MM-dd")}</em></div>
                            <div className="teacher">老师：<em>{item.userName}</em></div>
                            <div className="test">试题：<span>{item.count}</span></div>
                            <div className="lookBtn" onClick={() => history.push(`/looktestpaper?id=${item.id}&title=${item.name}&studentId=${queryObj.studentId}&schoolId=${queryObj.schoolId}&gradeId=${queryObj.gradeId}&classId=${queryObj.classId}&subjectNameId=${subjectNameId}`)}>查看</div>
                          </div>
                          )
                      }) : null
                    }
                  </div>
                </>
              ) : null
        }
      </div>
      <Modal
        className="ModalBox"
        title={activeInfo?.file?.name}
        visible={isModalVisible}
        onCancel={handleCancel}
        getContainer={dom}
        footer={null}
        width={"90vw"}
      >
        <ResourcePreview info={activeInfo}></ResourcePreview>
      </Modal>
    </div>
  );
};

export default PracticeReport;
