/*
 * @Author: your name
 * @Date: 2022-03-11 09:39:43
 * @LastEditTime: 2022-04-01 10:26:32
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newGitZnxt2.0\src\components\answerList\components\reciteWords\index.jsx
 */
import "./index.less";
// import ExamSteam from "../examSteam";
import WordStem from "../wordStem";
import Presenter from "./presenter";
import { connect } from "react-redux";

import React, { useState } from "react";

const ChioceExam = (props) => {
  const { info, examTypeId, wordDetails } = props;
  const [states, events] = Presenter(props);
  const { activeIndex, rightIndex, errorIndex, isTrue } = states;
  const { changeActiveIndex } = events;
  return (
    <div className="reciteWords">
      {/* 题干 */}
      <WordStem info={info} examTypeId={examTypeId} type={null}></WordStem>
      {/* 正确图标 */}
      <div className="goodImgBox">
        <img
          className="goodImg"
          src={require("@/assets/wordGood3.png").default}
          alt="logo"
        />
      </div>
      {/* {isTrue.current ? (
        <div className="goodImgBox">
          <img
            className="goodImg"
            src={require("@/assets/wordGood3.png").default}
            alt="logo"
          />
        </div>
      ) : (
        <div className="nullBox"></div>
      )} */}

      {/* 选项 */}
      <ul>
        {(info?.examOptions || []).map((v, index) => (
          <li
            key={index}
            className={`${
              ((activeIndex + "").split(",") || []).find(
                (v) => index + "" === v + ""
              )
                ? "active"
                : ""
            }
            ${
              ((rightIndex + "").split(",") || []).find(
                (v) => index + "" === v + ""
              )
                ? "activeRight"
                : ""
            } ${
              ((errorIndex + "").split(",") || []).find(
                (v) => index + "" === v + ""
              )
                ? "activeError"
                : ""
            }`}
            onClick={() => changeActiveIndex(index, v)}
          >
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: v.content }}
            ></div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ChioceExam;
