import React, { forwardRef, useImperativeHandle } from "react";
import "./index.less";

import Presenter from "./presenter.js";
import { Select } from "antd";
const { Option } = Select;

const PracticeReport = (props, ref) => {
  const [states, events] = Presenter(props);
  const { subjectList, value } = states;
  const { changeSubject } = events;

  const style = {
    height: "80%",
  };

  useImperativeHandle(ref, () => ({
    changeSubject: changeSubject,
  }));

  return (
    <div className="subjectSelect">
      <Select
        placeholder="请选择学科"
        value={value}
        getPopupContainer={() => document.querySelector(".subjectSelect")}
        onChange={changeSubject}
      >
        {(subjectList || []).map((v) => (
          <Option key={v.id} value={v.id}>
            {v.name}
            {/* <img src={v.subjectPic} alt="subject" style={style} /> */}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default forwardRef(PracticeReport);
