import "./index.less"
import Presetner from "./presenter"
import PadHeader from "@/components/padHeader"
import TopicItem from "../mistakesBank/components/topicItem"
import { getParams } from "@/util/util";


const LookTestPaper = (props) => {
    let queryObj = getParams(window.location.href);
    const [states, events] = Presetner(props)
    const { examList } = states
    const { addMistakeBook,collectChange } = events

    return (
        <div className="lookTestPaperWarp">
            <PadHeader>
                <div className="title">{decodeURI(queryObj.title)}</div>
            </PadHeader>
            <div className="testContent" id="topListContent">
                {
                    examList && examList.length !== 0 ? examList.map((item, index) => {
                        return <TopicItem
                            index={index}
                            showWitchOperate={3} //底部展示模块
                            info={item}
                            exam={item.exams.exam}
                            showSolution={true} //是否展示答案
                            key={item.id}
                            addMistakeBook={addMistakeBook}
                        />
                    }) : null
                }
            </div>
        </div>
    )
}

export default LookTestPaper