/*
 * @Author: your name
 * @Date: 2021-07-21 15:35:25
 * @LastEditTime: 2022-09-16 14:29:32
 * @LastEditors: c1107 chen1997
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/components/padHeader/presenter.js
 */
import { androidHandle } from "@/util/android";
import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import pubsub from "@/util/pubSub";
import store from "@/redux/store";
import { getParams } from "@/util/util";
import { PAD_HEADER_HREF } from "@/redux/constant.js";
import { message } from "antd";

const Presenter = (props) => {
  const [hideHead, setHideHead] = useState();
  const history = useHistory()

  let obj = getParams(window.location.href);

  useEffect(() => {
    setHideHead(obj.hideHead);
    window.bottomBackEvent = () => {
      backHandle();
    };
    if (!store.getState().padHeader.href) {
      store.dispatch({
        type: PAD_HEADER_HREF,
        data: { href: window.location.href.replace(/\?.*$/, "") },
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const backHandle = (e) => {
    if (props?.word) {
      props.goBack();
    } else {
      // 监听 backState 就可以知道页面即将返回
      store.dispatch({
        type: "updateBackState",
        data: new Date().getTime(),
      });
      setTimeout(() => {
        if (pubsub._pubsub_topics.submitBack) {
          // 检查是否提交的处理
          const { status } = store.getState().courseInfo;
          if (status === 0) {
            if (obj?.isAutonomyPractice) {
              window.location.hash = `#/autonomyPractice?studentId=${obj.studentId}&levelId=${obj.levelId}`;
            } else {
              nativeBack();
            }
          } else {
            nativeBack();
          }
        } else {
          if (obj?.isAutonomyPractice) {
            window.location.hash = `#/autonomyPractice?studentId=${obj.studentId}&levelId=${obj.levelId}`;
          } else {
            nativeBack();
          }
        }
      }, 200);
    }
  };

  const nativeBack = () => {
    if (window.navigator.userAgent.indexOf("sdzn-android-gr") > -1) {
      history.goBack();
    } else {
      history.goBack();
      if (window.location.href.replace(/\?.*$/, "") === store.getState().padHeader.href) {
        store.dispatch({
          type: PAD_HEADER_HREF,
          data: { href: "" }
        });
        setTimeout(() => androidHandle("nativeBackClick"), 0)
      }
    }
  };

  return [
    { hideHead },
    {
      backHandle,
    }
  ];
};

export default Presenter;