import "./index.less"
import Presenter from "./presenter"
import { Switch, Modal } from "antd"

function HistoricalDatum(props) {

    const [states, events] = Presenter(props)

    const { historyData, imgList, switchState, isShowModal, imgUrl } = states
    const { onChange, showImgModal, cancelModal } = events

    const ABC = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'G', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

    return (
        <>
            {
                props.answerHistory ? <>
                    {
                        historyData.length !== 0 ? (
                            <div className="SolutionWarp">
                                <div className="section">
                                    <div className="sign">历史作答：</div>
                                    <div className="details">
                                        {
                                            historyData.map((item, index) => <span key={index}>
                                                {
                                                    ABC[index]
                                                }
                                            </span>)
                                        }
                                    </div>
                                </div>
                            </div>
                        ) : null
                    }

                    {
                        imgList.length !== 0 ? (
                            <div className="SolutionWarp">
                                <div className="top">
                                    <div className="left">历史作答：</div>
                                    <div className="right">批改痕迹 <Switch onChange={onChange} /></div>
                                </div>
                                <div className="content">
                                    {
                                        imgList.map((item, index) =>
                                            <div className="warp" key={index}>
                                                <img src={switchState ? item.c : item.a} alt="" onClick={() => showImgModal(switchState ? item.c : item.a)} />
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        ) : null
                    }
                </> : null
            }

            <Modal title="历史作答" visible={isShowModal} footer={false} onCancel={cancelModal} width="90vw" destroyOnClose={true} wrapClassName={'GoodAnswerModalbox'}>
                <img src={imgUrl} alt="" />
            </Modal>
        </>
    )
}

export default HistoricalDatum