/*
 * @Author: your name
 * @Date: 2022-03-15 09:26:24
 * @LastEditTime: 2022-04-02 16:54:28
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newGitZnxt2.0\src\pages\wordStatistics\index.jsx
 */

import React from "react";

import "./index.less";
import Presenter from "./presenter.js";

import PadHeader from "@/components/padHeader";
import WordExamCard from "./components/wordExamCard";
import WordDialog from "@/components/wordAnswerList/components/wordDialog";

import { Tabs, Row, Col, Modal, Empty } from "antd";
const { TabPane } = Tabs;

const WordStatistics = (props) => {
  const [states, events] = Presenter(props);

  const {
    pageTopData,
    errWordList,
    trueWordList,
    isModalVisible,
    wordDetails,
  } = states;
  const { timeMethod, handleCancel, handleOk, goBack } = events;

  return (
    <div className="WordStatistics">
      <div className="padHeaderBox">
        <PadHeader
          name={"记单词任务1-检测结果"}
          word={true}
          goBack={goBack}
        ></PadHeader>
      </div>
      <div className="centerBox">
        <div className="topBox">
          <div>
            <img
              className="goodImg"
              src={require("@/assets/wordGood.png").default}
              alt="logo"
            />
          </div>
          <div>
            <div className="titleText">学习单词</div>
            <div className="themeColor">
              <span>{pageTopData.countExam}</span>个
            </div>
          </div>
          <div>
            <div>正确单词</div>
            <div className="goodColor">
              <span>{trueWordList.length}</span>个
            </div>
          </div>
          <div>
            <div>用时</div>
            <div></div>
            <div className="themeColor">
              <span>{timeMethod(pageTopData.useTime)}</span>
              {/* <span>3</span>分<span>46</span>秒 */}
            </div>
          </div>
          {/* <div>
            <div>下次复习时间</div>
            <div className="themeColor">
              <span>2022</span>年 <span>3</span>月<span>23</span>号
            </div>
          </div> */}
          <div>
            {trueWordList.length === pageTopData.countExam ? (
              <img
                className="goodImg"
                src={require("@/assets/wordGoodTwo.png").default}
                alt="good"
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <div>
          <Tabs defaultActiveKey="1">
            <TabPane tab="答错单词" key="1">
              <Row gutter={[16, 16]}>
                {errWordList.map((v, i) => (
                  <Col span={12} key={i}>
                    <WordExamCard
                      info={v}
                      index={i}
                      type={false}
                      handleOk={handleOk}
                      cancel={handleCancel}
                    ></WordExamCard>
                  </Col>
                ))}
                {errWordList?.length === 0 ? (
                  <div className="noData">
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="你没有错题，真棒"
                    />
                  </div>
                ) : (
                  ""
                )}
              </Row>
            </TabPane>
            <TabPane tab="答对单词" key="2">
              <Row gutter={[16, 16]}>
                {trueWordList.map((v, i) => (
                  <Col span={12} key={i}>
                    <WordExamCard
                      info={v}
                      index={i}
                      type={true}
                      handleOk={handleOk}
                      cancel={handleCancel}
                    ></WordExamCard>
                  </Col>
                ))}
                {trueWordList?.length === 0 ? (
                  <div className="noData">
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="继续努力哦"
                    />
                  </div>
                ) : (
                  ""
                )}
              </Row>
            </TabPane>
          </Tabs>
        </div>
      </div>
      {/* 单词详情弹窗 */}
      <Modal
        centered
        visible={isModalVisible}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
        className="ModalWordSSS"
        width={1000}
      >
        <WordDialog isStatistics={true} info={wordDetails}></WordDialog>
      </Modal>
    </div>
  );
};

export default WordStatistics;
