/*
 * @Author: your name
 * @Date: 2021-07-22 22:59:21
 * @LastEditTime: 2022-04-02 15:28:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/pages/studentAnswer/components/examFeature/presenter.js
 */

import { useEffect, useState, useRef } from "react";
import { setTimeFormater } from "@/util/util";

import store from "@/redux/store";

const Presenter = (props) => {
  const { seq, examTotal, state, updateTime } = props;
  const [useTime, setuseTime] = useState(2000);

  const timerRef = useRef();

  // 计时器
  useEffect(() => {
    // 先更新时间
    store.dispatch({
      type: "updateTime",
      data: 0,
    });

    timerRef.current && clearInterval(timerRef.current);
    // 先重置作答时间
    timerRef.current = setInterval(() => {
      store.dispatch({ type: "incrementTime", data: 1000 });
    }, 1000);
  }, [seq]); // eslint-disable-line react-hooks/exhaustive-deps

  // 更新时间
  useEffect(() => {
    setuseTime(store.getState().updateWordTime);
  }, [updateTime]); // eslint-disable-line react-hooks/exhaustive-deps

  return [
    {
      useTime,
    },
    { setTimeFormater },
  ];
};

export default Presenter;
