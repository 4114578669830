/*
 * @Author: your name
 * @Date: 2021-07-22 15:06:56
 * @LastEditTime: 2022-05-17 13:39:31
 * @LastEditors: slw 3289360550@qq.com
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/components/answerList/components/fillBackExam/presenter.js
 */

import { useState, useEffect, useRef, useMemo } from "react";
import { addCourseAnswer } from "@/api/answer";
import store from "@/redux/store";
import { getParams } from "@/util/util";

const Presenter = (props) => {
  const { info, isSubmit, inExamSeq, submitAnswerInfo, isMistakes } = props;
  const [imgList, setimgList] = useState([]);
  const [isCorrectData, setisCorrectData] = useState(true);
  const [defaultChecked, setdefaultChecked] = useState(isSubmit ? true : false);
  const [isShowKeyboard, setisShowKeyboard] = useState(false);
  const [keyboardType, setKeyboardType] = useState(1); //1正常键盘0简单键盘

  const inputTimer = useRef(null);
  const nowInputDom = useRef(null);
  const isNeedAdd = useRef(true);

  const text = "点击作答";

  useEffect(() => {
    if (isMistakes) {
      getImgList(isSubmit, (newArr) => {
        if (isSubmit && newArr.length === 0) {
          setdefaultChecked(false);
          let arr = JSON.parse(info?.answer || "[]");
          arr.forEach((element, i) => {
            if (element.a) {
              newArr.push({
                uid: i + 1,
                status: "done",
                name: "作答图片",
                url: element.a,
              });
            }
          });
        }
      });
    }
  }, [props.info]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getImgList(isSubmit ? true : false, (newArr) => {
      if (isSubmit && newArr.length === 0) {
        setdefaultChecked(false);
        let arr = JSON.parse(info?.answer || "[]");
        arr.forEach((element, i) => {
          if (element.a) {
            newArr.push({
              uid: i + 1,
              status: "done",
              name: "作答图片",
              url: element.a,
            });
          }
        });
      }
    });

    let allEnglishIds = [7, 8, 9];

    if (
      (allEnglishIds.indexOf(props.examItem?.resource?.exam?.baseSubjectId) >
        -1 ||
        allEnglishIds.indexOf(props.info?.resource?.exam?.baseSubjectId) >
        -1) &&
      !isSubmit
    ) {
      let doms =
        document?.querySelector(".examStemCom")?.querySelectorAll("input") ||
        [];
      clearTimeout(inputTimer.current);
      inputTimer.current = setTimeout(() => {
        doms.forEach((v, i) => {
          // doms[i].removeEventListener("keyup", submitFillexam, false);
          // doms[i].addEventListener("keyup", submitFillexam, false);
          doms[i].removeEventListener(
            "click",
            () => weekUpKeyBoard(event, i, doms),
            false
          );
          doms[i].addEventListener(
            "click",
            () => weekUpKeyBoard(event, i, doms),
            false
          );
        });
      }, 400);
    } else {
      let doms =
        document?.querySelector(".examStemCom")?.querySelectorAll("input") ||
        [];
      clearTimeout(inputTimer.current);
      inputTimer.current = setTimeout(() => {
        doms.forEach((v, i) => {
          doms[i].removeEventListener("keyup", submitFillexam, false);
          doms[i].addEventListener("keyup", submitFillexam, false);
        });
      }, 400);
    }

    if (!isSubmit) {
      let arr = document.querySelectorAll(".cus-com") || [];
      for (let index = 0; index < arr.length; index++) {
        arr[index].addEventListener(
          "focus",
          (e) => {
            // var reg = /([0-9])/g;
            let reg = new RegExp(text, "g");
            if (
              reg.test(e.target.value) &&
              e.target.value.trim().length === 4
            ) {
              e.target.value = "";
            }
          },
          false
        );
      }
    }
  }, [props.examItem, props.isSubmit, props.info]); // eslint-disable-line react-hooks/exhaustive-deps

  const addInputChoosedStyle = (doms, i) => {
    doms.forEach((element) => {
      console.error(element);
      element.classList.remove("inputActive");
    });
    doms[i].classList.add("inputActive");
  };

  //  调起键盘
  const weekUpKeyBoard = (e, i, doms) => {
    let examStemCom = document?.querySelector(".examStemCom");
    examStemCom.style.marginBottom = "calc(420vh / 12)";
    nowInputDom.current = e;
    addInputChoosedStyle(doms, i);
    let reg = new RegExp(text, "g");
    nowInputDom.current.target.value = nowInputDom.current.target.value.replace(
      reg,
      ""
    );
    setisShowKeyboard(true);
  };
  
  // 键盘赋值
  const changeInputValue = (val) => {
    nowInputDom.current.target.value = nowInputDom.current.target.value + val;
    nowInputDom.current.target.style.width = (22 * (nowInputDom.current.target.value.length > 10 ? nowInputDom.current.target.value.length : 10)) + 'px'
  };

  // 键盘删除操作
  const delInputValue = () => {
    nowInputDom.current.target.value = nowInputDom.current.target.value.slice(
      0,
      nowInputDom.current.target.value.length - 1
    );
    nowInputDom.current.target.style.width = (22 * (nowInputDom.current.target.value.length > 10 ? nowInputDom.current.target.value.length : 10)) + 'px'                    
  };

  // 键盘点击空格
  const inputOnBlank = (val) => {
    nowInputDom.current.target.value += val;
    nowInputDom.current.target.style.width = (22 * (nowInputDom.current.target.value.length > 10 ? nowInputDom.current.target.value.length : 10)) + 'px'
  };

  // 键盘点击对号
  const inputOnTrue = () => {
    submitFillexam();
  };
  // 键盘隐藏
  const closeKeyBoard = (val) => {
    setisShowKeyboard(val);
    submitFillexam();
  };

  // 获取input框的内容，调接口
  const submitFillexam = () => {
    console.log('更新作答----------------------');
    clearTimeout(inputTimer.current);
    inputTimer.current = setTimeout(() => {
      // 填空题键盘作答需要主动提交
      let examInfo = info?.resource?.exam;
      if (examInfo?.examTypeId === 6 && info?.answerType === 1) {
        let optionList = [];
        let doms = document
          ?.querySelector(".examStemCom")
          ?.querySelectorAll("input");
        if (!doms) return;
        let flag = false;
        doms.forEach((element, index) => {
          let reg = new RegExp(text, "g");
          let val = doms[index].value.replace(reg, "");
          if (val !== "") flag = true;
          optionList.push({
            examTypeId: info?.resource?.exam.examTypeId,
            answerType: info.answerType || 3,
            myAnswer: val,
          });
        });
        // console.log(flag);
        if (flag) {

          if (isMistakes) {
            isMistakes({ optionList })
            return
          }

          let obj = getParams(window.location.href);
          addCourseAnswer({
            lessonStudentId: store.getState().courseInfo.id,
            resourceId: info?.resource?.exam?.id,
            resourceType: 2,
            usedTime: store.getState().updateTime,
            optionList,
            isAdd: info?.answer === "",
          }).then((res) => { });
        }
      }
    }, 400);
  };

  const getImgList = (flag = true, callback) => {
    // 答案回填
    let arr = JSON.parse(info?.answer || "[]");
    let newArr = [];
    arr.forEach((element, i) => {
      if (element[flag ? "c" : "a"]) {
        newArr.push({
          uid: i + 1,
          status: "done",
          name: "作答图片",
          url: element[flag ? "c" : "a"],
        });
      }
    });
    if (callback) callback(newArr);
    setimgList(newArr);
  };

  const onChange = (flag) => {
    setdefaultChecked(flag);
    setisCorrectData(flag);
    getImgList(flag);
  };

  // 图片作答时的回调
  // 主动提交
  const updateAnswer = (imgList, isAdd) => {
    let optionList = [];
    imgList.forEach((element) => {
      optionList.push({
        examTypeId:
          info?.resource?.exam?.examTypeId === 4 ||
            info?.resource?.exam?.examTypeId === 6
            ? info?.resource?.exam?.examTypeId
            : 4,
        answerType: 3 || info?.resource?.exam?.answerType,
        myAnswer: element.url,
      });
    });

    let obj = {
      lessonStudentId: store.getState().courseInfo.id,
      resourceId: info?.resource?.exam?.id,
      resourceType: 2,
      usedTime: store.getState().updateTime,
      optionList,
      inExamSeq: inExamSeq,
      isAdd: isAdd && info?.answerState === 0 && isNeedAdd.current,
    };
    isNeedAdd.current = false;
    if (inExamSeq || inExamSeq === 0) {
      submitAnswerInfo(inExamSeq, obj);
    } else {
      if (isMistakes) {
        isMistakes(obj)
        return;
      }
      addCourseAnswer(obj).then((res) => { });
    }
    // console.log(obj,'submit--------------------');
  };

  // 切换键盘
  const onSwitch = (num) => {
    setKeyboardType(num);
  };

  return [
    { imgList, isCorrectData, defaultChecked, isShowKeyboard, keyboardType },
    {
      updateAnswer,
      onChange,
      changeInputValue,
      closeKeyBoard,
      delInputValue,
      inputOnBlank,
      inputOnTrue,
      onSwitch,

    },
  ];
};

export default Presenter;
