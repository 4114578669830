import { useEffect, useState } from "react"
import { getParams } from "@/util/util";
import { comprehensiveList, comprehensiveCondition } from "@/api/analysis"


const Presenter = (props) => {
    const [flageTabs, setFlageTabs] = useState(true)
    const [semesterTrunkList, setSemesterTrunkList] = useState([])
    const [remarkable, setRemarkable] = useState({})
    const [timeQuantum, setTimeQuantum] = useState(0)
    const [exhibitionArea, setExhibitionArea] = useState([])

    useEffect(() => {
        const { studentId } = getParams(window.location.href);
        (async () => {
            await comprehensiveList({ studentId, size: '20' }).then(async res => {
                setSemesterTrunkList(res)
                setTimeQuantum(res[0]?.id)

                await comprehensiveCondition({ 'extId': res[0]?.id }).then(res => {
                    setRemarkable(res ? res : {})
                })
            })

        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const changeFlageTabs = (falge) => {
        setFlageTabs(falge)
    }

    const changeExhibitionArea = (value) => {
        setExhibitionArea(value)
    }

    const changeTimeQuantum = async (id) => {
        setTimeQuantum(id)
        await comprehensiveCondition({ 'extId': id }).then(res => {
            setRemarkable(res ? res : {})
        },err => {
            setRemarkable({})
        })
    }

    return [
        { flageTabs, semesterTrunkList, remarkable, exhibitionArea },
        { changeFlageTabs, changeExhibitionArea, changeTimeQuantum }
    ]
}

export default Presenter