import "./index.less"
import PadHeader from "@/components/padHeader";
import Presenter from "./presenter.js";
import TopRightBox from "@/components/memorizeWords/topRightBox";
import ToneGenerator from "@/components/memorizeWords/toneGenerator"
import DictionaryComment from "@/components/memorizeWords/dictionaryComment"
import DictionaryModal from "@/components/memorizeWords/dictionaryModal"
import TaskModal from "@/components/memorizeWords/taskModal"
import { connect } from "react-redux";
import { setStudyRecorder } from "@/redux/actions/studyWords"
import { getParams } from "@/util/util"
import { useHistory } from 'react-router-dom';

function WordDetectionPage(props) {
    const history = useHistory()
    const query = getParams(window.location.href);
    const [states, events] = Presenter(props)
    const { executionOrder, schedule, info, examState, examIds, completeModalVisible } = states
    const { SubmitResponse, NextResponse } = events
    const { limit, isSubmit, optionIndex, isAnalysis, anlysisInfo } = examState
    return (
        <div className="WordDetectionPagePage">
            <PadHeader>
                <div className="pageHeaderBar">
                    <div className="title">{query?.type === '3' ? "单词复习" : "单词检测"}</div>
                    <TopRightBox className="wrongCount">
                        需巩固：<span>{
                            schedule < examIds.length ?
                                executionOrder.length - examIds.length :
                                executionOrder.length - (schedule + 1)
                        }</span>
                    </TopRightBox>
                    <TopRightBox className="currentCount">
                        已检测：<span>{
                            schedule + 1 > examIds?.length ? examIds?.length : schedule + 1
                        }/{examIds.length}</span>
                    </TopRightBox>
                </div>
            </PadHeader>
            <div className="substanceBox">
                <span className="reckonTimer">{limit} 秒</span>
                <div className={`question-main ${info?.examOptions?.length > 4 ? 'model' : ''}`}>
                    <div className="warp">
                        {
                            info?.examTypeId === 23 || info?.examTypeId === 28 ?
                                <div className="comment">
                                    <p>{info?.examStem}</p>
                                </div> :
                                info?.examTypeId === 22 || info?.examTypeId === 27 ?
                                    <div className="name">
                                        {info?.examStem}
                                    </div> : null
                        }
                        {
                            info?.examTypeId === 22 || info?.examTypeId === 27 ?
                                <section>
                                    {info?.phEnMp3 ? <ToneGenerator diction="英" symbol={info?.phEn} mp3Url={info?.phEnMp3} style={{ backgroundColor: '#F6F7FC' }} /> : null}
                                    {info?.phAmMp3 ? <ToneGenerator diction="美" symbol={info?.phAm} mp3Url={info?.phAmMp3} style={{ backgroundColor: '#F6F7FC' }} /> : null}
                                </section> : null
                        }
                    </div>
                </div>
                <p className="prompt">请选择正确的{(info?.examTypeId === 22 || info?.examTypeId === 27) ? '释义' : '单词'}：</p>

                <div className="selectOptions">
                    <div className="item">
                        {
                            info?.examOptions &&
                            info?.examOptions.map((v, i) =>
                                <DictionaryComment
                                    key={i}
                                    comment={(info?.examTypeId === 22 || info?.examTypeId === 27) ? v.content : undefined}
                                    word={(info?.examTypeId === 23 || info?.examTypeId === 28) ? v.content : undefined}
                                    textCenter
                                    state={isSubmit ?
                                        v.right ? "right" : (optionIndex === i || optionIndex === null) ? "error" : undefined
                                        : undefined
                                    }
                                    bottom="30"
                                    onClick={() => SubmitResponse(i, v.right)}
                                />
                            )
                        }
                    </div>
                </div>
            </div>

            <DictionaryModal
                visible={isAnalysis}
                closable={false}
                translateList={anlysisInfo?.cn?.split(';')}
                extWord={anlysisInfo?.extWord?.split(';')}
                word={anlysisInfo?.word}
                phEn={anlysisInfo?.phEn}
                phEnMp3={anlysisInfo?.phEnMp3}
                phAm={anlysisInfo?.phAm}
                phAmMp3={anlysisInfo?.phAmMp3}
                list={anlysisInfo?.newSentence}
                next={NextResponse}
            />

            <TaskModal
                title="检测完成"
                height="260"
                titleCenter
                visible={completeModalVisible}
                className="detectionComplete"
                okText="返回"
                ok={() => history.goBack()}
                cancel={false}
            >
                <p className="text">Perfect，本次复习单词<span>{examIds.length}</span>词，请再接再厉。</p>
            </TaskModal>
        </div>
    )
}

export default connect(({ studyWords }) => ({
    studyWords,
}), setStudyRecorder)(WordDetectionPage);