import "./index.less"
import AudioPlayer from "../audioPlayer"

function ToneGenerator({ diction, symbol, mp3Url, ...props }) {
    return (
        <label className="opSoundComponent" {...props}>
            <AudioPlayer url={mp3Url}>
                <span>{diction}</span>
                <b> /{symbol}/ </b>
                <i className="icon-Mp3"></i>
            </AudioPlayer>
        </label>
    )
}

export default ToneGenerator