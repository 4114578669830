import "./index.less"
import { useState } from "react";
import { SoundOutlined } from "@ant-design/icons";
import AudioPlayer from "@/components/memorizeWords/audioPlayer"
import SearchInput from "../searchInput"
import { wordTranslate } from "@/api/word";
import { getParams } from "@/util/util";
import { addCollectWord, delCollectWord } from "@/api/memorizeWords";

const FirstTab = ({ ...props }) => {
    const [text, setText] = useState("");
    const [firstWord, setFirstWord] = useState(null)
    const [isShowAdmin, setIsShowAdmin] = useState(false)

    const inputChange = (val) => {
        setText(val.trim())
        if (!val.trim()) setIsShowAdmin(false)
    };

    const handleTranslate = async (val = text) => {
        let paramsObj = getParams(window.location.href)
        const res = await wordTranslate({ word: val, type: 1, studentId: paramsObj.studentId })
        setIsShowAdmin(true)
        setFirstWord(res?.esWordDtoList[0] || null);
    };

    // 单词收藏事件处理
    const onCollectHandle = (flage, info, callBack) => {
        let paramsObj = getParams(window.location.href)

        let params = {
            studentId: paramsObj.studentId,
            subjectId: 0,
            type: 3,
            examId: info?.word || info?.examId
        }

        if (flage) {
            addCollectWord(params).then(res => {
                callBack && callBack(flage, info)
            })
        } else {
            delCollectWord(params).then(res => {
                callBack && callBack(flage, info)
            })
        }
    }

    const setFirstWordHandle = (flage) => {
        setFirstWord({ ...firstWord, collection: flage })
    }

    return (
        <div className="substanceBox firstTabComponent">

            <SearchInput
                className="searchInputMain"
                placeholder="请输入要查找的单词"
                allowClear
                value={text}
                storeKey="glossary_search_history_list"
                onChange={(e) => inputChange(e.target.value)}
                onSearch={(value) => handleTranslate(value)}
            />

            {
                isShowAdmin &&
                (firstWord?.word ?
                    <div className="examContent">
                        <span className="collect" onClick={() => onCollectHandle(!firstWord?.collection, firstWord, setFirstWordHandle)}>
                            {firstWord?.collection ? <><i className="ri-heart-3-fill" />取消收藏</> : <><i className="ri-heart-3-line" />收藏</>}
                        </span>
                        <div className="dialogPage">
                            <div className="wordStem">{firstWord?.word}</div>
                            <div className="transliterationBox">
                                {
                                    firstWord?.phAm &&
                                    <AudioPlayer url={firstWord?.phAmMp3} errorText>
                                        <div className="usa">
                                            <div className="borderBox">美</div>
                                            <div>[{firstWord?.phAm}]</div>
                                            {firstWord?.phAmMp3 && <SoundOutlined className="iconBox" />}
                                        </div>
                                    </AudioPlayer>
                                }
                                {
                                    firstWord?.phEn &&
                                    <AudioPlayer url={firstWord?.phEnMp3} errorText>
                                        <div className="usa">
                                            <div className="borderBox">英</div>
                                            <div>[{firstWord?.phEn}]</div>
                                            {firstWord?.phEnMp3 && <SoundOutlined className="iconBox" />}
                                        </div>
                                    </AudioPlayer>
                                }
                            </div>
                            <div className="contentBox" style={{maxHeight:'55.5vh'}}>
                                <div className="typesBox">
                                    <p className="titleBox">
                                        <span className="line"></span>
                                        释义
                                    </p>
                                    <div>
                                        <p>{firstWord?.cn}</p>
                                    </div>
                                </div>
                                <div className="typesBox">
                                    <p className="titleBox">
                                        <span className="line"></span>
                                        例句
                                    </p>
                                    {
                                        firstWord?.newSentence?.length ?
                                            firstWord?.newSentence?.slice(0, 3).map((v, i) =>
                                                <div key={i}>
                                                    <p>
                                                        {v.en}
                                                        <span className="chinese">{v.cn}</span>
                                                    </p>
                                                </div>
                                            )
                                            : <span className="chinese">暂无例句</span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="dialogPage">
                        <div className="wordStem">{text}</div>
                        <div className="nullText">暂无内容~</div>
                    </div>)
            }
        </div>
    )
}


export default FirstTab