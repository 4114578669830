import "./index.less";
import { forwardRef, useImperativeHandle } from "react";
import Presenter from "./presenter";
import React from "react";
import { Modal, Button, Input } from "antd";
import UploadFile from "@/components/answerList/components/uploadFile";

const Questionnaire = (props, ref) => {
  const { TextArea } = Input;
  const [states, events] = Presenter(props);

  const {
    showModal,
    handleOk,
    changeTextArea,
    handleType,
    updateAnswer,
    handleCancel,
  } = events;
  const uploadFileText = "添加图片";
  const { isModalVisible, typeList, active, TextAreaValue, imgList } = states;

  useImperativeHandle(ref, () => ({
    showModal: () => showModal(),
  }));

  const dom = document.querySelector("#root");

  return (
    <>
      <Modal
        className="questionnaireModalModal"
        title="请选择问题类型"
        visible={isModalVisible}
        getContainer={dom}
        onCancel={handleCancel}
        footer={[
          <Button key="submit" type="primary" onClick={handleOk}>
            提交
          </Button>,
        ]}
      >
        <div className="tpyeListBox">
          {typeList.map((v, index) => (
            <div
              onClick={() => handleType(index)}
              className={`typeBox ${active === index ? "active" : ""}`}
              key={v.type}
            >
              {v.text}
            </div>
          ))}
        </div>
        <p className="questionText">问题描述</p>
        <TextArea
          value={TextAreaValue}
          placeholder="请输入内容"
          className="questionInput"
          style={{ width: "100%", resize: "none" }}
          onChange={changeTextArea}
          maxLength={200}
          rows={5}
        />
        {/* <UploadFile
          imgList={imgList}
          updateAnswer={updateAnswer}
          uploadFileText={uploadFileText}
        /> */}
        {/* <div>添加图片</div> */}
      </Modal>
    </>
  );
};

export default forwardRef(Questionnaire);
