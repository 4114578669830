import { func } from "prop-types";
import request from "../util/request";

// 错题本首页 荣誉榜 清题榜 错题本科目错题入口
export function getWrongQuestionHome(params) {
    return request({
        url: "/class/v2/student/wrong/question/home",
        method: "get",
        params
    });
}

// 错题本首页 趋势图入口
export function getWrongQuestionTrend(params) {
    return request({
        url: "/class/v2/student/wrong/question/trend",
        method: "get",
        params
    });
}

// 收取小树能量
export function getScore(params) {
    return request({
        url: "/class/v2/student/get/score",
        method: "get",
        params
    })

}

// 榜单 班级年级切换
export function getWrongRankList(params) {

    return request({
        url: "/class/v2/student/wrong/rank/list",
        method: "get",
        params
    })
}

// 获取所有科目
export function getWrongQuestionSubject(params) {
    return request({
        url: "/class/v2/student/wrong/question/subject",
        method: "get",
        params
    })
}

// 获取当前错题的数据列表
export function getWrongQuestionList(params) {
    return request({
        url: "/class/v2/student/wrong/question/list",
        method: "get",
        params,
        meta: {
            notNeedSplit: true
        }
    })
}

// 做题提交
export function getWrongQuestionSubmit(data) {
    return request({
        url: "/class/v2/student/wrong/question/submit",
        method: "post",
        data
    })
}

// 主观判断对错
export function getWrongSubjectiveJudge(params) {
    return request({
        url: "/class/v2/student/wrong/subjective/judge",
        method: "post",
        params
    })
}

// 删除此题

export function removeWorngTopic(params) {
    return request({
        url: "/class/v2/student/wrong/deleted",
        method: "get",
        params
    })
}

// 创建标签
export function createlabel(params) {
    return request({
        url: "/class/v2/label/add",
        method: "get",
        params
    })
}

export function getLabelList(params) {
    return request({
        url: "/class/v2/label/list",
        method: "get",
        params
    })
}

export function updateWorngTopicLabel(params) {
    return request({
        url: "/class/v2/wrong/question/add/label",
        method: "get",
        params
    })
}

export function getWrongLabelList(params) {
    return request({
        url: "/class/v2/student/wrong/label/list",
        method: "get",
        params,
        meta: {
            notNeedSplit: true
        }
    })
}


export function deleteThisLabel(params) {
    return request({
        url: "/class/v2/label/del",
        method: "get",
        params
    })
}

export function deleteCurrentWrongLabel(params) {
    return request({
        url: "/class/v2/student/wrong/label/deleted",
        method: "get",
        params
    })
}

export function getChangeCurrentLabel(params) {
    return request({
        url: "/class/v2/label/update",
        method: "get",
        params
    })
}

// 错题添加收藏
export function addCollectMistakeExam(params) {
    return request({
        url: "/class/v2/stu/lesson/student/collection/add",
        method: "get",
        params
    })
}

// 错题取消收藏
export function delCollectMistakeExam(params) {
    return request({
        url: "/class/v2/stu/lesson/student/collection/del",
        method: "get",
        params
    })
}











// /class/v2/student/wrong/question/trend?