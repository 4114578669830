import "./index.less"
import React, { useEffect } from "react"
import ToneGenerator from "@/components/memorizeWords/toneGenerator"

function DictionaryBriefCard({ wordName, point, phEn, phEnMp3, phAm, phAmMp3, bottom, isCollect, comment, style, className, onCollectHandle, uhf, ...props }) {

    const regex = /(n\.|adj\.|adv\.|abbr\.|pron\.|num\.|v\.|vt\.|art\.|prep\.|conj\.|int\.|vi\.|aux.v|int\.|u\.|c\.|pl\.|det\.|interj\.|aux\.)/g;
    let text = comment;
    let processComment = text && text.replace(regex, '<span>$1</span>')

    if (props?.direction === 'vertical') {

        return (
            <div className={`DictionaryBriefCardComponent-Y${uhf ? ' uhf' : ''}${className ? ' ' + className : ''}`} style={{ marginBottom: `calc(${bottom || 0}vh / 12)`, ...style }} {...props}>
                <div className="top">
                    <div className={`hostWord ${point ? 'point' : ''}`}>{wordName}</div>
                    {phEn && <ToneGenerator diction={`英`} symbol={phEn} mp3Url={phEnMp3} style={{ marginRight: 'calc(20vh / 12)' }} />}
                    {phAm && <ToneGenerator diction={`美`} symbol={phAm} mp3Url={phAmMp3} style={{ marginRight: 0 }} />}
                    {
                        isCollect !== undefined &&
                        <span className="collect" onClick={(e) => { e.stopPropagation(); onCollectHandle && onCollectHandle(!isCollect) }}>
                            {isCollect ? <><i className="ri-heart-3-fill" />取消收藏</> : <><i className="ri-heart-3-line" />收藏</>}
                        </span>
                    }
                </div>
                <div className="text">
                    <p dangerouslySetInnerHTML={{ __html: processComment }}></p>
                </div>
            </div>
        )

    } else if (props?.direction === 'level' || props?.direction === undefined) {

        return (
            <div className={`DictionaryBriefCardComponent-X${(' ' + className) || ''}`} style={{ marginBottom: `calc(${bottom || 0}vh / 12)`, ...style }} {...props}>
                <div className={`hostWord ${point ? 'point' : ''}`}>{wordName}</div>
                <div className="info">
                    <div style={{ display: 'flex' }}>
                        {phEn && <ToneGenerator diction={`英`} symbol={phEn} mp3Url={phEnMp3} />}
                        {phAm && <ToneGenerator diction={`美`} symbol={phAm} mp3Url={phAmMp3} />}
                        {
                            isCollect !== undefined &&
                            <span className="collect" onClick={(e) => { e.stopPropagation(); onCollectHandle && onCollectHandle(!isCollect) }}>
                                {isCollect ? <><i className="ri-heart-3-fill" />取消收藏</> : <><i className="ri-heart-3-line" />收藏</>}
                            </span>
                        }
                    </div>
                    <div className="text">
                        <p dangerouslySetInnerHTML={{ __html: processComment }}></p>
                    </div>
                </div>
            </div>
        )
    }
}

export default React.memo(DictionaryBriefCard)