/*
 * @Author: your name
 * @Date: 2021-12-30 17:37:17
 * @LastEditTime: 2022-05-17 13:32:54
 * @LastEditors: slw 3289360550@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /znxt-student2.0/src/components/answerList/components/fillBackExam/index.jsx
 */
import ExamSteam from "../examSteam";
import UploadFile from "../uploadFile";
import Presenter from "./presenter";
import "./index.less";
import { Switch } from "antd";
import { connect } from "react-redux";
import Keyboard from "@/components/keyboard";
import KeyboardNew from "@/components/keyboardNew";
import { getParams } from "@/util/util";

const FillBackExam = (props) => {
  const { info, isSubmit, isCorrect, isMistakes } = props;
  const [states, events] = Presenter(props);
  const { imgList, defaultChecked, isShowKeyboard, keyboardType, } = states;
  const {
    updateAnswer,
    onChange,
    changeInputValue,
    closeKeyBoard,
    delInputValue,
    inputOnBlank,
    inputOnTrue,
    onSwitch
  } = events;



  let obj = getParams(window.location.href)

  let isAutonomyPractice = obj.isAutonomyPractice ? JSON.parse(obj.isAutonomyPractice) : false;  //判断是否是同步练习  下面需要判断 同步练习的不显示拍照功能了


  let isPic =  info?.answerType === 3 ||
    (info?.resource?.exam?.examTypeId === 4) ||
    (info?.resource?.exam?.examTypeId === 16);

    
  let isRec = isSubmit && info?.answerType !== 1;

  return (
    <div className="fillbackGrid">
      <ExamSteam info={info?.mark || info?.resource?.exam?.examStem} />
      {(isPic || isRec) && !isAutonomyPractice ? (
        <div className="imgArea">
          <div className="uploadCom">
            {
              isPic && <UploadFile
                imgList={imgList}
                isDisable={isSubmit || defaultChecked}
                info={info}
                updateAnswer={updateAnswer}
                isMistakes={isMistakes}
                isSubmit={isSubmit}
              />
            }
          </div>
          {((isRec && info.answerType === 3) || isCorrect) ? (
            <>
              {

                isMistakes ? null :
                  <div className="switch">
                    <span>批改痕迹</span>
                    <Switch checked={defaultChecked} onChange={onChange} />
                    {info?.correctType && info?.correctType !== 0 ?
                      <img src={require(`@/assets/correct${info.correctType}.png`).default} alt="" />
                      : null}
                  </div>
              }
            </>
          ) : (
            ""
          )}
        </div>
      ) : null}

      {/* 键盘 */}
      {isShowKeyboard ? (
        <div>
          {keyboardType === 1 ? (
            <Keyboard
              onSwitch={onSwitch}
              changeInputValue={changeInputValue}
              closeKeyBoard={closeKeyBoard}
              delInputValue={delInputValue}
              inputOnBlank={inputOnBlank}
              inputOnTrue={inputOnTrue}
            ></Keyboard>
          ) : (
            <KeyboardNew
              onSwitch={onSwitch}
              changeInputValue={changeInputValue}
              closeKeyBoard={closeKeyBoard}
              delInputValue={delInputValue}
              inputOnBlank={inputOnBlank}
              inputOnTrue={inputOnTrue}
            ></KeyboardNew>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
connect();

export default connect(
  (state) => ({
    examItem: state.examItem,
  }),
  {}
)(FillBackExam);
