/*
 * @Author: your name
 * @Date: 2021-12-27 10:40:49
 * @LastEditTime: 2021-12-29 17:56:50
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \sdzn-student2.0\src\pages\liveList\components\shopCart\presenter.js
 */
/*
 * @Description: 购物车
 */

import { useState, useEffect, useImperativeHandle } from "react";
import { getParams } from "@/util/util";
import {
  getShopCartList,
  getShopCartDel,
  getShopCartClear,
  statisticalShopCart,
  postPayMoney,
  validateWXpaySuccess,
} from "@/api/live.js";
import { message } from "antd";

let timer = null;
const Presenter = (props, ref) => {
  const [buyCourseList, setBuyCourseList] = useState([]); // 购买的课程列表
  const [buyCourseMoney, setBuyCourseMoney] = useState(0); // 购物车的金额
  const [isPayVisible, setIsPayVisible] = useState(false); //打开二维码窗口
  const [urlQRCode, setUrlQRCode] = useState(false); //打开二维码
  const [payType, setPayType] = useState(); // 选择的支付方式
  const [orderId, setOrderId] = useState(); // 订单id

  let urlObj = getParams(window.location.href);

  // 父级调用
  useImperativeHandle(ref, () => ({
    getShopCartData: () => getShopCartData(),
  }));

  // 购物车列表
  const getShopCartData = () => {
    let params = {
      userId: urlObj.userId, // urlObj.userId,
      type: 1, // 固定为1
      page: 1,
      size: 100,
    };
    getShopCartList(params).then((res) => {
      setBuyCourseList(res || []);
      setUrlQRCode("");
    });
  };

  // 购物车的金额
  const getBuyCourseMoney = () => {
    let money = 0;
    buyCourseList.forEach((item) => (money += item.currentPrice));
    money = money.toFixed(2);
    setBuyCourseMoney(money);
  };

  // 删除
  const onCourseDel = (data) => {
    let params = {
      userId: urlObj.userId, // urlObj.userId,
      type: 1, // 固定为1
      ids: data.id, //  商品id  多个，英文逗号隔开
    };
    getShopCartDel(params).then(() => {
      getShopCartData();
    });
  };

  // 清空购物车
  const onShopCartClear = () => {
    let params = {
      userId: urlObj.userId, // urlObj.userId,
      type: 1, // 固定为1
    };
    getShopCartClear(params).then(() => {
      setBuyCourseList([]);
    });
  };

  // 结算
  const statisticalBtn = () => {
    let goodsIds = buyCourseList.map((item) => item.goodsId);
    if (goodsIds.length === 0) {
      message.warn("请添加课程");
      return;
    }
    let params = {
      account: urlObj.account,
      userId: urlObj.userId,
      userName: decodeURIComponent(urlObj.userName),
      schoolId: urlObj.schoolId,
      schoolName: decodeURIComponent(urlObj.schoolName),
      gradeId: urlObj.gradeId,
      gradeName: decodeURIComponent(urlObj.gradeName),
      type: 1,
      goodsIds: JSON.stringify(goodsIds),
    };
    statisticalShopCart(params).then((res) => {
      message.success("课程订单创建成功");
      if (res.orderAmount === 0) {
        message.success("购买成功");
        // onShopCartClear();
      } else {
        setOrderId(res.id);
        openQRCode(true);
      }
    });
  };

  // 选择支付方式
  const onPayType = (num) => {
    setPayType(num);
  };

  // 订单直接结算生成微信支付二维码
  const getPayMoney = () => {
    let returnUrl = location.href;
    let params = {
      orderId,
      payType, // 1 微信pc支付，2微信支付， 3支付宝网站支付， 4支付宝手机网站支付
      userId: urlObj.userId,
      returnUrl: "", // 支付成功返回的页面
    };
    postPayMoney(params).then((res) => {
      if (payType === 1) {
        setUrlQRCode(res.payInfo); // wx支付
      }
      if (payType === 3) {
        setUrlQRCode(res.payInfo); // wx支付
        // document.write(res.payInfo); // 支付宝支付
        let o = document.getElementById("alipayFrame");
        let ed = o.contentWindow.document || o.contentDocument;
        ed.open();
        ed.write(res.payInfo); // 放入支付宝的表单数据
        ed.close();
        ed.contentEditable = true;
        ed.designMode = "on";
      }
    });
  };

  // 轮询验证微信支付
  const timerWXpayType = () => {
    timer = setInterval(() => wxPaySuccess(), 3000);
  };

  // 关闭wx轮询
  const clearWXtime = () => {
    clearInterval(timer);
    timer = null;
  };

  // 开/ 关 二维码
  const openQRCode = (bool) => {
    // 关闭 二维码、支付类型清空
    if (!bool) {
      setUrlQRCode(undefined);
      setPayType(undefined);
      clearWXtime();
    }
    setTimeout(() => {
      setIsPayVisible(bool);
    });
  };

  // 微信支付成功
  const wxPaySuccess = () => {
    let params = {
      orderId,
      userId: urlObj.userId,
    };
    validateWXpaySuccess(params).then((res) => {
      if (res.state === 1) {
        onShopCartClear();
        clearWXtime();
        openQRCode(false);
        setUrlQRCode(undefined);
        setPayType(undefined);
        props?.showShopCart?.();
      }
    });
  };

  // 选择支付方式
  useEffect(() => {
    if (payType) {
      getPayMoney();
      timerWXpayType();
    }
  }, [payType]); // eslint-disable-line react-hooks/exhaustive-deps

  // 实时金额
  useEffect(() => {
    getBuyCourseMoney();
    props.getShopCartNum((buyCourseList || []));
  }, [buyCourseList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getShopCartData();
    // if (urlObj.paySuccess === '1') {
    //   onShopCartClear();
    // }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return [
    { buyCourseList, buyCourseMoney, isPayVisible, urlQRCode, payType },
    { onCourseDel, onShopCartClear, statisticalBtn, openQRCode, onPayType },
  ];
};

export default Presenter;
