/*
 * @Author: your name
 * @Date: 2021-07-21 14:44:04
 * @LastEditTime: 2022-04-27 18:20:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/pages/studentAnswer/presenter.js
 */

import React, { useRef } from "react";

import { timeMethod } from "@/util/util";
import { getExamStatistics } from "@/api/answer";
import { getParams } from "@/util/util";
import { useState, useEffect } from "react";
import pubsub from "@/util/pubSub";

const Presenter = () => {
  const [examStatisticsData, setExamStatisticsData] = useState(null);
  const [answerList, setAnswerList] = useState([]);
  const [progessWidth, setprogessWidth] = useState(100);

  const [examInfoList, setExamInfoList] = useState([]);

  const progressRef = useRef(null);

  useEffect(() => {
    setprogessWidth(progressRef?.current?.clientWidth || 100);
  }, [progressRef]);

  useEffect(() => {
    let pageObj = getParams(window.location.href);
    pubsub.subscribe("submitBack", () => {
      window.location.hash = `#/answer?token=${pageObj.token}&pid=${pageObj.id}&id=${pageObj.id}&studentId=${pageObj.studentId}&lessonTeacherId=${pageObj.lessonTeacherId}&type=${pageObj.type}&name=${pageObj.name}&levelId=${pageObj.levelId}&gradeId=${pageObj.gradeId}&schoolId=${pageObj.schoolId}`;
    });
    return () => {
      pubsub.unsubscribe("submitBack");
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getExamStatisticsData = () => {
    let obj = getParams(window.location.href);
    getExamStatistics({
      id: obj.pid || obj.id,
      studentId: obj.studentId,
    }).then((res) => {
      setExamStatisticsData(res);
      setAnswerList(res.answer);
      res.examInfo.forEach((el) => {
        el.scoreTotal = Math.round(el?.scoreTotal * 100) / 100;
        el.avgScoreTotal = Math.round(el?.avgScoreTotal * 100) / 100;
        el.scoreFull = Math.round(el?.scoreFull * 100) / 100;
        el.scoreObjective = Math.round(el?.scoreObjective * 100) / 100;
        el.scoreSubjective = Math.round(el?.scoreSubjective * 100) / 100;
        el.accuracy = (el?.rightCount / el?.countExam) * 100;
      });
      setExamInfoList(res.examInfo);
    });
  };
  useEffect(() => {
    getExamStatisticsData();
  }, []);
  return [
    {
      answerList,
      examInfoList,
      progressRef,
      progessWidth,
    },
    { timeMethod },
  ];
};

export default Presenter;
