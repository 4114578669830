/*
 * @Author: your name
 * @Date: 2021-06-24 16:57:05
 * @LastEditTime: 2022-09-15 17:30:53
 * @LastEditTime: 2021-08-06 10:54:32
 * @LastEditors: c1107 chen1997
 * @Description: In User Settings Edit
 * @FilePath: /antd-demo/src/App.js
 */
import React from "react";
import "./App.less";
import Login from "./pages/login";
import IndexPage from "./pages/indexPage";

import WordPk from "./pages/wordPK";
import WordPkDetails from "./pages/PKdetails";

import AnswerWrapper from "./pages/studentAnswer";
import PracticeReport from "./pages/practiceReport";
import ListQuestionSheet from "./pages/listQuestionSheet";
import MyCollect from "./pages/myCollect";
import CooperativeInquiry from "./pages/cooperativeInquiry";
import ErrorBook from "./pages/errorBook";
import KeyboardPage from "./pages/keyboardPage";
import GroupsScoreRank from "./pages/groupsScoreRank";
import ElectronicBook from "./pages/electronicBook";
import ElectronicPdfBook from "./pages/electronicPdfBook";
import LiveList from "./pages/liveList";
import ShopCart from "./pages/liveList/components/shopCart";
import VideoPlayback from "./pages/liveList/components/videoPlayback";
import MyCourse from "./pages/liveList/components/myCourse";
import WordStatistics from "./pages/wordStatistics";
import WordsRecite from "./pages/wordsRecite";
import Test from "./pages/test";
import PktIndexPage from "./pages/pktIndexPage";
import Glossary from "./pages/glossary";
import AutonomyPractice from "./pages/autonomyPractice";
import AutonomyAnswer from "./pages/autonomyAnswer";
import AutonomyPracticeReport from "./pages/autonomyPracticeReport";
import AutonomyCheck from "./pages/autonomyCheck";
import MyResult from "./pages/myResult";
import StudentAnalysis from "./pages/studentAnalysis";
import Japanese from "./pages/japanese"
import MistakesBooks from "./pages/mistakesBooks"
import MistakesBank from "./pages/mistakesBank"
import MistakesRearrange from "./pages/mistakesRearrange"
import LookTestPaper from "./pages/lookTestPaper"
import MemorizeWords from "./pages/memorizeWords"
import StudyWordsWay from "./pages/studyWordsWay"
import WordsChapterExericse from "./pages/wordsChapterExercise"
import WordDetection from "./pages/wordDetection"
import WordLearningRecord from "./pages/wordLearningRecord"
import WordEnglishCorner from "./pages/wordEnglishCorner"
import WordReview from "./pages/wordReview"
import IterationInfor from "./pages/IterationInfor"
import EarlyReading from "./pages/earlyReading"
import Morality from "./pages/morality"
import MoralityEnter from "./pages/moralityEnter"

import { Route, Switch, HashRouter as Router } from "react-router-dom";
import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN';

const App = () => (

  <ConfigProvider locale={zhCN}>
    <Router>
      <Switch>
        <Route component={Login} exact path="/"></Route> {/*  exact:精确匹配 */}
        <Route component={Login} path="/login"></Route>
        <Route component={IndexPage} path="/indexPage"></Route>
        <Route component={AnswerWrapper} path="/answer"></Route>
        <Route component={WordsRecite} path="/wordsRecite"></Route>
        <Route component={PracticeReport} path="/practiceReport"></Route>
        <Route component={KeyboardPage} path="/KeyboardPage"></Route>
        <Route component={ListQuestionSheet} path="/listQuestionSheet"></Route>
        <Route component={MyCollect} path="/myCollect"></Route>
        <Route component={CooperativeInquiry} path="/cooperativeInquiry"></Route>
        <Route component={ErrorBook} path="/errorBook"></Route>
        <Route component={GroupsScoreRank} path="/GroupsScoreRank"></Route>
        <Route component={LiveList} path="/liveList"></Route>
        <Route component={ElectronicBook} path="/electronicBook"></Route>
        <Route component={ElectronicPdfBook} path="/electronicPdfBook"></Route>
        <Route component={ShopCart} path="/shopCart"></Route>
        <Route component={MyCourse} path="/myCourse"></Route>
        <Route component={VideoPlayback} path="/videoPlayback"></Route>
        <Route component={WordPk} path="/wordPk"></Route>
        <Route component={WordPkDetails} path="/wordPkDetails"></Route>
        <Route component={WordStatistics} path="/wordStatistics"></Route>
        <Route component={Test} path="/test"></Route>
        <Route component={PktIndexPage} path="/pktIndexPage"></Route>
        <Route component={Glossary} path="/glossary"></Route>
        <Route component={AutonomyPractice} path="/autonomyPractice"></Route>
        <Route component={AutonomyAnswer} path="/autonomyAnswer"></Route>
        <Route component={AutonomyPracticeReport} path="/autonomyPracticeReport"></Route>
        <Route component={AutonomyCheck} path="/autonomyCheck"></Route>
        <Route component={MyResult} path="/myResult"></Route>
        <Route component={StudentAnalysis} path="/analysis"></Route>
        <Route component={Japanese} path="/japanese"></Route>
        <Route component={MistakesBooks} path="/mistakesBooks"></Route>
        <Route component={MistakesBank} path="/mistakesBank"></Route>
        <Route component={MistakesRearrange} path="/mistakesRearrange"></Route>
        <Route component={LookTestPaper} path="/lookTestPaper"></Route>
        <Route component={MemorizeWords} path="/memorizeWords"></Route>
        <Route component={StudyWordsWay} path="/studyWordsWay"></Route>
        <Route component={WordsChapterExericse} path="/wordsChapterExerise"></Route>
        <Route component={WordDetection} path="/wordDetection"></Route>
        <Route component={WordLearningRecord} path="/wordLearningRecord"></Route>
        <Route component={WordEnglishCorner} path="/wordEnglishCorner"></Route>
        <Route component={WordReview} path="/WordReview"></Route>
        <Route component={IterationInfor} path="/IterationInfor"></Route>
        <Route component={EarlyReading} path="/earlyReading"></Route>
        <Route component={Morality} path="/morality"></Route>
        <Route component={MoralityEnter} path="/moralityEnter"></Route>
      </Switch>
    </Router>
  </ConfigProvider>
);

export default App;
