/*
 * @Author: your name
 * @Date: 2021-12-24 14:34:02
 * @LastEditTime: 2022-01-10 15:48:53
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /znxt-student2.0/src/api/live.js
 */
import request from "../util/request";
// 任务详情接口
export function getAllliveCourseList(params) {
  return request({
    url: "/crm/live/course/online",
    method: "get",
    params,
  });
}

// 购物车接口
export function getShopCartList(params) {
  return request({
    url: "/class/v2/shopcart/list",
    method: "get",
    params,
  });
}

// 添加购物车
export function getAddShopCart(params) {
  return request({
    url: "/class/v2/shopcart/add",
    method: "get",
    params,
  });
}

// 购物车删除单个或某些接口
export function getShopCartDel(params) {
  return request({
    url: "/class/v2/shopcart/del",
    method: "get",
    params,
  });
}

// 购物车清空
export function getShopCartClear(params) {
  return request({
    url: "/class/v2/shopcart/clear",
    method: "get",
    params,
  });
}

// 购物车结算
export function statisticalShopCart(data) {
  return request({
    url: "/class/v2/order/create",
    method: "post",
    data,
    meta: {
      isSerialize: true,
    },
  });
}

// 订单结算
export function postPayMoney(data) {
  return request({
    url: "/class/v2/order/unifiedOrder",
    method: "post",
    data,
    meta: {
      isSerialize: true,
    },
  });
}

// 订单列表
export function getOrderList(params) {
  return request({
    url: "/class/v2/order/list",
    method: "get",
    params,
  });
}

// 今日课程 getTodayLiveList
export function getTodayLiveList(params) {
  return request({
    url: "/class/v2/stu/lesson/getRecentLive",
    method: "get",
    params,
  });
}

// 验证微信是否支付成功
export function validateWXpaySuccess(params) {
  return request({
    url: "/class/v2/order/state",
    method: "get",
    params,
  });
}

// 已购课程列表 getBuyCourseList
export function getBuyCourseList(params) {
  return request({
    url: "/class/v2/order/course",
    method: "get",
    params,
  });
}

// 已经购买课程的课次
export function getCourseItemList(params) {
  return request({
    url: "/class/v2/teacher/course/listByDropDown",
    method: "get",
    params,
  });
}
