import "./index.less"
import Presenter from "./presenter.js";
import nextIcon from "../../../../assets/errorsBook/next.png"
import { Modal } from 'antd';
import { getParams } from "@/util/util"

const calc = (px) => {
    const clientHeight = window.document.querySelector('body').clientHeight / 100
    return px * clientHeight / 12
}

function Control(props) {
    const [states, events] = Presenter(props);
    const { style, isSubmit, isAnswer, type, showAction, isNextButton } = props
    const { isSubmitModalOpen, verify } = states
    const { onSubmitRespond, clouseSubmit, submitSuccessfully, messageHint, onChangeJudge, onChangeNext } = events

    return (
        <>
            <div className="OperateParticularly" style={style}>
                {
                    // 判断当前是否有作答权限，有就可以显示提交相关按钮，没有则无需显示
                    isAnswer &&
                    <>
                        {
                            // 判断当前已提交 且是需要自主判断对错题型
                            isSubmit && type && showAction &&
                            <>
                                <div className={verify === "" ? "correct" : verify === "error" ? "correct disabled" : "correct"} onClick={() => onChangeJudge('right')}>回答正确</div>
                                <div className={verify === "" ? "error" : verify === "right" ? "error disabled" : "error"} onClick={() => onChangeJudge('error')}>回答错误</div>
                            </>
                        }
                        {
                            // 提交后不再展示提交按钮
                            !isSubmit &&
                            <div className="submit-one" onClick={onSubmitRespond}>提交</div>
                        }
                    </>
                }
                {
                    isNextButton && props.index !== 0 &&
                    <div className="next" onClick={props.onChangePrev} >
                        上一题
                    </div>
                }
                <div className={isNextButton ? "next" : "next dis"} onClick={() => onChangeNext(messageHint)} >
                    下一题
                </div>
            </div>

            <Modal visible={isSubmitModalOpen} wrapClassName="submitModal" closable={false} centered maskClosable footer={null}>
                <div className="SubmitModalText" >是否提交答案？</div>
                <div className="btn">
                    <div className="goOn" onClick={clouseSubmit}>继续作答</div>
                    <div className="over" onClick={submitSuccessfully}>确认提交</div>
                </div>
            </Modal>
        </>
    )
}

export default Control