/*
 * @Author: your name
 * @Date: 2021-10-20 17:42:38
 * @LastEditTime: 2022-02-21 19:31:42
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /znxt-student2.0/src/components/answerList/components/judgeExam/index.jsx
 */
import "./index.less";
import ExamSteam from "../examSteam";
import Presenter from "./presenter";
import { connect } from "react-redux";

const judgeList = [
  {
    name: "正确",
    icon: "ri-check-line",
  },
  {
    name: "错误",
    icon: "ri-close-line",
  },
];

const JudgeExam = (props) => {
  const { info } = props;
  const [states, events] = Presenter(props);

  const { activeIndex } = states;
  const { changeActiveIndex } = events;

  return (
    <div className="JudgeExamComponent">
      <ExamSteam
        info={info?.mark || info?.resource?.exam?.examStem}
      ></ExamSteam>
      <ul>
        {(info?.resource?.exam?.examOptions || []).map((v, index) => (
          <li
            key={index}
            className={activeIndex === v.seq ? "active" : ""}
            onClick={() => changeActiveIndex(index, v, info)}
          >
            <p className="item">
              <i className={judgeList[index].icon}></i>
            </p>
            <div className="content">{v.content}</div>
          </li>
        ))}
      </ul>
    </div>
  );
};

connect();

export default connect(
  (state) => ({
    examItem: state.examItem,
  }),
  {}
)(JudgeExam);
