/*
 * @Author: your name
 * @Date: 2021-12-24 14:35:28
 * @LastEditTime: 2021-12-31 21:30:51
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /znxt-student2.0/src/pages/liveList/presenter.js
 */

import { useState, useEffect, useRef } from "react";
import {
  getAllliveCourseList,
  getAddShopCart,
  getCourseItemList,
} from "@/api/live.js";
import { getParams } from "@/util/util";
import { message } from "antd";
const Presenter = () => {
  const childShopCartRef = useRef("购物车");
  const [liveList, setliveList] = useState([]);
  const [show, setShow] = useState(false);
  const [shopCartNum, setShopCartNum] = useState(0);
  const [buyCourseItems, setBuyCourseItems] = useState([]); // 已购课程-课次
  const [isCourseVisible, setIsCourseVisible] = useState(false); //打开课次
  const [shopCartList, setShopCartList] = useState([]); // 购物车列表

  let urlObj = getParams(window.location.href);

  const showShopCart = () => {
    setShow(!show);
  };

  // 添加到购物车
  const addShopCart = (e, data) => {
    e.stopPropagation();
    let params = {
      goodsId: data.id,
      userId: urlObj.userId,
      account: urlObj.account,
      type: 1, // 固定为 1
    };
    getAddShopCart(params).then((res) => {
      childShopCartRef.current.getShopCartData();
      message.success("已加入");
    });
  };

  // 全部课程列表
  const allLiveCourseList = () => {
    let params = {
      schoolId: urlObj.schoolId, // 学校id
      gradeId: urlObj.gradeId, // 年级id
      // subjectId: urlObj.subjectId, // 科目id
    };
    getAllliveCourseList(params).then((res) => {
      setliveList(res);
    });
  };

  // 购物车的数量
  const getShopCartNum = (list) => {
    setShopCartNum(list.length);
    setShopCartList(list);
  };

  // 打开课次弹窗
  const openLessonModal = (bool, data) => {
    setBuyCourseItems([]);
    setIsCourseVisible(bool);
    getCourseItems(data);
  };

  // 获取课次
  const getCourseItems = (data) => {
    if (!data?.id) return;
    let params = {
      liveCourseId: data.id,
      types: 70, // 直播课
    };
    getCourseItemList(params).then((res) => {
      setBuyCourseItems(res || []);
    });
  };

  useEffect(() => {
    allLiveCourseList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return [
    {
      liveList,
      show,
      childShopCartRef,
      shopCartNum,
      buyCourseItems,
      isCourseVisible,
      shopCartList,
    },
    { showShopCart, addShopCart, getShopCartNum, openLessonModal },
  ];
};
export default Presenter;
