/*
 * @Author: your name
 * @Date: 2021-07-21 14:44:04
 * @LastEditTime: 2022-09-16 14:02:40
 * @LastEditors: c1107 chen1997
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/pages/studentAnswer/presenter.js
 */

import React from "react";

import { useState, useEffect } from "react";
import { addCourseAnswer } from "@/api/answer";
import { getParams } from "@/util/util";
import store from "@/redux/store";
import { getEduselflearn } from "@/api/autonomyPractice";

const Presenter = () => {
  const [courseDetail, setCourseDetail] = useState(null);
  const name =
    decodeURIComponent(getParams(window.location.href).name) || "课程名称";
  const [activeInfo, setActiveInfo] = useState({
    key: 0,
    val: {},
    index: 0,
  }); // 当前选中得失哪一类：导学案：0，资源：1，试题包：2

  const [weekEnFiles, setWeekEnFiles] = useState({}); //周清听力
  const urlObj = getParams(window.location.href);

  useEffect(() => {
    getDetail();
    return () => {
      store.dispatch({
        type: "updateCourseInfo",
        data: {},
      });
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getDetail = () => {
    let obj = getParams(window.location.href);
    // getEduselflearn({
    //   chapterId: obj.chapterId,
    //   studentId: obj.studentId,
    // }).then((res) => {
    let data =
      {
        lessonStudentId: obj?.lessonStudentId,
        id: obj?.lessonStudentId,
        levelId: obj.levelId,
        name: obj.name,
        studentId: obj?.studentId,
        // studentName: res?.studentName,
        chapterId: obj.chapterId,
        countExam: obj?.countExam,
        correctState: 1,
        status: 0,
      } || {};

    setCourseDetail(data);

    setDefault({
      examInfo: [data],
    });
    // store.dispatch({
    //   type: "updateCourseInfo",
    //   data: data,
    // });

    //   setDefault(res);
  };

  const setData = (res) => {
    if (res?.info) {
      let obj = getParams(window.location.href);
      let info = JSON.parse(res.info);
      if (obj.type === "30") {
        res.models = [
          {
            c: info?.comment,
            n: "备注",
            t: 0,
          },
        ];
      }
      return res;
    } else {
      return res;
    }
  };

  const initResourceSee = (obj) => {
    if (obj.useTime === 0) {
      addCourseAnswer({
        lessonStudentId: obj?.relationId,
        resourceId: obj?.file?.id,
        resourceType: 1,
        usedTime: 1000, // timerNum
      });
    }
  };

  const setFile = (res, defaultVal) => {
    let obj = getParams(window.location.href);

    if (res.files && res.files.length > 0) {
      defaultVal.key = 1;
      // val默认是最后一个，但是如果链接中的name是资源名称的话，就需要查找一下index
      if (obj.name) {
        let index = res.files.findIndex(
          (v) => encodeURIComponent(v.file.name) === obj.name
        );
        defaultVal.val = res.files[index > -1 ? index : res.files.length - 1];
        initResourceSee(res.files[index > -1 ? index : res.files.length - 1]);
      } else {
        defaultVal.val = res.files[res.files.length - 1];
        initResourceSee(res.files[res.files.length - 1]);
      }
      setActiveInfo(defaultVal);
      return;
    }
    setExam(res, defaultVal);
  };

  const setExam = (res, defaultVal) => {
    console.log(res);
    let obj = getParams(window.location.href);
    // if (res.examInfo && res.examInfo.length > 0) {
    if (res.examInfo && res.examInfo.length > 0) {
      let index = 0;
      // if (obj.pid) {
      //   index = res.examInfo.findIndex((v) => v.id === Number(obj.pid));
      // } else {
      //   index = res.examInfo.findIndex((v) => v.id === Number(obj.id));
      // }

      defaultVal.key = 2;
      defaultVal.val = res.examInfo[index];
      setActiveInfo(defaultVal);

      store.dispatch({
        type: "updateRightAnswerShowState",
        data: false,
      });
      console.log(res);

      // if (
      //   new Date().getTime() <
      //   res.examInfo[index > -1 ? index : 0].answerPublishTime
      // ) {
      //   store.dispatch({
      //     type: "updateRightAnswerShowState",
      //     data: false,
      //   });
      //   res.examInfo[index > -1 ? index : 0].notShowAnswerBtn = true;
      // } else {
      //   store.dispatch({
      //     type: "updateRightAnswerShowState",
      //     data: true,
      //   });
      //   res.examInfo[index > -1 ? index : 0].notShowAnswerBtn = false;
      // }

      return;
    }
  };

  const setDefault = (res) => {
    let defaultVal = {
      key: 0,
      val: {},
      index: 0,
    };
    // 默认是展示导学案模板的
    // 优先级从高到低： 导学案模板、资源、试题
    setExam(res, defaultVal);
    // let obj = getParams(window.location.href);
    // if (obj.pid) {
    //   setExam(res, defaultVal);
    // } else {
    //   if (!res.models) {
    //     setFile(res, defaultVal);
    //   }
    // }
  };

  const changeComponents = (obj) => {
    setActiveInfo(obj);
  };

  return [
    {
      courseDetail,
      activeInfo,
      name,
      weekEnFiles,
    },
    {
      changeComponents,
    },
  ];
};

export default Presenter;
