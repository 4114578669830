/*
 * @Author: your name
 * @Date: 2021-10-20 17:42:38
 * @LastEditTime: 2022-04-01 15:38:32
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /znxt-student2.0/src/components/keyboardNew/index.jsx
 */
import react from "react";
import presenter from "./presenter";
import "./index.less";

const keyboardNew = (props) => {
  const [states, events] = presenter(props);
  const { clickKey, switchClick } = events;
  const { lowercase, keyObj } = states;
  return (
    <div>
      <div className="keyboardNew">
        <div className="keyboardTop">
          <div className="leftBox">
            <div className="icon">
              <i className="ri-keyboard-box-line"></i>
            </div>
            <div className="text">键盘作答</div>
            <div className="textTwo" onClick={switchClick}>
              正常键盘
            </div>
          </div>
          <div className="rightBox" onClick={() => clickKey("close")}>
            <div className="one">丨</div>
            <div className="line">
              <i className="ri-arrow-down-s-line"></i>
            </div>
          </div>
        </div>
        {(lowercase || []).map((item, index) => (
          <div key={index} className="rowBox">
            {(item || []).map((el, i) => (
              <span
                className={`box ${el}`}
                key={el}
                onClick={() => clickKey(el)}
              >
                {keyObj[el] ? (
                  <i>
                    {/* {keyObj[el]} */}
                    {/* <i className='ri-home-2-line'></i> */}
                  </i>
                ) : (
                  <span>{el}</span>
                )}
              </span>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default keyboardNew;
