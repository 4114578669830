import "./index.less"
import PadHeader from "@/components/padHeader";
import Presenter from "./presenter.js";
import TopRightBox from "@/components/memorizeWords/topRightBox"
import DictionaryBriefCard from "@/components/memorizeWords/dictionaryBriefCard"
import DictionaryModal from "@/components/memorizeWords/dictionaryModal"
import ScrollView from "@/components/memorizeWords/scrollView"
import { getParams, dateFormat, isSameDay } from "@/util/util"
import { useHistory } from 'react-router-dom'

function WordReview(props) {
    const history = useHistory()
    const [states, events] = Presenter(props)
    const { wordMenuList, count, keyCount, wordDetail, wordModalVisible } = states
    const { addWordMenuList, offWordModal, openWordModal } = events
    const query = getParams(window.location.href);

    return (
        <div className="WordReviewPage">
            <PadHeader>
                <div className="pageHeaderBar">
                    <div className="title">{query?.count ? "复习单词" : "重点单词"}</div>
                    <div className="right">
                        <TopRightBox className="wordCount" >
                            {query?.count ? <span>单词总数：<i>{count}</i></span> : null}
                            {query?.count && query?.keyCount ? <em></em> : null}
                            {query?.keyCount ? <span>重点单词数：<i>{keyCount}</i></span> : null}
                        </TopRightBox>
                    </div>
                </div>
            </PadHeader>
            <div className="substanceBox">
                <div className="operate">
                    {query?.count && count !== 0 ? <div className="btn all"
                        onClick={() =>
                            history.push(`/wordDetection?studentId=${query.studentId}&studentName=${query.studentName}&gradeId=${query.gradeId}&classId=${query.classId}&schoolId=${query.schoolId}&type=${3}&dayTime=${query.dayTime}&chapterId=${0}`)
                        }
                    >复习全部单词</div> : null}
                    {query?.keyCount && keyCount !== 0 ? <div className="btn key"
                        onClick={() =>
                            history.push(`/wordDetection?studentId=${query.studentId}&studentName=${query.studentName}&gradeId=${query.gradeId}&classId=${query.classId}&schoolId=${query.schoolId}&type=${3}&dayTime=${query.dayTime}&resultState=${1}&chapterId=${0}`)
                        }
                    >复习重点单词</div> : null}
                </div>
                <ScrollView height="calc(100vh - (160vh / 12))" onScrollBottom={addWordMenuList} bottomDistance="100">
                    {
                        (wordMenuList || []).map((v, i) =>

                            <div className="everydayItem" key={i}>
                                {isSameDay(wordMenuList[i - 1]?.createTime, v?.createTime) ? null : <span className="pointOfTime">{dateFormat(v.createTime, "yyyy.MM.dd")}</span>}
                                <DictionaryBriefCard
                                    key={i}
                                    point={query?.count ? !!v.keyState : false}
                                    wordName={v.word}
                                    comment={v.cn}
                                    phEn={v.phEn}
                                    phEnMp3={v.phAmMp3}
                                    phAm={v.phAm}
                                    phAmMp3={v.phAmMp3}
                                    bottom="20"
                                    onClick={() => openWordModal(i)}
                                />
                            </div>
                        )
                    }
                    <div className="box" style={{ height: "calc(74vh / 12)" }}></div>
                </ScrollView>
            </div>

            <DictionaryModal
                visible={wordModalVisible}
                onCancel={offWordModal}
                translateList={wordDetail?.cn?.split(';')}
                extWord={wordDetail?.extWord?.split(';')}
                word={wordDetail?.word}
                phEn={wordDetail?.phEn}
                phEnMp3={wordDetail?.phEnMp3}
                phAm={wordDetail?.phAm}
                phAmMp3={wordDetail?.phAmMp3}
                list={wordDetail?.newSentence}
            />
        </div>
    )
}


export default WordReview