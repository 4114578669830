import { useEffect, useMemo, useState } from "react"
import { getParams } from "@/util/util"
import { getReviewWordList } from "@/api/memorizeWords";
import { useHistory } from 'react-router-dom'


const Presenter = (props) => {
    const history = useHistory()
    const query = getParams(window.location.href);
    const [wordMenuList, setWordMenuList] = useState([])
    const [lastId, setLastId] = useState(0)
    const [count, setCount] = useState(0)
    const [keyCount, setKeyCount] = useState(0)
    const [wordActive, setWordActive] = useState(0)
    const wordDetail = useMemo(() => {
        return wordMenuList[wordActive]
    }, [wordActive, wordMenuList])
    const [wordModalVisible, setWordModalVisible] = useState(false)

    useEffect(() => {
        let params = {
            studentId: query?.studentId,
            dayTime: query?.dayTime,
            resultState: query?.resultState,
            size: 10,
            lastId: lastId
        }

        getReviewWordList(params).then(data => {

            let res = data.data

            if (data?.meta) {
                setCount(data?.meta?.page || 0)
                setKeyCount(data?.meta?.size || 0)
            }

            if (lastId === 0) {
                if (res.length === 0) return history.goBack()
                setWordMenuList(res || [])
            } else {
                setWordMenuList(list => [...list, ...(res || [])])
            }
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastId])

    const addWordMenuList = () => {
        setLastId(wordMenuList[wordMenuList.length - 1]["wordLearningRecordId"])
    }

    const offWordModal = () => {
        setWordModalVisible(false)
    }

    const openWordModal = (index) => {
        setWordActive(index)
        setWordModalVisible(true)
    }


    return [
        { wordMenuList, count, keyCount, wordDetail, wordModalVisible },
        { addWordMenuList, offWordModal, openWordModal }
    ]
}

export default Presenter