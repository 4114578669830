import React, { forwardRef } from "react";
import Presenter from "./presenter.js";
import "./index.less";
import { Button, Modal } from "antd";
import { dateFormat, isApp } from "@/util/util";
import QRCode from "qrcode.react";

const ShopCart = (props, ref) => {
  const [states, events] = Presenter(props, ref);
  const { buyCourseList, buyCourseMoney, isPayVisible, urlQRCode, payType } =
    states;
  const {
    onCourseDel,
    onShopCartClear,
    statisticalBtn,
    openQRCode,
    onPayType,
  } = events;

  return (
    <div className="shop_cart_cont">
      <div className="title">我的购物车</div>
      <div className="clear">
        <span className="total">
          共<span className="color">{buyCourseList.length}</span>门课程
        </span>
        <Button danger size="small" onClick={() => onShopCartClear()}>
          清空购物车
        </Button>
      </div>
      <div className="course_list">
        {buyCourseList.map((item) => (
          <div className="course_shop_cont" key={item.id}>
            <img
              src={
                item.logo.indexOf("http") > -1
                  ? item.logo
                  : require("@/assets/login/teacherBk.png").default
              }
              alt="logo"
            />
            <div className="data_cont">
              <div className="txt name">
                {/* <span className="label">课程名：</span> */}
                {item.goodsName}
              </div>
              {/* <div className="txt account"><span className='label'>账号：</span>{item.account}</div> */}
              {/* <div className="txt state">
                <span className="label">状态：</span>
                {item.goodsState}
              </div> */}
              <div className="txt price">
                <span className="label">价格：</span>￥{item.currentPrice}
              </div>
              <div className="txt time">
                {/* <span className="label">时间：</span> */}
                {dateFormat(item.updateTime - 0)}
              </div>
            </div>
            <div className="btns">
              <Button danger size="small" onClick={() => onCourseDel(item)}>
                删除
              </Button>
            </div>
          </div>
        ))}
        {buyCourseList.length === 0 && (
          <div className="no_data">添加课程，来提升自己吧</div>
        )}
      </div>
      <div className="settlement_cont">
        <div className="statistical_price">
          <span className="color">合计：</span> ￥{buyCourseMoney}
          <span className="color">元</span>
        </div>
        <Button
          className="statistical_btn"
          size="large"
          onClick={statisticalBtn}
        >
          立即结算
        </Button>
      </div>
      <Modal
        title="选择支付方式"
        visible={isPayVisible}
        onCancel={() => openQRCode(false)}
        footer={null}
      >
        <div className="QRCode_code">
          {!urlQRCode && (
            <div className="select_pay_cont">
              <div className="pay_item pay_item1" onClick={() => onPayType(3)}>
                {/* 支付宝 */}
              </div>
              <div className="pay_item pay_item2" onClick={() => onPayType(1)}>
                {/* 微信 */}
              </div>
            </div>
          )}
          {urlQRCode && payType === 1 && <QRCode value={urlQRCode}></QRCode>}
          {urlQRCode && payType === 3 && (
            <iframe
              title="支付宝"
              className="alipay_frame"
              id="alipayFrame"
            ></iframe>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default forwardRef(ShopCart);
