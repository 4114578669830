/*
 * @Author: your name
 * @Date: 2021-08-19 11:02:14
 * @LastEditTime: 2021-08-19 11:07:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/redux/actions/subjectId.js
 */
import { UPDATESUBJECTID } from "../constant";

const initState = null;
export default function subjectIdReducer(preState = initState, action) {
  const { type, data } = action;
  switch (type) {
    case UPDATESUBJECTID:
      return data;
    default:
      return preState;
  }
}
