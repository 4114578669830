/*
 * @Author: your name
 * @Date: 2021-07-22 08:44:41
 * @LastEditTime: 2022-04-01 10:32:53
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/components/answerList/components/chioceExam/presenter.js
 */

import { useState, useRef, useEffect } from "react";

const Presenter = (props) => {
  const [activeIndex, setActiveIndex] = useState(""); // 学生选中的 - active
  const [rightIndex, setrightIndex] = useState(""); // 正确答案的 - activeRight
  const [errorIndex, seterrorIndex] = useState(""); // 错误答案的 - activeError
  const errorNum = useRef(0);
  const isTrue = useRef(false);

  const { info, seq, state, examTotal } = props;
  useEffect(() => {
    let imgBox = document.querySelector(".goodImgBox");
    imgBox.style.opacity = 0;
    setActiveIndex("");
    setrightIndex("");
    seterrorIndex("");
    errorNum.current = 0;
    isTrue.current = false;
  }, [seq, state]);

  const changeActiveIndex = (index, v) => {
    let str = "";

    if (state) {
      // 检测作答
      if (v.right) {
        errorNum.current = errorNum.current + 1;
        if (errorNum.current === 1) {
          setrightIndex(v.seq - 1 + str);
          // 正确图标
          setTrueImg();
        }
        // 作答
        props.wordAnswer(v.seq);
        setTimeout(() => {
          // 下一题
          if (examTotal > seq) {
            props.propsSetIndexSeq(seq + 1);
          } else {
            props.goToStatistics();
          }
        }, 600);
      } else {
        errorNum.current = errorNum.current + 1;

        if (errorNum.current === 1) {
          str = activeIndex + "," + index;
          setActiveIndex(str);
          seterrorIndex(str);
          props.wordAnswer(v.seq);
          props.shakeAnimation();

          setTimeout(() => {
            props.showModal();
          }, 600);
        }
      }
    } else {
      // 学习阶段
      if (v.right) {
        errorNum.current = errorNum.current + 1;
        if (errorNum.current === 1) {
          // 正确图标
          setTrueImg();
          setrightIndex(v.seq - 1 + str);
          setTimeout(() => {
            props.showModal();
          }, 600);
        } else if (errorNum.current <= 2) {
          setrightIndex(v.seq - 1 + str);
          setTimeout(() => {
            props.showModal();
          }, 600);
        }
      } else {
        errorNum.current = errorNum.current + 1;

        if (errorNum.current === 2 && !isTrue.current) {
          props.shakeAnimation();
          str = activeIndex + "," + index;
          setActiveIndex(str);
          seterrorIndex(str);

          setTimeout(() => {
            props.showModal();
          }, 600);
        } else if (errorNum.current <= 2 && !isTrue.current) {
          props.shakeAnimation();
          str = activeIndex + "," + index;
          setActiveIndex(str);
          seterrorIndex(str);
        }
      }
    }
  };

  const setTrueImg = () => {
    isTrue.current = true;
    let imgBox = document.querySelector(".goodImgBox");
    imgBox.style.opacity = 1;
  };

  return [
    {
      activeIndex,
      rightIndex,
      errorIndex,
      errorNum,
      isTrue,
    },
    {
      changeActiveIndex,
    },
  ];
};

export default Presenter;
