/*
 * @Author: your name
 * @Date: 2022-03-14 10:10:11
 * @LastEditTime: 2022-04-06 11:30:20
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newGitZnxt2.0\src\components\answerList\components\wordDialog\presenter.js
 */

import { useEffect, useState, useRef } from "react";
// import Range from "./unit/Range";
import store from "@/redux/store";
import { getParams } from "@/util/util";
import { androidHandle } from "@/util/android";

const WordDialog = (props) => {
  const { info, total, seq, state } = props;

  const [title, setTitle] = useState("");
  const [translateList, setTranslateList] = useState([]);

  const translate = useRef([]);

  const examSteam = useRef(null);
  const url = "https://file.aiznclass.com/word";
  useEffect(() => {
    let obj = getParams(window.location.href);

    if (obj?.todayReview) {
      // 今日复习
      if (total > seq) {
        setTitle("下一题 →");
      } else {
        setTitle("完成复习");
      }
    } else {
      if (state) {
        if (total > seq) {
          setTitle("下一题 →");
        } else {
          setTitle("检测结果");
        }
      } else {
        if (total > seq) {
          setTitle("下一题 →");
        } else {
          setTitle("去检测");
        }
      }
    }

    let arr = info?.cn || "";
    let newArr = arr.split(";") || [];
    setTranslateList(newArr); 
  }, [seq, total, state]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleNext = (title) => {
    let obj = getParams(window.location.href);

    if (obj?.todayReview) {
      // 今日复习
      if (title === "完成复习") {
        androidHandle("nativeBackHome");
        localStorage.removeItem("wordProcess");
      } else {
        // 关闭弹窗
        props.cancel();
        // 下一题
        props.propsSetIndexSeq(seq + 1);
      }
    } else {
      if (state) {
        // 检测作答
        if (title === "检测结果") {
          props.goToStatistics();
          props.cancel();
          localStorage.removeItem("wordProcess");
        } else {
          props.cancel();
          props.propsSetIndexSeq(seq + 1);
        }
      } else {
        // 学习阶段
        if (title === "去检测") {
          props.getOneExam();
          props.cancel();
        } else {
          // 关闭弹窗
          props.cancel();
          // 下一题
          props.propsSetIndexSeq(seq + 1);
        }
      }
    }
  };

  const playMp3 = (type) => {
    if (type === "UK") {
      const audios = document.getElementById("musicUK");
      audios.play();
    } else {
      const audios = document.getElementById("musicUSA");
      audios.play();
    }
  };

  return [
    { examSteam, title, translateList },
    { handleNext, playMp3 },
  ];
};

export default WordDialog;
