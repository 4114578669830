/*
 * @Author: your name
 * @Date: 2022-03-15 17:20:03
 * @LastEditTime: 2022-04-01 13:54:26
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newGitZnxt2.0\src\pages\reciteWords\presenter.js
 */

import React from "react";

import { useState, useEffect } from "react";
import { getParams } from "@/util/util";
import store from "@/redux/store";
let token = localStorage.getItem("sdzn-token");

const Presenter = (props) => {
  let isReview = getParams(window.location.href).review;
  const name =
    isReview === "true"
      ? decodeURIComponent(
          getParams(window.location.href).name +
            "-第" +
            `${getParams(window.location.href).extNum - 1}` +
            "次复习"
        )
      : decodeURIComponent(getParams(window.location.href).name || "今日复习");

  useEffect(() => {
    return () => {
      store.dispatch({
        type: "updateCourseInfo",
        data: {},
      });
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const goToStatistics = () => {
    let item = getParams(window.location.href);
    props.history.push(
      `/wordStatistics?id=${item.id}&lessonTeacherId=${item.lessonTeacherId}&type=${item.type}&studentId=${item.id}&name=${item.lessonTeacherName}&token=${token}&levelId=${item.levelId}&schoolId=${item.schoolId}&classId=${item.classId}&gradeId=${item.gradeId}&isPc=true`
    );
  };

  const goBack = () => {
    props.history.push(`/indexPage`);
  };

  return [
    {
      name,
    },
    { goToStatistics, goBack },
  ];
};

export default Presenter;
