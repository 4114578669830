/*
 * @Author: your name
 * @Date: 2021-08-05 18:37:00
 * @LastEditTime: 2022-04-27 18:20:58
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \znxt2.0\src\components\subjectSelect\presenter.js
 */

import React from "react";

import { getBaseSubjectListAll } from "@/api/answer";
import { useState, useEffect } from "react";
import { getParams } from "@/util/util";
import store from "@/redux/store";

const Presenter = (props) => {
  const [subjectList, setSubjectList] = useState([]);
  const [value, setvalue] = useState(undefined);

  const { isDefault } = props;

  const getSubjectList = () => {
    let obj = getParams(window.location.href);
    getBaseSubjectListAll({
      levelId: obj.levelId,
    }).then((res) => {
      // res.forEach((v) => {
      //   v.subjectPic = JSON.parse(v.subjectPic).notSelect;
      // });
      setSubjectList(res);
      if (isDefault) {
        props.changeSubject(res[0].id, res[0].name);
        setvalue(res[0].id);
        store.dispatch({
          type: "updateSubjectId",
          data: res[0].id,
        });
      }
    });
  };

  const changeSubject = (val) => {
    if (val || val === 0) {
      store.dispatch({
        type: "updateSubjectId",
        data: val,
      });
      setvalue(val);
      let name = subjectList.find((v) => v.id === val)?.name;
      props.changeSubject(val, name);
    }
  };

  useEffect(() => {
    getSubjectList();
  }, [isDefault]); // eslint-disable-line react-hooks/exhaustive-deps
  return [{ subjectList, value }, { changeSubject }];
};

export default Presenter;
