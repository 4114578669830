/*
 * @Author: your name
 * @Date: 2021-08-12 15:37:46
 * @LastEditTime: 2021-08-20 11:45:11
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/api/errorBook.js
 */

import request from "../util/request";
// 学生错题本
export function errorListAll(params) {
  return request({
    url: "/class/v2/stu/lesson/student/book/list",
    method: "get",
    params,
  });
}

// 学生错题本
export function errorBookList(params) {
  return request({
    url: "/class/v2/stu/lesson/all/task",
    method: "get",
    params,
  });
}

export function createErrors(params) {
  return request({
    url: "/class/v2/stu/lesson/errors/create",
    method: "get",
    params,
  });
}
