import "./index.less"
import { Modal, Table, Form, Input, Radio, InputNumber } from 'antd';
import { useEffect, useState } from "react";
import { getParams } from "@/util/util";
import { getAllCommentLabel, updateLabelUsePower, deleteCommentLabel, createCommentLabel, editCommentLabel } from "@/api/morality"
import messageHint from "@/pages/mistakesRearrange/compoents/messageHint"

const MoralityCreateScoreComponent = ({ formFields, ...props }) => {
    const paramsObj = getParams(window.location.href)
    const [form] = Form.useForm()

    const onFinish = (values) => {
        let params = {
            ...values,
            score: values.type === 1 ? values.score : values.score * -1,
            gradeId: paramsObj.levelId,
            schoolId: paramsObj.schoolId,
            createUserId: paramsObj.studentId,
            classString: paramsObj.classId,
            id: formFields?.[0]?.value
        }

        if (formFields) {
            editCommentLabel(params).then(res => {
                messageHint('修改打分项成功');
                onCancel();
            })

        } else {
            createCommentLabel(params).then(res => {
                messageHint('创建打分项成功');
                onCancel();
            })
        }

        console.log(values, formFields);
    }

    const onSubmit = () => {
        form.submit();
    }

    const onCancel = () => {
        form.resetFields();
        props.onCancel();
    }

    return (
        <Modal wrapClassName="moralityCreateScoreComponentModal" centered width="calc(632vh / 12)" title={null} footer={null} {...props}>
            <div className="title">{formFields ? '编辑打分项' : '新建打分项'}</div>
            <div className="content">

                <Form form={form} fields={formFields} onFinish={onFinish}>
                    <Form.Item name="name" label="名称" rules={[
                        {
                            required: true,
                            message: '请输入打分项名称',
                        }
                    ]}>
                        <Input placeholder="请输入打分项名称" />
                    </Form.Item>
                    <Form.Item name="type" label="类型" rules={[
                        {
                            required: true,
                            message: '请选择打分类型',
                        }
                    ]}>
                        <Radio.Group>
                            <Radio value={1}>表扬</Radio>
                            <Radio value={2}>待改进</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name="score" label="分值" rules={[
                        {
                            required: true,
                            message: '请设置1~10之间的分值',
                        }
                    ]}>
                        <InputNumber min={1} max={10} />
                    </Form.Item>
                </Form>
            </div>
            <div className="footer">
                <div className="cancel" onClick={onCancel}>取消</div>
                <div className="ok" onClick={onSubmit} >确认</div>
            </div>
        </Modal>
    )
}


const MoralityEditScoreComponent = ({ ...props }) => {
    const paramsObj = getParams(window.location.href)
    const [labelTableOrigin, setLabelTableOrigin] = useState([]);
    const [isCreateScoreVisible, setIsCreateScoreVisible] = useState(false);
    const [updateBack, setUpdateBack] = useState(false);
    const [editCommentInfo, setEditCommentInfo] = useState(undefined)


    useEffect(() => {
        if (props.visible) {
            let params = {
                schoolId: paramsObj.schoolId,
                gradeId: paramsObj.levelId,
                classId: paramsObj.classId
            }
            getAllCommentLabel(params).then(res => {
                setLabelTableOrigin(res)
            })
        }
    }, [props.visible, updateBack]) // eslint-disable-line react-hooks/exhaustive-deps



    const changeLabelUse = (info) => {
        let params = {
            classLabelIdList: info.id,
            classId: paramsObj.classId
        }
        updateLabelUsePower(params).then(res => {
            setUpdateBack(!updateBack)
        })
    }

    const editCurrentLabel = (info) => {
        if (info.labelRelationRpcDtos?.length !== 0) return messageHint('该标签已被其它班级使用不可编辑')
        setEditCommentInfo([
            {
                name: ['id'],
                value: info.id
            },
            {
                name: ['name'],
                value: info.name
            },
            {
                name: ['type'],
                value: info.type
            },
            {
                name: ['score'],
                value: Math.abs(info.score)
            },
            {
                name: ['classString'],
                value: info?.labelRelationRpcDtos?.map(v => v.className)?.join(',')
            }
        ]);
        setIsCreateScoreVisible(true)
    }

    const delCurrentLabel = (info) => {
        if (info.labelRelationRpcDtos?.length !== 0) return messageHint('该标签已被其它班级使用不可删除')
        deleteCommentLabel({ id: info.id }).then(res => {
            setUpdateBack(!updateBack)
        })
    }


    const columns = [
        {
            title: '打分项',
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            align: 'center',
        },
        {
            title: '类型',
            dataIndex: 'type',
            key: 'type',
            width: '10%',
            align: 'center',
            render: (text) => text === 1 ? <span style={{ color: '#28D6AC' }}>表扬</span> : <span style={{ color: '#FD4669' }}>待改进</span>,
        },
        {
            title: '分值',
            dataIndex: 'score',
            key: 'score',
            width: '10%',
            align: 'center',
        },
        {
            title: '班级',
            dataIndex: 'labelRelationRpcDtos',
            key: 'labelRelationRpcDtos',
            render: (text) => text?.map(v => v.className)?.join('、'),
        },
        {
            title: '操作',
            dataIndex: 'control',
            key: 'control',
            className: 'control',
            width: '22%',
            render: (text, record, index) =>
                <div className="row">
                    {
                        record.labelRelationRpcDtos.some(v => v.classId === Number(paramsObj.classId)) ?
                            <span onClick={() => changeLabelUse(record)}>已使用</span> :
                            <span onClick={() => changeLabelUse(record)}>使用</span>
                    }
                    <span disabled={record.labelRelationRpcDtos?.length !== 0} onClick={() => editCurrentLabel(record)}>编辑</span>
                    <span disabled={record.labelRelationRpcDtos?.length !== 0} onClick={() => delCurrentLabel(record)}>删除</span>
                </div>
        }
    ]




    return (
        <>
            <Modal wrapClassName="moralityEditScoreComponentModal" centered width="85vw" title={null} footer={null} {...props}>
                <div className="title">编辑打分项 <span className="subTitle">（已使用的打分项不可修改、不可删除，可停止使用）</span></div>
                <div className="content">
                    <Table columns={columns} dataSource={labelTableOrigin} rowKey="id" pagination={false} scroll={{ y: 'calc(600vh / 12)' }} bordered size="small" />
                </div>
                <div className="footer">
                    <div className="createLabel">
                        <div className="face" onClick={() => setIsCreateScoreVisible(true)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="calc(28vh /12)" height="calc(28vh / 12)" viewBox="0 0 28 28" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM14 7C13.2268 7 12.6 7.6268 12.6 8.4V12.6H8.4C7.6268 12.6 7 13.2268 7 14C7 14.7732 7.6268 15.4 8.4 15.4H12.6V19.6C12.6 20.3732 13.2268 21 14 21C14.7732 21 15.4 20.3732 15.4 19.6V15.4H19.6C20.3732 15.4 21 14.7732 21 14C21 13.2268 20.3732 12.6 19.6 12.6H15.4V8.4C15.4 7.6268 14.7732 7 14 7Z" fill="#0043F2" />
                            </svg>
                            新建打分项
                        </div>
                    </div>
                </div>
            </Modal>
            <MoralityCreateScoreComponent visible={isCreateScoreVisible} onCancel={() => { setUpdateBack(!updateBack); setIsCreateScoreVisible(false); setEditCommentInfo(undefined); }} formFields={editCommentInfo} />
        </>
    )
}

export default MoralityEditScoreComponent