import { useEffect, useState } from "react";
import { getParams } from "@/util/util";

const Presenter = (props) => {
   

    return [
        {},
        {}
    ]
}

export default Presenter