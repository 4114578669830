/*
 * @Author: your name
 * @Date: 2021-07-30 16:44:17
 * @LastEditTime: 2023-01-17 10:50:23
 * @LastEditors: c1107 chen1997
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/components/answerList/components/examHeader/presenter.js
 */

import { useRef, useState, useEffect } from "react";
import { collectionAdd, collectionDel } from "@/api/answer";
import { getParams } from "@/util/util";
import { message } from "antd";

import store from "@/redux/store";

const Presenter = (props) => {
  const dialogRef = useRef(null);
  const { info } = props;

  const [isCollect, setisCollect] = useState(0);

  const [isGoodAnswerModalOpen, setIsGoodAnswerModalOpen] = useState(false);

  const [pictureActive,setPictureActive] = useState({})
  const [isPictureActiveOpen,setIsPictureActiveOpen] = useState(false)

  useEffect(() => {
    setisCollect(info?.collection || 0);
  }, [info]);

  const submitQuestion = () => {
    dialogRef.current.showModal();
  };

  const collectExam = () => {
    let pageObj = getParams(window.location.href);
    if (isCollect) {
      collectionDel({
        studentId: pageObj.studentId,
        subjectId:
          pageObj?.subjectId ||
          info?.subjectId ||
          info?.baseSubjectId ||
          info?.resource?.exam?.baseSubjectId,
        type: 2,
        examId: info?.resource?.exam?.id,
        lessonStudentId: pageObj.id, //任务ID
      }).then((res) => {
        setisCollect(isCollect ? 0 : 1);
      });
    } else {
      collectionAdd({
        studentId: pageObj.studentId,
        subjectId:
          pageObj?.subjectId ||
          info?.subjectId ||
          info?.baseSubjectId ||
          info?.resource?.exam?.baseSubjectId,
        type: 2,
        examId: info?.resource?.exam?.id,
        lessonStudentId: pageObj.id, //任务ID
      }).then((res) => {
        console.log(res);
        if (res) {
          message.warning(res);
        } else {
          message.warning("收藏成功");
          setisCollect(isCollect ? 0 : 1);
        }
      });
    }
  };

  const handleGoodAnswerModal = () => {
    setIsGoodAnswerModalOpen(true);
  }

  const handleGoodAnswerCancel = () => {
    setIsGoodAnswerModalOpen(false)
  }

  const viewAnswerPicture = ({ studentName, img }) => {
    setPictureActive({
      studentName,
      img
    })
    setIsPictureActiveOpen(true)
  }

  const handleViewAnswerPicture = () => {
    setIsPictureActiveOpen(false)
  }

  return [
    {
      dialogRef,
      isCollect,
      isGoodAnswerModalOpen,
      pictureActive,
      isPictureActiveOpen
    },
    {
      submitQuestion,
      collectExam,
      handleGoodAnswerModal,
      handleGoodAnswerCancel,
      viewAnswerPicture,
      handleViewAnswerPicture
    },
  ];
};

export default Presenter;
