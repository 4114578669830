/*
 * @Author: your name
 * @Date: 2021-08-09 08:53:16
 * @LastEditTime: 2021-08-25 19:32:26
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/components/answerList/components/correctResult/presenter.js
 */

import { useState, useEffect } from "react";
import store from "@/redux/store";

const Presneter = (props) => {
  const [isShowAnswer, setisShowAnswer] = useState(true);

  useEffect(() => {
    setisShowAnswer(store.getState().isShowAnswer);
  }, [props.isShowAnswer]); // eslint-disable-line react-hooks/exhaustive-deps

  return [{ isShowAnswer }, {}];
};

export default Presneter;
