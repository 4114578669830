import { useState, useEffect } from "react"

const Presenter = (props) => {

    const { changeFlageTabs } = props

    const tabsListTwo = [
        {
            text: "综合成绩",
            type: 1,
            active: true
        },
        {
            text: "学科成绩",
            type: 2,
            active: false
        }
    ]

    const [tabsList, setTabsList] = useState(tabsListTwo)
    const [activeType, setActiveType] = useState(1);

    const handleType = (ev) => {
        if (ev.target.innerHTML === tabsListTwo[0].text) {
            tabsListTwo[0].active = true;
            tabsListTwo[1].active = false;
            setActiveType(1);

        } else {
            tabsListTwo[0].active = false;
            tabsListTwo[1].active = true;
            setActiveType(2);
        }
        setTabsList(tabsListTwo)
        changeFlageTabs(tabsListTwo[0].active)
    }

    return [
        { tabsList },
        { handleType }
    ]

}

export default Presenter;