
import "./index.less"
import presenter from "./presenter"
import { Empty } from 'antd';

const PercentageList = (props) => {
    const [states, events] = presenter(props)

    const { remarkable } = props

    return (
        <>
            <div className="PercentageListWarp">
                <div className="subjectList">
                    {
                        remarkable.examTaskSubjectList ? remarkable?.examTaskSubjectList.map((item, index) => {
                            return (
                                    <div className="subjectItem" key={index}>
                                        <div className="subjectText">{item.subjectName}</div>
                                        <div className="chart">
                                            <div className="len">
                                                <span style={{ width: `${item.score / item.fullScore * 100}%` }}></span>
                                            </div>
                                            <div className="len">
                                                <span style={{ width: `${item.gradeAvgScore / item.fullScore * 100}%` }}></span>
                                            </div>
                                            <div className="len">
                                                <span style={{ width: `${item.classAvgScore / item.fullScore * 100}%` }}></span>
                                            </div>
                                        </div>
                                        <div className="score">
                                            <span>
                                                <em>·</em>
                                                我的分数：{item.score}
                                            </span>
                                            <span>
                                                <em>·</em>
                                                年级平均：{item.gradeAvgScore}
                                            </span>
                                            <span>
                                                <em>·</em>
                                                班级平均：{item.classAvgScore}
                                            </span>
                                        </div>
                                    </div>
                            )
                        }) : <div style={{ paddingTop: "50px" }}>
                            <Empty description="暂无数据"></Empty>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default PercentageList