/*
 * @Author: your name
 * @Date: 2022-01-19 14:03:14
 * @LastEditTime: 2022-01-19 14:04:21
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /sdzn-assist-line/src/util/downloadFile.js
 */

function downloadFile(content, filename) {
  var a = document.createElement('a')
  var blob = new Blob([content])
  var url = window.URL.createObjectURL(blob)
  a.href = url
  a.download = filename
  a.click()
  window.URL.revokeObjectURL(url)
}

function ajax(url, callback, options) {
  window.URL = window.URL || window.webkitURL
  var xhr = new XMLHttpRequest()
  xhr.open('get', url, true)
  if (options.responseType) {
    xhr.responseType = options.responseType
  }
  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      callback(xhr)
    }
  }
  xhr.send()
}

export const download = (url, filename) => {
  ajax(
    url,
    function (xhr) {
      downloadFile(xhr.response, filename)
    },
    {
      responseType: 'blob'
    }
  )
}
