import React from "react";
import "./index.less";

import Presenter from "./presenter.js";
import { Select } from "antd";
const { Option } = Select;

const PracticeReport = (props) => {
  const [states, events] = Presenter();
  const { typeList } = states;

  return (
    <div className="subjectSelect">
      <Select placeholder="请选择问题分类" onChange={props.changeType}>
        {(typeList || []).map((v) => (
          <Option key={v.type} value={v.type}>
            {v.text}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default PracticeReport;
