/*
 * @Author: your name
 * @Date: 2021-08-05 18:37:00
 * @LastEditTime: 2021-08-25 19:43:45
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \znxt2.0\src\components\subjectSelect\presenter.js
 */

import { getBySubjectId } from "@/api/common";
import { useState, useEffect } from "react";
import { getParams } from "@/util/util";
import store from "@/redux/store";

const Presenter = (props) => {
  const [subjectList, setSubjectList] = useState([]);
  const [value, setvalue] = useState(null);

  const getSubjectList = () => {
    let obj = getParams(window.location.href);
    let id = store.getState().subjectId
      ? store.getState().subjectId
      : obj.subjectId;
    if (!id) {
      setSubjectList([]);
      return;
    }
    getBySubjectId({
      subjectId: id,
    }).then((res) => {
      setSubjectList(res);
    });
  };

  const onChange = (val) => {
    setvalue(val);
    props.changeExamTemplateId(val);
  };

  useEffect(() => {
    setvalue(null);
    props.changeExamTemplateId(null);
    getSubjectList();
  }, [props.subjectId]); // eslint-disable-line react-hooks/exhaustive-deps
  return [{ subjectList, value }, { onChange }];
};

export default Presenter;
