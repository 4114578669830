/*
 * @Author: your name
 * @Date: 2022-03-16 09:36:46
 * @LastEditTime: 2022-04-02 17:01:39
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newGitZnxt2.0\src\components\wordAnswerList\index.jsx
 */
import "./index.less";
import Presenter from "./presenter";
import "./index.less";
import { connect } from "react-redux";
import { Modal } from "antd";
import WordDialog from "./components/wordDialog";
import TimeAndSeq from "./components/timeAndSeq";
import Keyboard from "@/components/keyboard";

import PadHeader from "@/components/padHeader";

import WordCompletion from "@/components/wordAnswerList/components/wordCompletion";
import ReciteWords from "@/components/wordAnswerList/components/reciteWords";

const WordAnswerList = (props) => {
  const [states, events] = Presenter(props);
  const { name } = props;

  const {
    state,
    courseDetail,
    examTypeId,
    isModalVisible,
    indexSeq,
    wordTotal,
  } = states;
  const {
    propsSetIndexSeq,
    showModal,
    handleOk,
    handleCancel,
    getWordExamApi,
    wordAnswer,
    shakeAnimation,
    goToStatistics,
    changeInputValue,
    closeKeyBoard,
    delInputValue,
    inputOnBlank,
    inputOnTrue,
    goBack,
  } = events;
  const exam = courseDetail?.resource?.exam;
  const wordDetails = courseDetail?.resource?.esWordDto;

  return (
    <div>
      <PadHeader name={name}>
        {state !== 0 ? (
          <TimeAndSeq
            state={state}
            seq={indexSeq}
            examTotal={wordTotal}
          ></TimeAndSeq>
        ) : (
          ""
        )}
      </PadHeader>

      <div className="examInsideWrapper">
        <div className="itemGrid">
          <div className="examArea">
            {examTypeId === 22 ? (
              // 英译汉
              <>
                <ReciteWords
                  state={state}
                  info={exam}
                  examTotal={wordTotal}
                  examTypeId={examTypeId}
                  seq={indexSeq}
                  wordDetails={wordDetails}
                  showModal={showModal}
                  wordAnswer={wordAnswer}
                  propsSetIndexSeq={propsSetIndexSeq}
                  shakeAnimation={shakeAnimation}
                  goToStatistics={goToStatistics}
                ></ReciteWords>
              </>
            ) : examTypeId === 23 ? (
              <>
                <ReciteWords
                  state={state}
                  info={exam}
                  examTotal={wordTotal}
                  examTypeId={examTypeId}
                  seq={indexSeq}
                  wordDetails={wordDetails}
                  showModal={showModal}
                  wordAnswer={wordAnswer}
                  propsSetIndexSeq={propsSetIndexSeq}
                  shakeAnimation={shakeAnimation}
                  goToStatistics={goToStatistics}
                ></ReciteWords>
              </>
            ) : examTypeId === 24 ? (
              "英译汉-PK"
            ) : examTypeId === 25 ? (
              "汉译英-PK"
            ) : examTypeId === 26 ? (
              <>
                {/* 补全句子&&汉译英 */}
                <WordCompletion
                  info={exam}
                  seq={indexSeq}
                  examTotal={wordTotal}
                  wordDetails={wordDetails}
                  showModal={showModal}
                  propsSetIndexSeq={propsSetIndexSeq}
                  wordAnswer={wordAnswer}
                  goToStatistics={goToStatistics}
                ></WordCompletion>
              </>
            ) : (
              ""
            )}
          </div>
          {/* 单词详情弹窗 */}
          <Modal
            centered
            visible={isModalVisible}
            footer={null}
            onOk={handleOk}
            className="ModalWord"
            width={1000}
          > 
            <WordDialog
              wordAnswerData={exam}
              state={state}
              info={wordDetails}
              total={wordTotal}
              seq={indexSeq}
              propsSetIndexSeq={propsSetIndexSeq}
              cancel={handleCancel}
              getOneExam={getWordExamApi}
              goToStatistics={goToStatistics}
            ></WordDialog>
          </Modal>
        </div>
      </div>
    </div>
  );
};
connect();

export default connect(() => ({}), {})(WordAnswerList);
