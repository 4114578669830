/*
 * @Author: your name
 * @Date: 2021-09-24 13:46:09
 * @LastEditTime: 2021-12-03 13:44:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \newGitZnxt2.0\src\pages\keyboardPage\presenter.js
 */

import { useState, useEffect, useRef } from "react";
import { getLogin } from "@/api/login";

const Presenter = () => {
  const userName = useRef("");
  const passWord = useRef("");

  const onChangeUserName = (e) => {
    let inputValue = e.target.value;
    userName.current = inputValue;
  };

  const onChangePassWord = (e) => {
    let inputValue = e.target.value;
    passWord.current = inputValue;
  };

  const loginBtn = () => {
    getLogin({
      username: userName.current,
      password: passWord.current,
    }).then((res) => {
      let toKen = res.access_token;
      let userInfo = res.userDetail || {};
      localStorage.setItem("sdzn-token", toKen);
      localStorage.setItem("sdzn-userInfo", JSON.stringify(userInfo));
      window.location.hash = `#/indexPage`;
    });
  };

  useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps

  return [
    {},
    {
      loginBtn,
      onChangeUserName,
      onChangePassWord,
    },
  ];
};

export default Presenter;
