/*
 * @Author: your name
 * @Date: 2021-07-21 14:44:04
 * @LastEditTime: 2023-01-17 10:36:57
 * @LastEditors: c1107 chen1997
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/pages/studentAnswer/presenter.js
 */

import React from "react";

import { useState, useEffect } from "react";
import { getCourseDetail, addCourseAnswer } from "@/api/answer";
import { getParams } from "@/util/util";
import store from "@/redux/store";

const Presenter = () => {
  const [courseDetail, setCourseDetail] = useState(null);
  const name =
    decodeURIComponent(getParams(window.location.href).name) || "课程名称";
  const [activeInfo, setActiveInfo] = useState({
    key: 0,
    val: {},
    index: 0,
  }); // 当前选中得失哪一类：导学案：0，资源：1，试题包：2

  const [weekEnFiles, setWeekEnFiles] = useState({}); //周清听力
  // let  subjectList = [7, 8, 9, 55, 60]; //英语，日语
  useEffect(() => {
    getDetail();
    return () => {
      store.dispatch({
        type: "updateCourseInfo",
        data: {},
      });
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getDetail = () => {
    let obj = getParams(window.location.href);

    getCourseDetail({
      id: obj.id,
      lessonTeacherId:
        obj.lessonTeacherId === "undefined" ? obj.id : obj.lessonTeacherId,
      type: obj.type,
      studentId: obj.studentId,
    }).then((res) => {
      if (obj.type === "60") {
        if (res?.files) {
          let filesListData = res.files;
          let examObj = res.examInfo?.[0];
          let subjectList = [7, 8, 9, 55, 60]; //英语，日语
          if (subjectList.indexOf(examObj?.subjectId) > -1) {
            let mp3Index = -1;
            filesListData.forEach((v, i) => {
              let arr = v.file.resourceUrl.split(".");
              let sufix = arr[arr.length - 1].toLowerCase();
              if (
                sufix === "mp3" ||
                sufix === "wma" ||
                sufix === "midi" ||
                sufix === "mpeg"
              ) {
                mp3Index = i;
              }
            });
            if (mp3Index !== -1) {
              setWeekEnFiles(res.files[mp3Index]);
              if (filesListData.length === 1 && examObj.status < 2) {
                // 只有一个英语听力且没有作答
                delete res.files;
              }
            }
          }
        }
      }

      res = setData(res);
      res = setSubmitStatus(res);
      setCourseDetail(res);
      setDefault(res);
    });
  };

  const setSubmitStatus = (res) => {
    (res.examInfo || []).forEach((element) => {
      if (element.status === 4) {
        // 4 为系统提交。所以需要判断是否可以继续作答
        if (element.continueLearn === 0) {
          //  判断是否可以继续作答
          element.status = 0;
        }
      }
    });
    return res;
  };

  const setData = (res) => {
    if (!res?.info) return res

    let info = JSON.parse(res.info);
    if (info.comment.length !== 0) {
      res.models = [
        {
          c: info?.comment,
          n: "老师备注",
          t: 0,
        },
      ];
    }

    return res;
  };

  const initResourceSee = (obj) => {
    if (obj?.useTime === 0) {
      addCourseAnswer({
        lessonStudentId: obj?.relationId,
        resourceId: obj?.file?.id,
        resourceType: 1,
        usedTime: 1000, // timerNum
      });
    }
  };

  const setFile = (res, defaultVal) => {
    console.log(res);
    let obj = getParams(window.location.href);
    if (res.files && res.files.length > 0) {
      defaultVal.key = 1;
      if (res.examInfo && res.examInfo.length > 0 && obj.type === "60") {
        let examObj = res.examInfo?.[0];
        let filesList = res.files;
        let subjectList = [7, 8, 9, 55, 60]; //英语，日语
        if (examObj?.status >= 2) {
          if (obj.name) {
            let index = res.files.findIndex(
              (v) => encodeURIComponent(v.file.name) === obj.name
            );
            defaultVal.val =
              res.files[index > -1 ? index : res.files.length - 1];
            initResourceSee(
              res.files[index > -1 ? index : res.files.length - 1]
            );
          } else {
            defaultVal.val = res.files[res.files.length - 1];
            initResourceSee(res.files[res.files.length - 1]);
          }
          setActiveInfo(defaultVal);
        } else {
          if (subjectList.indexOf(examObj?.subjectId) > -1) {
            let indexNum = -1;
            filesList.forEach((v, i) => {
              let arr = v.file.resourceUrl.split(".");
              let sufix = arr[arr.length - 1].toLowerCase();
              if (
                sufix === "mp3" ||
                sufix === "wma" ||
                sufix === "midi" ||
                sufix === "mpeg"
              ) {
                //  indexNum  = i
                // console.log(indexNum);
                filesList.splice(i, 1);
              }
            });
            defaultVal.val = filesList[filesList.length - 1];
            initResourceSee(filesList[filesList.length - 1]);
          } else {
            if (obj.name) {
              let index = res.files.findIndex(
                (v) => encodeURIComponent(v.file.name) === obj.name
              );
              defaultVal.val =
                res.files[index > -1 ? index : res.files.length - 1];
              initResourceSee(
                res.files[index > -1 ? index : res.files.length - 1]
              );
            } else {
              defaultVal.val = res.files[res.files.length - 1];
              initResourceSee(res.files[res.files.length - 1]);
            }
            setActiveInfo(defaultVal);
          }
          setActiveInfo(defaultVal);
        }
      } else {
        if (obj.name) {
          let index = res.files.findIndex(
            (v) => encodeURIComponent(v.file.name) === obj.name
          );
          defaultVal.val = res.files[index > -1 ? index : res.files.length - 1];
          initResourceSee(res.files[index > -1 ? index : res.files.length - 1]);
        } else {
          defaultVal.val = res.files[res.files.length - 1];
          initResourceSee(res.files[res.files.length - 1]);
        }
        setActiveInfo(defaultVal);
      }

      return;
    }
    setExam(res, defaultVal);
  };

  const setExam = (res, defaultVal) => {
    let obj = getParams(window.location.href);
    if (res.examInfo && res.examInfo.length > 0) {
      let index = 0;
      if (obj.pid) {
        index = res.examInfo.findIndex((v) => v.id === Number(obj.pid));
      } else {
        index = res.examInfo.findIndex((v) => v.id === Number(obj.id));
      }

      defaultVal.key = 2;
      defaultVal.val = res.examInfo[index];
      setActiveInfo(defaultVal);

      if (
        new Date().getTime() <
        res.examInfo[index > -1 ? index : 0].answerPublishTime
      ) {
        store.dispatch({
          type: "updateRightAnswerShowState",
          data: false,
        });
        res.examInfo[index > -1 ? index : 0].notShowAnswerBtn = true;
      } else {
        store.dispatch({
          type: "updateRightAnswerShowState",
          data: true,
        });
        res.examInfo[index > -1 ? index : 0].notShowAnswerBtn = false;
      }

      store.dispatch({
        type: "updateCourseInfo",
        data: res.examInfo[index > -1 ? index : 0],
      });
      return;
    }
  };

  const setDefault = (res) => {
    let defaultVal = {
      key: 0,
      val: {},
      index: 0,
    };
    // 默认是展示导学案模板的
    // 优先级从高到低： 导学案模板、资源、试题

    let obj = getParams(window.location.href);
    if (obj.pid) {
      setExam(res, defaultVal);
    } else {
      if (!res.models) {
        setFile(res, defaultVal);
      }
    }
  };

  const changeComponents = (obj) => {
    setActiveInfo(obj);
  };

  return [
    {
      courseDetail,
      activeInfo,
      name,
      weekEnFiles,
    },
    {
      changeComponents,
    },
  ];
};

export default Presenter;
