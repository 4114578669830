/*
 * @Author: your name
 * @Date: 2021-07-26 17:47:01
 * @LastEditTime: 2022-09-14 14:13:33
 * @LastEditors: c1107 chen1997
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/pages/studentAnswer/components/answerCard/presenter.js
 */

import { useState, useEffect } from "react";
import { courseAnswerSituation } from "@/api/answer";
import { getParams } from "@/util/util";
import store from "@/redux/store";

const Presenter = (props) => {
  const { changeCardStatus } = props;

  const [answerData, setanswerData] = useState([]);

  let dom = document.querySelector("body");
  dom.onclick = () => {
    if (answerData.length > 0) {
      changeCardStatus && changeCardStatus();
    }
  };

  useEffect(() => {
    let obj = getParams(window.location.href);
    let list = store.getState().answerStates;
    if (list.length) {
      setanswerData(list);
      setanswerData(list);
    } else {
      courseAnswerSituation({
        id: store.getState().courseInfo.id,
      }).then((res) => {
        store.dispatch({
          type: "updateAnswerStates",
          data: res,
        });
        setanswerData(res);
      });
    }
  }, [props.answerStates]); // eslint-disable-line react-hooks/exhaustive-deps

  const goTo = (event, index) => {
    event.stopPropagation();
    store.dispatch({ type: "updateNowExamIndex", data: index - 1 });
  };

  const cardClicked = (event) => {
    event.stopPropagation();
  };

  const changeDialogStatus = () => {
    changeCardStatus();
  };

  return [
    {
      answerData,
    },
    {
      changeDialogStatus,
      goTo,
      cardClicked,
    },
  ];
};

export default Presenter;
