import ExamHeader from "@/components/answerList/components/examHeader";

import ChioceExam from "@/components/answerList/components/chioceExam";
import FillBackExam from "@/components/answerList/components/fillBackExam";
import JudgeExam from "@/components/answerList/components/judgeExam";
import CorrectResult from "@/components/answerList/components/correctResult";
import Comprehensive from "@/components/answerList/components/comprehensive";
import ExamSteam from "../answerList/components/examSteam";
import ReciteWord from "@/components/answerList/components/reciteWord"
import ReciteSentence from "@/components/answerList/components/reciteSentence"
import TopicHeader from "@/pages/mistakesRearrange/compoents/topicHeader";
import HistoricalDatum from "@/pages/mistakesBank/components/historicalDatum"
import Solution from "@/pages/mistakesBank/components/solution"
import mathJax from "@/util/globalVariable";


import "./index.less";
import { useEffect } from "react";

const newExamTypeEnum = {
  1: "单选题",
  2: "多选题",
  3: "判断题",
  4: "简答题",
  6: "填空题",
  12: "阅读理解",
  14: "完形填空",
  16: "综合题",
  17: "填空题",
  18: "7选5",
  19: "综合客观题",
  20: "不定项选择",
  21: "单选题组",
  29: "听读音写单词",
  30: "听读音写句子"
};

const ExamItem = (props) => {
  const {
    examTypeId,
    index,
    exam,
    isCorrect,
    isNotCollect,
    isNotQuesition,
    isMistakes,
    wrongRate,
    isSubmit,
    iscollect
  } = props;

  mathJax()

  console.log(iscollect);


  return (
    <div className="examInsideWrapper">
      <div className="itemGrid">
        {/* 顶部题目信息 */}

        {
          isMistakes ?
            <TopicHeader
              sort={index + 1}
              wrongRate={wrongRate}
              isNotCollect={isNotCollect}
              iscollect={iscollect}
              onChange={props.isCollectChange}
              topicType={exam?.resource?.exam?.desc || newExamTypeEnum[exam?.resource?.exam?.examTypeId]}
            />
            :
            <ExamHeader
              isNotCollect={isNotCollect}
              isNotQuesition={isNotQuesition}
              sort={index + 1}
              info={exam}
              isMistakes={isMistakes}
              wrongRate={wrongRate}
              isSubmit={isSubmit}
            />
        }
        {/* 题目信息展示区 */}
        <div className={isMistakes ? "examArea examArea-mistakes" : "examArea"}>
          {
            // 选择
            [1, 2, 20].includes(examTypeId) ?
              <ChioceExam
                info={exam}
                isSubmit={props.isSubmit}
                isMistakes={isMistakes}
              /> :

              // 判断
              [3].includes(examTypeId) ?
                <JudgeExam
                  info={exam}
                  isSubmit={props.isSubmit}
                  isMistakes={isMistakes}
                /> :

                // 填空 简单
                [4, 6, 12, 14, 16, 17].includes(examTypeId) && !exam?.resource?.exam?.children ?
                  <FillBackExam
                    info={exam}
                    isSubmit={props.isSubmit}
                    isCorrect={isCorrect}
                    isMistakes={isMistakes}
                  /> :
                  // 背单词
                  [29].includes(examTypeId) ?
                    <ReciteWord
                      info={exam}
                      isSubmit={props.isSubmit}
                      isMistakes={isMistakes}
                    /> :
                    // 听写句子
                    [30].includes(examTypeId) ?
                      <ReciteSentence
                        info={exam}
                        isSubmit={props.isSubmit}
                        isMistakes={isMistakes}
                      /> :
                      // 综合
                      [14, 16, 17, 18, 19].includes(examTypeId) && !exam?.resource?.exam?.examStem ?
                        <Comprehensive
                          isCorrect={isCorrect}
                          exam={exam}
                          isSubmit={props.isSubmit}
                          examTypeId={examTypeId}
                          index={index}
                          isMistakes={isMistakes}
                        /> :
                        [12, 14, 16, 17, 18, 19, 21].includes(examTypeId) ?
                          <ExamSteam
                            info={exam?.mark || exam?.resource?.exam?.examStem}
                            isMistakes={isMistakes}
                          /> :
                          null
          }

          {/* 批改结果的展示 */}
          {isCorrect && isSubmit &&
            <>
              {
                isMistakes ?
                  <Solution examAnswer={exam?.resource?.exam?.examAnswer} examAnalysis={exam?.resource?.exam?.examAnalysis} /> :
                  <CorrectResult isSubmit={props.isSubmit} info={exam} sort={index} isMistakes={isMistakes}></CorrectResult>
              }
              {
                isMistakes &&
                <HistoricalDatum answerHistory={exam?.answerHistory} />
              }
            </>
          }
        </div>
      </div>

      {/* 综合题作答区域 */}
      {
        [12, 14, 16, 17, 18, 19, 21].includes(examTypeId) && exam?.resource?.exam?.examStem && exam?.resource?.exam?.children?.length > 0 &&
        <Comprehensive
          exam={exam}
          isSubmit={props.isSubmit}
          isCorrect={isCorrect}
          examTypeId={examTypeId}
          index={index}
          isMistakes={isMistakes}
        />
      }
    </div>
  );
};

export default ExamItem;