/*
 * @Author: your name
 * @Date: 2021-07-22 09:02:52
 * @LastEditTime: 2021-08-19 10:57:29
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/api/common.js
 */
import request from "@/util/request";

// 七牛 /getUploadToken
export const clientUploadToken = () => {
  return request({
    url: "/knowledgecenter/resource/getUploadToken",
    method: "get",
  });
};

// 资料typeList
export const getDictItemList = (data) => {
  return request({
    url: "/usercenter/dictTypes/dictItemList",
    data,
    method: "post",
  });
};

// 资料typeList
export const getBySubjectId = (data) => {
  return request({
    url: "/knowledgecenter/subjectQuestionTypes/getBySubjectId",
    data,
    method: "post",
  });
};
