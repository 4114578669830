export const volumeList = {
  7: {
    list: [
      {
        name: "初一上学期",
        type: 1,
        id: 7,
      },
      {
        name: "初一下学期",
        type: 2,
        id: 7,
      },
    ],
  },
  8: {
    list: [
       {
        name: "初一上学期",
        type: 1,
        id: 7,
      },
      {
        name: "初一下学期",
        type: 2,
        id: 7,
      },
      {
        name: "初二上学期",
        type: 1,
        id: 8,
      },
      {
        name: "初二下学期",
        type: 2,
        id: 8,
      },
    ],
  },
  9: {
    list: [
      {
        name: "初一上学期",
        type: 1,
        id: 7,
      },
      {
        name: "初一下学期",
        type: 2,
        id: 7,
      },
      {
        name: "初二上学期",
        type: 1,
        id: 8,
      },
      {
        name: "初二下学期",
        type: 2,
        id: 8,
      },
      {
        name: "初三上学期",
        type: 1,
        id: 9,
      },
      {
        name: "初三下学期",
        type: 2,
        id: 9,
      },
    ],
  },
  10: {
    list: [
      {
        name: "高一上学期",
        type: 1,
        id: 10,
      },
      {
        name: "高一下学期",
        type: 2,
        id: 10,
      },
    ],
  },
  11: {
    list: [
      {
        name: "高一上学期",
        type: 1,
        id: 10,
      },
      {
        name: "高一下学期",
        type: 2,
        id: 10,
      },
      {
        name: "高二上学期",
        type: 1,
        id: 11,
      },
      {
        name: "高二下学期",
        type: 2,
        id: 11,
      },
    ],
  },
  12: {
    list: [
      {
        name: "高一上学期",
        type: 1,
        id: 10,
      },
      {
        name: "高一下学期",
        type: 2,
        id: 10,
      },
      {
        name: "高二上学期",
        type: 1,
        id: 11,
      },
      {
        name: "高二下学期",
        type: 2,
        id: 11,
      },
      {
        name: "高三上学期",
        type: 1,
        id: 12,
      },
      {
        name: "高三下学期",
        type: 2,
        id: 12,
      },
    ],
  },
};