import "./index.less"
import { useEffect, useRef, useState } from "react"
import { Dropdown, Menu } from "antd";

// 音频时间计算
export function millisecondToDate(time) {
    const second = Math.floor(time % 60)
    let minite = Math.floor(time / 60)
    return `${minite}:${second >= 10 ? second : `0${second}`}`
}

// 是否静音
export function SoundButton({ isMute, size, className, style, ...props }) {
    const autoSize = `calc(${size || 22}vh / 12)`
    return (
        <div className={`soundButtonComponent ${className || ''}`} style={{ width: autoSize, height: autoSize, ...style }} {...props}>
            {
                isMute ?
                    <img src={require('./icon/white/mute.png').default} alt="icon" style={{}} /> :
                    <img src={require('./icon/white/volume.png').default} alt="icon" />
            }
        </div>
    )
}

// 是否全屏
export function FullButton({ isFull, size, className, style, ...props }) {
    const autoSize = `calc(${size || 22}vh / 12)`
    return (
        <div className={`fullButtonComponent ${className || ''}`} style={{ width: autoSize, height: autoSize, ...style }} {...props}>
            {
                isFull ?
                    <img src={require('./icon/white/lower.png').default} alt="icon" /> :
                    <img src={require('./icon/white/fullScreen.png').default} alt="icon" />
            }
        </div>
    )
}

// 播放键
export function PlayingButton({ isPlay, size, ...props }) {

    const autoSize = `calc(${size || 32}vh / 12)`
    return (
        <div style={{ width: autoSize, height: autoSize }} {...props}>
            {
                isPlay ?
                    <svg xmlns="http://www.w3.org/2000/svg" width={autoSize} height={autoSize} viewBox="0 0 28 28" fill="none">
                        <circle cx="14" cy="14" r="14" fill="#FA541C" />
                        <rect x="9" y="7" width="3" height="14" rx="1.5" fill="white" />
                        <rect x="16" y="7" width="3" height="14" rx="1.5" fill="white" />
                    </svg> :
                    <svg xmlns="http://www.w3.org/2000/svg" width={autoSize} height={autoSize} viewBox="0 0 28 28" fill="none">
                        <circle cx="14" cy="14" r="14" fill="#979797" />
                        <path d="M20.6111 13.3205C21.1297 13.6225 21.1297 14.3776 20.6111 14.6796L11.2778 20.116C10.7593 20.418 10.1111 20.0405 10.1111 19.4364L10.1111 8.56368C10.1111 7.95963 10.7593 7.58211 11.2778 7.88413L20.6111 13.3205Z" fill="white" />
                    </svg>
            }
        </div>
    )
}

// 倍速键
export function CredibilityKey({ speed, white, ...props }) {
    const initSpeed = ["2.0", "1.5", "1.25", "1.0", "0.75", "0.5"]
    const [currentSpeed, setCurrentSpeed] = useState('3')
    const speedMenu = (
        <Menu defaultSelectedKeys={[currentSpeed]} selectedKeys={currentSpeed} className="speedMenuDOM" onClick={({ key }) => { setCurrentSpeed(key); props?.onChange(initSpeed[key]) }}>
            {initSpeed.map((v, i) => <Menu.Item key={(i + '')}>{v}</Menu.Item>)}
        </Menu>
    )

    const theme = white ? 'white' : 'black'

    useEffect(() => {
        speed && setCurrentSpeed(initSpeed.findIndex(v => v === speed) + '')
    }, [speed]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="credibilityKeyComponent" {...props}>
            <Dropdown placement="bottomRight" overlay={speedMenu} trigger="click">
                <img src={require(`./icon/${theme}/${initSpeed[currentSpeed]}.png`).default} alt="_" style={{ width: 'calc(27vh / 12)', display: 'block' }} onClick={e => e.preventDefault()} />
            </Dropdown>
        </div>
    )
}

// 进度条
export function ProgressBar({ progress, onChange, className, ...props }) {
    const [currentProgress, setCurrentProgress] = useState(0);
    const progressRef = useRef(null)

    useEffect(() => {
        setCurrentProgress(progress)
        if (progressRef.current) progressRef.current.style.backgroundSize = `${progress}% 100%`
    }, [progress])

    const handleProgressChange = (event) => {
        const newProgress = parseInt(event.target.value);
        setCurrentProgress(newProgress);
        onChange && onChange(newProgress);
        event.target.style.backgroundSize = `${newProgress}% 100%`
    };

    return (
        <input
            ref={progressRef}
            className={`progressBarComponent ${className || ''}`}
            type="range"
            min="0"
            max="100"
            value={currentProgress}
            onChange={handleProgressChange}
            {...props}
        />
    );
};

// 视频初始加载控件
export function Loading({ isLoading, className, size, ...props }) {
    const autoSize = `calc(${size || 32}vh / 12)`
    return (
        <div className={`loadingComponent ${className || ''}`} {...props}>
            <img src={require(`./icon/black/loading.png`).default} alt="_" style={{ width: autoSize, height: autoSize, display: 'block' }} />
            loading...
        </div >
    )
}