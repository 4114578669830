/*
 * @Author: your name
 * @Date: 2021-07-22 22:59:21
 * @LastEditTime: 2022-07-08 14:22:08
 * @LastEditors: c1107 chen1997
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/pages/studentAnswer/components/examFeature/presenter.js
 */

import { useEffect, useState, useRef } from "react";
import { setTimeFormater } from "@/util/util";
import { message } from "antd";
import { courseAnswerSituation } from "@/api/answer";

import { submitCourseAnswer } from "@/api/answer";
import { getParams } from "@/util/util";
import { androidHandle } from "@/util/android";
import store from "@/redux/store";
import pubsub from "@/util/pubSub";

const Presenter = (props) => {
  const [useTime, setuseTime] = useState(1000);
  const [countExam, setcountExam] = useState(0);
  const [answerCount, setanswerCount] = useState(0);
  const [isShow, setisShow] = useState(false);
  const [isSubmit, setisSubmit] = useState(false); // 是否提交
  const [wordIndex, setwordIndex] = useState(0); // 提示的文案在数组中的index
  const [isModalVisible, setisModalVisible] = useState(false); // 提示的文案在数组中的index
  const [pageObj, setpageObj] = useState({});
  const [submitNotAnswerNumbers, setSubmitNotAnswerNumbers] = useState(0)


  const submitWordList = [
    "你还未提交，确定返回吗？",
    `你还有${submitNotAnswerNumbers}道题未作答，确定提交吗？`,
  ];

  const offSubmitAnswerModalText = "查看答题卡" 


  const timerRef = useRef();

  const backWarn = () => {
    if (store.getState().courseInfo.status === 0) {
      setwordIndex(0);
      setisModalVisible(true);
    } else {
      if (navigator.userAgent.indexOf("sdzn-android-gr") > -1) {
        window.history.back();
      } else {
        androidHandle("nativeBackClick");
        window.history.back();
      }
    }
  };

  useEffect(() => {
    let obj = getParams(window.location.href);
    setpageObj(obj);

    pubsub.subscribe("submitBack", backWarn);
    return () => {
      store.dispatch({
        type: "updateErrorExamState",
        data: false,
      });

      store.dispatch({
        type: "updateRightAnswerShowState",
        data: true,
      });

      pubsub.unsubscribe("submitBack");
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const { countExam, answerCount, status } = store.getState().courseInfo;

    if (status === 0) {
      setisSubmit(false);
    } else {
      setisSubmit(true);
    }

    setcountExam(countExam);
    setanswerCount(answerCount);
  }, [props.courseInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  // 计时器
  useEffect(() => {
    // 先更新时间
    if (
      store.getState().examItem.useTime ||
      store.getState().examItem.useTime === 0
    ) {
      store.dispatch({
        type: "updateTime",
        data: store.getState().examItem.useTime || 0,
      });
    }

    timerRef.current && clearInterval(timerRef.current);
    // 先重置作答时间
    timerRef.current = setInterval(() => {
      store.dispatch({ type: "incrementTime", data: 1000 });
    }, 1000);

    return () => {
      timerRef.current && clearInterval(timerRef.current);
    };

    // 监听试题信息状态，更新计时器
  }, [props.examItem]); // eslint-disable-line react-hooks/exhaustive-deps

  // 更新时间
  useEffect(() => {
    setuseTime(store.getState().updateTime);
  }, [props.updateTime]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeCardStatus = ({flage,state}) => {
    // console.log(1,flage,state);
    setisShow(flage);
    // return false
  };

  const submitHandle = (NotAnswer) => {
    if (countExam > answerCount) {
      setwordIndex(1);
      setisModalVisible(true);
      setisShow(false)
      setSubmitNotAnswerNumbers(NotAnswer)
      return;
    }
    requestSubmit();
  };

  const requestSubmit = () => {
    let obj = getParams(window.location.href);
    submitCourseAnswer({
      lessonStudentId: store.getState().courseInfo.id,
      studentId: obj.studentId,
    }).then((res) => {
      setisModalVisible(false);
      message.success("提交成功");
      setTimeout(() => {
        window.location.hash = `#/practiceReport?token=${pageObj.token}&pid=${store.getState().courseInfo.id
          }&id=${pageObj.id}&studentId=$handleCancel{pageObj.studentId}&lessonTeacherId=${pageObj.lessonTeacherId
          }&type=${pageObj.type}&name=${pageObj.name}&levelId=${pageObj.levelId
          }&gradeId=${pageObj.gradeId}`;
        // androidHandle("nativeBackClick");
      }, 800);
    });
  };

  const handleCancel = (e) => {
    e.stopPropagation()
    e.target.innerHTML === offSubmitAnswerModalText && changeCardStatus({flage:true,state:'提交打开答题卡'});
    setisModalVisible(false);
  };

  const handleOk = () => {
    if (wordIndex === 1) {
      requestSubmit();
    } else {
      if (navigator.userAgent.indexOf("sdzn-android-gr") > -1) {
        window.history.back();
      } else {
        androidHandle("nativeBackClick");
        window.history.back();
      }
      setisModalVisible(false);
      setisShow(false)
    }
  };

  const onChangeAnswer = (val) => {
    store.dispatch({
      type: "updateRightAnswerShowState",
      data: val,
    });
  };

  const onChangeErrorExam = (val) => {
    store.dispatch({
      type: "updateErrorExamState",
      data: val,
    });
  };

  const onChangerewriteExam = (val) => {
    if (props.answerStates.length === 0) {
      courseAnswerSituation({
        id: store.getState().courseInfo.id,
      }).then((res) => {
        store.dispatch({
          type: "updateAnswerStates",
          data: res,
        });

        isUpdateRewrite(res, val);
      });
    } else {
      isUpdateRewrite(props.answerStates, val);
    }
  };

  const isUpdateRewrite = (data, val) => {
    let res = data.filter((v) => v.redo === 1);

    if (res.length === 0) {
      message.info("已订正完毕，可以提交了");
      return;
    }
    store.dispatch({
      type: "updateRewriteState",
      data: val,
    });
  };

  return [
    {
      useTime,
      countExam,
      answerCount,
      isShow,
      isSubmit,
      submitWordList,
      wordIndex,
      isModalVisible,
      pageObj,
      setSubmitNotAnswerNumbers
    },
    {
      submitHandle,
      changeCardStatus,
      setTimeFormater,
      handleCancel,
      handleOk,
      onChangeAnswer,
      onChangeErrorExam,
      onChangerewriteExam,
    },
  ];
};

export default Presenter;
