
const subjectIconData = [
    { name: "语文", icon: "yuwen" },
    { name: "数学", icon: "shuxue" },
    { name: "英语", icon: "yingyu" },
    { name: "地理", icon: "dili" },
    { name: "生物", icon: "shengwu" },
    { name: "化学", icon: "huaxue" },
    { name: "政治", icon: "zhengzi" },
    { name: "道德与法治", icon: "zhengzi" },
    { name: "历史", icon: "lishi" },
    { name: "物理", icon: "wuli" },
    // { name: "综合", icon: "wuli" },
]

// const params = {
//     userStudentId: studentId,
//     baseSubjectId: subjectListDataActive === -1 ? subjectListData[0].baseSubjectId : subjectListDataActive,
//     dateType: timerActive === -1 ? undefined : timerActive,
//     startTime: dateActive[0],
//     endTime: dateActive[1],
//     clear: questionBank,
//     examType: questionTypes,
//     size: size,
//     lastId: type === 'switch' ? 0 : lastId
// }

const Presenter = (props) => {
    // `/mistakesRearrange?userStudentId=${userStudentId}&baseSubjectId=${baseSubjectId}&dateType=${3}&startTime=${''}&endTime=${''}&clear=${2}&examType=${0}&size=${1}&lastId=${0}`

    return [
        { subjectIconData },
        {}
    ]
}

export default Presenter