import React from "react";
import "./index.less";
import Presenter from "./presenter.js";

import Keyboard from "@/components/keyboard";

const KeyboardPage = () => {
  const [states, events] = Presenter();

  // const {} = states;
  // const {} = events;
  // const getResourceTypeIndexs = getResourceTypeIndex;

  return (
    <div className="keyboardPage">
      {/* <textarea
      rows="3"
      cols="20"
      ref="keyboard"
      v-model="inputValue"
      @focus="onFocus"
    ></textarea> */}
      {/* <Keyboard
      :option="option"
      @keyVal="getInputValue"
      @close="option.show = false"
    ></Keyboard> */}
      <Keyboard></Keyboard>
    </div>
  );
};

export default KeyboardPage;
