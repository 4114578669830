import { useEffect, useLayoutEffect, useMemo, useState } from "react"
import { useHistory } from 'react-router-dom';
import { getParams } from "@/util/util"
import { getExamList, getWordInfo, getExamAnswer, getReviewWordAllIds } from "@/api/memorizeWords"

import store from "@/redux/store"
import { SET_STUDY_RECORDER, CLEAR_STUDY_RECORDER } from "@/redux/constant"

const randomArray = (arr) => {
    let len = arr.length;
    for (let i = 0; i < len; i++) {
        let index = Math.floor((Math.random() * arr.length));
        [arr[i], arr[index]] = [arr[index], arr[i]];
    }
    return arr
}

// 答题状态
let es = null

const Presenter = (props) => {
    const history = useHistory()
    const query = getParams(window.location.href);
    const [examIds, setExamIds] = useState([]) // 试题的Id
    const [examList, setExamList] = useState([]) //试题列表
    const [executionOrder, setExecutionOrder] = useState([]) //需要执行试题列表的顺序（examlist下标）
    const [schedule, setSchedule] = useState(0) // 作答进度，每次作答都会自增
    const currentIndex = useMemo(() => executionOrder[schedule], [executionOrder, schedule]) // 当前需要作答试题的下标
    const info = useMemo(() => examList[currentIndex], [examList, currentIndex]) // 当前试题信息
    const [examState, setExamState] = useState({
        limit: 10,
        isSubmit: false,
        optionIndex: null,
        isAnalysis: false,
        anlysisInfo: null
    })
    const [completeModalVisible, setCompleteModalVisible] = useState(false)

    useLayoutEffect(() => {
        es = {
            limit: 10, //作答时间
            isSubmit: false, //是否提交
            optionIndex: null, // 选择下标
            isAnalysis: false, // 是否展示解析
            anlysisInfo: null // 解析内容
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // 初始化数据
    useEffect(() => {
        (async () => {
            if (query?.examIds) {
                const examIdArr = JSON.parse(decodeURIComponent(query.examIds))
                const arr = examIdArr //随机打乱试题顺序(后端接口不允许打乱) randomArray(examIdArr)
                setExamIds(arr)
                setExecutionOrder(arr.map((_, i) => i))
                requestExam(arr[schedule])
            } else {
                let params = {
                    studentId: query?.studentId,
                    dayTime: query?.dayTime,
                    resultState: query?.resultState,
                    startTime: query?.startTime,
                    endTime: query?.endTime
                }

                let res = await getReviewWordAllIds(params)

                const examIdArr = res.map(v => {
                    return {
                        ids: v.examIds.filter(j => j.examTypeId === 22 || j.examTypeId === 23).map(s => s.ids[0]),
                        id: v.id,
                        wordId: v.wordId,
                        rid: v.wordLearningRecordId
                    }
                })

                const arr = examIdArr //随机打乱试题顺序(后端接口不允许打乱) randomArray(examIdArr)
                setExamIds(arr)
                setExecutionOrder(arr.map((_, i) => i))
                requestExam(arr[schedule])
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // 作答倒计时
    useEffect(() => {
        let timer;
        // 初始数据存在后执行倒计时
        if (examList[currentIndex]) {
            if (es.limit > 0 && !es.isSubmit) {
                timer = setTimeout(() => {
                    es.limit = es.limit - 1
                    setExamState({ ...es })
                }, 1000)
            } else if (es.limit === 0) {
                SubmitResponse(null)
            }
        }

        return () => clearTimeout(timer)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [examList[currentIndex], examState.isSubmit, examState.limit])

    // 获取试题 
    const requestExam = (v) => {
        if (Object.keys(v || {}).length === 0) return history.goBack();
        let randomNumber = Math.floor(Math.random() * v['ids']?.length);
        let params = { wordId: v['ids'][randomNumber] }

        return new Promise((resolve, reject) => {
            getExamList(params).then(res => {
                setExamList(list => [...list, { ...res[0], anlysisId: v['id'], wordId: v['wordId'], wordLearningRecordId: v['rid'] }])
                resolve(res)
            })
        })
    }

    // 提交作答
    const SubmitResponse = async (optionIndex, trial) => {
        if (es.isSubmit) return
        es.optionIndex = optionIndex;
        es.isSubmit = true
        let isRight = !trial || optionIndex === null //true 答错了  // false 正确

        const anlysisInfo = await getWordInfo({ esId: info.anlysisId });
        es.anlysisInfo = anlysisInfo[0];

        // 如果答错了会将题目对应的下标存到 执行列表 最后面等待重新作答
        isRight && setExecutionOrder(list => [...list, currentIndex]);

        // 需要巩固的题目 作答不要调取接口
        (schedule < examIds.length) && getExamAnswer({
            ...query,
            studentName: decodeURIComponent(query?.studentName),
            chapterName: decodeURIComponent(query?.chapterName),
            examIds: undefined,
            dayTime: undefined,
            wordId: info.wordId,
            volumeId: anlysisInfo[0].volumeId || 0,
            resultState: isRight ? 2 : 1,
            wordLearningRecordId: info?.wordLearningRecordId || 0
        });

        // 记录每题第一次作答结果
        (schedule < examIds.length) && store.dispatch({
            type: SET_STUDY_RECORDER,
            data: {
                isRight: !isRight,
            }
        });

        // 加载下一题的数据
        examIds[schedule + 1] && requestExam(examIds[schedule + 1]);

        setTimeout(() => isRight ? openWordModal() : NextResponse(), 800)

        setExamState({ ...es })
    }

    // 切入下一题
    const NextResponse = () => {

        if ((schedule + 1) < executionOrder.length) {
            es.isAnalysis = false
            es.optionIndex = null
            es.limit = 10
            es.isSubmit = false
            setExamState({ ...es })
            setSchedule(schedule + 1)
        } else {
            // 复习单词检测完成后要展示本次检测的词数
            if (query?.type === "3") {
                setCompleteModalVisible(true)
                store.dispatch({
                    type: CLEAR_STUDY_RECORDER,
                    data: null
                })
            } else {
                history.goBack();
            }
        }
    }

    const openWordModal = () => {
        es.isAnalysis = true
        setExamState({ ...es })
    }

    return [
        { examList, executionOrder, schedule, info, examState, examIds, completeModalVisible },
        { SubmitResponse, NextResponse }
    ]
}

export default Presenter