import React from "react";
import "./index.less";
import { connect } from "react-redux";

import Presenter from "./presenter.js";
import { Select } from "antd";
const { Option } = Select;

const PracticeReport = (props) => {
  const [states, events] = Presenter(props);
  const { subjectList, value } = states;
  const { onChange } = events;
  return (
    <div className="subjectSelect">
      <Select placeholder="全部题型" onChange={onChange} value={value}>
        {(subjectList || []).map((v) => (
          <Option key={v.id} value={v.id}>
            {v.name}
          </Option>
        ))}
      </Select>
    </div>
  );
};

connect();

export default connect(
  (state) => ({
    subjectId: state.subjectId,
  }),
  {}
)(PracticeReport);
