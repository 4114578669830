import "./index.less"
import presenter from "./presenter";
import { StockOutlined } from '@ant-design/icons';

const HeadOperation = (props) => {
    const [states, events] = presenter(props)
    const { tabsList } = states
    const { handleType } = events

    return (
        <div className="headOpertion">
            <div className="leftTabsBox">
                {tabsList.map((item, index) => {
                    return (<div style={{
                        color: item.active ? "#0043F2" : "#8c8c8c"
                    }}
                        key={index}
                        onClick={handleType}
                    >{item.text}</div>)
                })}
            </div>

            {/* {tabsList[0].active ? (
                <div className="rightText">
                    <div className="icon"><StockOutlined /></div>
                    <div className="text">学情趋势图</div>
                </div>
            ) : null} */}
        </div>
    )
}

export default HeadOperation