/*
 * @Author: c1107 chen1997
 * @Date: 2022-08-09 15:48:26
 * @LastEditors: c1107 chen1997
 * @LastEditTime: 2022-08-16 16:11:55
 * @FilePath: \ZNXT-Student2.0\src\pages\glossary\index.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from "react";
import { RetweetOutlined, SoundOutlined } from "@ant-design/icons";
import { Input } from "antd";
import PadHeader from "@/components/padHeader";
import "./index.less";
import Presenter from "./presenter.js";

const Japanese = () => {
  const [states, events] = Presenter();
  const { text, paraphrase, examCenterBox } = states;
  const { inputChange, handleTranslate } = events;

  return (
    <div className="glossary_cont">
      <PadHeader name={"日语词汇"}></PadHeader>
      {/* 电子课本内容 */}
      <div className="book_wai_cont">
        <div className="book_cont">
          <div className="topTab">
          </div>
          <div className="inputBox">
            <Input
              size="large"
              className="inputTextBox"
              placeholder="请输入要查找的单词"
              value={text}
              onChange={inputChange}
              allowClear
              adjust-position={false}
              always-embed
              hold-keyboard
            />
          </div>
          <div className="btnBox">
            {text ? (
              <div className="translateBtn2" onClick={handleTranslate}>
                查词
              </div>
            ) : (
              <div className="translateBtn1">查词</div>
            )}
          </div>

          {examCenterBox ? <>
            {
              paraphrase && paraphrase.length !== 0 ?
                <div className="examCenterBox">
                  <div className="dialogPage">
                    <div className="wordStem">{text}</div>
                    <div className="contentBox">

                      {
                        paraphrase.map((item, index) => {

                          return (
                            <div className="typesBox">
                              <p className="titleBox">
                                <span className="line"></span>
                                释义
                              </p>
                              <div>
                                <p>
                                  {item}
                                </p>
                              </div>
                            </div>)
                        })
                      }
                    </div>
                  </div>
                </div>
                :
                <div className="dialogPage">
                  <div className="wordStem">{text}</div>
                  <div className="nullText">暂无内容~</div>
                </div>
            }
          </> : null}
        </div>
      </div>
    </div>
  );
};

export default Japanese;
 