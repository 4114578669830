import { useEffect, useState } from "react"
import { getWrongQuestionHome, getWrongQuestionTrend, getScore, getWrongRankList } from "@/api/mistakesBooks"
import { getParams } from "@/util/util"


const hotList = [
    "天助自助者，你要你就能。",
    "拼一载春秋，搏一生无悔。",
    "只要路是对的，就不怕路远。",
    "非学无以广才，非志无以成学。",
    "努力就能成功，坚持确保胜利。",
    "不为失败找借口，要为成功找方法。",
    "书山有路勤为径，学海无涯苦作舟。",
    "今天多一份拼搏、明天多几份欢笑。",
    "宝剑锋从磨砺出，梅花香自苦寒来。",
    "不为失败找理由，要为成功找方法。",
    "成功者绝不放弃，放弃者绝不会成功。",
    "站在新起点，迎接新挑战，创造新成绩。",
    "贵在坚持，难在坚持，成在坚持。",
    "既然选择了远方，便只顾风雨兼程！",
    "所谓天才，就是努力的力量。",
    "光阴易逝，岂容我待。",
    "我们坚信，只有拼搏才会胜利。",
    "信心来自于实力，实力来自于勤奋。",
    "知识改变命运，勤奋创造未来。",
    "把握现在、就是创造未来。",
    "只要路是对的，就不怕路远。",
    "细节决定命运，态度决定高度。",
    "只有千锤百炼，才能成为好钢。",
    "希望，只有和勤奋作伴，才能如虎添翼。",
    "奋斗没有终点，任何时候都是一个起点。",
    "勤奋努力加自信，辉煌成就属于你。",
    "拼搏每一天，充实每一天，快乐每一天。",
    "海阔凭鱼游鲤跃龙门；天高任鸟飞鹏程万里。",
    "全力冲刺，笑到最后。",
    "你的明天由你来决定。",
    "让思考在成功的道路上留下痕迹。",
    "耐心虽苦，成果却甜。",
    "黑发不知勤学早，白首方悔读书迟。",
    "行万里路，读万卷书。",
    "读书要三到：心到、眼到、口到。"
]

const Presenter = (props) => {
    const obj = getParams(window.location.href);

    // 荣誉榜数据
    const [honorList, setHonorList] = useState([])
    // 清题榜数据
    const [clearList, setClearList] = useState([])
    // 本周错题本数据
    const [weeklyWrong, setWeeklyWrong] = useState([])
    // 树等级
    const [userLevel, setUserLevel] = useState(0)
    // 树周围能量
    const [levelScore, setLevelScore] = useState([])
    // 错题趋势图
    const [wrongTrend, setWrongTrend] = useState([])

    const [hot, setHot] = useState("")

    let time = null


    useEffect(() => {

        ; (async () => {

            const { honorList, clearList, weekWrong, levelScoreList, userLevel } = await getWrongQuestionHome({
                studentId: obj.studentId,
                classId: obj.classId,
                schoolId: obj.schoolId
            })

            const wrongTrendList = await getWrongQuestionTrend({
                studentId: obj.studentId,
                gradeId: obj.gradeId,
                pageSize: 100,
                lastId: 0
            })

            setHonorList(x => honorList || [])   // 荣誉榜
            setClearList(x => clearList || [])   //清题榜
            setWeeklyWrong(x => subjectSort(weekWrong, "subjectName") || [])  //科目错题
            setUserLevel(x => userLevel)  // 等级
            setLevelScore(x => levelScoreList)  //能量
            setWrongTrend(x => wrongTrendList ? wrongTrendList.reverse() : [])  //错题趋势图
        })();

        setHot(hotList[Math.floor(Math.random() * (hotList.length - 1))])

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const subjectSort = (arr = [], active) => {
        let first = ["语文", "数学", "英语"]
        let arrs = []
        first.forEach((item, index) => {
            let obj = arr.find(x => x[active] === item);
            if (obj) {
                arrs.push(...arr.splice(arr.indexOf(obj), 1))
            }
        })
        return [...arrs, ...arr]
    }


    // 小树搜集能量
    const suckUpChange = async (id) => {

        // 调取收集能量接口
        await getScore({
            userLevelLogIds: id,
            studentId: obj.studentId
        })

        // 收集能量后重新刷新 荣誉榜 清题榜 成长树 数据
        const { honorList, clearList, levelScoreList, userLevel } = await getWrongQuestionHome({
            studentId: obj.studentId,
            classId: obj.classId,
            schoolId: obj.schoolId
        })

        setHonorList(x => honorList || [])   // 荣誉榜
        setClearList(x => clearList || [])   //清题榜

        clearTimeout(time)
        time = setTimeout(() => {
            setUserLevel(x => userLevel)  // 等级
            setLevelScore(x => levelScoreList || [])  //能量
        }, 800);
    }

    // 榜单 班级年级切换
    const changeRankingList = async ({ type, dateType }) => {
        const res = await getWrongRankList(dateType === 1 ? {
            type,
            dateType,
            classId: obj.classId,
            studentId: obj.studentId,
            schoolId: obj.schoolId
        } : {
            type,
            dateType,
            studentId: obj.studentId,
            gradeId: obj.gradeId,
            schoolId: obj.schoolId
        })

        if (type === 1) {
            setClearList(res)
        } else if (type === 2) {
            setHonorList(res)
        }
    }


    return [
        { honorList, clearList, weeklyWrong, userLevel, levelScore, wrongTrend, obj, hot },
        { changeRankingList, suckUpChange }
    ]
}

export default Presenter