export const topRightText = [
    "Time is money, but money is not everything.",
    "The sunshine, having no alternative, on the nothing new.",
    "Winners do what losers don't want to do.",
    "Whatever is worth doing is worth doing well.",
    "Live well, love lots, and laugh often.",
    "Brief is life, but love is long.",
    "The moonlight stands for my heart!",
    "Truth needs no colour; beauty,no pencil.",
    "A man cannot spin and reap at the same time.",
    "Character is what you are in the dark.",
    "Time flies never to be recalled",
    "A word spoken is past recalling",
    "Winter is coming, spring is sure to follow. ",
    "An hour in the morning is worth two in the evening.",
    "Sow nothing, reap nothing.",
    "Action speaks louder than words.",
    "We learn from our mistakes.",
    "Self-improvement is a lifelong process.",
    "Time will tell.",
    "Keep calm and carry on.",
    "I'm proud of myself.",
    "The foundation to any healthy relationship is honesty.",
    "No matter where you go, I'm gonna follow you.",
    "Open your mind to the possibilities.",
    "You make your own opportunities.",
    "Never let your head hang down.",
    "Success is about preparation and planning.",
    "In me the tiger sniffs the rose.",
    "Fading is true while flowering is past."
]

export function randomArr(arr) {
    const index = Math.floor(Math.random() * arr.length);
    return arr[index]
}