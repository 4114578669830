/*
 * @Author: your name
 * @Date: 2021-09-24 13:46:09
 * @LastEditTime: 2021-12-08 19:17:04
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \newGitZnxt2.0\src\pages\keyboardPage\presenter.js
 */

import { useState, useEffect, useRef } from 'react';
import { getGroupsRank, groupScoreRankList, getStudentGroup } from '@/api/groupScoring';
import { getParams, getNowWeekTime, getNowMonthTime, getSeptember1st } from '@/util/util';

const Presenter = () => {
  const [scoreAndSort, setScoreAndSort] = useState({
    place: 0,
    score: 0,
    sort: 0,
  });
  const [groupsList, setGroupsList] = useState([]);
  const [tabActive, setTab] = useState(2);
  const [stuGroup, setStuGroup] = useState({});
  const [initY, setInitY] = useState(0);
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState(false);

  let obj = getParams(window.location.href);

  // tab
  const tabClick = (num) => {
    setTab(num);
  };

  // 我的得分。排名
  const getDetail = () => {
    let params = {
      classId: obj.classId,
      subjectId: obj.subjectId,
      studentId: obj.studentId,
    };

    getGroupsRank(params).then((res) => {
      setScoreAndSort(res || {});
    });
  };
  // 小组排行榜
  const getGroupScoreRankList = () => {
    // 0全部 ，1 本周，2 本月
    let params = {
      classId: obj.classId,
      subjectId: obj.subjectId,
      groupType: tabActive, // 1行政，2学科
    };
    let time = [];
    if (obj.timeType === '1') {
      time = getNowWeekTime();
    } else if (obj.timeType === '2') {
      time = getNowMonthTime();
    } else {
      time = getSeptember1st();
    }
    params.startTime = time?.[0];
    params.endTime = time?.[1];

    groupScoreRankList(params).then((res) => {
      setGroupsList(res?.find((item) => item.typeId === 1)?.scoreRank || []);
      setLoading(false);
      setStart(false);
    });
  };
  // 学生所在 行政/学科组
  const getStuGroup = () => {
    let params = {
      subjectId: tabActive === 1 ? 0 : obj.subjectId,
      studentId: obj.studentId,
    };
    getStudentGroup(params).then((res) => {
      let index = groupsList.findIndex((item) => item.groupId === res.groupId) || 0;
      let stuData = Object.assign({}, groupsList[index], { index });
      setStuGroup(stuData);
    });
  };

  let scrollTimer = null;
  const initScroll = () => {
    let dom1 = document.querySelector('#groupsScoreRank');
    if (dom1) {
      let num = dom1.scrollTop;
      if (num === 0) {
        scrollTimer && clearTimeout(scrollTimer);
        scrollTimer = null;
        setLoading(true);
        scrollTimer = setTimeout(() => {
          setStart(true);
          setInitY(0);
        }, 1000);
      }
    }
  };

  const touchEndEvent = () => {
    setInitY(0);
  };

  const touchmoveEvent = (e) => {
    let diff = e.targetTouches?.[0]?.pageY;
    if (diff) {
      if (initY === 0) {
        setInitY(e.targetTouches[0].pageY);
      }
      if (diff - initY > 5) {
        initScroll();
      }
    }
  };

  useEffect(() => {
    if (start) {
      getDetail();
      getGroupScoreRankList();
    }
  }, [start]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getGroupScoreRankList();
  }, [tabActive]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (groupsList.length > 0) {
      getStuGroup();
    } else {
      setStuGroup({});
    }
  }, [groupsList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getDetail();
    getGroupScoreRankList();
    document.addEventListener('touchstart', touchEndEvent, false);
    document.addEventListener('touchmove', touchmoveEvent, false);
    document.addEventListener('touchend', touchEndEvent, false);
    document.addEventListener('touchcancel', touchEndEvent, false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return [{ groupsList, scoreAndSort, tabActive, stuGroup, loading }, { tabClick }];
};

export default Presenter;
