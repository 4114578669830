/*
 * @Author: your name
 * @Date: 2021-12-03 09:53:09
 * @LastEditTime: 2022-07-14 09:15:18
 * @LastEditors: c1107 chen1997
 * @Description: 打开koroFileHeader查看配置 进行设置: https:github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newGitZnxt2.0\src\pages\indexPage\presenter.js
 */

import { useState, useEffect, useRef } from "react";
import { message } from "antd";

import { getTaskList } from "@/api/login";
import { getParams } from "@/util/util";

const Presenter = (props) => {
  const [indexData, setindexData] = useState([]);
  const [lastMaxId, setLastMaxId] = useState(0);
  const [showMore, setShowMore] = useState(true);
  const [status, setStatus] = useState(null);

  let obj = localStorage.getItem("sdzn-userInfo");
  let token = localStorage.getItem("sdzn-token");
  obj = JSON.parse(obj || "{}");
  const endTime =
    new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000;

  const getActiveType = (type) => {
    console.log(type);
    setStatus(type.type);
    setindexData([]);
  };

  const allTaskHandle = () => {
    props.history.push(
      `/myCollect?studentId=${obj.id}&token=${token}&levelId=${obj.levelId}&schoolId=${obj.schoolId}&classId=${obj.classId}&gradeId=${obj.gradeId}`
    );
  };
  const lookHandle = (item) => {
    let obj = JSON.parse(localStorage.getItem("sdzn-userInfo"));
    const markIndex = item.path.indexOf("?");
    props.history.push(
      `${item.path}${markIndex === -1 ? "?" : "&"}levelId=${
        obj.levelId
      }&studentId=${obj.id}`
    );
  };
  const bindCard = (item) => {
    if (item.resourceType === 5) {
      if (item.status) {
        // 已作答去统计页面
        props.history.push(
          `/wordStatistics?id=${item.id}&lessonTeacherId=${item.lessonTeacherId}&type=${item.type}&studentId=${obj.id}&name=${item.lessonTeacherName}&token=${token}&levelId=${obj.levelId}&schoolId=${obj.schoolId}&classId=${obj.classId}&gradeId=${obj.gradeId}&isPc=true`
        );
      } else {
        // 记单词
        props.history.push(
          `/wordsRecite?id=${item.id}&lessonTeacherId=${
            item.lessonTeacherId
          }&type=${item.type}&studentId=${obj.id}&name=${
            item.lessonTeacherName
          }&token=${token}&levelId=${obj.levelId}&schoolId=${
            obj.schoolId
          }&classId=${obj.classId}&gradeId=${obj.gradeId}&isPc=true&status=${
            item.status
          }&review=${item?.ext > 1 ? "true" : "false"}&extNum=${
            item?.ext
          }&countExam=${item?.countExam}`
        );
      }
    } else {
      props.history.push(
        `/answer?id=${item.id}&lessonTeacherId=${item.lessonTeacherId}&type=${item.type}&studentId=${obj.id}&name=${item.lessonTeacherName}&token=${token}&levelId=${obj.levelId}&schoolId=${obj.schoolId}&classId=${obj.classId}&gradeId=${obj.gradeId}`
      );
    }
  };

  const getSubjectName = (name) => {
    let s = name.substr(0, 1);
    return s;
  };

  const moreLearn = (name) => {
    let length = indexData.length;
    let lastId = indexData[length - 1]?.id;
    setLastMaxId(lastId);
  };

  useEffect(() => {
    let pageObj = getParams(window.location.href) || {};
    let num = localStorage.getItem("sdzn-message") || 0;

    if (pageObj?.isH5 && !num) {
      message.info("请手机横屏使用！！");
      localStorage.setItem("sdzn-message", 1);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let pageObj = getParams(window.location.href) || {};

    if (pageObj?.id) {
      localStorage.setItem("sdzn-userInfo", JSON.stringify(pageObj));
      localStorage.setItem("goBackPktUrl", pageObj?.goBackUrl || "");
    }
    let obj = localStorage.getItem("sdzn-userInfo") || JSON.stringify(pageObj);
    if (obj) {
      obj = JSON.parse(obj);
      getTaskList({
        studentId: obj.id || pageObj?.id,
        size: 10,
        lastMaxId: lastMaxId,
        endTime: endTime,
        status: status,
      }).then((res) => {
        if (res.length < 10) {
          setShowMore(false);
        }
        let arr = indexData.concat(res);
        setindexData(arr);
      });
    } else {
    }
  }, [lastMaxId, status]); // eslint-disable-line react-hooks/exhaustive-deps

  return [
    { indexData, showMore },
    {
      allTaskHandle,
      lookHandle,
      bindCard,
      getSubjectName,
      moreLearn,
      getActiveType,
    },
  ];
};

export default Presenter;
