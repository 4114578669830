

import { useState, useEffect } from "react";
import { wordJapanese } from "@/api/word";

const Presenter = () => {
  const [text, setText] = useState("");
  const [paraphrase,setParaphrase] = useState("")
  const [examCenterBox,setExamCenterBox] = useState(false)


  const inputChange = (val) => {
    setText(val.target.value.trim());
 
    if( val.target.value.trim().length === 0) {
      setExamCenterBox(false)
    }

  };

  const handleTranslate = () => {
    wordJapanese({ word: text }).then((res) => {
      setParaphrase(res)
      setExamCenterBox(true)
    });
  };


  return [
    { text,paraphrase ,examCenterBox},
    { inputChange, handleTranslate },
  ];
};

export default Presenter;
