import { Carousel, Empty } from "antd";
import ExamItem from "@/components/examItem";
import listPng from "@/assets/newSence/list.png";

const CarouselGenerator = (props) => {
  return (
    <div className="CarouselGenerator">
      {props.examInfo ? (
        <Carousel beforeChange={props.onChange}>
          {(new Array(1).fill(1) || []).map((el, index) => (
            <div className="itemGridWrapper" key={index}>
              <ExamItem
                isNotQuesition={true}
                index={props.nowIndex}
                examTypeId={props.examInfo?.exam?.examTypeId}
                exam={props.examInfo}
                isSubmit={true}
                isCorrect={true}
                isNotCollect={true}
              ></ExamItem>
            </div>
          ))}
        </Carousel>
      ) : (
        <Empty
          image={listPng}
          description={""}
          imageStyle={{
            height: 300,
          }}
        />
      )}

      {/* 切换按钮 */}
      <div className="switchExam">
        <div className="lastExam" onClick={() => props.switchExam(0)}>
          <i className="ri-arrow-left-line"></i>
        </div>
        <div className="nextExam" onClick={() => props.switchExam(1)}>
          <i className="ri-arrow-right-line"></i>
        </div>
      </div>
    </div>
  );
};

export default CarouselGenerator;
