/*
 * @Author: your name
 * @Date: 2021-07-30 10:02:57
 * @LastEditTime: 2022-04-27 18:21:00
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \znxt2.0\src\pages\studentAnswer\components\questionnaire\presenter.js
 */

import { useState, useEffect } from "react";
import { getParams } from "@/util/util";
import store from "@/redux/store";
import { createQuestionSheet } from "@/api/answer";
import { typeList } from "@/mockData/quesitonList";

const Presenter = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [active, setactive] = useState(-1);
  const [imgList, setimgList] = useState([]);
  const [TextAreaValue, setTextAreaValue] = useState("");

  const { info } = props;

  useEffect(() => {
    setTextAreaValue("");
    setactive(
      typeList.findIndex((v) => v.type === info?.questionSheet?.askType)
    );
    if (info?.questionSheet?.ask) {
      info?.questionSheet?.ask.forEach((element) => {
        if (element.type === 1 && element.content) {
          setTextAreaValue(element.content);
        } else if (element.type === 2) {
          if (typeof element.content === "string") {
            let arr = JSON.parse(element.content || "[]");
            if (typeof arr === "object" && arr.length) setimgList(arr);
          } else {
            if (typeof element.content === "object" && element.content.length)
              setimgList(element.content);
          }
        }
      });
    } else {
      setTextAreaValue("");
      setimgList([]);
    }
  }, [info]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const updateAnswer = (arr) => {
    let imgs = arr.map((v) => v.url);
    setimgList(imgs);
  };

  const handleOk = () => {
    let query = getParams(window.location.href);

    let content = [];
    let obj = {};
    obj.lessonStudentId = store.getState().courseInfo.id || query.id;
    obj.type = typeList[active].type;
    obj.questionSeq = store.getState().nowExamIndex + 1;
    // 文本提问
    if (TextAreaValue) {
      content.push({
        type: 1,
        content: TextAreaValue,
      });
    }
    // 图片提问

    let arr = [...imgList];
    if (arr.length) {
      arr = arr.filter((v) => typeof v === "string");
      content.push({
        type: 2,
        content: JSON.stringify(arr),
      });
    }

    if (content.length) {
      // obj.contents = JSON.stringify(content);
      obj.contents = content;
    }
    createQuestionSheet(obj).then((res) => {
      setIsModalVisible(false);
    });
  };

  const handleCancel = () => {
    setimgList([]);
    setTextAreaValue("");
    setIsModalVisible(false);
  };
  const changeTextArea = (val) => {
    setTextAreaValue(val.target.value);
  };
  const handleType = (index) => {
    setactive(index);
  };
  return [
    {
      isModalVisible,
      typeList,
      TextAreaValue,
      active,
      imgList,
    },
    {
      showModal,
      handleOk,
      handleCancel,
      changeTextArea,
      handleType,
      updateAnswer,
    },
  ];
};

export default Presenter;
