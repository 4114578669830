/*
 * @Author: your name
 * @Date: 2021-07-21 14:44:04
 * @LastEditTime: 2022-09-16 09:13:34
 * @LastEditors: c1107 chen1997
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/pages/studentAnswer/presenter.js
 */

import React, { useRef } from "react";

import { timeMethod } from "@/util/util";
import { getExamStatistics } from "@/api/answer";
import { getParams } from "@/util/util";
import { useState, useEffect } from "react";
import pubsub from "@/util/pubSub";

const Presenter = () => {
  const [examStatisticsData, setExamStatisticsData] = useState(null);
  const [answerList, setAnswerList] = useState([]);
  const [progessWidth, setprogessWidth] = useState(100);

  const [examInfoList, setExamInfoList] = useState([]);
  const [showBtn, setShowBtn] = useState(false);
  const [seq, setSeq] = useState(0);

  const progressRef = useRef(null);

  useEffect(() => {
    setprogessWidth(progressRef?.current?.clientWidth || 100);
  }, [progressRef]);

  const getExamStatisticsData = () => {
    let obj = getParams(window.location.href);
    getExamStatistics({
      id: obj.pid || obj.id,
      studentId: obj.studentId,
    }).then((res) => {
      setExamStatisticsData(res);
      setAnswerList(res.answer);
      res.examInfo.forEach((el) => {
        // el.scoreTotal = Math.round(el?.scoreTotal * 100) / 100;
        // el.avgScoreTotal = Math.round(el?.avgScoreTotal * 100) / 100;
        el.scoreFull = Math.round(el?.scoreFull * 100) / 100;
        // el.scoreObjective = Math.round(el?.scoreObjective * 100) / 100;
        // el.scoreSubjective = Math.round(el?.scoreSubjective * 100) / 100;
        el.accuracy = (el?.rightCount / el?.countExam) * 100;
      });

      let arr = res.answer.filter((v) => v.correctState === 0) || [];
      if (arr.length) {
        console.log(arr);
        // let seqList = arr.map((item) => (item = item.seq));
        // console.log(seqList.toString());
        // setSeq(seqList.toString());
        setSeq(res.answer.length);

        setShowBtn(true);
      }

      setExamInfoList(res.examInfo);
    });
  };

  const handleCheckAnswer = () => {
    let pageObj = getParams(window.location.href);
    window.location.hash = `#/autonomyCheck?id=${pageObj.id}&studentId=${pageObj.studentId}&name=${pageObj.name}&levelId=${pageObj.levelId}&seq=${answerList.length}&isAutonomyPractice=true`;
  };

  useEffect(() => {
    getExamStatisticsData();
  }, []);
  return [
    {
      answerList,
      examInfoList,
      progressRef,
      progessWidth,
      showBtn,
    },
    { timeMethod, handleCheckAnswer },
  ];
};

export default Presenter;
