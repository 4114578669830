import { useState, useEffect, useRef } from "react"
import * as echarts from "echarts"

const Presenter = (props) => {

    const { exhibitionArea, flageTabs } = props

    useEffect(() => {
        const initChart = () => {
            let element = document.getElementById("echartContent");
            let chart = echarts.init(element);
            chart.clear()
    
            let option = {
                grid:{
                    x:80,
                    y:80
                },
                title: {
                    text: '趋势图',
                    left: 0
                  },
                legend: {
                    data: ["学生得分率", "班级平均分得分率", "年级平均分得分率"],
                    textStyle:{
                        fontSize:18
                    },
                    right:40
                },
                tooltip: {
                    trigger: 'axis',
                    valueFormatter: (value) => value + "%",
                    textStyle:{
                        fontSize:18
                    }
                },
                xAxis: {
                    type:"category",
                    data: exhibitionArea.map(item => item.date)
                },
                yAxis: {
                    nameTextStyle:{
                        fontSize:18
                    },
                    type: 'value',
                    axisLabel: {
                        formatter: '{value} %'
                    },
                    min: "0",
                    max: "100"
                },
                series: [
                    {
                        data: exhibitionArea.map(item => item.rate.toFixed(2)),
                        type: 'line',
                        name: "学生得分率",
                        label:{
                            normal:{
                                textStyle:{
                                    fontSize:18
                                }
                            }
                        }
                    },
                    {
                        data: exhibitionArea.map(item => item.classRate.toFixed(2)),
                        type: 'line',
                        name: "班级平均分得分率"
                    },
                    {
                        data: exhibitionArea.map(item => item.gradeRate.toFixed(2)),
                        type: 'line',
                        name: "年级平均分得分率"
                    }
                ]
            };
    
            option && chart.setOption(option)
        }

        initChart()
       
    }, [exhibitionArea, flageTabs])
    return [
        {}, {}
    ]
}

export default Presenter