import { useState } from "react"
import { getParams } from "@/util/util"

const studentId = getParams(window.location.href).studentId;
const Presenter = (props) => {
    const { onChange } = props

    const [cutActive, setCutActive] = useState(0)



    const changeCutActive = (value, obj) => {
        setCutActive(value)
        onChange(obj)
    }

    return [
        { cutActive, studentId },
        { changeCutActive }
    ]
}

export default Presenter