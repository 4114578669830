import "./index.less"
import presenter from "./presenter"

const ExhibitionArea = (props) => {

    const [states, events] = presenter(props)
    const { exhibitionArea, flageTabs } = props


    return (
        <>
            <div className="ExhibitionAreaWrap">
                <div className="echartMain" id="echartContent">

                </div>
            </div>
        </>
    )
}

export default ExhibitionArea