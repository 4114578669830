/*
 * @Author: your name
 * @Date: 2021-07-22 09:02:10
 * @LastEditTime: 2022-07-08 17:22:51
 * @LastEditors: c1107 chen1997
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/util/util.js
 */
import { clientUploadToken } from "@/api/common";
import * as qiniu from "qiniu-js";
//表单序列化
export const serialize = (data) => {
  let list = [];
  Object.keys(data).forEach((ele) => {
    list.push(`${ele}=${data[ele]}`);
  });
  return list.join("&");
};
export const getObjType = (obj) => {
  var toString = Object.prototype.toString;
  var map = {
    "[object Boolean]": "boolean",
    "[object Number]": "number",
    "[object String]": "string",
    "[object Function]": "function",
    "[object Array]": "array",
    "[object Date]": "date",
    "[object RegExp]": "regExp",
    "[object Undefined]": "undefined",
    "[object Null]": "null",
    "[object Object]": "object",
  };
  if (obj instanceof Element) {
    return "element";
  }
  return map[toString.call(obj)];
};
export const getViewDom = () => {
  return window.document
    .getElementById("avue-view")
    .getElementsByClassName("el-scrollbar__wrap")[0];
};

/**
 *
 */

export const accurateNumber = (num) => {
  return Math.floor(num * 100) / 100;
};

/**
 * 对象深拷贝
 */
export const deepClone = (data) => {
  var type = getObjType(data);
  var obj;
  if (type === "array") {
    obj = [];
  } else if (type === "object") {
    obj = {};
  } else {
    //不再具有下一层次
    return data;
  }
  if (type === "array") {
    for (var i = 0, len = data.length; i < len; i++) {
      obj.push(deepClone(data[i]));
    }
  } else if (type === "object") {
    for (var key in data) {
      obj[key] = deepClone(data[key]);
    }
  }
  return obj;
};
/**
 * 设置灰度模式
 */
export const toggleGrayMode = (status) => {
  if (status) {
    document.body.className = document.body.className + " grayMode";
  } else {
    document.body.className = document.body.className.replace(" grayMode", "");
  }
};
/**
 * 设置主题
 */
export const setTheme = (name) => {
  document.body.className = name;
};

/**
 * 加密处理
 */
export const encryption = (params) => {
  let { data, type, param, key } = params;
  let result = JSON.parse(JSON.stringify(data));
  if (type === "Base64") {
    param.forEach((ele) => {
      result[ele] = btoa(result[ele]);
    });
  } else if (type === "Aes") {
    param.forEach((ele) => {
      result[ele] = window.CryptoJS.AES.encrypt(result[ele], key).toString();
    });
  }
  return result;
};

/**
 * 浏览器判断是否全屏
 */
export const fullscreenToggel = () => {
  if (fullscreenEnable()) {
    exitFullScreen();
  } else {
    reqFullScreen();
  }
};
/**
 * esc监听全屏
 */
export const listenfullscreen = (callback) => {
  function listen() {
    callback();
  }
  document.addEventListener("fullscreenchange", function () {
    listen();
  });
  document.addEventListener("mozfullscreenchange", function () {
    listen();
  });
  document.addEventListener("webkitfullscreenchange", function () {
    listen();
  });
  document.addEventListener("msfullscreenchange", function () {
    listen();
  });
};
/**
 * 浏览器判断是否全屏
 */
export const fullscreenEnable = () => {
  var isFullscreen =
    document.isFullScreen ||
    document.mozIsFullScreen ||
    document.webkitIsFullScreen;
  return isFullscreen;
};

/**
 * 浏览器全屏
 */
export const reqFullScreen = () => {
  if (document.documentElement.requestFullScreen) {
    document.documentElement.requestFullScreen();
  } else if (document.documentElement.webkitRequestFullScreen) {
    document.documentElement.webkitRequestFullScreen();
  } else if (document.documentElement.mozRequestFullScreen) {
    document.documentElement.mozRequestFullScreen();
  }
};
/**
 * 浏览器退出全屏
 */
export const exitFullScreen = () => {
  if (document.documentElement.requestFullScreen) {
    document.exitFullScreen();
  } else if (document.documentElement.webkitRequestFullScreen) {
    document.webkitCancelFullScreen();
  } else if (document.documentElement.mozRequestFullScreen) {
    document.mozCancelFullScreen();
  }
};
/**
 * 递归寻找子类的父类
 */

export const findParent = (menu, id) => {
  for (let i = 0; i < menu.length; i++) {
    if (menu[i].children.length !== 0) {
      for (let j = 0; j < menu[i].children.length; j++) {
        if (menu[i].children[j].id === id) {
          return menu[i];
        } else {
          if (menu[i].children[j].children.length !== 0) {
            return findParent(menu[i].children[j].children, id);
          }
        }
      }
    }
  }
};
/**
 * 判断2个对象属性和值是否相等
 */

/**
 * 动态插入css
 */

export const loadStyle = (url) => {
  const link = document.createElement("link");
  link.type = "text/css";
  link.rel = "stylesheet";
  link.href = url;
  const head = document.getElementsByTagName("head")[0];
  head.appendChild(link);
};
/**
 * 判断路由是否相等
 */
export const diff = (obj1, obj2) => {
  delete obj1.close;
  var o1 = obj1 instanceof Object;
  var o2 = obj2 instanceof Object;
  if (!o1 || !o2) {
    /*  判断不是对象  */
    return obj1 === obj2;
  }

  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
    //Object.keys() 返回一个由对象的自身可枚举属性(key值)组成的数组,例如：数组返回下表：let arr = ["a", "b", "c"];console.log(Object.keys(arr))->0,1,2;
  }

  for (var attr in obj1) {
    var t1 = obj1[attr] instanceof Object;
    var t2 = obj2[attr] instanceof Object;
    if (t1 && t2) {
      return diff(obj1[attr], obj2[attr]);
    } else if (obj1[attr] !== obj2[attr]) {
      return false;
    }
  }
  return true;
};

/**
 * 根据字典的value查找对应的index
 */
export const findArray = (dic, value) => {
  for (let i = 0; i < dic.length; i++) {
    if (dic[i].value === value) {
      return i;
    }
  }
  return -1;
};
/**
 * 生成随机len位数字
 */
export const randomLenNum = (len, date) => {
  let random = "";
  random = Math.ceil(Math.random() * 100000000000000)
    .toString()
    .substr(0, len ? len : 4);
  if (date) random = random + Date.now();
  return random;
};

/**
 * @param {String} url
 * @description 从URL中解析参数
 */
export const getParams = (url) => {
  let paramObj = {};
  if (url.indexOf("?") !== -1) {
    // const keyValueArr = url.split("?")[1].split("&");
    let data = url.split("?").slice(1);
    const keyValueArr = data.toString().split("&");
    keyValueArr.forEach((item) => {
      const keyValue = item.split("=");
      paramObj[keyValue[0]] = keyValue[1];
    });
  }
  return paramObj;
};

export const isApp = () => {
  let userAgent = navigator.userAgent;
  if (userAgent.indexOf("sdzn-android-gr") > -1) {
    return false;
  }
  if (
    userAgent.indexOf("sdzn-ios") > -1 ||
    userAgent.indexOf("sdzn-android") > -1
  ) {
    return true;
  } else {
    return false;
  }
};

export function isEmpty(val) {
  if (!val && val !== 0) return true;
  if (val === undefined) return true;
  if (typeof val === "object") return Object.keys(val).length === 0;
  if (typeof val === "string") return val === null || val.length === 0;
  return false;
}

// 上传图片1
export function upLoadImgs(formData, imgFile, option, base64) {
  clientUploadToken()
    .then((res) => {
      const options = {
        quality: 0.5,
        noCompressIfLarger: true,
      };
      // console.log(formData,res,base64);

      qiniu.compressImage(formData, options).then((data) => {
        let observable = qiniu.upload(
          data.dist,
          new Date().getTime() + (formData.name || ""),
          res
        );
        observable.subscribe(
          (res) => {
            if (option.loadProgress) {
              option.loadProgress(res);
            }
          },
          (err) => {
            if (option.failLoadCallBack) {
              option.failLoadCallBack(err);
            }
          },
          (complete) => {
            if (option.uploadCallBack) {
              option.uploadCallBack(complete, base64);
            }
          }
        );
      });
    })
    .catch((e) => {
      if (option.failLoadCallBack) {
        option.failLoadCallBack(e);
      }
    });
}

/**
 * 日期格式化
 */

export function dateFormat(date, format) {
  format = format || "yyyy-MM-dd hh:mm:ss";  // 如果未提供格式参数，则默认为"yyyy-MM-dd hh:mm:ss"
  date = new Date(date);  // 将传入的日期转换为Date对象
  if (date !== "Invalid Date") {  // 检查日期是否有效
    let o = {
      "M+": date.getMonth() + 1,  // 月份
      "d+": date.getDate(),  // 日期
      "h+": date.getHours(),  // 小时
      "m+": date.getMinutes(),  // 分钟
      "s+": date.getSeconds(),  // 秒钟
      "q+": Math.floor((date.getMonth() + 3) / 3),  // 季度
      S: date.getMilliseconds(),  // 毫秒
    };
    if (/(y+)/.test(format))
      format = format.replace(
        RegExp.$1,
        (date.getFullYear() + "").substr(4 - RegExp.$1.length)
      );
    for (let k in o)
      if (new RegExp("(" + k + ")").test(format))
        format = format.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? o[k]
            : ("00" + o[k]).substr(("" + o[k]).length)
        );
    return format;
  }
  return "";
}

// 判断日期是否是同一天
export function isSameDay(date1, date2) {

  if (!date1 || !date2) return false
  // 将参数转换为 Date 对象
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  // 检查转换后的 Date 对象是否有效
  if (isNaN(d1.getTime()) || isNaN(d2.getTime())) {
    throw new Error('Invalid date format');
  }

  // 比较年份、月份和日期
  const isSameYear = d1.getFullYear() === d2.getFullYear();
  const isSameMonth = d1.getMonth() === d2.getMonth();
  const isSameDate = d1.getDate() === d2.getDate();

  return isSameYear && isSameMonth && isSameDate;
}

export function setResourceSize(val) {
  val = Number(val) / 1024; // 转换kb

  if (val < 20) {
    return `${Math.floor(val * 100) / 100}KB`;
  } else {
    return `${Math.floor((val / 1024) * 100) / 100}MB`;
  }
}

export const isJson = (str) => {
  try {
    let obj = JSON.parse(str);
    if (typeof obj == "object" && obj) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

/**
 * 解决Vue Template模板中无法使用可选链的问题
 * @param obj
 * @param rest
 * @returns {*}
 */
export const optionalChaining = (obj, ...rest) => {
  let tmp = obj;
  for (let key in rest) {
    let name = rest[key];
    tmp = tmp?.[name];
  }
  return tmp || "";
};

export const setTimeFormater = (hs) => {
  var sTime = parseInt(hs / 1000 + ""); // 秒
  var mTime = 0; // 分
  var hTime = 0; // 时
  if (sTime > 60) {
    //如果秒数大于60，将秒数转换成整数
    //获取分钟，除以60取整数，得到整数分钟
    mTime = parseInt(sTime / 60 + "");
    //获取秒数，秒数取佘，得到整数秒数
    sTime = parseInt((sTime % 60) + "");
    //如果分钟大于60，将分钟转换成小时
    if (mTime > 60) {
      //获取小时，获取分钟除以60，得到整数小时
      hTime = parseInt(mTime / 60 + "");
      //获取小时后取佘的分，获取分钟除以60取佘的分
      mTime = parseInt((mTime % 60) + "");
    }
  }
  var result = "";
  if (sTime >= 0 && sTime < 10) {
    result = "0" + parseInt(sTime + "") + "";
  } else {
    result = "" + parseInt(sTime + "") + "";
  }
  if (mTime >= 0 && mTime < 10) {
    result = "0" + parseInt(mTime + "") + ":" + result;
  } else {
    result = "" + parseInt(mTime + "") + ":" + result;
  }
  // if (hTime >= 0 && hTime < 10) {
  //   result = "0" + parseInt(hTime) + ":" + result;
  // } else {
  //   result = "" + parseInt(hTime) + ":" + result;
  // }
  return result;
};
/**
 * 解决时间展示问题
 */
export const timeMethod = (msd) => {
  var time = parseFloat(msd) / 1000;
  if (null != time && "" !== time) {
    if (time > 60 && time < 60 * 60) {
      time =
        parseInt(time / 60.0) +
        "分" +
        parseInt((parseFloat(time / 60.0) - parseInt(time / 60.0)) * 60) +
        "秒";
    } else if (time >= 60 * 60 && time < 60 * 60 * 24) {
      time =
        parseInt(time / 3600.0) +
        "小时" +
        parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) +
        "分" +
        parseInt(
          (parseFloat(
            (parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60
          ) -
            parseInt(
              (parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60
            )) *
          60
        ) +
        "秒";
    } else {
      time = parseInt(time) + "秒";
    }
  }
  return time;
};

// 获取当前日期的0点、23:59:59... 的时间戳
export const getNowDateTime = () => {
  let dayStart = new Date(new Date().toLocaleDateString()).getTime();
  let dayEnd = dayStart + 24 * 60 * 60 * 1000 - 1;

  return [dayStart, dayEnd];
};

// 获取当前周的周一0点、周日23:59:59... 的时间戳
export const getNowWeekTime = () => {
  let nowdate = new Date();
  let weekFirstDay = new Date(nowdate - (nowdate.getDay() - 1) * 86400000); // 本周第一天
  let weekFirstDayTime = new Date(weekFirstDay.toLocaleDateString()).getTime();

  let weekLastDay = new Date((weekFirstDay / 1000 + 6 * 86400) * 1000); // 本周最后一天
  let weekLastDayTime =
    new Date(weekLastDay.toLocaleDateString()).getTime() +
    24 * 60 * 60 * 1000 -
    1;

  return [weekFirstDayTime, weekLastDayTime];
};

// 获取当前月的本月1号0点、本月最后一天的23:59:59... 的时间戳
export const getNowMonthTime = () => {
  let Nowdate = new Date();
  let MonthFirstDay = new Date(
    Nowdate.getFullYear(),
    Nowdate.getMonth(),
    1
  ).getTime();
  let MonthLastDay =
    new Date(Nowdate.getFullYear(), Nowdate.getMonth() + 1, 1).getTime() - 1;

  return [MonthFirstDay, MonthLastDay];
};

// 获取上个9月1号,到现在
export const getSeptember1st = () => {
  let Nowdate = new Date();
  let Month = Nowdate.getMonth();
  let FullYear = Nowdate.getFullYear();
  if (Month < 8) {
    FullYear = FullYear - 1;
  }
  let startTime = new Date(FullYear + "-9-1").getTime();
  let lastDay = new Date(new Date().toLocaleDateString()).getTime();
  let lastDayTime = lastDay + 24 * 60 * 60 * 1000 - 1;

  return [startTime, lastDayTime];
};

// 今日
export function getTodayTimeRange() {
  var now = new Date();
  var startTime = +new Date(now.getFullYear(), now.getMonth(), now.getDate());
  var endTime = +new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);

  return { startTime, endTime };
}

// 本周
export function getThisWeekTimeRange() {
  var now = new Date();
  var currDayOfWeek = now.getDay();
  var first = now.getDate() - currDayOfWeek;
  var last = first + 7;

  var startTime = +new Date(now.getFullYear(), now.getMonth(), first);
  var endTime = +new Date(now.getFullYear(), now.getMonth(), last);

  return { startTime, endTime };
}

// 本月
export function getThisMonthTimeRange() {
  var now = new Date();
  var startTime = +new Date(now.getFullYear(), now.getMonth(), 1);
  var endTime = +new Date(now.getFullYear(), now.getMonth() + 1, 0);

  return { startTime, endTime };
}

// 本学年
export function getThisAcademicYearRange() {
  var now = new Date();
  var currentMonth = now.getMonth();
  var currentYear = now.getFullYear();
  var academicYearStartMonth = 9; // Assuming the academic year starts in September

  var startYear = currentMonth >= academicYearStartMonth ? currentYear : currentYear - 1;
  var endYear = startYear + 1;

  var startTime = +new Date(startYear, academicYearStartMonth, 1);
  var endTime = +new Date(endYear, academicYearStartMonth - 1, 31);

  return { startTime, endTime };
}

export function formatDate(date) {
  const now = new Date();
  const diff = now - date;
  const oneDay = 24 * 60 * 60 * 1000; // 一天的毫秒数

  if (diff < oneDay && date.getDate() === now.getDate()) {
    // 今日
    return '今日 ' + formatTime(date);
  } else if (diff < 2 * oneDay && date.getDate() === now.getDate() - 1) {
    // 昨日
    return '昨日 ' + formatTime(date);
  } else {
    // 其他日期
    return formatDateOnly(date) + ' ' + formatTime(date);
  }
}

export function formatDateOnly(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function formatTime(date) {
  const hour = String(date.getHours()).padStart(2, '0');
  const minute = String(date.getMinutes()).padStart(2, '0');
  return `${hour}:${minute}`;
}

