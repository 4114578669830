/*
 * @Author: your name
 * @Date: 2021-07-22 15:06:56
 * @LastEditTime: 2022-04-22 11:21:43
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/components/answerList/components/fillBackExam/presenter.js
 */

import { useState, useEffect, useRef } from 'react';
import { message } from 'antd';
import { Upload } from 'antd';
import { upLoadImgs } from '@/util/util';
import { baseConfigUrl } from '@/util/configUrl';
import { isApp, getParams } from '@/util/util';
import { androidHandle } from "@/util/android";

const allowUploadImgSuffix = ['jpg', 'jpeg', 'png', 'PNG', 'JPG', 'JPEG'];

const Presenter = (props) => {
  const [previewVisible, setpreviewVisible] = useState(false);
  const [previewImage, setpreviewImage] = useState('');
  const [previewTitle, setpreviewTitle] = useState('');
  const [fileList, setfileList] = useState([]);
  const [openAudioType, setOpenAudioType] = useState(false);
  const [mistakesImgList, setMistakesImgList] = useState([])

  const { updateAnswer, info, isDisable, imgList, isMistakes, isSubmit } = props;

  const inputDom = useRef(null);

  const handleCancel = () => setpreviewVisible(false);

  window.takePhotoToWeb = (jsonObj) => {
    jsonObj = JSON.parse(jsonObj);
    let arr = [
      ...fileList,
      {
        uid: new Date().getTime(),
        name: jsonObj.name,
        status: 'done',
        url: jsonObj.key,
      },
    ];
    setfileList(arr);
    updateAnswer(arr, fileList.length === 0);
  };

  useEffect(() => {
    console.log(isSubmit, 'upload isSubmit0---------------');
    let dom = document.querySelectorAll('input') || [];
    for (let index = 0; index < dom.length; index++) {
      dom[index].setAttribute('capture', 'camera');
    }
  }, [isSubmit]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    let arr = [...imgList];
    arr = arr.map((v) => {
      if (typeof v === 'string') {
        return {
          uid: Math.random() * 10000 + '',
          name: v,
          status: 'done',
          url: v,
        };
      } else {
        return v;
      }
    });
    setfileList(arr || []);
  }, [imgList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isMistakes && getParams(window.location.href).isAnswer === '0') {
      setMistakesImgList(fileList)
    }
  }, [fileList])  // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (isMistakes && isSubmit) {
      if (getParams(window.location.href).isAnswer === '0') {
        window.setTimeout(() => {
          if (mistakesImgList.length !== 0) {
            setfileList(mistakesImgList)
          }
        }, 10)
      }
    }
  }, [isSubmit])  // eslint-disable-line react-hooks/exhaustive-deps


  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setpreviewImage(file.url || file.preview);
    setpreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    if (isApp()) {
      androidHandle("previewImageUrlClicked", file.url || file.preview);
    } else {
      setpreviewVisible(true);
    }
  };

  const previewImageHandler = (url) => {
    androidHandle("previewImageUrlClicked", url);
  }

  // const handleChange = ({ fileList }) => setfileList(fileList);

  const onRemove = (a, b) => {
    let arr = [...fileList];
    let index = arr.findIndex((v) => v.uid === a.uid);
    arr.splice(index, 1);
    setfileList(arr);
    updateAnswer(arr);
    return true;
  };



  const beforeUpload = (file, fileLists) => {
    let arr = file.name.split('.');
    let suffix = arr[arr.length - 1];
    if (allowUploadImgSuffix.indexOf(suffix) < 0) {
      fileLists.splice(fileList.length - 1, 1);
      message.info('请上传图片文件');
      return Upload?.LIST_IGNORE;
    }
    upLoadImg(file);
  };

  // pad点击上传作答
  const handlerCamera = () => {
    if (info?.answerType === 4) {
      closeAudio(true);
    } else if (info?.answerType === 5) {
      // pad 视频上传
      window?.android?.takeVideo?.();
    } else {
      window?.android?.takePhoto?.();
    }
  };

  // 关闭录制浮窗
  const closeAudio = (bool) => {
    setOpenAudioType(bool);
  };
  // 开关录音
  const tiggerPadRecord = (nun) => {
    // 0 关， 1开
    window?.android?.getPadRecord?.(nun);
  };

  const playAndPauseAudio = (e, i) => {
    const audioDom = e?.currentTarget?.children?.[1];
    const audios = [...fileList];
    audios[i].playType = audioDom?.paused;
    if (audioDom?.paused) {
      audioDom?.play();
    } else {
      audioDom?.pause();
    }
    setfileList(audios);
  };

  const audioEnded = (e, i) => {
    const audioDom = e?.currentTarget?.children?.[1];
    const audios = [...fileList];
    audios[i].playType = audioDom?.paused;
    setfileList(audios);
  };

  const upLoadImg = (file) => {
    if (isMistakes) {
      setMistakesImgList(x => fileList)
    }

    upLoadImgs(
      file,
      {},
      {
        uploadCallBack(res) {
          let arr = [
            ...fileList,
            {
              uid: new Date().getTime(),
              name: file.name,
              status: 'done',
              url: baseConfigUrl + res.key,
            },
          ];
          setfileList(arr);
          updateAnswer(arr, fileList.length === 0);
        },
        failLoadCallBack() { },
      }
    );
  };

  return [
    {
      fileList,
      previewVisible,
      previewImage,
      previewTitle,
      isDisable,
      inputDom,
      info,
      openAudioType,
    },
    {
      handleCancel,
      handlePreview,
      // handleChange,
      beforeUpload,
      onRemove,
      handlerCamera,
      closeAudio,
      tiggerPadRecord,
      playAndPauseAudio,
      audioEnded,
      previewImageHandler
    },
  ];
};

export default Presenter;
