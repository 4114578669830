/*
 * @Author: your name
 * @Date: 2022-03-18 09:48:11
 * @LastEditTime: 2022-03-25 11:44:51
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newGitZnxt2.0\src\components\wordAnswerList\components\timeAndSeq\index.jsx
 */
import "./index.less";
import Presenter from "./presenter";
import store from "@/redux/store";
import { connect } from "react-redux";

const TimeAndSeq = (props) => {
  const [states, events] = Presenter(props);
  const { seq, examTotal, state } = props;

  const { useTime } = states;
  const { setTimeFormater } = events;

  return (
    <div className="examFeature">
      {/* 使用时间 */}
      <>
        <div className="time">
          <i className="ri-time-fill"></i>
          <span>{setTimeFormater(useTime)}</span>
        </div>
        {/* 作答进度 */}
        <div className="progress">
          检测进度：
          <span>{seq || 0}</span>/{examTotal || 0}
        </div>
      </>
    </div>
  );
};

// export default TimeAndSeq

connect();

export default connect(
  (state) => ({
    updateTime: state.updateTime,
  }),
  {}
)(TimeAndSeq);
