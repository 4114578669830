/*
 * @Author: your name
 * @Date: 2021-07-21 14:44:04
 * @LastEditTime: 2022-04-27 18:20:51
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/pages/myCollect/presenter.js
 */

import {
  getBaseSubjectListAll,
  collectionAdd,
  collectionDel,
  newMyCollectList,
} from "@/api/answer";
import { useState, useEffect, useRef } from "react";
import { getParams } from "@/util/util";
import store from "@/redux/store";
import mathJax from "@/util/globalVariable";
import { message } from "antd";
const Presenter = () => {
  const [examList, setExamList] = useState([]);
  const [activeType, setActiveType] = useState(2);
  const [subjectId, setSubjectId] = useState(0);
  const [examInfo, setexamInfo] = useState(null);
  const [nowIndex, setnowIndex] = useState(0);
  const [activeInfo, setActiveInfo] = useState({});
  const [isModalVisible, setisModalVisible] = useState(false);

  const timeFilter = useRef({});
  const lastIdNum = useRef(0);
  const subjectList = useRef([]);
  const subjectActiveId = useRef(0);
  const activetype = useRef(2);
  const timer = useRef(null);
  const examListRef = useRef([]);
  const keyWord = useRef("");

  useEffect(() => {
    setTimeout(() => {
      mathJax();
    }, 0);
  }, [examInfo]);

  useEffect(() => {
    let obj = getParams(window.location.href);
    getBaseSubjectListAll({
      levelId: obj.levelId,
    }).then((res) => {
      subjectList.current = res;
    });
  }, []);

  const getSubjectName = (id) => {
    let obj = (subjectList.current || []).find(
      (v) => Number(v.id) === Number(id)
    );
    let name = obj?.name;
    if (name) {
      return name.substr(0, 1);
    }
    return "";
  };

  // 时间
  const handleChangTime = (time) => {
    setnowIndex(0);
    setexamInfo(null);
    setExamList([]);
    examListRef.current = [];
    lastIdNum.current = 0;
    if (time) {
      timeFilter.current = {
        startTime: new Date(
          new Date(time[0]._d).toLocaleDateString()
        ).getTime(), // new Date(time[0]._d).getTime(),
        endTime:
          new Date(new Date(time[1]._d).toLocaleDateString()).getTime() +
          24 * 60 * 60 * 1000 -
          1, // new Date(time[1]._d).getTime(),
      };
    } else {
      timeFilter.current = {
        startTime: "",
        endTime: "",
      };
    }
    getMyCollectList(undefined, undefined, true, undefined);
  };
  // 学科下拉
  const changeSubject = (val) => {
    setnowIndex(0);
    subjectActiveId.current = val;
    setexamInfo(null);
    setExamList([]);
    examListRef.current = [];
    lastIdNum.current = 0;
    setSubjectId(val);
    getMyCollectList(activeType, val);
  };
  // Type
  const getActiveType = (type) => {
    keyWord.current = "";
    if (activetype.current !== type) {
      setexamInfo(null);
      setExamList([]);
      examListRef.current = [];
      lastIdNum.current = 0;
      activetype.current = type;
      setActiveType(type);
    }
  };
  // 资源类型
  const handleTypeList = (v) => {
  };
  const changeInput = (val) => {
    setexamInfo(null);
    setExamList([]);
    examListRef.current = [];
    lastIdNum.current = 0;
    keyWord.current = val;
    getMyCollectList();
  };

  // 资源收藏
  const collectExam = (v, i) => {
    if (v.isCollectExam) {
      // 新资源取消收藏
      collectionDel({
        studentId: v.studentId, //207975
        subjectId: v.subjectId, //store.getState().courseInfo.subjectId,
        type: 1,
        examId: v.resource.id,
      }).then((res) => {
        let arr = [...examList];
        arr[i].isCollectExam = !arr[i].isCollectExam;
        setExamList(arr);
        examListRef.current = arr;
      });
    } else {
      //  新资源收藏
      collectionAdd({
        studentId: v.studentId,
        subjectId: v.subjectId,
        type: 1,
        examId: v.resource.id,
        chapterId: v.resource?.chapterId, //章节id
        // resourceType: v.resource?.resourceSuffix, //资源类型
        resourceName: v.resource?.name, //资源名称
      }).then((res) => {
        let arr = [...examList];
        arr[i].isCollectExam = !arr[i].isCollectExam;
        setExamList(arr);
        examListRef.current = arr;
      });
    }
  };

  // 查看
  const examine = (v) => {
    let obj = { file: v.resource, collection: v.isCollectExam };
    setActiveInfo(obj);
    setisModalVisible(true);
  };

  const getMyCollectList = (type, sub, isZore) => {
    let obj = getParams(window.location.href);
    newMyCollectList({
      type: type !== undefined ? type : activetype.current,
      startTime: timeFilter.current.startTime,
      endTime: timeFilter.current.endTime,
      subjectId: sub !== undefined ? sub : subjectActiveId.current, //subjectId,
      studentId: obj.studentId,
      lastId: lastIdNum.current,
      keyWord: activetype.current === 1 ? keyWord.current : undefined,
    }).then((res) => {
      if (activetype.current === 2) {
        // 试题收藏
        if (res.length !== 0) {
          res.forEach((element) => {
            element.exam = element.resourceDto.exam;
            element.collection = true;
            element.resource = {};
            element.resource.exam = element.resourceDto.exam;
            // 综合题处理
            let copyExamAnswer = JSON.parse(
              element.resourceDto.exam.childrenAnswer || "[]"
            );
            element?.resourceDto?.exam?.children?.forEach((vv, index) => {
              vv.resource = {};
              vv.resource.exam = {};
              vv.resource.exam = vv;
              vv.resource.exam.answer =
                JSON.stringify(copyExamAnswer[index]) || "";
            });
          });
          // 第一次赋值
          if (examList.length >= 0 || isZore) {
            setexamInfo(res[0]);
          }
          if (isZore) {
            setExamList([...res]);
            examListRef.current = [...res];
          } else {
            setExamList([...examListRef.current, ...res]);
            examListRef.current = [...examListRef.current, ...res];
          }
        }
      } else {
        // 资源收藏
        if (res?.length > 0) {
          (res || []).forEach((element) => {
            element.isCollectExam = true;
            element.resource = element.resourceDto.file;
          });
          if (isZore) {
            examListRef.current = [...res];
            setExamList([...res]);
          } else {
            setExamList([...examListRef.current, ...res]);
            examListRef.current = [...examListRef.current, ...res];
          }
        }
      }
    });
  };

  const handleInfiniteOnLoad = () => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      lastIdNum.current = examList[examList.length - 1].id;
      getMyCollectList();
    }, 50);
  };

  const handleCancel = () => {
    closemedial();
    setisModalVisible(false);
  };

  const closemedial = () => {
    let mp3 = document.querySelectorAll("audio") || [];
    let mp4 = document.querySelectorAll("video") || [];
    let doms = [...mp3, ...mp4];
    for (let index = 0; index < doms.length; index++) {
      doms[index].pause();
    }
  };

  useEffect(() => {
    if (subjectId !== 0) {
      getMyCollectList(activeType, subjectId, true, undefined);
    }
  }, [activeType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (examList.length > 0) {
      store.dispatch({ type: "updateExam", data: examList[nowIndex] });
      setexamInfo(examList[nowIndex]);
    }
  }, [nowIndex]); // eslint-disable-line react-hooks/exhaustive-deps
  // 试题上一题下一题
  const switchExam = (val) => {
    if (val === 0) {
      setnowIndex(nowIndex - 1 >= 0 ? nowIndex - 1 : 0);
    } else {
      if (nowIndex === examList.length - 2) {
        // if (nowIndex >= examList.length - 4) {
        lastIdNum.current = examList[examList.length - 1].id;
        getMyCollectList();
      }
      if (nowIndex === examList.length - 1 && nowIndex > 0) {
        message.warning("没有更多了");
      }
      // 提前判断好是否需要加载更多
      // if (nowIndex >= examList.length - 3 && examList.length >= 10) {
      //   getMyCollectList();
      // }
      setnowIndex(
        nowIndex + 1 < examList.length ? nowIndex + 1 : examList.length - 1
      );
    }
  };
  return [
    { examList, activeType, examInfo, nowIndex, activeInfo, isModalVisible },
    {
      getActiveType,
      handleChangTime,
      changeSubject,
      switchExam,
      examine,
      collectExam,
      handleCancel,
      getSubjectName,
      handleInfiniteOnLoad,
      handleTypeList,
      changeInput,
    },
  ];
};

export default Presenter;
