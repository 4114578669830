import "./index.less";
import Presenter from "./presenter";
import AnswerCard from "../answerCard";
import { Modal, Switch } from "antd";
import { Link } from "react-router-dom";
import store from "@/redux/store";
import { connect } from "react-redux";

import AudioBtn from "../audioBtn";
let userInfo = localStorage.getItem("sdzn-userInfo");
const ExamFeature = (props) => {
  const [states, events] = Presenter(props);

  const { weekEnFiles } = props;

  const {
    useTime,
    countExam,
    answerCount,
    isShow,
    isSubmit,
    submitWordList,
    isModalVisible,
    wordIndex,
    pageObj,
  } = states;
  const {
    submitHandle,
    changeCardStatus,
    setTimeFormater,
    handleOk,
    handleCancel,
    onChangeAnswer,
    onChangeErrorExam,
    onChangerewriteExam,
  } = events;

  return (
    <div className="examFeature">
      <>
        {weekEnFiles?.file?.resourceUrl ? (
          <AudioBtn url={weekEnFiles.file.resourceUrl}></AudioBtn>
        ) : (
          <></>
        )}
      </>
      <>
        {/* 使用时间 */}
        {!isSubmit ? (
          <>
            <div className="time">
              <i className="ri-time-fill"></i>
              <span>{setTimeFormater(useTime)}</span>
            </div>
            {/* 作答进度 */}
            <div className="progress">
              作答进度：
              <span>{answerCount > countExam ? countExam : answerCount}</span>/
              {countExam || 0}
            </div>
          </>
        ) : (
          ""
        )}

        {/* 只看错题 */}
        {isSubmit && !store.getState().courseInfo.notShowAnswerBtn ? (
          <div className="answerCard answerCardColume">
            <Switch defaultChecked={false} onChange={onChangeErrorExam} />
            <span>只看错题</span>
          </div>
        ) : (
          ""
        )}

        {!isSubmit && Number(store.getState()?.courseInfo?.redo) === 1 ? (
          <div className="answerCard answerCardColume">
            <Switch defaultChecked={false} onChange={onChangerewriteExam} />
            <span>只看订正</span>
          </div>
        ) : (
          ""
        )}

        {/* 是否可以看答案 */}
        {isSubmit && !store.getState().courseInfo.notShowAnswerBtn ? (
          <div className="answerCard answerCardColume">
            <Switch defaultChecked={true} onChange={onChangeAnswer} />
            <span>答案</span>
          </div>
        ) : (
          ""
        )}

        {/* 问题统计 */}

        {isSubmit ? (
          <Link
            to={`/listQuestionSheet?token=${pageObj.token}&pid=${
              store.getState().courseInfo.id
            }&publishLogId=${store.getState().courseInfo.publishLogId}&id=${
              pageObj.id
            }&studentId=${pageObj.studentId}&lessonTeacherId=${
              pageObj.lessonTeacherId
            }&type=${pageObj.type}&gradeId=${pageObj.gradeId}&schoolId=${
              pageObj.schoolId
            }&levelId=${pageObj.levelId || userInfo.levelId}&name=${
              pageObj.name
            }`}
          >
            <div className="answerCard btn1">
              <i className="ri-questionnaire-fill"></i>
              <span>问题统计</span>
            </div>
          </Link>
        ) : (
          ""
        )}

        {/* 练习报告 */}
        {isSubmit ? (
          <Link
            to={`/practiceReport?token=${pageObj.token}&pid=${
              store.getState().courseInfo.id
            }&id=${pageObj.id}&studentId=${pageObj.studentId}&lessonTeacherId=${
              pageObj.lessonTeacherId
            }&type=${pageObj.type}&name=${pageObj.name}&levelId=${
              pageObj.levelId
            }&gradeId=${pageObj.gradeId}&schoolId=${pageObj.schoolId}`}
          >
            <div className="answerCard btn1">
              <i className="ri-file-chart-fill"></i>
              <span>练习报告</span>
            </div>
          </Link>
        ) : (
          ""
        )}

        {/* 答题卡 */}
        <div className="answerCard btn1" onClick={() => changeCardStatus({flage:!isShow,state:'打开或关闭答题卡'})}>
          <i className="ri-file-edit-fill"></i>
          <span>答题卡</span>
        </div>

        {!isSubmit ? (
          <>
            {/* 提交按钮 */}
            <div className="btn1 submitBtn" onClick={() => submitHandle(countExam-answerCount)}>
              提交
            </div>
          </>
        ) : (
          ""
        )}
      </>
      {/* 提交提示 */}
      <Modal
        title="提示"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText={"查看答题卡"}
        okText={"提交"}
      >
        <p>{submitWordList[wordIndex]}</p>
      </Modal>
      {/* 答题卡 */}
     
      {isShow ? (
        <AnswerCard
          isSubmit={isSubmit}
          closeCardStatus={(status) => changeCardStatus(status)}
        ></AnswerCard>
      ) : (
        ""
      )}
    </div>
  );
};

connect();

export default connect(
  (state) => ({
    courseInfo: state.courseInfo,
    examItem: state.examItem,
    updateTime: state.updateTime,
    answerStates: state.answerStates,
  }),
  {}
)(ExamFeature);
