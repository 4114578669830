import "./index.less"
import presenter from "./presenter"
import { getParams, dateFormat } from "@/util/util";

const RemarkableGrad = (props) => {

    const [states, events] = presenter(props)
    const { levelId } = getParams(window.location.href);

    const { remarkable } = props

    return (
        <>
            <div className="RemarkableGradWarp">
                <div className="content">
                    {remarkable ? (
                        <>
                            <div className="title">{remarkable.name || "暂无考试信息"}</div>
                            <div className="data">
                                <span className="icon">
                                    <svg t="1679625725372" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3705"><path d="M384 128v69.792h256V128h64v69.792h96a64 64 0 0 1 64 64V832a64 64 0 0 1-64 64H224a64 64 0 0 1-64-64V261.824a64 64 0 0 1 64-64l96-0.032V128h64zM224 448v384h576V448H224z m480 224v64H320v-64h384z m0-128v64H320v-64h384zM320 255.968L224 256v128h576V256l-96-0.032V320h-64V255.968h-256V320h-64V255.968z" fill="#686C78" p-id="3706"></path></svg>
                                </span>
                                {
                                    remarkable.name ? dateFormat(remarkable.createTime, "yyyy-MM-dd") : '00-00-00'
                                }
                            </div>
                            <div className="score">
                                <span>满分：<em>{remarkable.fullScore || 0}</em></span>
                                <span>总分：<em>{remarkable.score || 0}</em></span>
                                <span>年级平均分：<em>{remarkable.gradeAvgScore ? remarkable.gradeAvgScore.toFixed(2) : 0}</em></span>
                                <span>班级平均分：<em>{remarkable.classAvgScore ? remarkable.classAvgScore.toFixed(2) : 0}</em></span>

                                {
                                    levelId >= 3 ? <>
                                        <span style={{ color: "#005eff" }}>年级排名：<em style={{ color: "#005eff" }}>{remarkable.gradeRank || 0}</em></span>
                                        <span style={{ color: "#005eff" }}>班级排名：<em style={{ color: "#005eff" }}>{remarkable.classRank || 0}</em></span>
                                    </> : null
                                }
                            </div>
                            {
                                remarkable.gradeRank <= 100 ? (
                                    <div className="gradeClassBgimg">
                                        <div className="text">{remarkable.gradeRank || 0}名</div>
                                    </div>
                                ) : null
                            }
                        </>
                    ) : null}
                </div>
            </div>
        </>
    )
}

export default RemarkableGrad