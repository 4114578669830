import "./index.less"
import React, { useEffect, useRef } from "react"
import { Modal } from "antd"
import ToneGenerator from "@/components/memorizeWords/toneGenerator"
import DictionaryComment from "@/components/memorizeWords/dictionaryComment"
import DictionarySentence from "@/components/memorizeWords/dictionarySentence"

function DictionaryModal({ className, word, next, translateList, phEn, phEnMp3, phAm, phAmMp3, list, extWord, isCollect, onCollectHandle, ...props }) {

    const dictionaryModalComponentRef = useRef(null)

    useEffect(() => {
        if (props?.visible) {
            if (dictionaryModalComponentRef.current) {
                setTimeout(() => {
                    dictionaryModalComponentRef.current.scrollTop = 0
                }, 0)
            }
        }
    }, [props?.visible])

    return (<>
        <Modal wrapClassName={`dictionary-Modal-Component ${className || ''}`} centered width="calc(1440vh / 12)" title={null} footer={null} {...props}>

            <div className="warp">
                <div className="content" ref={dictionaryModalComponentRef}>
                    <div className="title">
                        <div className="name">{word}</div>
                        {phEn && <ToneGenerator diction={`英`} symbol={phEn} style={{ backgroundColor: '#F6F7FC', marginTop: "calc(5vh / 12)" }} mp3Url={phEnMp3} />}
                        {phAm && <ToneGenerator diction={`美`} symbol={phAm} style={{ backgroundColor: '#F6F7FC', marginTop: "calc(5vh / 12)" }} mp3Url={phAmMp3} />}
                        {
                            isCollect !== undefined &&
                            <span className="collect" onClick={(e) => { e.stopPropagation(); onCollectHandle && onCollectHandle(!isCollect) }}>
                                {isCollect ? <><i className="ri-heart-3-fill" />取消收藏</> : <><i className="ri-heart-3-line" />收藏</>}
                            </span>
                        }
                    </div>
                    {
                        extWord &&
                        <section>
                            <div className="navName key">新东方绿皮书释义：</div>
                            {
                                (extWord || []).map((v, i) => {
                                    return (
                                        v ? <DictionaryComment
                                            style={{ backgroundColor: '#FFF2F5' }}
                                            key={i}
                                            comment={v}
                                            bottom="20" />
                                            : null
                                    )
                                })
                            }
                        </section>
                    }

                    <section>
                        <div className="navName">常用释义：</div>
                        {
                            (translateList || []).map((v, i) => {
                                return (
                                    v ? <DictionaryComment
                                        key={i}
                                        comment={v}
                                        bottom="20" />
                                        : null
                                )
                            })
                        }
                    </section>
                    <DictionarySentence list={list} word={word} />
                    {
                        next &&
                        <div className="footer">
                            <div className="button confirm" onClick={next}>下一词</div>
                        </div>
                    }
                </div>
            </div>
        </Modal>
    </>)
}

export default React.memo(DictionaryModal)