import "./index.less"

import Presenter from "./presenter.js";
function Ranking(props) {
    const { style, title, list, text } = props
    const [states, events] = Presenter(props);
    const { cutActive, studentId } = states
    const { changeCutActive } = events


    return (
        <>
            <div className="RankingWarp" style={style}>
                <div className="header">
                    <div className="title">{title}</div>
                    <div className="cut">
                        <span className={cutActive === 0 ? "active" : ""} onClick={() => changeCutActive(0, { type: title === "荣誉榜" ? 2 : 1, dateType: 1 })}>班级</span>
                        <em>/</em>
                        <span className={cutActive === 1 ? "active" : ""} onClick={() => changeCutActive(1, { type: title === "荣誉榜" ? 2 : 1, dateType: 2 })}>年级</span>
                    </div>
                </div>
                <div className="section">
                    {
                        list && list.length > 0 ? list.map((item, index) => {

                            return (
                                // 判断 是否是当前用户，如果是 将名字替换为 我 并增加底色样式
                                <div className={item.studentId + '' === studentId ? "item my" : "item"} key={index}>
                                    <div className="rank">
                                        {index === 0 ? <img src={require("@/assets/errorsBook/GroupFirst.png").default} alt="_" /> : null}
                                        {index === 1 ? <img src={require("@/assets/errorsBook/GroupTwo.png").default} alt="_" /> : null}
                                        {index === 2 ? <img src={require("@/assets/errorsBook/GroupThree.png").default} alt="_" /> : null}
                                        {index >= 3 ? index + 1 : null}
                                    </div>
                                    <div className="text">
                                        <div className={index <= 2 ? "name vital" : "name"}>
                                            {
                                                item.studentId + '' === studentId ? "我" : item.name || item.studentName
                                            }
                                        </div>
                                        <div className="cunt">{(item.count !== undefined ? item.count : item.score) + ' ' + (text ? text : '')}</div>
                                    </div>
                                </div>
                            )
                        }) : <div className="empty">
                            <span>
                                <img src={require("@/assets/errorsBook/empty.png").default} alt="_" />
                                <div className="text">暂无数据</div>
                            </span>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default Ranking