import React from 'react';
import PadHeader from '@/components/padHeader';
import Presenter from './presenter.js';
import ResourcePreview from '@/components/resourcePreview';
import './index.less';

const ElectronicBook = () => {
  const [states, events] = Presenter();
  const { bookData } = states;

  return (
    <div className="electronic_book_cont">
      {bookData.file ? <PadHeader name={decodeURIComponent(bookData.file.name)}></PadHeader> : null}
      {bookData.file ? <ResourcePreview info={bookData} collect={false} download={false}></ResourcePreview> : null}
    </div>
  );
};

export default ElectronicBook;
