import { useEffect, useRef, useState } from "react"
import * as echarts from "echarts"

const calc = (px) => {
    const clientHeight = window.document.querySelector('body').clientHeight / 100
    return px * clientHeight / 12
}

const Presenter = (props) => {

    const { dataList } = props

    const [subjectActive, setSubjectActive] = useState(0)
    const [subjectList, setSubjectList] = useState([])

    const changeSubjectList = (i) => {
        setSubjectActive(x => i)
    }

    useEffect(() => {
        if (dataList && dataList.length !== 0) {

            // 时间段数据
            let timerList = dataList.map(item => {
                let time = item.date.split('-')[1] + "-" + item.date.split('-')[2]
                return time
            })

            let subjectLists = []

            dataList.forEach((item) => {
                item.list.forEach(subItem => {
                    let subject = subjectLists.find(x => x === subItem.subjectName)
                    if (!subject) {
                        subjectLists.push(subItem.subjectName)
                    }
                })
            })

            setSubjectList(x => subjectLists)


            // 当前学科名称
            let subjectNameList = dataList.map(x => subjectLists[subjectActive])

            // 当前学科错题数据
            let subjectPresent = dataList.map(item => {
                let subject = item.list.find(x => x.subjectName === subjectLists[subjectActive])

                if (subject) {
                    return subject.count
                } else {
                    return 0
                }
            });

            // 当前时间段所有错题数据
            let totalList = dataList.map(item => {
                let sum = 0
                item.list.forEach(x => {
                    sum += x.count
                });
                return sum
            })

            const initChart = () => {
                let element = document.getElementById("ErrorsChartContent");
                let chart = echarts.init(element);

                <svg t="1684216200136" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6268" width="200" height="200"><path d="" p-id="6269" fill="#4dc0fc"></path></svg>

                let option = {
                    dataZoom: [
                        {
                            type: 'slider',
                            show: true,
                            yAxisIndex: [0, 1],//设置组件控制的y轴
                            left: '95.6%',//距离左侧的距离 可以使百分比，也可以是像素 left: '30'（30像素）
                            start: 0,
                            end: 100,
                            handleIcon: "M512 938.666667C276.352 938.666667 85.333333 747.648 85.333333 512S276.352 85.333333 512 85.333333s426.666667 191.018667 426.666667 426.666667-191.018667 426.666667-426.666667 426.666667z m0-256a170.666667 170.666667 0 1 0 0-341.333334 170.666667 170.666667 0 0 0 0 341.333334z",
                            width: calc(34),
                            handleStyle: {
                                borderColor: '#aab6c6',
                                shadowBlur: calc(4),
                                shadowOffsetX: calc(1),
                                shadowOffsetY: calc(1),
                                shadowColor: '#e5e5e5'
                            },
                            textStyle:false,
                            orient:'vertical'
                        },
                    ],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        },
                        textStyle: {
                            fontSize: calc(20)
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '10%',
                        bottom: '6%',
                        top: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'value',
                        axisLabel: {
                            fontSize: calc(20),  //使用rem自适应
                        },
                        // 设置X轴网格线样式
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: ['#DCDEE0'],
                                width: calc(1.25),
                                type: 'solid'
                            }
                        },
                    },
                    yAxis: [
                        {
                            type: 'category',
                            data: timerList,
                            axisLabel: {
                                fontSize: calc(20),  //使用rem自适应
                            },

                        },
                        {
                            type: 'category',
                            axisTick: {
                                show: false
                            },
                            axisLine: {
                                show: false,
                            },
                            axisLabel: {
                                show: false
                            },
                            data: subjectNameList
                        }
                    ],
                    series: [
                        {
                            name: '所有科目错题',
                            type: 'bar',
                            data: totalList,
                            barWidth: calc(10),
                            itemStyle: {
                                show: true,
                                color: '#CCD9FC'
                            },
                            label: {
                                position: "right",
                                show: true,
                                color: '#0043F2',
                                fontSize: calc(18),
                                formatter: function (params) {
                                    if (params.value === 0) {  //为0时不显示
                                        return ''
                                    } else {
                                        return params.value
                                    }
                                }
                            },
                        },
                        {
                            name: '当前科目错题',
                            type: 'bar',
                            yAxisIndex: 1,
                            data: subjectPresent,
                            barWidth: calc(10),
                            barGap: '10%',
                            itemStyle: {
                                show: true,
                                color: '#356AF2'
                            },
                            label: {
                                position: 'top',
                                show: true,
                                color: '#356AF2',
                                // color: '#FFFFFF',
                                fontSize: calc(18),
                                formatter: function (params) {
                                    if (params.value === 0) {  //为0时不显示
                                        return ''
                                    } else {
                                        return params.value
                                    }
                                }
                            },
                        },
                    ]
                };

                option && chart.setOption(option)
            }
            initChart()
        }

    }, [dataList, subjectActive])


    return [
        { subjectActive, subjectList },
        { changeSubjectList }
    ]
}

export default Presenter