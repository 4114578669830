import { useEffect, useRef, useState } from "react"
import { getParams, isSameDay } from "@/util/util"
import { getWordHome, wordSetPlan } from "@/api/memorizeWords"
import { topRightText, randomArr } from "@/components/memorizeWords/topRightText"
import store from "@/redux/store"
import { SET_STUDY_STYLE } from "@/redux/constant"

const Presenter = (props) => {
    const query = getParams(window.location.href)
    const [info, setInfo] = useState({})
    const [planModalVisible, setPlanModalVisible] = useState(false)
    const [planDate, setPlanDate] = useState(null)
    const [text, setText] = useState('')
    const datePickerRef = useRef(null);


    useEffect(() => {
        getWordHome(query).then(res => {
            setInfo(res)
            store.dispatch({
                type: SET_STUDY_STYLE,
                data: { style: res?.style || 1, model: res?.model || 0 }
            })
        })
        setText(randomArr(topRightText))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (datePickerRef.current && planModalVisible) {
            const inputDOM = document.querySelector('.ant-picker-input input');
            inputDOM.addEventListener('focus', function (event) {
                event.target.setAttribute(`readonly`, `readonly`)
            });
        }
    }, [planModalVisible])

    const planModalVisibleChange = (flage) => {
        setPlanModalVisible(flage)
    }

    const planDateChange = (date) => {
        let nowDate = new Date().getTime()
        let planDate = new Date(date).getTime()

        if (planDate > nowDate && !isSameDay(nowDate, planDate)) {
            setPlanDate(date)
        }
    }

    const planModalOk = () => {
        let params = {
            studentId: query.studentId,
            learningEndTime: new Date(planDate).getTime(),
            type: query.gradeId > 9 ? 3 : 2,//3 是高中 2 是初中
            classId: query.classId,
            gradeId: query.gradeId,
        }
        if (params.learningEndTime === 0) return planModalVisibleChange(false)
        wordSetPlan(params).then(res => {
            setInfo(info => {
                return {
                    ...info,
                    residueDay: res.days,
                    highTodayCount: res?.dayCount
                }
            })
            planModalVisibleChange(false)
        })
    }

    return [
        { info, query, planModalVisible, planDate, text, datePickerRef },
        { planModalVisibleChange, planDateChange, planModalOk }
    ]
}

export default Presenter