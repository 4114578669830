import React from "react";
import "./index.less";
import TimeAnt from "@/components/timeAnt";
import { Input, Select } from "antd";
import SubjectSelect from "@/components/subjectSelect";

import Presenter from "./presenter.js";
const HeadOperation = (props) => {
  const [states, events] = Presenter(props);

  const { isDefault, typeList, resourcesTypeList, value } = states;
  const { handleType, changeType, changeInput } = events;
  const { Option } = Select;

  return (
    <div className="headOperation">
      <div className="leftTypeBox">
        {typeList.map((v, index) => (
          <div
            style={{
              color: v.active ? "#FA541C" : "#8c8c8c",
            }}
            key={index}
            value={v.type}
            onClick={handleType}
          >
            {v.text}
          </div>
        ))}
      </div>
      <div className="rightBox">
        {/* <div className="typeBox">
          {typeList[1].active ? (
            <>
              <Select
                placeholder="请选择资源类型"
                value={value}
                getPopupContainer={() =>
                  document.querySelector(".subjectSelect")
                }
                onChange={changeType}
              >
                {(resourcesTypeList || []).map((v) => (
                  <Option key={v.id} value={v.id}>
                    {v.name}
                  </Option>
                ))}
              </Select>
            </>
          ) : (
            ""
          )}
        </div> */}
        <div>
          {typeList[1].active ? (
            <>
              <Input
                className="inputBox"
                placeholder="请输入资源名称"
                style={{ width: 200 }}
                onChange={changeInput}
              />
            </>
          ) : (
            ""
          )}
        </div>

        <div>
          <TimeAnt handleChangTime={props.handleChangTime} />
        </div>
        <div className="rightMinBox">
          <SubjectSelect
            isDefault={isDefault}
            changeSubject={props.changeSubject}
          />
        </div>
      </div>
    </div>
  );
};

export default HeadOperation;
