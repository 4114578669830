import "./index.less";
import Presenter from "./presenter";
import { connect } from "react-redux";
import ResourcePreview from "@/components/resourcePreview";

const CorrectResult = (props) => {
  const { info, isSubmit } = props;

  const [states] = Presenter(props);

  const correctRight = [
    {
      word: "我的得分",
      color: "#30CB82",
      key: "scoreTotal",
    },
    {
      word: "总分",
      color: "#FA541C",
      key: "scoreFull",
    }
  ]; // 错误答案的 - activeError

  let obj = JSON.parse(info.judgeComment || "{}");

  return (<>

    {props.isShowAnswer &&
      <div className="correctResult">

        <div className="left">
          {
            info?.resource?.exam?.examAnswer && isSubmit &&
            <div className="answer">
              <p className="leftTitle">答案</p>
              <p className="content" dangerouslySetInnerHTML={{ __html: info?.resource?.exam?.examAnswer }} />
            </div>
          }

          {
            info?.resource?.exam?.examAnalysis && isSubmit &&
            <div className="analysis">
              <p className="leftTitle">解析</p>
              <p className="content" dangerouslySetInnerHTML={{ __html: info?.resource?.exam?.examAnalysis }} />
            </div>
          }

          {
            (obj?.text || obj?.mp3List?.length > 0) &&
            <div className="analysis">
              <p className="leftTitle">评语</p>
              <div className="rightCont">
                {obj?.text && <p className="content" dangerouslySetInnerHTML={{ __html: obj?.text }} />}
                {obj?.mp3List?.map((v) => <audio controls controlsList="nodownload" src={v.url} />)}
              </div>
            </div>
          }
        </div>

        <div className="right">
          {correctRight.map((v, i) =>
            info?.[v?.key] === undefined ? (
              ""
            ) : (
              <span key={i}>
                <p style={{ color: v.color }}>
                  {info.correctState === 0 && i === 0
                    ? "-"
                    : info?.[v?.key] >= 0
                      ? info?.[v?.key]
                      : "——"}
                </p>
                <p>{v.word}</p>
              </span>
            )
          )}
        </div>
      </div>
    }

  </>);
};

connect();

export default connect(
  (state) => ({
    isShowAnswer: state.isShowAnswer,
  }),
  {}
)(CorrectResult);
