/*
 * @Author: your name
 * @Date: 2021-07-22 08:44:41
 * @LastEditTime: 2022-02-09 11:03:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/components/answerList/components/chioceExam/presenter.js
 */

import { useState, useRef, useEffect } from "react";
import { addCourseAnswer } from "@/api/answer";
import { getWrongQuestionSubmit } from "@/api/mistakesBooks"
import store from "@/redux/store";

const Presenter = (props) => {
  const chioceList = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  const [activeIndex, setActiveIndex] = useState(""); // 学生选中的 - active
  const [rightIndex, setrightIndex] = useState(""); // 正确答案的 - activeRight
  const [errorIndex, seterrorIndex] = useState(""); // 错误答案的 - activeError

  const { info, isSubmit, inExamSeq, submitAnswerInfo, isMistakes, childrenAnswer } = props;

  const timer = useRef(null);

  useEffect(() => {

    if (isMistakes) {
      setrightIndex("")
      seterrorIndex("")
    }

    let answer = JSON.parse(info.answer || childrenAnswer || "[]"); // 学生作答
    let rightAnswe = info?.resource?.exam?.examOptions || info?.examOptions;
    if (isSubmit && store.getState().isShowAnswer) {
      let rightStr = "";
      (rightAnswe || []).forEach((element) => {
        if (element.right) {
          rightStr += element.seq - 1 + ",";
        }
      });
      setrightIndex(rightStr);
      console.log(rightStr, 'right---------')

      let errorAnswer = "";
      if (answer instanceof Array) {
        (answer || []).forEach((element) => {
          // console.log(element, Number(element.a -1  || element[0].a - 1) + "", 'elel');

          if (rightStr.indexOf(Number(element?.a - 1 || element[0]?.a - 1)) < 0) {
            errorAnswer += Number(element?.a - 1 || element[0]?.a - 1) + ",";
          }

        });
        console.log(errorAnswer);
        seterrorIndex(errorAnswer);
      }
    }
    // else {
    // 初始化答案
    let str = "";
    if (answer.length > 0) {
      answer.forEach((element) => {
        str += element.a - 1 + ",";
      });
    }
    setActiveIndex(str);

    // }
  }, [props.examItem, props.info, props.isSubmit]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeActiveIndex = (index, v, info) => {

    if (props.isSubmit) return;
    // 单选是更新，多选是追加
    let str = "";
    if (
      info?.resource?.exam?.examTypeId === 2 ||
      info?.resource?.exam?.examTypeId === 20
    ) {
      if ((activeIndex.split(",") || []).find((v) => index + "" === v + "")) {
        // 存在就删除
        var reg = new RegExp(`${index}`, "g");
        str = activeIndex.replace(reg, "");
        setActiveIndex(str);
      } else {
        str = activeIndex + "," + index;
        setActiveIndex(str);
      }
    } else {
      str = index + "";
      setActiveIndex(index + "");
    }
    let arr = str.split(",");
    let optionList = [];
    arr = arr.filter((v) => v);

    (arr || []).forEach((el) => {
      optionList.push({
        examTypeId: info?.resource?.exam?.examTypeId || info.examTypeId, // 错题本修改
        selectSeq: info?.resource?.exam?.examOptions[Number(el)]?.seq || info?.examOptions[Number(el)]?.seq,  // 错题本修改
      });
    });

    let obj = {
      lessonStudentId: store.getState().courseInfo.id,
      resourceId: info?.resource?.exam?.id || info?.id,  // 错题本修改
      resourceType: 2,
      usedTime: store.getState().updateTime,
      optionList: optionList,
      isAdd: activeIndex === "",
      inExamSeq: inExamSeq,
    };

    clearTimeout(timer.current);

    // 错题本修改
    // if (isMistakes) {
    //   isMistakes(obj)
    //   return
    // }

    console.log({ inExamSeq, obj, info });

    if (inExamSeq || inExamSeq === 0) {
      submitAnswerInfo(inExamSeq, obj);
    } else {
      if (isMistakes) {
        isMistakes(obj)
        return;
      }
      addCourseAnswer(obj).then((res) => { });
    }
    // }, 0);
  };

  return [
    {
      activeIndex,
      chioceList,
      rightIndex,
      errorIndex,
      isSubmit,
    },
    {
      changeActiveIndex,
    },
  ];
};

export default Presenter;
