

import request from "../util/request";
// 任务详情接口



export function getMoralityHome(params) {
    return request({
        url: "/class/v2/ClassOptimize/student/home",
        method: "get",
        params
    });
}

export function getMoralityRecord(params) {
    return request({
        url: "/class/v2/ClassOptimize/student/record",
        method: "get",
        params
    });
}

export function createMoralityEnterGroup(params) {
    return request({
        url: "/class/v2/class/group/list",
        method: "get",
        params
    });
}


export function getMoralityEnterGroup(params) {
    return request({
        url: "/class/v2/class/group/list",
        method: "get",
        params
    });
}

export function getMoralityEnterStudent(params) {
    return request({
        url: "/class/v2/class/student/comment",
        method: "get",
        params
    });
}

export function getSubjectShould(params) {
    return request({
        url: "/knowledgecenter/baseSubject/list",
        method: "get",
        params
    })
}

export function getCommentLabel(params) {
    return request({
        url: "/class/v2/ClassOptimize/class/label",
        method: "get",
        params
    })
}

export function getMoralityEnterRecord(params) {
    return request({
        url: "/class/v2/student/record",
        method: "get",
        params
    })
}

export function revokeCommentRecord(params) {
    return request({
        url: "/class/v2/student/comment/cancel",
        method: "get",
        params
    })
}

export function commentScoringAdd(data) {
    return request({
        url: "/class/v2/student/comment/add",
        method: "post",
        data,
        meta: {
            isSerialize: true,
            notNeedSplit: true
        }
    })
}

export function createNewGroup(params) {
    return request({
        url: "/class/v2/class/group/add",
        method: "get",
        params
    })
}

export function getGroupStudents(params) {
    return request({
        url: "/class/v2/class/group/students",
        method: "get",
        params
    })
}

export function deleteCommentGroup(params) {
    return request({
        url: "/class/v2/class/group/delete",
        method: "get",
        params
    })
}

export function addStudentToGroup(params) {
    return request({
        url: "/class/v2/group/add/student",
        method: "get",
        params
    })
}

export function getAllCommentLabel(params) {
    return request({
        url: "/class/v2/ClassOptimize/school/grade/label",
        method: "get",
        params
    })
}

export function updateLabelUsePower(params) {
    return request({
        url: "/class/v2/ClassOptimize/label/addRelation",
        method: "get",
        params
    })
}

export function deleteCommentLabel(data) {
    return request({
        url: "/class/v2/ClassOptimize/label/del",
        method: "post",
        data,
        meta: {
            isSerialize: true,
            notNeedSplit: true
        }
    })
}

export function createCommentLabel(data) {
    return request({
        url: "/class/v2/ClassOptimize/label/addAndRelation",
        method: "post",
        data,
        meta: {
            isSerialize: true,
            notNeedSplit: true
        }
    })
}

export function editCommentLabel(data) {
    return request({
        url: "/class/v2/ClassOptimize/label/update",
        method: "post",
        data,
        meta: {
            isSerialize: true,
            notNeedSplit: true
        }
    })
}