import "./index.less";
import Presenter from "./presenter"
import PadHeader from "@/components/padHeader";
import ScreenTopic from "./components/screenTopic";
import TopicList from "./components/topicList";

function MistakesBank(props) {

    const [states, events] = Presenter(props)
    const { subjectListData, wrongQuestionList, questionBank, MistakesBookParamsObj, wrongQuestionCount, useLabelList } = states
    const { onChangeSubject, onChangeTimer, onChangeDate, onChangeBank, onChangeType, scrollBottomChange, removePresentTopic, onChangeLabelName, refReshUseLabelList } = events

    return (
        <>
            <div className="MistakesBankWarp">
                <PadHeader>
                    <div className="HeaderTitle">错题库</div>
                </PadHeader>
                <div className="contentBox">
                    <ScreenTopic
                        subjectListData={subjectListData}
                        useLabelList={useLabelList}
                        onChangeSubject={onChangeSubject}
                        onChangeTimer={onChangeTimer}
                        onChangeDate={onChangeDate}
                        onChangeBank={onChangeBank}
                        onChangeType={onChangeType}
                        onChangeLabelName={onChangeLabelName}
                    />
                    <TopicList
                        wrongQuestionList={wrongQuestionList}
                        wrongQuestionCount={wrongQuestionCount}
                        questionBank={questionBank}
                        MistakesBookParamsObj={MistakesBookParamsObj}
                        scrollBottomChange={scrollBottomChange}
                        removePresentTopic={removePresentTopic}
                        refReshUseLabelList={refReshUseLabelList}
                    ></TopicList>
                </div>
            </div>
        </>
    )
}

export default MistakesBank