/*
 * @Author: slw 3289360550@qq.com
 * @Date: 2022-02-16 08:40:59
 * @LastEditors: slw 3289360550@qq.com
 * @LastEditTime: 2022-05-17 10:22:11
 * @FilePath: /znxt-student2.0/src/components/answerList/index.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import "./index.less";
import { Carousel } from "antd";
import Presenter from "./presenter";
import "./index.less";
import store from "@/redux/store";
import { connect } from "react-redux";

import ExamItem from "@/components/examItem";
import { useEffect } from "react";

const AnswerList = (props) => {
  const [states, events] = Presenter(props);

  console.log(props, 'props');

  const { inputEl, nowIndex } = states;

  const { onChange, switchExam } = events;

  const examTypeId = props.exam?.resource?.exam?.examTypeId;

  // r: 1：对 2：错 3：半对
  const courseInfo = store.getState().courseInfo;

  return (
    <div className="examList">
      {/* <Carousel beforeChange={onChange} ref={inputEl}>
        {(props.errorExamState
          ? props.answerStates
          : new Array(courseInfo.countExam).fill(1) || []
        ).map((v, index) => ( */}
      <div className="itemGridWrapper">

        {/* {nowIndex - 1 === index ? ( */}
        <ExamItem
          index={(props?.exam?.seq || 1) - 1}
          examTypeId={examTypeId}
          exam={props.exam}
          isSubmit={courseInfo.status !== 0 || (courseInfo.redo === 1 ? props?.exam?.redo === 0 : courseInfo.status !== 0)}
          isCorrect={courseInfo.status !== 0 || courseInfo.redo === 1}
          isNotCollect={courseInfo.status === 0}
        ></ExamItem>

      </div>
      {/* // ))} */}
      {/* </Carousel> */}
      {/* 切换按钮 */}
      <div className="switchExam">
        <div className="lastExam" onClick={() => switchExam(0)}>
          <i className="ri-arrow-left-line"></i>
        </div>
        <div className="nextExam" onClick={() => switchExam(1)}>
          <i className="ri-arrow-right-line"></i>
        </div>
      </div>
    </div>
  );
};
connect();

export default connect(
  (state) => ({
    courseInfo: state.courseInfo,
    nowExamIndex: state.nowExamIndex,
    errorExamState: state.errorExamState,
    answerStates: state.answerStates,
    exam: state.examItem,
    rewriteState: state.rewriteState
  }),
  {}
)(AnswerList);
