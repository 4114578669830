import "./index.less";
import ExamSteam from "../examSteam";
import Presenter from "./presenter";
import { connect } from "react-redux";

const ChioceExam = (props) => {

  const [states, events] = Presenter(props);

  const { activeIndex, chioceList, rightIndex, errorIndex } = states;
  const { changeActiveIndex } = events;

  const { info, isMistakes } = props
  // let infos = props.info
  // let info = infos?.resource?.exam || infos

  return (
    <div className="chioceExamComponent">
      <ExamSteam
        info={info?.mark || info?.resource?.exam?.examStem || info?.examStem}  // 错题本修改
        isMistakes={isMistakes}
        inExamSeq={props.inExamSeq}
      ></ExamSteam>
    
         <ul>
          {(info?.resource?.exam?.examOptions || info?.examOptions   /* 错题本修改 */ || []).map((v, index) => (
            <li
              key={index}
              className={`${((activeIndex + "").split(",") || []).find(
                (v) => index + "" === v + ""
              )
                ? "active"
                : ""
                }
            ${((rightIndex + "").split(",") || []).find(
                  (v) => index + "" === v + ""
                ) && props.isShowAnswer
                  ? "activeRight"
                  : ""
                } ${((errorIndex + "").split(",") || []).find(
                  (v) => index + "" === v + ""
                ) && props.isShowAnswer
                  ? "activeError"
                  : ""
                }`}
              onClick={() => changeActiveIndex(index, v, info)}
            >
              <p className="item">{chioceList[index]}</p>
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: v.content }}
              ></div>
            </li>
          ))}
        </ul>
    </div>
  );
};

connect();

export default connect(
  (state) => ({
    examItem: state.examItem,
    isShowAnswer: state.isShowAnswer,
  }),
  {}
)(ChioceExam);
