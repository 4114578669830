import "./index.less";
import PadHeader from "@/components/padHeader";
import SubjectSelect from "@/components/subjectSelect";
import { getParams, dateFormat } from "@/util/util";
import listPng from "@/assets/newSence/list.png";
// import InfiniteScroll from "react-infinite-scroller";
import ItemCard from "./components/itemCard";
import Presenter from "./presenter";
import { Progress, Modal, Empty, Select } from "antd";
const { Option } = Select;
const tabs = [
  {
    desc: "同步练习",
    val: 0,
  },
  // {
  //   desc: "作答试卷",
  //   val: 1,
  // },
];
const obj = getParams(window.location.href);

const AutonomyPractice = () => {
  const [states, events] = Presenter();
  const {
    active,
    todoList,
    modalRef,
    subjectSelectRef,
    bookValue,
    bookList,
    bookVolumesVal,
    bookVolumesList,
  } = states;
  const {
    onChange,
    changeActive,
    changeSubject,
    handleInfiniteOnLoad,
    changeBook,
    changeBookVolumes,
  } = events;

  // const todoList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];

  return (
    <div ref={modalRef} className="autonomyPractice">
      <PadHeader>
        <div className="errorHead">
          <div className="left">
            {tabs.map((v, index) => (
              <p
                className={`tabItem ${active === index ? "active" : ""}`}
                key={index}
                onClick={() => changeActive(index)}
              >
                {v.desc}
              </p>
            ))}
          </div>
          <div className="right">
            <SubjectSelect
              ref={subjectSelectRef}
              changeSubject={changeSubject}
              isDefault={true}
            />
            <div className="bar"></div>
            {active === 0 ? (
              <>
                <div className="BookSelect">
                  <Select
                    placeholder="请选择版本"
                    value={bookValue}
                    getPopupContainer={() =>
                      document.querySelector(".subjectSelect")
                    }
                    onChange={changeBook}
                  >
                    {(bookList || []).map((v) => (
                      <Option key={v.id} value={v.id}>
                        {v.name}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="bar"></div>
                <div className="BookSelect2 ">
                  <Select
                    placeholder="请选择册别"
                    value={bookVolumesVal}
                    getPopupContainer={() =>
                      document.querySelector(".subjectSelect")
                    }
                    onChange={changeBookVolumes}
                  >
                    {(bookVolumesList || []).map((v) => (
                      <Option key={v.id} value={v.id}>
                        {v.bookVersionName + v.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </PadHeader>
      {active === 0 ? (
        <div className="errorExamPractice">
          <div className="todoCard">
            {(todoList || []).map((v, i) => (
              <div key={i}>
                <ItemCard
                  itemInfo={v}
                  bookVolumesVal={bookVolumesVal}
                ></ItemCard>
              </div>
            ))}
            {todoList.length === 0 ? (
              <Empty
                image={listPng}
                description={""}
                imageStyle={{
                  height: 300,
                }}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <div className="examList">
          <div className="examListGrid">作答试卷</div>
        </div>
      )}
    </div>
  );
};

export default AutonomyPractice;
