/*
 * @Author: your name
 * @Date: 2022-03-11 11:14:56
 * @LastEditTime: 2022-03-21 08:48:51
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newGitZnxt2.0\src\components\answerList\components\wordStem\index.jsx
 */
import "./index.less";
import Presenter from "./presenter";
import { SoundOutlined } from "@ant-design/icons";
const WordStem = (props) => {
  const { info, type, examTypeId } = props;
  const url = "https://file.aiznclass.com/word";
  const [states, events] = Presenter(props);
  // const {} = states;
  const { playMp3 } = events;

  return (
    <div>
      {type === 1 ? (
        <>
          <div className="stemBox">{info?.examStem}</div>
          <div className="questionType">
            <div className="types">题型</div>
            <div className="title">拼写单词</div>
          </div>
        </>
      ) : type === 2 ? (
        <>
          <div className="questionType">
            <div className="types">题型</div>
            <div className="title">补全句子</div>
          </div>
        </>
      ) : type === 3 ? (
        <>
          <div className="transliterationBox">
            <div className="usa">
              <div className="borderBox">美</div>
              <div>/bat/</div>
              <SoundOutlined className="iconBox" />
            </div>
            <div className="uk">
              <div className="borderBox">英</div>
              <div>/bet/</div>
              <SoundOutlined className="iconBox" />
            </div>
          </div>
          <div className="questionType">
            <div className="types">题型</div>
            <div className="title">听单词选中文</div>
          </div>
        </>
      ) : type === 4 ? (
        <>
          <div className="stemBox">{info?.examStem}</div>
          <div className="questionType">
            <div className="types">题型</div>
            <div className="title">英译汉</div>
          </div>
        </>
      ) : (
        <>
          <div className="wordStemCom">{info?.examStem}</div>
          {examTypeId === 22 ? (
            <>
              {info?.phAm.length || info?.phEn.length ? (
                <div className="transliteration">
                  <div className="usa" onClick={() => playMp3("USA")}>
                    <div className="borderBox">美</div>
                    {info?.phAm.length ? <div>[{info?.phAm}]</div> : ""}
                    {info?.phAmMp3.length ? (
                      <SoundOutlined className="iconBox" />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="uk" onClick={() => playMp3("UK")}>
                    <div className="borderBox">英</div>
                    {info?.phEn.length ? <div>[{info?.phEn}]</div> : ""}
                    {info?.phEnMp3.length ? (
                      <SoundOutlined className="iconBox" />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            <></>
          )}
        </>
      )}
      <audio id="musicUSABox" src={url + info?.phAmMp3} />
      <audio id="musicUKBox" src={url + info?.phEnMp3} />
    </div>
  );
};

export default WordStem;
