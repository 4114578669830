/*
 * @Author: your name
 * @Date: 2021-07-22 10:33:19
 * @LastEditTime: 2022-10-31 10:58:36
 * @LastEditors: c1107 chen1997
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/pages/studentAnswer/components/funBar/presenter.js
 */
import { useRef, useState, useEffect } from "react";
import { getParams } from "@/util/util";
import { addCourseAnswer } from "@/api/answer";
import store from "@/redux/store";

const Presenter = (props) => {
  const { changeComponents, courseDetail } = props;

  const [isShow, setIsShow] = useState(true);

  const [todoList, settodoList] = useState([]);

  const [active, setactive] = useState(0);

  const timerRef = useRef(null);

  const timerNum = useRef(0);
  const nowInfo = useRef({});


  useEffect(() => {
    clearInterval(timerRef.current);
    timerNum.current = todoList[active]?.useTime || 0;
    timerRef.current = setInterval(() => {
      timerNum.current = timerNum.current + 1000;
      if ((timerNum.current / 1000) % 5 === 0) {
        searchResource(nowInfo.current);
      }
    }, 1000);
    return () => {
      clearInterval(timerRef.current);
    };
  }, [active]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let query = getParams(window.location.href);
    // let name = query.type === "10" ? "备注" : "备注";
    let name = "备注";
    // 梳理数据
    let obj = { ...courseDetail };
    let arr = [
      // ...(JSON.parse(obj?.info || "{}")),
      ...(obj?.examInfo || []),
      ...(obj?.files || []),
      ...(obj?.models ? [{ name: name, type: 0 }] : []),
    ];
    // 周清发布资料后（英语，日语）MP3处理
    let examList = [...(obj?.examInfo || [])];
    let filesList = [...(obj?.files || [])];
    let subjectList = [7, 8, 9, 55, 60]; //英语，日语

    if (
      examList.length &&
      subjectList.indexOf(examList[0]?.subjectId) > -1 &&
      filesList.length &&
      query?.type === "60"
    ) {
      if (examList[0]?.status >= 2) {
        //0未作答,2已完成，3补交，4系统提交
        settodoList(arr);
      } else {
        filesList.forEach((v, i) => {
          let arr = v.file.resourceUrl.split(".");
          let sufix = arr[arr.length - 1].toLowerCase();
          if (
            sufix === "mp3" ||
            sufix === "wma" ||
            sufix === "midi" ||
            sufix === "mpeg"
          ) {
            filesList.splice(i, 1);
            // delete filesList[i];
          }
        });
        let arr2 = [
          ...(obj?.examInfo || []),
          ...(filesList || []),
          ...(obj?.models ? [{ name: name, type: 0 }] : []),
        ];
        settodoList(arr2);
      }
    } else {
      settodoList(arr);
    }
    // 先确定是否有试题
    if (query.pid) {
      let index = arr.findIndex((v) => v.id === Number(query.pid));
      setactive(index || 0);
    } else {
      let index = arr.length - 1;
      arr.forEach((v, i) => {
        if (v.file && encodeURIComponent(v.file.name) === query.name) {
          index = i;
        }
      });
      nowInfo.current = arr[index];
      setactive(index);
    }
  }, [courseDetail]);// eslint-disable-line react-hooks/exhaustive-deps

  const btnClicked = () => {
    setIsShow(!isShow);
  };

  const chooseActive = (info, index) => {
    timerNum.current = 0;
    nowInfo.current = info;
    if (index === active) return;
    let key = info.type !== 0 && info.type !== 1 ? 2 : info.type;
    changeComponents({
      key: key,
      val: info,
      index: index,
    });
    // 更新试题包信息
    if (key === 2) {
      if (new Date().getTime() < info.answerPublishTime) {
        store.dispatch({
          type: "updateRightAnswerShowState",
          data: false,
        });
        info.notShowAnswerBtn = true;
      } else {
        store.dispatch({
          type: "updateRightAnswerShowState",
          data: true,
        });
        info.notShowAnswerBtn = false;
      }
      store.dispatch({ type: "updateCourseInfo", data: info });
    }
    setIsShow(!isShow);
    setactive(index);
    if (key === 1 || key === 0) {
      searchResource(info);
    }
  };

  const searchResource = (info) => {
    let key = info.type !== 0 && info.type !== 1 ? 2 : info.type;
    if (key === 2) return;
    let oldTime = todoList[active]?.useTime || 0;

    // 暂定为时间间隔为5秒 ，认定学生是产生了思考的,需要更新作答时间
    // 资源暂时去掉这个限制 || info.useTime === 0
    if ((oldTime || oldTime === 0) && timerNum.current - oldTime >= 5000) {
      // if (!todoList[active]?.file?.id) return;
      let query = getParams(window.location.href);

      addCourseAnswer({
        lessonStudentId: todoList[active]?.relationId || Number(query.id),
        // lessonStudentId: query.id,
        resourceId: todoList[active]?.file?.id,
        resourceType: key === 1 ? 1 : 3,
        usedTime: timerNum.current || 1000, // timerNum
      })
        .then((res) => {
          // timerNum.current = 0;
        })
        .catch(() => {
          // timerNum.current = 0;
        });
    }
  };

  return [
    {
      isShow,
      todoList,
      active,
    },
    {
      btnClicked,
      chooseActive,
    },
  ];
};

export default Presenter;
