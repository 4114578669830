import { message } from "antd"
import "./index.less"

const messageHint = (text) => {
    message["info"]({
        icon: (<span />),
        content: text,
        className: 'submitMessage-class',
        style: {
            marginTop: '40vh',
        },
        duration: 1.5
    })
}

export default messageHint
