import React, { useEffect, useRef, useState } from 'react';
import "./index.less"
import { SoundButton, millisecondToDate, PlayingButton, FullButton, ProgressBar, CredibilityKey, Loading } from "@/components/memorizeWords/controls"


const VideoPlayer = ({ url, ...props }) => {
    const videoRef = useRef(null)
    const [isPlaying, setIsPlaying] = useState(false)
    const [isMute, setIsMute] = useState(false)
    const [isFull, setisFull] = useState(false)
    const [totalTime, setTotalTime] = useState(0)
    const [currentTime, setCurrentTime] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsPlaying(false)
        setIsMute(false)
        setisFull(false)
        setTotalTime(0)
        setCurrentTime(0)
        setIsLoading(false)
    }, [url])

    const playingChange = (event) => {
        event.stopPropagation()
        document.querySelectorAll('video').forEach(el => {
            el.pause()
        });
        videoRef.current.play();
        setIsPlaying(true)
    }

    const pauseingChange = () => {
        if (isPlaying) {
            videoRef.current.pause();
            setIsPlaying(false)
        }
    }

    const handleMuteChange = (event) => {
        event.stopPropagation()
        setIsMute(!isMute)
        videoRef.current.muted = !isMute
    }

    const handleFullChange = (event) => {
        event.stopPropagation()
        if (videoRef.current.requestFullscreen) {
            videoRef.current.requestFullscreen();
        } else if (videoRef.current.mozRequestFullScreen) { // 兼容Firefox
            videoRef.current.mozRequestFullScreen();
        } else if (videoRef.current.webkitRequestFullscreen) { // 兼容Chrome、Safari和Opera
            videoRef.current.webkitRequestFullscreen();
        } else if (videoRef.current.msRequestFullscreen) { // 兼容IE和Edge
            videoRef.current.msRequestFullscreen();
        }
    }

    const handleSpeedChange = (speed) => {
        videoRef.current.playbackRate = speed;
    }

    const handleProgressChange = (currentTime) => {
        setCurrentTime(currentTime / 100 * totalTime)
        videoRef.current.currentTime = currentTime / 100 * totalTime
    }

    const handleEndedChange = () => {
        setCurrentTime(0)
        setIsPlaying(false)
        videoRef.current.pause()
        videoRef.current.currentTime = 0
    }


    return (
        <div className="videoplayer-wrapper" onDoubleClick={pauseingChange} key={url}>
            <video
                ref={videoRef}
                preload="auto"
                x5-playsinline="true"
                webkit-playsinline="true"
                x5-video-player-type="h5"
                x5-video-player-fullscreen="true"
                {...props}
                onLoadedMetadata={(e) => setTotalTime(e.target.duration)}
                onLoadedData={() => setTimeout(() => setIsLoading(true), 200)}
                onTimeUpdate={(e) => setCurrentTime(e.target.currentTime)}
                onEnded={handleEndedChange}
                onPause={() => setIsPlaying(false)}
            >
                <source src={url} type="video/mp4" />
                <source src={url} type="video/webm" />
            </video>
            {
                isLoading ?
                    <>
                        {!isPlaying && <PlayingButton isPlay={isPlaying} size="80" onClick={(e) => playingChange(e)} className="playingButton" />}
                        <div className="control">
                            <div className="progressBar">
                                <span className='realTime'>{millisecondToDate(currentTime)} / {millisecondToDate(totalTime)}</span>
                                <ProgressBar className="videoProgressBar" progress={Math.floor(currentTime / totalTime * 100) || 0} onChange={handleProgressChange} />
                            </div>
                            <CredibilityKey white onChange={handleSpeedChange} style={{ marginLeft: 'calc(15vh / 12)' }} />
                            <SoundButton isMute={isMute} onClick={(e) => handleMuteChange(e)} style={{ marginLeft: 'calc(15vh / 12)' }} />
                            <FullButton isFull={isFull} onClick={(e) => handleFullChange(e)} style={{ marginLeft: 'calc(15vh / 12)' }} />
                        </div>

                    </> :
                    <Loading />
            }
        </div >
    );
};

export default VideoPlayer;