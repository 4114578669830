/*
 * @Author: your name
 * @Date: 2021-08-09 11:13:48
 * @LastEditTime: 2021-08-13 14:13:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/mockData/quesitonList.js
 */
export const typeList = [
  {
    text: "题干有误",
    type: 1,
  },
  {
    text: "题干有歧义",
    type: 2,
  },
  {
    text: "题干缺失不全",
    type: 3,
  },
  {
    text: "题干展示错乱",
    type: 4,
  },
  {
    text: "答案错误",
    type: 5,
  },
  {
    text: "其他",
    type: 6,
  },
];
