import React from "react";
import "./index.less";

import PadHeader from "@/components/padHeader";
import HeadOperation from "./components/headOperation";
import ExamItem from "@/components/examItem";
import ResourcePreview from "@/components/resourcePreview";

import { getResourceTypeIndex } from "@/mockData/resource";
import InfiniteScroll from "react-infinite-scroller";

import Presenter from "./presenter.js";
import { Button, Carousel, Empty, Modal } from "antd";
import listPng from "@/assets/newSence/list.png";
import { dateFormat } from "@/util/util";

const PracticeReport = () => {
  const [states, events] = Presenter();
  const dom = document.querySelector("#root");

  const {
    isModalVisible,
    examList,
    activeType,
    examInfo,
    nowIndex,
    activeInfo,
  } = states;
  const {
    handleChangTime,
    changeSubject,
    handleCancel,
    getActiveType,
    onChange,
    switchExam,
    examine,
    collectExam,
    getSubjectName,
    handleInfiniteOnLoad,
    handleTypeList,
    changeInput,
  } = events;
  const getResourceTypeIndexs = getResourceTypeIndex;

  return (
    <div className="myCollect">
      {/* pad顶部 */}
      <PadHeader>
        <HeadOperation
          changeInput={changeInput}
          handleTypeList={handleTypeList}
          handleChangTime={handleChangTime}
          changeSubject={changeSubject}
          getActiveType={getActiveType}
        ></HeadOperation>
      </PadHeader>
      {/* 中间的卡片区域 */}
      <div className="card" id="bodyWrapper">
        {activeType === 2 ? (
          <div className="examCard">
            {examInfo ? (
              <Carousel beforeChange={onChange}>
                {(new Array(1).fill(1) || []).map((el, index) => (
                  <div className="itemGridWrapper" key={index}>
                    <ExamItem
                      isNotQuesition={true}
                      index={nowIndex}
                      examTypeId={examInfo?.exam?.examTypeId}
                      exam={examInfo}
                      isSubmit={true}
                      isCorrect={true}
                    ></ExamItem>
                  </div>
                ))}
              </Carousel>
            ) : (
              <div className="nodata">
                <Empty image={listPng} description="" />
              </div>
            )}
            {/* 切换按钮 */}
            <div className="switchExam">
              <div className="lastExam" onClick={() => switchExam(0)}>
                <i className="ri-arrow-left-line"></i>
              </div>
              <div className="nextExam" onClick={() => switchExam(1)}>
                <i className="ri-arrow-right-line"></i>
              </div>
            </div>
          </div>
        ) : examList.length > 0 ? (
          <div className="scrollWrapper">
            <InfiniteScroll
              initialLoad={false}
              pageStart={0}
              loadMore={handleInfiniteOnLoad}
              useWindow={false}
              hasMore={true}
            >
              {(examList || []).map((v, i) => (
                <div className="collectCard" key={i}>
                  <div className="collectCardLeft">
                    <img
                      className="typeIcon"
                      src={
                        require(`@/assets/fileIcons/icon${getResourceTypeIndexs(
                          v?.resource?.resourceUrl
                        )}.png`).default
                      }
                      alt="icon"
                    />
                    <div>
                      <div className="titleText">
                        {getSubjectName(v?.resource?.subjectId) ? (
                          <div className="iconText">
                            {getSubjectName(v?.resource?.subjectId)}
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="text"> {v.resource.name}</div>
                      </div>
                      <div className="subhead">{v.resource.chapterName}</div>
                    </div>
                  </div>
                  <div className="collectCardRight">
                    <div>
                      <div className="topText">分类</div>
                      <div className="bottomText">教案</div>
                    </div>
                    <div className="rightThree">
                      <div className="topText">收藏时间</div>
                      <div className="bottomText">
                        {dateFormat(v.resource.createTime, "yyyy-MM-dd hh:mm")}
                      </div>
                    </div>
                    <div>
                      <Button
                        className="rightTwo"
                        onClick={() => collectExam(v, i)}
                      >
                        {v.isCollectExam ? (
                          <div className="rightBtnGrid">
                            <i className="ri-heart-3-fill"></i>
                            <span>已收藏</span>
                          </div>
                        ) : (
                          <div className="rightBtnGrid">
                            <i className="ri-heart-3-line"></i>
                            <span>收藏</span>
                          </div>
                        )}
                      </Button>
                    </div>
                    <div>
                      <Button
                        className="rightOne"
                        onClick={() => {
                          examine(v);
                        }}
                      >
                        查看
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </InfiniteScroll>
          </div>
        ) : (
          <div className="nodata">
            <Empty image={listPng} description="" />
          </div>
        )}
      </div>
      <Modal
        className="ModalBox"
        title={activeInfo?.file?.name}
        visible={isModalVisible}
        onCancel={handleCancel}
        getContainer={dom}
        footer={null}
        width={"90vw"}
      >
        <ResourcePreview info={activeInfo}></ResourcePreview>
      </Modal>
    </div>
  );
};

export default PracticeReport;
