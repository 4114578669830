/*
 * @Author: your name
 * @Date: 2021-08-09 09:19:26
 * @LastEditTime: 2022-05-17 09:10:09
 * @LastEditors: slw 3289360550@qq.com
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/redux/reducers/errorExamState.js
 */
import { UPDATA_REWRITE_STATE } from "../constant";

const initState = false;
export default function rewriteStateReducer(preState = initState, action) {
  const { type, data } = action;
  switch (type) { 
    case UPDATA_REWRITE_STATE:
      return data;
    default:
      return preState;
  }
}
