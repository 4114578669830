import React from "react";
import "./index.less";
import Presenter from "./presenter.js";
import { Image } from "antd";
import { dateFormat } from "@/util/util";

const IndexPage = (props) => {
  const [states, events] = Presenter(props);

  const { toolPlate, indexData, showMore } = states;
  const { allTaskHandle, lookHandle, bindCard, getSubjectName, moreLearn } =
    events;
  // const getResourceTypeIndexs = getResourceTypeIndex;

  return (
    <div className="index-page-first-cont">
      {/* <div className="slogan-cont">
        <div className="slogan">
          <div className="text">
            认真学习、不断努力，<span className="color">成就最好的自己！</span>
          </div>
        </div>
        <div className="go-zhinang" onClick={() => allTaskHandle()}>
          <div className="text">
            <div className="title">我的收藏</div>
            <div className="info">查看我的资源、题库收藏</div>
          </div>
          <Image
            src={require("@/assets/index/logoYuan.png").default}
            alt="logo"
            // style="width: 32px; height: 32px"
          />
        </div>
      </div> */}
      <div className="tools-body-cont">
        <div className="learn-cont">
          <div className="tools">
            <div className="title">学习工具</div>
            {toolPlate.map((item, index) => (
              <div
                className="item"
                onClick={() => lookHandle(item)}
                key={index}
              >
                <Image src={item.img} />
                <div className="text">
                  <div className="name">{item.name}</div>
                  <div className="info">{item.info}</div>
                </div>
                <div className="look">查看</div>
              </div>
            ))}
          </div>
          <div className="learn">
            <div className="item-cont">
              <div className="title">
                <span className="name">
                  全部任务
                  <span className="on-finish"></span>
                </span>
                <span className="all">{/* 全部任务 &gt; */}</span>
              </div>
              <div>
                {indexData.map((item, index) => (
                  <div
                    className="item"
                    onClick={() => bindCard(item)}
                    key={index}
                  >
                    <span className="subjectName">
                      {getSubjectName(item.subjectName)}
                    </span>
                    <div className="text">
                      {item.resourceType === 5 && item?.ext > 1 ? (
                        <>
                          <div className="name">
                            {item.lessonTeacherName} - 第{item.ext - 1}次复习
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="name">{item.lessonTeacherName}</div>
                        </>
                      )}

                      <div className="info">{item.chapterName}</div>
                    </div>
                    <span className="time">{dateFormat(item.createTime)}</span>
                  </div>
                ))}
                {showMore ? (
                  <div className="more_learn" onClick={() => moreLearn()}>
                    加载更多
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndexPage;
