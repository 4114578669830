import React, { useState } from 'react';
import { message } from "antd"

const messageHint = (text) => {
    message["info"]({
        icon: (<span />),
        content: text,
        className: 'submitMessage-class',
        style: {
            marginTop: '40vh',
        },
        duration: 1.5
    })
}


const Presenter = (props) => {
    const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
    const { onChangeSubmit, mistakesType } = props
    const [verify, setVerify] = useState("")


    // 提交作答
    const onSubmitRespond = () => {

        if ([1, 2, 3, 20].includes(mistakesType)) {
            submitSuccessfully()
        } else {
            setIsSubmitModalOpen(true)
        }
    }

    // 关闭提交 继续作答
    const clouseSubmit = () => {
        setIsSubmitModalOpen(false)
    }

    // 提交完成
    const submitSuccessfully = () => {
        setIsSubmitModalOpen(false)
        onChangeSubmit()
    }

    // 下一题
    const onChangeNext = (messageHint) => {
        setVerify("")
        props.onChangeNext(messageHint)
    }

    // 判断正误
    const onChangeJudge = (verify) => {
        setVerify(verify)
        props.onChangeJudge(verify)
    }


    return [
        { isSubmitModalOpen, verify },
        { onSubmitRespond, clouseSubmit, submitSuccessfully, messageHint, onChangeJudge, onChangeNext }
    ]
}

export default Presenter