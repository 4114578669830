import "./index.less"

function TopRightBox({ children, className, ...props }) {

    return (
        <div className={`topCardComponent ${className || ''}`} {...props}>
            {children}
        </div>
    )
}

export default TopRightBox