/*
 * @Author: your name
 * @Date: 2021-08-09 09:35:02
 * @LastEditTime: 2022-04-27 18:20:55
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/pages/listQuestionSheet/presenter.js
 */

import { useState, useEffect } from "react";

import { listQuestionSheet } from "@/api/answer";

import { getParams } from "@/util/util";
import { typeList } from "@/mockData/quesitonList";
import pubsub from "@/util/pubSub";

import store from "@/redux/store";

const Presenter = () => {
  const [examList, setexamList] = useState([]);
  const [subjectId, setSubjectId] = useState("");
  const [typrId, setTyprId] = useState("");
  const [switchDatd, setSwitchDatd] = useState(false);

  useEffect(() => {
    let pageObj = getParams(window.location.href);
    if (pageObj.lessonTeacherId) {
      pubsub.subscribe("submitBack", () => {
        window.location.hash = `#/answer?token=${pageObj.token}&pid=${pageObj.id}&id=${pageObj.id}&studentId=${pageObj.studentId}&lessonTeacherId=${pageObj.lessonTeacherId}&type=${pageObj.type}&name=${pageObj.name}&levelId=${pageObj.levelId}&gradeId=${pageObj.gradeId}&schoolId=${pageObj.schoolId}`;
      });
    }

    return () => {
      pubsub.unsubscribe("submitBack");
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // 只看已回复
  const changeSwitch = (val) => {
    setSwitchDatd(val);
  };
  // 学科下拉
  const changeSubject = (val) => {
    setSubjectId(val);
  };
  // type下拉
  const changeType = (val) => {
    setTyprId(val);
  };

  // 接口请求
  const getListQuestionSheet = () => {
    let obj = getParams(window.location.href);
    let info = store.getState().courseInfo;
    listQuestionSheet({
      studentId: obj.studentId,
      publishLogId: obj.publishLogId,
      subjectId: info.subjectId || subjectId,
      hasReply: switchDatd, // 只看已回复
      type: typrId,
      pageSize: 10,
      // lastAskTime: 0,
    }).then((res) => {
      res.forEach((v) => {
        v.resource = v.resourceDto;

        // 综合题处理
        let copyExamAnswer = JSON.parse(
          v?.resourceDto?.exam?.childrenAnswer || "[]"
        );

        v?.resourceDto?.exam?.children?.forEach((vv, index) => {
          vv.resource = {};
          vv.resource.exam = {};
          vv.resource.exam = vv;
          vv.resource.exam.answer = JSON.stringify(copyExamAnswer[index]) || "";
        });

        v.questionSheet.askType = getType(v?.questionSheet?.askType);
        v?.questionSheet?.ask?.forEach((element) => {
          if (element.type === 2) {
            element.content = JSON.parse(element.content);
          }
        });

        v?.questionSheet?.reply?.forEach((element) => {
          if (element.type === 2) {
            try {
              element.content = JSON.parse(element.content);
            } catch (error) {
              element.content = [];
            }
          }
        });
      });
      setexamList(res);
    });
  };

  useEffect(() => {
    getListQuestionSheet();
  }, [switchDatd, typrId, subjectId]); // eslint-disable-line react-hooks/exhaustive-deps

  const getType = (type) => {
    return typeList.find((v) => v.type === type)?.text;
  };

  return [{ examList }, { changeSubject, changeType, changeSwitch }];
};

export default Presenter;
