/*
 * @Author: your name
 * @Date: 2021-08-12 14:15:46
 * @LastEditTime: 2022-04-27 18:20:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/pages/errorBook/presenter.js
 */

import { useState, useEffect, useRef } from "react";
import { errorBookList, createErrors, errorListAll } from "@/api/errorBook";
import { getParams, dateFormat } from "@/util/util";
import mathJax from "@/util/globalVariable";
import { message } from "antd";
const Presenter = () => {
  const [active, setactive] = useState(0);

  const [examInfo, setexamInfo] = useState(null);
  const [examList, setexamList] = useState([]);
  const [nowIndex, setnowIndex] = useState(0);
  const [todoList, settodoList] = useState([]);
  const [subjectId, setsubjectId] = useState(0);
  const [subjectName, setsubjectName] = useState("");
  const [isModal, setisModal] = useState(false);
  const [examTemplateId, setexamTemplateId] = useState(undefined);
  const [timeFilter, settimeFilter] = useState({});
  const [isDefault, setisDefault] = useState(false);

  const modalRef = useRef(null);

  const subjectSelectRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      mathJax();
    }, 0);
  }, [examInfo]);

  const [generatorErrorList, setgeneratorErrorList] = useState([
    {
      desc: "全部时间", // startTime endTime
      val: -1,
      list: [
        {
          val: () => new Date().getTime() - 1 * 24 * 60 * 60 * 1000,
          desc: "一天内",
        },
        {
          val: () => new Date().getTime() - 2 * 24 * 60 * 60 * 1000,
          desc: "两天内",
        },
        {
          val: () => new Date().getTime() - 3 * 24 * 60 * 60 * 1000,
          desc: "三天内",
        },
        {
          val: () => new Date().getTime() - 7 * 24 * 60 * 60 * 1000,
          desc: "近一周",
        },
        {
          val: () => new Date().getTime() - 30 * 24 * 60 * 60 * 1000,
          desc: "近一月",
        },
        {
          val: () => new Date().getTime() - 30 * 3 * 24 * 60 * 60 * 1000,
          desc: "近三月",
        },
      ],
    },
    {
      desc: "生成顺序", // type
      val: -1,
      list: [
        {
          val: 0,
          desc: "随机",
        },
        {
          val: 1,
          desc: "顺序",
        },
      ],
    },
  ]);

  useEffect(() => {
    if (active === 0) {
      getList(true);
    } else {
      getqueryHomeworkList(true);
    }
  }, [timeFilter, subjectId, examTemplateId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let pageObj = getParams(window.location.href);
    if (pageObj.subjectId) {
      subjectSelectRef?.current?.changeSubject(Number(pageObj.subjectId));
    } else {
      setisDefault(true);
    }
  }, []);

  useEffect(() => {
    setexamInfo(examList[nowIndex]);
    if (nowIndex > examList.length - 4) {
      getList();
    }
  }, [nowIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  const goAnswer = (v) => {
    let pageObj = getParams(window.location.href);
    window.location.hash = `#/answer?token=${pageObj.token}&id=${v.id}&studentId=${pageObj.studentId}&lessonTeacherId=${v.lessonTeacherId}&type=40&name=${v.lessonTeacherName}&levelId=${pageObj.levelId}&gradeId=${pageObj.gradeId}`;
  };

  const getList = (isInit) => {
    if (isInit) {
      setnowIndex(0);
    }
    if (!subjectId) return;
    let pageObj = getParams(window.location.href);

    errorListAll({
      studentId: pageObj.studentId,
      pageSize: 10,
      subjectId: subjectId,
      examTemplateId: examTemplateId,
      lastId: isInit ? 0 : examList[examList.length - 1]?.id,
      startTime: timeFilter.startTime,
      endTime: timeFilter.endTime,
    }).then((res) => {
      res.forEach((element) => {
        element.exam = JSON.parse(element.examText || "{}");

        // 综合题处理
        let copyExamAnswer = JSON.parse(element.exam.childrenAnswer || "[]");

        element?.exam?.children?.forEach((vv, index) => {
          vv.resource = {};
          vv.resource.exam = {};
          vv.resource.exam = vv;
          vv.resource.exam.answer = JSON.stringify(copyExamAnswer[index]) || "";
        });
      });
      res = res.filter((v) => v.exam.examStem);
      res.forEach((element) => {
        element.resource = {};
        element.resource.exam = element.exam;
      });
      if (isInit) {
        setexamList([...res]);
        setexamInfo(res[isInit ? 0 : nowIndex]);
      } else {
        setexamList([...examList, ...res]);
      }
    });
  };

  const getqueryHomeworkList = (isInit) => {
    let pageObj = getParams(window.location.href);
    errorBookList({
      size: 10,
      types: "40,50",
      studentId: pageObj.studentId,
      subjectId: subjectId,
      lastMaxId: todoList[todoList.length - 1]
        ? todoList[todoList.length - 1].id
        : 0,
      // startTime: timeFilter.startTime,
      // endTime: timeFilter.endTime,
    }).then((res) => {
      if (isInit) {
        settodoList([...res]);
      } else {
        settodoList([...todoList, ...res]);
      }
    });
  };

  const switchExam = (type) => {
    if (type === 0) {
      let i = nowIndex - 1;
      if (i < 0) {
        return;
      }
      setnowIndex(i);
    } else {
      let i = nowIndex + 1;
      if (i === examList.length) {
        return;
      }
      setnowIndex(i);
    }
  };

  const onChange = (flag) => {
    setnowIndex(flag);
  };

  const changeSubject = (val, name) => {
    let pageObj = getParams(window.location.href);
    setsubjectName(name || decodeURIComponent(pageObj.subjectName));
    setsubjectId(val);
  };

  const openModal = () => {
    setisModal(true);
  };

  const changeActive = (a) => {
    setactive(a);
    if (a === 1) {
      getqueryHomeworkList();
    } else {
      getList();
    }
  };

  const handleCancel = () => {
    setisModal(false);
  };

  const changemodalActive = (i, ii) => {
    let arr = [...generatorErrorList];
    arr[i].val = ii;
    setgeneratorErrorList(arr);
  };

  const handleChangTime = (arr) => {
    if (arr) {
      settimeFilter({
        startTime: new Date(arr[0]._d).getTime(),
        endTime: new Date(arr[1]._d).getTime(),
      });
    } else {
      settimeFilter({
        startTime: "",
        endTime: "",
      });
    }
  };

  const handleOk = () => {
    let pageObj = getParams(window.location.href);
    if (generatorErrorList[1].val === -1) {
      message.error("请选择生成顺序");
      return;
    }
    if (!generatorErrorList[0]?.list[generatorErrorList[0]?.val]?.val()) {
      message.error("请选择时间范围");
      return;
    }

    createErrors({
      type: generatorErrorList[1].val,
      startTime: generatorErrorList[0]?.list[generatorErrorList[0]?.val]?.val(),
      endTime: new Date().getTime(),
      studentId: pageObj.studentId,
      studentName: decodeURIComponent(pageObj.studentName),
      subjectId: subjectId,
      subjectName: subjectName,
      classId: pageObj.classId,
      className: decodeURIComponent(pageObj.className),
    })
      .then((res) => {
        let v = res;
        window.location.hash = `#/answer?token=${pageObj.token}&id=${
          v || 98306
        }&studentId=${
          pageObj.studentId
        }&lessonTeacherId=${v}&type=40&name=${dateFormat(
          new Date().getTime(),
          "yyyy-MM-dd hh:mm"
        )}${subjectName}错题练习&levelId=${pageObj.levelId}&gradeId=${
          pageObj.gradeId
        }`;
      })
      .catch((res) => {});
  };

  const changeExamTemplateId = (val) => {
    setexamTemplateId(val);
  };

  const handleInfiniteOnLoad = () => {
    getqueryHomeworkList();
  };

  return [
    {
      active,
      modalRef,
      examInfo,
      nowIndex,
      todoList,
      isModal,
      generatorErrorList,
      subjectSelectRef,
      isDefault,
    },
    {
      onChange,
      handleOk,
      switchExam,
      changeActive,
      goAnswer,
      changeSubject,
      openModal,
      changemodalActive,
      handleCancel,
      handleChangTime,
      changeExamTemplateId,
      handleInfiniteOnLoad,
    },
  ];
};

export default Presenter;
