/*
 * @Author: your name
 * @Date: 2021-07-21 14:44:04
 * @LastEditTime: 2022-09-16 17:12:01
 * @LastEditors: c1107 chen1997
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/pages/studentAnswer/presenter.js
 */

import React from "react";

import { useState, useEffect } from "react";
import { addCourseAnswer } from "@/api/answer";
import { getParams } from "@/util/util";
import store from "@/redux/store";
import { getEduselflearn } from "@/api/autonomyPractice";

const Presenter = () => {
  const [courseDetail, setCourseDetail] = useState(null);
  const name =
    decodeURIComponent(getParams(window.location.href).name) || "课程名称";
  const [activeInfo, setActiveInfo] = useState({
    key: 0,
    val: {},
    index: 0,
  }); // 当前选中得失哪一类：导学案：0，资源：1，试题包：2

  const [weekEnFiles, setWeekEnFiles] = useState({}); //周清听力
  const urlObj = getParams(window.location.href);

  useEffect(() => {
    getDetail();
    return () => {
      store.dispatch({
        type: "updateCourseInfo",
        data: {},
      });
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getDetail = () => {
    let data =
      {
        lessonStudentId: urlObj?.id,
        id: urlObj?.id,
        levelId: urlObj.levelId,
        name: urlObj.name,
        studentId: urlObj?.studentId,
        countExam: urlObj?.seq,
        correctState: 1,
        status: 3,
        isCheck: true,
      } || {};

    // store.dispatch({
    //   type: "updateRightAnswerShowState",
    //   data: true,
    // });
    setCourseDetail(data);

    store.dispatch({
      type: "updateCourseInfo",
      data: data,
    });

    //   setDefault(res);
  };

  const changeComponents = (obj) => {
    setActiveInfo(obj);
  };

  return [
    {
      courseDetail,
      activeInfo,
      name,
      weekEnFiles,
    },
    {
      changeComponents,
    },
  ];
};

export default Presenter;
