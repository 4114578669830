/*
 * @Author: your name
 * @Date: 2021-07-21 14:44:04
 * @LastEditTime: 2023-02-24 16:25:52
 * @LastEditors: c1107 chen1997
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/pages/myCollect/presenter.js
 */

import {
  getBaseSubjectListAll,
  collectionAdd,
  collectionDel,
  newMyCollectList,
  myDatabaseList,
  getDataBankList,
  getDatabankSubjectList
} from "@/api/answer";
import { useState, useEffect, useRef } from "react";
import { getParams } from "@/util/util";
import store from "@/redux/store";
import mathJax from "@/util/globalVariable";
import { message } from "antd";
import { volumeList } from "./data";
import messageHint from "@/pages/mistakesRearrange/compoents/messageHint"
import { useLocation } from "react-router-dom";




const Presenter = () => {
  const location = useLocation()
  const [examList, setExamList] = useState([]);
  const [activeType, setActiveType] = useState(1);
  const [subjectId, setSubjectId] = useState(0);
  const [examInfo, setexamInfo] = useState(null);
  const [nowIndex, setnowIndex] = useState(0);
  const [activeInfo, setActiveInfo] = useState({});
  const [isModalVisible, setisModalVisible] = useState(false);
  const [volumeListGiaoList, setVolumeListGiaoList] = useState({});
  const [checkVal, setCheckVal] = useState(false);
  const [subjectListData, setSubjectListData] = useState([]);
  const [activeSubject, setActiveSubject] = useState("");
  const [whereGrade, setWhereGrade] = useState(null);
  const [term, setTerm] = useState(1); //1上2下
  const [subjectNameList, setSubjectNameList] = useState([]);
  const [subjectNameActive, setSubjectNameActive] = useState(0);
  const [searchExamValue, setSearchExamValue] = useState('');
  const [examTestPaperList, setExamTestPaperList] = useState([]);
  const [subjectNameId, setSubjectNameId] = useState(-1);
  const [subjectNameLastId, setSubjectNameLastId] = useState(0);

  const timeFilter = useRef({});
  const lastIdNum = useRef(0);
  const subjectList = useRef([]);
  const subjectActiveId = useRef(0);
  const activetype = useRef(1);
  const timer = useRef(null);
  const examListRef = useRef([]);
  const keyWord = useRef("");
  const termVal = useRef(1);
  const whereGradeVal = useRef(null);
  const collected = useRef(0);
  const examDataListRef = useRef(null)

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const tabParam = searchParams.get('tab')
    if (tabParam) {
      activetype.current = Number(tabParam);
      setActiveType(Number(tabParam));
    }

    let obj = getParams(window.location.href);
    let month = doHandleMonth();
    if (month >= 2 && month < 9) {
      setTerm(2);
      termVal.current = 2;
    } else if (month >= 9 && month < 2) {
      setTerm(1);
      termVal.current = 1;
    }
    setWhereGrade(Number(obj.gradeId));
    whereGradeVal.current = obj.gradeId;
    setVolumeListGiaoList(volumeList[Number(obj.gradeId)] || {});
    getBaseSubjectListAll({
      levelId: obj.levelId,
    }).then((res) => {
      subjectList.current = res;
      setSubjectList(res);
      setActiveSubject(obj.subjectId || res[0].id);
      subjectActiveId.current = obj.subjectId || res[0].id;
      setTimeout(() => {
        getMyDatabaseList();
      }, 0);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTimeout(() => {
      mathJax();
    }, 0);
  }, [examInfo]);

  useEffect(() => {

    if (subjectId !== 0) {
      if (activeType === 2) {
        getMyCollectList(activeType, subjectId, true, undefined);
      } else {
        getMyDatabaseList();
      }
    }

  }, [activeType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (examList.length > 0) {
      store.dispatch({ type: "updateExam", data: examList[nowIndex] });
      setexamInfo(examList[nowIndex]);
    }
  }, [nowIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (activeType === 3) {
      getSubjectNameList()
    }
  }, [activeType]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (activeType === 3 && subjectNameLastId !== -1) {
      getTestPaperList()
    }
  }, [subjectNameId, searchExamValue]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (activeType === 3 && subjectNameLastId !== -1) {
      getTestPaperList('add')
    }
  }, [subjectNameLastId]) // eslint-disable-line react-hooks/exhaustive-deps

  const setSubjectList = (list) => {
    let subList = [];
    list.forEach((v) => {
      switch (v.name) {
        case "语文":
          subList.push(v);
          break;
        case "数学":
          subList.push(v);
          break;
        case "英语":
          subList.push(v);
          break;
        case "物理":
          subList.push(v);
          break;
        case "化学":
          subList.push(v);
          break;
        case "生物":
          subList.push(v);
          break;
        case "历史":
          subList.push(v);
          break;
        case "地理":
          subList.push(v);
          break;
        case "道德与法治":
          subList.push(v);
          break;
        case "思想政治":
          subList.push(v);
          break;
        case "政治":
          subList.push(v);
          break;
        case "日语":
          subList.push(v);
          break;
        case "俄语":
          subList.push(v);
          break;
        default:
          break;
      }
    });
    setSubjectListData(subList);
  };

  const getSubjectName = (id) => {
    let obj = (subjectList.current || []).find(
      (v) => Number(v.id) === Number(id)
    );
    let name = obj?.name;
    if (name) {
      return name.substr(0, 1);
    }
    return "";
  };

  // 时间
  const handleChangTime = (time) => {
    setnowIndex(0);
    setexamInfo(null);
    setExamList([]);
    examListRef.current = [];
    lastIdNum.current = 0;
    if (time) {
      timeFilter.current = {
        startTime: new Date(
          new Date(time[0]._d).toLocaleDateString()
        ).getTime(), // new Date(time[0]._d).getTime(),
        endTime:
          new Date(new Date(time[1]._d).toLocaleDateString()).getTime() +
          24 * 60 * 60 * 1000 -
          1, // new Date(time[1]._d).getTime(),
      };
    } else {
      timeFilter.current = {
        startTime: "",
        endTime: "",
      };
    }
    getMyCollectList(undefined, undefined, true, undefined);
  };
  // 学科下拉
  const changeSubject = (val) => {
    setnowIndex(0);
    subjectActiveId.current = val;
    setexamInfo(null);
    setExamList([]);
    examListRef.current = [];
    lastIdNum.current = 0;
    setSubjectId(val);
    setActiveSubject(val);
    getMyCollectList(activeType, val);
  };
  // Type
  const getActiveType = (type) => {
    keyWord.current = "";
    if (activetype.current !== Number(type)) {
      setexamInfo(null);
      setExamList([]);
      examListRef.current = [];
      lastIdNum.current = 0;
      activetype.current = type;
      setActiveType(type);
    }
  };
  // 资源类型
  const handleTypeList = (v) => { };
  const changeInput = (val) => {
    setexamInfo(null);
    setExamList([]);
    examListRef.current = [];
    lastIdNum.current = 0;
    keyWord.current = val;
    getMyDatabaseList();
    // getMyCollectList();
  };

  // 资源收藏
  const collectExam = (v, i) => {
    console.log(v);

    if (v.collected) {
      // 新资源取消收藏
      collectionDel({
        studentId: v.studentId, //207975
        subjectId: v.subjectId, //store.getState().courseInfo.subjectId,
        type: 1,
        examId: v.resource.id,
      }).then((res) => {
        let arr = [...examList];
        arr[i].collected = 0;
        setExamList(arr);
        examListRef.current = arr;
      });
    } else {
      //  新资源收藏
      collectionAdd({
        studentId: v.studentId,
        subjectId: v.subjectId,
        type: 1,
        examId: v.resource.id,
        chapterId: v.resource?.chapterId, //章节id
        // resourceType: v.resource?.resourceSuffix, //资源类型
        resourceName: v.resource?.name, //资源名称
      }).then((res) => {
        let arr = [...examList];
        // arr[i].isCollectExam = !arr[i].isCollectExam;
        arr[i].collected = 1;
        setExamList(arr);
        examListRef.current = arr;
      });
    }
  };

  // 查看
  const examine = (v) => {
    let obj = { file: v.resource, collection: v.isCollectExam };
    setActiveInfo(obj);
    setisModalVisible(true);
  };

  const getMyCollectList = (type, sub, isZore) => {
    let obj = getParams(window.location.href);
    newMyCollectList({
      type: type !== undefined ? type : activetype.current,
      startTime: timeFilter.current.startTime,
      endTime: timeFilter.current.endTime,
      subjectId: sub !== undefined ? sub : subjectActiveId.current, //subjectId,
      studentId: obj.studentId,
      lastId: lastIdNum.current,
      keyWord: activetype.current === 1 ? keyWord.current : undefined,
    }).then((res) => {
      // if (activetype.current === 2) {
      // 试题收藏
      if (res.length !== 0) {
        res.forEach((element) => {
          if (element.exam) element.exam = element.resourceDto.exam;
          element.collection = true;
          element.resource = {};
          if (element.resourceDto.exam) element.resource.exam = element.resourceDto.exam;
          // 综合题处理
          let copyExamAnswer = JSON.parse(
            element.resourceDto?.exam?.childrenAnswer || "[]"
          );
          element?.resourceDto?.exam?.children?.forEach((vv, index) => {
            vv.resource = {};
            vv.resource.exam = {};
            vv.resource.exam = vv;
            vv.resource.exam.answer =
              JSON.stringify(copyExamAnswer[index]) || "";
          });
        });
        // 第一次赋值
        if (examList.length >= 0 || isZore) {
          setexamInfo(res[0]);
        }
        if (isZore) {
          setExamList([...res]);
          examListRef.current = [...res];
        } else {
          setExamList([...examListRef.current, ...res]);
          examListRef.current = [...examListRef.current, ...res];
        }
      }
      // }
      // else {
      //   // 资源收藏
      //   if (res?.length > 0) {
      //     (res || []).forEach((element) => {
      //       element.isCollectExam = true;
      //       element.resource = element.resourceDto.file;
      //     });
      //     if (isZore) {
      //       examListRef.current = [...res];
      //       setExamList([...res]);
      //     } else {
      //       setExamList([...examListRef.current, ...res]);
      //       examListRef.current = [...examListRef.current, ...res];
      //     }
      //   }
      // }
    });
  };

  const getMyDatabaseList = (isZore) => {
    let obj = getParams(window.location.href);
    console.log(subjectActiveId.current);
    myDatabaseList({
      studentId: obj.studentId,
      subjectId: subjectActiveId.current,
      type: termVal.current,
      grade: obj.gradeId,
      whereGrade: whereGradeVal.current,
      lastId: lastIdNum.current,
      keyWord: keyWord.current,
      size: 10,
      collected: collected.current, //0是未收藏 1 是已收藏
    }).then((res) => {
      if (res?.length > 0) {
        (res || []).forEach((element) => {
          element.isCollectExam = true;
          element.resource = element.resource.file;
        });
        if (isZore) {
          examListRef.current = [...res];
          setExamList([...res]);
        } else {
          setExamList([...examListRef.current, ...res]);
          examListRef.current = [...examListRef.current, ...res];
        }
      }
    });
  };

  const handleInfiniteOnLoad = () => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      lastIdNum.current = examList[examList.length - 1].id;
      // getMyCollectList();
      getMyDatabaseList();
    }, 50);
  };

  const handleCancel = () => {
    closemedial();
    setisModalVisible(false);
  };

  const doHandleMonth = () => {
    var myDate = new Date();
    var tMonth = myDate.getMonth();
    var m = tMonth + 1;
    return m;
  };

  const handleCheck = (val) => {
    collected.current = val ? 1 : 0;
    setCheckVal(val);
    setExamList([]);
    examListRef.current = [];
    lastIdNum.current = 0;
    getMyDatabaseList();
  };
  const handleSubject = (val) => {
    setActiveSubject(val.id);
    subjectActiveId.current = val.id;
    setExamList([]);
    examListRef.current = [];
    lastIdNum.current = 0;
    getMyDatabaseList();
  };

  const handleVolume = (val) => {
    setTerm(val.type);
    termVal.current = val.type;
    setWhereGrade(val.id);
    whereGradeVal.current = val.id;
    setExamList([]);
    examListRef.current = [];
    lastIdNum.current = 0;
    getMyDatabaseList();
  };

  const closemedial = () => {
    let mp3 = document.querySelectorAll("audio") || [];
    let mp4 = document.querySelectorAll("video") || [];
    let doms = [...mp3, ...mp4];
    for (let index = 0; index < doms.length; index++) {
      doms[index].pause();
    }
  };

  // 试题上一题下一题
  const switchExam = (val) => {
    if (val === 0) {
      setnowIndex(nowIndex - 1 >= 0 ? nowIndex - 1 : 0);
    } else {
      if (nowIndex === examList.length - 2) {
        // if (nowIndex >= examList.length - 4) {
        lastIdNum.current = examList[examList.length - 1].id;
        getMyCollectList();
      }
      if (nowIndex === examList.length - 1 && nowIndex > 0) {
        message.warning("没有更多了");
      }
      // 提前判断好是否需要加载更多
      // if (nowIndex >= examList.length - 3 && examList.length >= 10) {
      //   getMyCollectList();
      // }
      setnowIndex(
        nowIndex + 1 < examList.length ? nowIndex + 1 : examList.length - 1
      );
    }
  };

  const getSubjectNameList = async () => {
    let obj = getParams(window.location.href);
    let subjectNameListResult = await getDatabankSubjectList({
      studentId: obj.studentId
    })
    setSubjectNameList(subjectNameListResult || [])
    setSubjectNameId(subjectNameListResult[0].subjectId)
  }

  const getTestPaperList = async (type) => {
    let obj = getParams(window.location.href);
    // subjectId   studentId  name  lastId  size
    let examTestPaperListResult = await getDataBankList({
      subjectId: subjectNameId,
      studentId: obj.studentId,
      name: searchExamValue,
      size: 12,
      lastId: type ? subjectNameLastId : 0
    })

    if (type === 'add') {
      setExamTestPaperList([...examTestPaperList, ...(examTestPaperListResult || [])])
      if (!examTestPaperListResult) messageHint('没有更多数据了~')
    } else {
      console.log('学科切换');
      setExamTestPaperList(examTestPaperListResult || [])
    }
  }

  // 我的试卷  学科切换
  const subjectNameBtnChange = (index, id) => {
    examDataListRef.current.scrollTop = 0
    setSubjectNameActive(index)
    setSubjectNameId(id)
  }

  const testPaperListScrollBottom = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target
    if (scrollTop + clientHeight === scrollHeight) {
      setSubjectNameLastId(examTestPaperList[examTestPaperList.length - 1]['teacherDataBankClassId'])
    }
  }

  const searchValueChange = (val) => {
    setSearchExamValue(val)
  }

  return [
    {
      examList,
      activeType,
      examInfo,
      nowIndex,
      activeInfo,
      isModalVisible,
      volumeListGiaoList,
      checkVal,
      subjectList,
      subjectListData,
      activeSubject,
      term,
      whereGrade,
      subjectNameList,
      subjectNameActive,
      examTestPaperList,
      subjectNameId,
      examDataListRef
    },
    {
      getActiveType,
      handleChangTime,
      changeSubject,
      switchExam,
      examine,
      collectExam,
      handleCancel,
      getSubjectName,
      handleInfiniteOnLoad,
      handleTypeList,
      changeInput,
      handleCheck,
      handleSubject,
      handleVolume,
      subjectNameBtnChange,
      testPaperListScrollBottom,
      searchValueChange
    },
  ];
};

export default Presenter;
