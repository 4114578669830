import "./index.less"
import PadHeader from "@/components/padHeader";
import Presenter from "./presenter.js";
import ScrollView from "@/components/memorizeWords/scrollView"
import { getParams, dateFormat } from "@/util/util"
import { useHistory } from 'react-router-dom'

function WordsChapterExercisePage(props) {
    const history = useHistory()
    const query = getParams(window.location.href);
    const [states, events] = Presenter(props)

    const { volumesList, volumeActive, chapterList, volumeListRef, listScrollTop } = states
    const { changeVolumeActive } = events

    return (
        <div className="WordsChapterExercisePage">
            <PadHeader>
                <div className="pageHeaderBar">
                    <div className="title">章节同步练习</div>
                </div>
            </PadHeader>
            <div className="substanceBox">
                <div className="gradeBar" ref={volumeListRef}>
                    <div className="warp" >
                        {
                            volumesList.map((v, i) =>
                                <span className={`v ${volumeActive === i ? 'v-current' : ''} ${v?.hot ? 'hot' : ''}`} onClick={() => changeVolumeActive(i)} key={i}>
                                    {v.volumeName}
                                </span>
                            )
                        }
                    </div>
                </div>
                <ScrollView height="calc(100vh - (244vh / 12))" className="en-unit-list" scrollTop={listScrollTop}>
                    {
                        (chapterList || []).map((v, i) =>
                            <div className={`en-unit-card ${Number(query.chapterId) === v.chapterId ? 'current-Study-unit' : ''}`} key={v.chapterId}>
                                <div className="title font">{v.chapterName}</div>
                                <div className="piece">
                                    <span className="sub-font">学习进度</span>
                                    <span className="font"><span className="font-Active">{v.wordCount}</span>/{v.wordTotalCount}</span>
                                </div>
                                <div className="piece">
                                    <span className="sub-font">最近学习时间</span>
                                    <span className="font">{Number(v.maxDay) ? dateFormat(new Date(Number(v.maxDay)), "yyyy-MM-dd") : '--'}</span>
                                </div>
                                <span>
                                    {
                                        v.wordCount === 0 ? <div className="inlet-btn"
                                            onClick={() =>
                                                history.push(`/studyWordsWay?studentId=${query.studentId}&studentName=${query.studentName}&gradeId=${query.gradeId}&classId=${query.classId}&schoolId=${query.schoolId}&type=${1}&chapterId=${v.chapterId}&chapterName=${encodeURIComponent(v.chapterName)}&total=${v.wordTotalCount}`)
                                            }
                                        >记单词</div> :
                                            v.wordCount === v.wordTotalCount ? <div className="inlet-btn inlet-btn-deputy"
                                                onClick={() =>
                                                    history.push(`/studyWordsWay?studentId=${query.studentId}&studentName=${query.studentName}&gradeId=${query.gradeId}&classId=${query.classId}&schoolId=${query.schoolId}&chapterId=${v.chapterId}`)
                                                }
                                            >查看记录</div> :
                                                v.wordCount !== 0 ? <div className={`inlet-btn ${Number(query.chapterId) === v.chapterId ? 'inlet-btn-active' : 0}`}
                                                    onClick={() =>
                                                        history.push(`/studyWordsWay?studentId=${query.studentId}&studentName=${query.studentName}&gradeId=${query.gradeId}&classId=${query.classId}&schoolId=${query.schoolId}&type=${1}&chapterId=${v.chapterId}&chapterName=${encodeURIComponent(v.chapterName)}&total=${v.wordTotalCount}`)
                                                    }
                                                >继续学习</div> : null
                                    }
                                </span>
                            </div>
                        )
                    }
                </ScrollView>
            </div>
        </div>
    )
}

export default WordsChapterExercisePage