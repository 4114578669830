/*
 * @Author: your name
 * @Date: 2021-08-11 09:47:55
 * @LastEditTime: 2021-08-11 20:05:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \znxt-student2.0\src\api\cooperativeInquiry.js
 */
import request from '@/util/request';

// 合作探究-详情
export const cooperativeInquiry = (params) => {
  return request({
    url: '/class/v2/student/groupTask/detail',
    method: 'get',
    params,
  });
};

// 学生上传
export function upDataImgs(data) {
  return request({
    url: '/class/v2/student/groupTask/uploadResult',
    method: 'post',
    data,
    meta: {
      isSerialize: true,
    },
  });
}

// 全部人员;未分组;小组; 学生分页列表
export function allStudents(data) {
  return request({
    url: '/usercenter/schoolClassGroup/group/allStudents',
    method: 'post',
    data,
    meta: {
      isSerialize: true,
    },
  });
}
