/*
 * @Author: slw 3289360550@qq.com
 * @Date: 2022-05-17 11:17:52
 * @LastEditors: slw 3289360550@qq.com
 * @LastEditTime: 2022-05-17 11:28:26
 * @FilePath: /znxt-student2.0/src/components/rewritePop/index.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { EditOutlined } from '@ant-design/icons';
import './index.less';

const RewritePop = () => {
  return (
    <div className="RewritePopCom">
      <img src={require("@/assets/rewrite.png").default} alt="" />
      <EditOutlined className='writeIcom' />
    </div>    
  )
}

export default RewritePop;