import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react"
import { getParams } from "@/util/util"
import { getChapterList } from "@/api/memorizeWords"

const Presenter = (props) => {
    const query = getParams(window.location.href);
    const volumesList = useMemo(() => {
        if (query.gradeId < 9) {
            return [
                { volumeName: '七年级上', id: 49 },
                { volumeName: '七年级下', id: 50 },
                { volumeName: '八年级上', id: 51 },
                { volumeName: '八年级下', id: 52 },
                { volumeName: '九年级全册', id: 53 },
            ]
        } else {
            return [
                { volumeName: '第一册(必修)', id: 1385 },
                { volumeName: '第二册(必修)', id: 1406 },
                { volumeName: '第三册(必修)', id: 1475 },
                { volumeName: '第一册(选修)', id: 1514 },
                { volumeName: '第二册(选修)', id: 1533 },
                { volumeName: '第三册(选修)', id: 1599 },
                { volumeName: '第四册(选修)', id: 1600 },
                { volumeName: '新东方高考英语词汇', id: 1697, hot: true }
            ]
        }
    }, [query.gradeId])
    const [volumeActive, setVolumeActive] = useState(
        Number(query?.bookVolumeId) && volumesList.findIndex(v => v.id === Number(query?.bookVolumeId)) !== -1 ?
            volumesList.findIndex(v => v.id === Number(query?.bookVolumeId))
            : 0
    )
    const [chapterList, setChapterList] = useState([])
    const volumeListRef = useRef(null)
    const [listScrollTop, setListScrollTop] = useState(0)

    useEffect(() => {
        changeVolumeActive(volumeActive)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        let params = {
            volumeId: volumesList[volumeActive]['id'],
            studentId: query.studentId,
            gradeId: query.gradeId
        }

        getChapterList(params).then(res => {
            setChapterList(res)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [volumeActive])

    useEffect(() => {
        if (chapterList.length !== 0) {
            const currentStudy = document.querySelector('.WordsChapterExercisePage').querySelector('.current-Study-unit')

            if (currentStudy) {
                currentStudy.scrollIntoView({
                    behavior: "smooth",
                    block: "center"
                })
            } else {
                setListScrollTop(listScrollTop + Math.random())
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chapterList])

    const changeVolumeActive = (i) => {
        setVolumeActive(i)
        const listElement = volumeListRef?.current;
        const containerWidth = listElement?.offsetWidth;
        const element = listElement?.children[0].children[i];
        const elementWidth = element?.offsetWidth;
        const scrollLeft = element?.offsetLeft - ((containerWidth - elementWidth) / 2);
        listElement.scrollTo({
            left: scrollLeft,
            behavior: "smooth"
        })
    }

    return [
        { volumesList, volumeActive, chapterList, volumeListRef, listScrollTop },
        { changeVolumeActive }
    ]
}

export default Presenter