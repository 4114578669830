/*
 * @Author: your name
 * @Date: 2021-08-05 17:02:27
 * @LastEditTime: 2021-08-18 17:28:03
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \znxt2.0\src\pages\myCollect\components\headOperation\presenter.js
 */
import React from "react";
import { getParams } from "@/util/util";

import { useState, useEffect } from "react";

const Presenter = (props) => {
  const [isShowSubject, setIsShowSubject] = useState(true);

  useEffect(() => {
    let obj = getParams(window.location.href);
    if (obj?.id) {
      setIsShowSubject(false);
    } else {
      setIsShowSubject(true);
    }
  }, []);
  return [{ isShowSubject }, {}];
};

export default Presenter;
