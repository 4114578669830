/*
 * @Description: 电子课本
 * @FilePath: \newGitZnxt2.0\src\pages\keyboardPage\presenter.js
 */

import { useState, useEffect } from 'react';
import { getElectronicBooks } from '@/api/groupScoring';
import { getBaseSubjectListAll } from '@/api/answer';
import { getParams } from '@/util/util';

const Presenter = () => {
  const [bookList, setBookList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [subjectId, setSubjectId] = useState();
  const [activeInfo, setActiveInfo] = useState({});

  let obj = getParams(window.location.href);

  // 学科列表
  const getSubjectList = () => {
    getBaseSubjectListAll({
      levelId: obj.levelId,
    }).then((res) => {
      setSubjectList(res);
      if (res.length > 0) setSubjectId(res[0].id);
    });
  };

  // 电子教材列表
  const getElecBooks = () => {
    let params = {
      levelId: obj.levelId,
      subjectId, // subjectId
      studentId: obj.studentId,
    };
    getElectronicBooks(params).then((res) => {
      setBookList(res || []);
    });
  };
  // 改变学科
  const changesubject = (id) => {
    setSubjectId(id);
  };
  // 选中pdf
  const activeBook = (data) => {
    data.file = { convertResourceUrl: data.bookUrl };
    setActiveInfo(data);
    window.location.hash = `#/electronicPdfBook?bookName=${data.bookVolumeName + '-' + data.bookVersionName}&bookUrl=${
      data.bookUrl
    }&id=${data.id}&studentId=${obj.studentId}&subjectId=${subjectId}`;
  };

  useEffect(() => {
    if (subjectId) getElecBooks();
  }, [subjectId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getSubjectList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return [
    { bookList, activeInfo, subjectList, subjectId },
    { changesubject, activeBook },
  ];
};

export default Presenter;
