/*
 * @Author: your name
 * @Date: 2020-11-03 12:23:51
 * @LastEditTime: 2021-08-06 09:07:38
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /sdzn-assist-line/src/mockData/resource.js
 */
// 根据type拿到对应的index，就是资源icon的图像地址 eg：icon${index}.png
import resourceTypeList from "./resourceTypeList";
export const resourceTransformList = [
  {
    type: "",
  },
  {
    type: "word",
  },
  {
    type: "ppt",
  },
  {
    type: "excel",
  },
  {
    type: "pdf",
  },
  {
    type: "video",
  },
  {
    type: "text",
  },
  {
    type: "img",
  },
  {
    type: "audio",
  },
  {
    type: "docx", // 试卷暂时定义为 docx
  },
  {
    type: "docx", // 备注暂时定义为 docx
  },
];

export const getResourceTypeIndex = (name) => {
  if (name) {
    name = name.toLowerCase(name);
    let arr = name.split(".");
    if (arr.length < 2) return 10;
    let con = arr[arr.length - 1];
    let item = resourceTypeList.find((v) => {
      let value = v.value.toLowerCase();
      value = value.replace(/\s*/g, "");
      return value === con;
    });
    if (item) {
      let index = resourceTransformList.findIndex(
        (v) => v.type.indexOf(item.note) > -1
      );
      return index > -1 ? index : 10;
    } else {
      return 10;
    }
  }
  return 10;
};
