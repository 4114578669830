/*
 * @Author: your name
 * @Date: 2021-08-05 17:02:27
 * @LastEditTime: 2023-02-23 10:02:37
 * @LastEditors: c1107 chen1997
 * @Description: In User Settings Edit
 * @FilePath: \znxt2.0\src\pages\myCollect\components\headOperation\presenter.js
 */
import React from "react";
import { getVolumeList } from "@/api/answer";
import { useState, useEffect } from "react";

const Presenter = (props) => {
  let typeListTwo = [
    {
      text: "综合成绩",
      type: 2,
      active: true,
    },
    {
      text: "学科成绩",
      type: 1,
      active: false,
    },
  ];

  let resourcesTypeList = [
    {
      id: 0,
      name: "全部类型",
    },
    {
      id: 1,
      name: "word",
    },
    {
      id: 2,
      name: "ppt",
    },
    {
      id: 3,
      name: "文本",
    },
    {
      id: 4,
      name: "视频",
    },
    {
      id: 5,
      name: "音频",
    },
    {
      id: 6,
      name: "图像",
    },
    {
      id: 13,
      name: "压缩包",
    },
    {
      id: 14,
      name: "PDF",
    },
    {
      id: 15,
      name: "其他",
    },
  ];
  const [typeList, setType] = useState(typeListTwo);
  const [activeType, setActiveType] = useState(1);
  const [isDefault, setisDefault] = useState(true);
  const [value, setvalue] = useState(0);

  const handleType = (event) => {
    if (event.target.innerHTML === "资源收藏") {
      typeListTwo[0].active = false;
      typeListTwo[1].active = true;
      setActiveType(1);
      props.getActiveType(1);
    } else {
      typeListTwo[0].active = true;
      typeListTwo[1].active = false;
      setActiveType(2);
      props.getActiveType(2);
    }
    setType(typeListTwo);
  };

  const changeType = (v) => {
    setvalue(v);
    props.handleTypeList(v);
  };
  const changeInput = (e) => {
    props.changeInput(e.target.value);
  };

  const initVolumeList = () => {
    getVolumeList({}).then((res) => {});
  };

  useEffect(() => {}, []);
  return [
    { typeList, activeType, isDefault, resourcesTypeList, value },
    { handleType, changeType, changeInput },
  ];
};

export default Presenter;
