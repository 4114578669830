import "./index.less"
import AudioPlayer from "../audioPlayer"

function DictionarySentence({ list, word, className, ...props }) {
    const regex = new RegExp(word, 'gi')

    let processList = (list || []).map(v => {
        v.en = v?.en.replace(regex, '<span>$&</span>')

        return v
    })

    return (<>
        <div className={`dictionary-sentence-Component ${className || ''}`} {...props}>
            <div className="caseTitle">例句：</div>
            {
                (processList || []).map((v, i) =>
                    <div className="sentence" key={i}>
                        <span>{i >= 9 ? (++i) : ('0' + ++i)}.</span>
                        <div className="text">
                            <AudioPlayer url={v.mp3}>
                                <div className="en" dangerouslySetInnerHTML={{ __html: v.en + `<i class="icon-Mp3"></i>` }}></div>
                                <div className="ch">{v?.cn}</div>
                            </AudioPlayer>
                        </div>
                    </div>
                )
            }
        </div>
    </>)
}

export default DictionarySentence