import { useEffect, useState, useRef } from "react"
import { getLabelList, deleteThisLabel, getChangeCurrentLabel, createlabel } from "@/api/mistakesBooks"
import { getParams } from "@/util/util"

const Presenter = (props) => {
    const [isShowLabelModal, setisShowLabelModal] = useState(false);
    const [myAllLabelList, setMyAllLabelList] = useState([]);
    const [isShowEditLabelInput, setIsShowEditLabelInput] = useState(false)
    const createLabelInputDom = useRef(null)
    const searchLabelInputDom = useRef(null)

    useEffect(() => {
        isShowEditLabelInput && createLabelInputDom.current.focus();
    }, [isShowEditLabelInput]) // eslint-disable-next-line react-hooks/exhaustive-deps

    const scrollBottomChange = (e) => {
        const box = e.target
        if (box.scrollTop >= box.scrollHeight - box.clientHeight - 15) {
            props?.scrollBottomChange()
        }
    }

    const labelModalVisibleChange = (isOpen) => {
        const { studentId } = getParams(window.location.href);
        setisShowLabelModal(isOpen)
        if (isOpen) {
            getLabelList({
                studentId
            }).then(res => {
                setMyAllLabelList(res?.map(v => ({ isModify: false, ...v })));
            })
        }
    }

    const getWantLabelList = (name) => {
        const { studentId } = getParams(window.location.href);
        getLabelList({
            studentId,
            name
        }).then(res => {
            setMyAllLabelList(res || [])
        })
    }

    const openCurrentLabelEditing = (index) => {
        setMyAllLabelList(myAllLabelList.map((v, i) => {
            if (i === index) return { ...v, isModify: true }
            return v
        }));
    }

    const closeCurrentLabelEditing = (index) => {
        console.log(index);
        setMyAllLabelList(myAllLabelList.map((v, i) => {
            if (i === index) return { ...v, isModify: false }
            return v
        }));
    }

    const changeCurrentLabelOk = (id, index, e) => {
        let value = e.target.previousSibling.previousSibling.value
        if (!value.trim()) return
        getChangeCurrentLabel({
            id,
            name: value
        }).then(res => {
            setMyAllLabelList(myAllLabelList.map((v, i) => {
                if (index === i) {
                    return { ...v, labelName: value, isModify: false }
                } else {
                    return v
                }
            }))
            props.refReshUseLabelList()
        })
    }

    const removeCurrentLabel = (id) => {
        deleteThisLabel({ id }).then(res => {
            setMyAllLabelList(myAllLabelList.filter(v => v.id !== id))
        })
    }

    const changeIsShowLabelInput = (flage) => {
        setIsShowEditLabelInput(flage)
    }

    const createNewLabelHandle = () => {
        const { studentId } = getParams(window.location.href);

        if (createLabelInputDom?.current.value.trim()) {
            createlabel({
                studentId,
                labelName: createLabelInputDom?.current.value
            }).then(res => {
                getWantLabelList(searchLabelInputDom.current.value)
                props.refReshUseLabelList()
            })
        }
    }

    return [
        { isShowLabelModal, myAllLabelList, isShowEditLabelInput, createLabelInputDom, searchLabelInputDom },
        { scrollBottomChange, labelModalVisibleChange, openCurrentLabelEditing, removeCurrentLabel, getWantLabelList, closeCurrentLabelEditing, changeCurrentLabelOk, changeIsShowLabelInput, createNewLabelHandle }
    ]
}

export default Presenter