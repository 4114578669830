/* eslint-disable */
var agent = navigator.userAgent.toLowerCase(),
  opera = window.opera,
  browser = {
    /* @property {boolean} ie 检测当前浏览器是否为IE
     */
    ie: /(msie\s|trident.*rv:)([\w.]+)/i.test(agent),

    /**
     * @property {boolean} opera 检测当前浏览器是否为Opera
     */
    opera: !!opera && opera.version,

    /**
     * @property {boolean} webkit 检测当前浏览器是否是webkit内核的浏览器
     */
    webkit: agent.indexOf(' applewebkit/') > -1,

    /**
     * @property {boolean} mac 检测当前浏览器是否是运行在mac平台下
     */
    mac: agent.indexOf('macintosh') > -1,

    /**
     * @property {boolean} quirks 检测当前浏览器是否处于“怪异模式”下
     */
    quirks: document.compatMode == 'BackCompat',
  };

/**
 * @property {boolean} gecko 检测当前浏览器内核是否是gecko内核
 */
browser.gecko = navigator.product == 'Gecko' && !browser.webkit && !browser.opera && !browser.ie;

var version = 0;

// Internet Explorer 6.0+
if (browser.ie) {
  var v1 = agent.match(/(?:msie\s([\w.]+))/);
  var v2 = agent.match(/(?:trident.*rv:([\w.]+))/);
  if (v1 && v2 && v1[1] && v2[1]) {
    version = Math.max(v1[1] * 1, v2[1] * 1);
  } else if (v1 && v1[1]) {
    version = v1[1] * 1;
  } else if (v2 && v2[1]) {
    version = v2[1] * 1;
  } else {
    version = 0;
  }

  browser.ie11Compat = document.documentMode == 11;
  /**
   * @property { boolean } ie9Compat 检测浏览器模式是否为 IE9 兼容模式
   * @warning 如果浏览器不是IE， 则该值为undefined
   * @example
   * ```javascript
   * if ( UE.browser.ie9Compat ) {
   *     console.log( '当前浏览器运行在IE9兼容模式下' );
   * }
   * ```
   */
  browser.ie9Compat = document.documentMode == 9;

  /**
   * @property { boolean } ie8 检测浏览器是否是IE8浏览器
   * @warning 如果浏览器不是IE， 则该值为undefined
   * @example
   * ```javascript
   * if ( UE.browser.ie8 ) {
   *     console.log( '当前浏览器是IE8浏览器' );
   * }
   * ```
   */
  browser.ie8 = !!document.documentMode;

  /**
   * @property { boolean } ie8Compat 检测浏览器模式是否为 IE8 兼容模式
   * @warning 如果浏览器不是IE， 则该值为undefined
   * @example
   * ```javascript
   * if ( UE.browser.ie8Compat ) {
   *     console.log( '当前浏览器运行在IE8兼容模式下' );
   * }
   * ```
   */
  browser.ie8Compat = document.documentMode == 8;

  /**
   * @property { boolean } ie7Compat 检测浏览器模式是否为 IE7 兼容模式
   * @warning 如果浏览器不是IE， 则该值为undefined
   * @example
   * ```javascript
   * if ( UE.browser.ie7Compat ) {
   *     console.log( '当前浏览器运行在IE7兼容模式下' );
   * }
   * ```
   */
  browser.ie7Compat = (version == 7 && !document.documentMode) || document.documentMode == 7;

  /**
   * @property { boolean } ie6Compat 检测浏览器模式是否为 IE6 模式 或者怪异模式
   * @warning 如果浏览器不是IE， 则该值为undefined
   * @example
   * ```javascript
   * if ( UE.browser.ie6Compat ) {
   *     console.log( '当前浏览器运行在IE6模式或者怪异模式下' );
   * }
   * ```
   */
  browser.ie6Compat = version < 7 || browser.quirks;

  browser.ie9above = version > 8;

  browser.ie9below = version < 9;

  browser.ie11above = version > 10;

  browser.ie11below = version < 11;
}

// Gecko.
if (browser.gecko) {
  var geckoRelease = agent.match(/rv:([\d\.]+)/);
  if (geckoRelease) {
    geckoRelease = geckoRelease[1].split('.');
    version = geckoRelease[0] * 10000 + (geckoRelease[1] || 0) * 100 + (geckoRelease[2] || 0) * 1;
  }
}

/**
 * @property { Number } chrome 检测当前浏览器是否为Chrome, 如果是，则返回Chrome的大版本号
 * @warning 如果浏览器不是chrome， 则该值为undefined
 * @example
 * ```javascript
 * if ( UE.browser.chrome ) {
 *     console.log( '当前浏览器是Chrome' );
 * }
 * ```
 */
if (/chrome\/(\d+\.\d)/i.test(agent)) {
  browser.chrome = +RegExp['\x241'];
}

/**
 * @property { Number } safari 检测当前浏览器是否为Safari, 如果是，则返回Safari的大版本号
 * @warning 如果浏览器不是safari， 则该值为undefined
 * @example
 * ```javascript
 * if ( UE.browser.safari ) {
 *     console.log( '当前浏览器是Safari' );
 * }
 * ```
 */
if (/(\d+\.\d)?(?:\.\d)?\s+safari\/?(\d+\.\d+)?/i.test(agent) && !/chrome/i.test(agent)) {
  browser.safari = +(RegExp['\x241'] || RegExp['\x242']);
}

// Opera 9.50+
if (browser.opera) version = parseFloat(opera.version());

// WebKit 522+ (Safari 3+)
if (browser.webkit) version = parseFloat(agent.match(/ applewebkit\/(\d+)/)[1]);

/**
 * @property { Number } version 检测当前浏览器版本号
 * @remind
 * <ul>
 *     <li>IE系列返回值为5,6,7,8,9,10等</li>
 *     <li>gecko系列会返回10900，158900等</li>
 *     <li>webkit系列会返回其build号 (如 522等)</li>
 * </ul>
 * @example
 * ```javascript
 * console.log( '当前浏览器版本号是： ' + UE.browser.version );
 * ```
 */
browser.version = version;

/**
 * @property { boolean } isCompatible 检测当前浏览器是否能够与UEditor良好兼容
 * @example
 * ```javascript
 * if ( UE.browser.isCompatible ) {
 *     console.log( '浏览器与UEditor能够良好兼容' );
 * }
 * ```
 */
browser.isCompatible =
  !browser.mobile &&
  ((browser.ie && version >= 6) ||
    (browser.gecko && version >= 10801) ||
    (browser.opera && version >= 9.5) ||
    (browser.air && version >= 1) ||
    (browser.webkit && version >= 522) ||
    false);

export default browser;
/* eslint-enable */
