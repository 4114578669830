import { useEffect, useState } from "react"
import { getParams } from "@/util/util";
import { subjectTendency, subjectPerformance } from "@/api/analysis"

const Presenter = (props) => {

    const { changeExhibitionArea } = props

    const timeBucketList = [
        {
            text: "本学期",
            id: "TimeBucketBUTTON_TWO",
            value: "2"
        },
        {
            text: "本学年",
            id: "TimeBucketBUTTON_Three",
            value: "3"
        }
    ]

    const [timeBucket, setTimeBucket] = useState(timeBucketList)

    const [timeBucketActive, setTimeBucketActive] = useState(0)
    const [subjectList, setSubjectList] = useState([])
    const [subjectActive, setSubjectActive] = useState(0)

    useEffect(() => {
        const { studentId } = getParams(window.location.href);
        (async () => {
            // 根据时间段请求考试的学科
            await subjectTendency({ studentId, "dateType": timeBucketList[timeBucketActive]["value"] }).then(async res => {
                setSubjectList(res)
                let flage = res.find(obj => obj.subjectId === subjectActive)

                if (subjectActive === 0 || !flage) {
                    setSubjectActive(res[0]?.subjectId)
                } else {
                    // 根据学科请求考试的详情
                    await subjectPerformance({
                        studentId,
                        "dateType": timeBucketList[timeBucketActive]["value"],
                        "subjectId": subjectActive === 0 || !flage ? res[0]["subjectId"] : subjectActive
                    }).then(val => {
                        changeExhibitionArea(val)
                    })
                }
            })
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeBucketActive, subjectActive])

    const changeTimeBucket = async (active) => {
        setTimeBucketActive(active)
    }

    const changeSubject = (active) => {
        setSubjectActive(active)
    }

    return [
        { timeBucket, timeBucketActive, subjectList, subjectActive },
        { changeTimeBucket, changeSubject }
    ]
}

export default Presenter