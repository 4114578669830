/*
 * @Author: your name
 * @Date: 2022-03-16 09:37:11
 * @LastEditTime: 2022-04-27 18:20:57
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newGitZnxt2.0\src\components\wordAnswerList\presenter.js
 */

import { useState, useEffect, useRef } from "react";
import { androidHandle } from "@/util/android";

import { getWordExamDetail, getTodayReviewWord } from "@/api/word";
import {
  getExamSignDetail,
  addCourseAnswer,
  submitCourseAnswer,
} from "@/api/answer";
import { getParams } from "@/util/util";

import store from "@/redux/store";
import { message } from "antd";

const Presenter = (props) => {
  const [state, setState] = useState(0); //当前状态
  const [isModalVisible, setIsModalVisible] = useState(false); //详情弹窗
  const [wordTotal, setWordTotal] = useState(null); //单词（试题）总数
  const [indexSeq, setIndexSeq] = useState(1); //当前单词(试题)
  const [examTypeId, setExamTypeId] = useState(null); //题型
  const [courseDetail, setCourseDetail] = useState(null); // 题目信息
  const todayWordExam = useRef([]); // 今日复习的全部单词
  const todayId = useRef(null); // 今日复习单词的提交

  useEffect(() => {
    let obj = getParams(window.location.href);
    getLocalData();
    // 今日复习
    if (obj?.todayReview) {
      if (todayWordExam.current.length) {
        getTodayExamWord();
      } else {
        getTodayReviewWordApi(); //第一次调用获取全部单词
      }
    } else {
      setWordTotal(Number(obj?.countExam)); //总题数

      if (obj?.review === "true") {
        //判断是复习还是学习
        setState(1); //改变状态
        getExamWord();
      } else {
        if (state) {
          // 作答阶段
          getExamWord();
        } else {
          // 学习阶段
          getWordDetail();
        }
      }
    }
  }, [indexSeq]); // eslint-disable-line react-hooks/exhaustive-deps

  // 记单词接口调用
  const getWordDetail = () => {
    let obj = getParams(window.location.href);
    getWordExamDetail({
      resourceType: 5, // 5:记单词
      id: obj.id,
      seq: indexSeq,
      studentId: obj.studentId,
    }).then((data) => {
      setExamTypeId(data?.resource?.exam?.examTypeId);
      setCourseDetail(data);
    });
  };

  // 记单词题目首次调用
  const getWordExamApi = () => {
    let obj = getParams(window.location.href);

    // 学习以后的作答阶段
    setLocalData({
      id: obj.id,
      seq: 1,
      type: 1,
    });

    setState(1); //改变状态
    setIndexSeq(1); //重置seq
    getExamWord(1); //调用接口
  };

  const getExamWord = (num) => {
    let obj = getParams(window.location.href);
    let query = {
      resourceType: 5,
      id: obj.id,
      seq: num || indexSeq,
      studentId: obj.studentId,
    };

    getExamSignDetail(query).then((res) => {
      setExamTypeId(res?.resource?.exam?.examTypeId);
      setCourseDetail(res);
    });
  };

  // 作答
  const wordAnswer = (selectSeq) => {
    let obj = getParams(window.location.href);
    if (examTypeId === 22 || examTypeId === 23) {
      // 选择题其实就是
      let optionList = [[{ examTypeId: examTypeId, selectSeq: selectSeq }]];
      addCourseAnswer({
        lessonStudentId: obj?.id || todayId.current,
        resourceId: courseDetail.resourceId,
        resourceType: 5,
        usedTime: store.getState().updateTime,
        optionList,
      }).then((res) => {
        // 最后一个题提交
        if (indexSeq === wordTotal) {
          wordSubmitApi();
        }
      });
    } else {
      // setisShowKeyboard(true);

      let optionList = [
        [{ examTypeId: examTypeId, answerType: 1, myAnswer: selectSeq }],
      ];
      addCourseAnswer({
        lessonStudentId: obj?.id || todayId.current,
        resourceId: courseDetail.resourceId,
        resourceType: 5,
        usedTime: store.getState().updateTime,
        optionList,
      }).then((res) => {
        // 最后一个题提交
        if (indexSeq === wordTotal) {
          wordSubmitApi();
        }
      });
    }
  };

  // 提交接口调用
  const wordSubmitApi = () => {
    let obj = getParams(window.location.href);
    submitCourseAnswer({
      lessonStudentId: obj.id,
      studentId: obj.studentId,
    }).then((res) => {
      message.success("已提交");
    });
  };

  // 下一题改变题号
  const propsSetIndexSeq = (seq) => {
    setIndexSeq(seq);

    let obj = getParams(window.location.href);
    if (obj?.review === "true") {
      // 复习阶段
      setLocalData({
        id: obj.id,
        seq: seq,
        type: 2,
      });
    } else {
      if (state) {
        // 学习以后的作答阶段
        setLocalData({
          id: obj.id,
          seq: seq,
          type: 1,
        });
      } else {
        // 学习阶段
        setLocalData({
          id: obj.id,
          seq: seq,
          type: 3,
        });
      }
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  // 今日复习接口调用
  const getTodayReviewWordApi = () => {
    let obj = getParams(window.location.href);

    getTodayReviewWord({
      studentId: obj.studentId,
      startTime: obj.startTime,
      endTime: obj.endTime,
      subjectId: obj.subjectId,
    }).then((res) => {
      todayWordExam.current = res;
      setState(1); //改变状态
      setWordTotal(res.length); //总题数
      getTodayExamWord();
    });
  };

  // 获取今日复习的题
  const getTodayExamWord = () => {
    todayId.current = todayWordExam.current[indexSeq - 1].lessonStudentId;

    let obj = getParams(window.location.href);
    let query = {
      resourceType: 5,
      id: todayWordExam.current[indexSeq - 1].lessonStudentId,
      seq: todayWordExam.current[indexSeq - 1].seq,
      studentId: obj.studentId,
    };
    getExamSignDetail(query).then((res) => {
      setExamTypeId(res?.resource?.exam?.examTypeId);
      setCourseDetail(res);
    });
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // 错误窗口抖动
  const shakeAnimation = () => {
    let elem = document.getElementsByClassName("examInsideWrapper");
    if (elem) {
      elem[0].classList.add("shake");
      setTimeout(() => {
        elem[0].classList.remove("shake");
      }, 800);
    }
  };

  // 跳转统计
  const goToStatistics = () => {
    props.goToStatistics();
  };

  // 键盘赋值
  const changeInputValue = (val) => {
    nowInputDom.current.target.value += val;
  };

  // 键盘删除操作
  const delInputValue = () => {
    nowInputDom.current.target.value = nowInputDom.current.target.value.slice(
      0,
      nowInputDom.current.target.value.length - 1
    );
  };

  // 键盘点击空格
  const inputOnBlank = (val) => {
    nowInputDom.current.target.value += val;
  };

  // 键盘点击对号
  const inputOnTrue = () => {
    submitFillexam();
  };
  // 键盘隐藏
  const closeKeyBoard = (val) => {
    setisShowKeyboard(val);
    submitFillexam();
  };

  // 右上角返回
  const goBack = () => {
    props.goBack();
  };

  // 进度存本地
  const setLocalData = (data) => {
    localStorage.setItem("wordProcess", JSON.stringify(data));
  };

  const getLocalData = () => {
    let localData = JSON.parse(localStorage.getItem("wordProcess"));
    let obj = getParams(window.location.href);
    if (localData) {
      if (obj.id === localData.id) {
        if (localData.type === 3) {
          setState(0); //学习阶段
          setIndexSeq(localData.seq); //重置seq
        } else {
          setState(1); //改变状态
          setIndexSeq(localData.seq); //重置seq
        }
      }
    }
  };

  return [
    {
      state,
      isModalVisible,
      examTypeId,
      wordTotal,
      courseDetail,
      indexSeq,
    },
    {
      propsSetIndexSeq,
      showModal,
      handleOk,
      handleCancel,
      getWordExamApi,
      wordAnswer,
      shakeAnimation,
      goToStatistics,
      changeInputValue,
      closeKeyBoard,
      delInputValue,
      inputOnBlank,
      inputOnTrue,
      goBack,
    },
  ];
};

export default Presenter;
