/*
 * @Author: your name
 * @Date: 2021-08-06 08:52:16
 * @LastEditTime: 2021-08-06 09:39:20
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /sdzn-assist-line/src/mockData/resourceTypeList.js
 */

const resourceList = [
  {
    id: 17,
    code: "file_upload_txt",
    value: "TXT",
    note: "text",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 18,
    code: "file_upload_doc",
    value: "DOC",
    note: "word",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 19,
    code: "file_upload_pdf",
    value: "PDF",
    note: "pdf",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 20,
    code: "file_upload_caj",
    value: "CAJ",
    note: "text",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 21,
    code: "file_upload_xls",
    value: "XLS",
    note: "excel",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 22,
    code: "file_upload_ppt",
    value: "PPT",
    note: "ppt",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 23,
    code: "file_upload_docx",
    value: "DOCX",
    note: "word",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 24,
    code: "file_upload_xlsx",
    value: "XLSX",
    note: "excel",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 25,
    code: "file_upload_pptx",
    value: "PPTX",
    note: "ppt",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 26,
    code: "file_upload_jpg",
    value: "JPG",
    note: "img",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 27,
    code: "file_upload_jpeg",
    value: "JPEG",
    note: "img",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 28,
    code: "file_upload_png",
    value: "PNG",
    note: "img",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 29,
    code: "file_upload_tiff",
    value: "TIFF",
    note: "img",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 30,
    code: "file_upload_swf",
    value: "SWF",
    note: "video",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 31,
    code: "file_upload_bmp",
    value: "BMP",
    note: "img",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 32,
    code: "file_upload_gif",
    value: "GIF",
    note: "img",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 33,
    code: "file_upload_flv",
    value: "FLV",
    note: "video",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 34,
    code: "file_upload_rmvb",
    value: "RMVB",
    note: "video",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 35,
    code: "file_upload_mp4",
    value: "MP4",
    note: "video",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 36,
    code: "file_upload_mvb",
    value: "MVB",
    note: "video",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 37,
    code: "file_upload_avi",
    value: "AVI",
    note: "video",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 38,
    code: "file_upload_mov",
    value: "MOV",
    note: "video",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 39,
    code: "file_upload_mpg",
    value: "MPG",
    note: "video",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 40,
    code: "file_upload_rm",
    value: "RM",
    note: "video",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 41,
    code: "file_upload_swf",
    value: "SWF",
    note: "video",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 42,
    code: "file_upload_3gp",
    value: "3GP",
    note: "video",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 43,
    code: "file_upload_wmv",
    value: "WMV",
    note: "video",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 44,
    code: "file_upload_asf",
    value: "ASF",
    note: "video",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 45,
    code: "file_upload_kux",
    value: "KUX",
    note: "video",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 46,
    code: "file_upload_wav",
    value: "WAV",
    note: "audio",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 47,
    code: "file_upload_wma",
    value: "WMA",
    note: "audio",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 48,
    code: "file_upload_mp3",
    value: "MP3",
    note: "audio",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 49,
    code: "file_upload_midi",
    value: "MIDI",
    note: "audio",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 50,
    code: "file_upload_mpeg",
    value: "MPEG",
    note: "audio",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 51,
    code: "file_upload_aac",
    value: "AAC",
    note: "audio",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 52,
    code: "file_upload_ra",
    value: "RA",
    note: "audio",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 53,
    code: "file_upload_rm",
    value: "RM",
    note: "audio",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 54,
    code: "file_upload_rmx",
    value: "RMX",
    note: "audio",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 55,
    code: "file_upload_cda",
    value: "CDA",
    note: "audio",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 56,
    code: "file_upload_ape",
    value: "APE",
    note: "audio",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
  {
    id: 57,
    code: "file_upload_flac",
    value: "FLAC",
    note: "audio",
    imgUrl: "",
    redirectUrl: "",
    seq: 1,
    dictTypeId: 12,
    dictTypeCode: "file_upload",
    dictTypeName: "文件上传",
    status: 1,
  },
];

export default resourceList;
