/*
 * @Author: your name
 * @Date: 2021-09-24 13:46:09
 * @LastEditTime: 2021-09-24 13:54:50
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \newGitZnxt2.0\src\pages\keyboardPage\presenter.js
 */

import { useState, useEffect, useRef } from "react";
import { getParams } from "@/util/util";
import store from "@/redux/store";
import mathJax from "@/util/globalVariable";

const Presenter = () => {
  const [examList, setExamList] = useState([]);
  const examListRef = useRef([]);

  // 时间
  const handleChangTime = (time) => {};

  // useEffect(() => {
  //   // scrollLoadData();
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return [
    {},
    {
      handleChangTime,
    },
  ];
};

export default Presenter;
