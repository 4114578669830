/*
 * @Author: your name
 * @Date: 2022-03-23 17:20:43
 * @LastEditTime: 2022-04-02 15:40:30
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newGitZnxt2.0\src\pages\wordPK\index.jsx
 */
import React from "react";
import "./index.less";
import Presenter from "./presenter.js";
import { Popover } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
const WordPk = (props) => {
  const dom = document.querySelector("#root");

  const [states, events] = Presenter(props);

  const {
    playGame,
    examList,
    redIndex,
    blueIndex,
    redScore,
    blueScore,
    gameOver,
    redAnswerTime,
    blueAnswerTime,
    win,
    visibleL,
    visibleR,
  } = states;
  const {
    handlePlay,
    answer,
    lookAnswerDetails,
    handleVisibleChangeL,
    handleVisibleChangeR,
  } = events;

  return (
    <div className="wordPkPage">
      {/* 头部 */}
      <div className="TopBox">
        <div className="leftTop">
          <div className="LBox">
            <img
              className="leftStar"
              src={require("@/assets/wordPk/leftStar.png").default}
              alt="leftStar"
            />
            <div className="score">{redScore}</div>

            <div className="processLeftBox">
              <span className="scoreText">{redIndex + 1}</span>/
              {examList.length}
            </div>
            <div></div>

            <img
              className="leftFist"
              src={require("@/assets/wordPk/red.png").default}
              alt="aperture"
            />
          </div>
        </div>
        <div className="rightTop">
          <div className="RBox">
            <img
              className="rightStar"
              src={require("@/assets/wordPk/leftStar.png").default}
              alt="leftStar"
            />
            <div></div>
            <div className="processRightBox">
              <span className="scoreText">{examList.length - blueIndex}</span>/{" "}
              {examList.length}
            </div>
            <div className="score">{blueScore}</div>

            <img
              className="rightFist"
              src={require("@/assets/wordPk/blue.png").default}
              alt="aperture"
            />
          </div>
        </div>
      </div>

      <div className="leftBox">
        {playGame ? (
          <div className="testBoxLeft">
            <div className="stemText">
              <Popover
                placement="topLeft"
                content={examList?.[redIndex]?.exam.examStem}
                trigger="click"
                visible={visibleL}
                onVisibleChange={handleVisibleChangeL}
              >
                {examList?.[redIndex]?.exam.examStem}
              </Popover>
            </div>
            <div className="optionBox">
              {examList?.[redIndex]?.exam.redArr.map((v, i) => (
                <div
                  className={`option`}
                  id={`lOption${i}`}
                  key={i}
                  onClick={(e) => answer(0, v, e, redIndex)}
                >
                  {v.content}
                </div>
              ))}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="rightBox">
        {playGame ? (
          <div className="testBoxRight">
            <div className="stemText">
              <Popover
                placement="topLeft"
                content={examList?.[blueIndex]?.exam.examStem}
                trigger="click"
                visible={visibleR}
                onVisibleChange={handleVisibleChangeR}
              >
                {examList?.[blueIndex]?.exam.examStem}
              </Popover>
            </div>
            <div className="optionBox">
              {examList?.[blueIndex]?.exam.blueArr.map((v, i) => (
                <div
                  className={`option`}
                  id={`rOption${i}`}
                  key={i}
                  onClick={(e) => answer(1, v, e, blueIndex)}
                >
                  {v.content}
                </div>
              ))}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {/* 光圈 */}
      {!playGame ? (
        <>
          {" "}
          <div className="aperture"></div>
          <div className="iconBox">
            {/* 开始PK */}
            <img
              onClick={handlePlay}
              className="play"
              src={require("@/assets/wordPk/play.png").default}
              alt="play"
            />
            {/* 拳头 */}
            <img
              className="redFist shake"
              src={require("@/assets/wordPk/red.png").default}
              alt="aperture"
            />
            <img
              className="blueFist shake"
              src={require("@/assets/wordPk/blue.png").default}
              alt="aperture"
            />
            <img
              className="pkTop"
              src={require("@/assets/wordPk/pk.png").default}
              alt="Pk"
            />
          </div>
        </>
      ) : (
        ""
      )}
      {/* 结束弹窗 */}
      {gameOver ? (
        <>
          <div className="divBG"></div>
          <div className="resultModal">
            <div className="redTeam">
              <div className="text">红队</div>
              <div className="starBox">
                <div>
                  <img
                    className="starImg"
                    src={require("@/assets/wordPk/leftStar.png").default}
                    alt="leftStar"
                  />
                </div>
                <div className="scoreRedText">{redScore}</div>
              </div>
              <div className="censusBox">
                <div className="trueBox">答对 {redScore / 10}</div>
                <div className="falseBox">
                  答错 {examList.length - redScore / 10}
                </div>
              </div>
              <div className="tiemBox">
                <ClockCircleOutlined />
                <span className="timeText">{redAnswerTime}</span>
              </div>
              {win === "red" ? (
                <img
                  className="winImg"
                  src={require("@/assets/wordPk/win.png").default}
                  alt="win"
                />
              ) : (
                ""
              )}
            </div>
            <div className="blueTeam">
              <div className="text">蓝队</div>
              <div className="starBox">
                <div>
                  <img
                    className="starImg"
                    src={require("@/assets/wordPk/leftStar.png").default}
                    alt="leftStar"
                  />
                </div>
                <div className="scoreBlueText">{blueScore}</div>
              </div>
              <div className="censusBox">
                <div className="trueBox">答对 {blueScore / 10}</div>
                <div className="falseBox">
                  答错 {examList.length - blueScore / 10}
                </div>
              </div>
              <div className="tiemBox">
                <ClockCircleOutlined />
                <span className="timeText">{blueAnswerTime}</span>
              </div>
              {win === "blue" ? (
                <img
                  className="winImg"
                  src={require("@/assets/wordPk/win.png").default}
                  alt="win"
                />
              ) : (
                ""
              )}
            </div>
            <img
              className="pkImg"
              src={require("@/assets/wordPk/pk2.png").default}
              alt="Pk"
            />
            <img
              onClick={lookAnswerDetails}
              className="lookAnswerBtn"
              src={require("@/assets/wordPk/lookAnswer.png").default}
              alt="Pk"
            />
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default WordPk;
