/*
 * @Author: your name
 * @Date: 2021-08-05 17:02:27
 * @LastEditTime: 2023-02-23 17:00:09
 * @LastEditors: c1107 chen1997
 * @Description: In User Settings Edit
 * @FilePath: \znxt2.0\src\pages\myCollect\components\headOperation\presenter.js
 */
import React from "react";
import { getVolumeList } from "@/api/answer";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";


let time = null

const Presenter = (props) => {
  let typeListTwo = [
    {
      text: "我的资料库",
      type: 1,
      active: true,
    },
    {
      text: "我的试题库",
      type: 2,
      active: false,
    },
    {
      text: "我的试卷",
      type: 3,
      active: false,
    },
  ];

  let resourcesTypeList = [
    {
      id: 0,
      name: "全部类型",
    },
    {
      id: 1,
      name: "word",
    },
    {
      id: 2,
      name: "ppt",
    },
    {
      id: 3,
      name: "文本",
    },
    {
      id: 4,
      name: "视频",
    },
    {
      id: 5,
      name: "音频",
    },
    {
      id: 6,
      name: "图像",
    },
    {
      id: 13,
      name: "压缩包",
    },
    {
      id: 14,
      name: "PDF",
    },
    {
      id: 15,
      name: "其他",
    },
  ];
  const location = useLocation()
  const [typeList, setType] = useState(typeListTwo);
  const [activeType, setActiveType] = useState(null);
  const [isDefault, setIsDefault] = useState(true);
  const [value, setvalue] = useState(0);
  const [checkedVal, setcCheckedVal] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const tabParam = searchParams.get('tab')

    console.log(tabParam,'tabparam0------------------------------------');

    if (tabParam) {
      setActiveType(Number(tabParam));
    }else {
      setActiveType(1);
    }
    
  }, [location.search])



  const handleType = (ev, key) => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('tab', key)
    window.history.replaceState(null, null, `#${location.pathname}?${searchParams}`)

    typeListTwo.forEach((v, i) => {
      if (v.type === key) {
        typeListTwo[i].active = true;
        setActiveType(v.type);
        props.getActiveType(v.type);
      } else {
        typeListTwo[i].active = false;
      }
    })
    setType(typeListTwo);
  };

  const changeType = (v) => {
    setvalue(v);
    props.handleTypeList(v);
  };
  const changeInput = (e) => {
    props.changeInput(e.target.value);
  };
  const changeCheckBox = (e) => {
    setcCheckedVal(e.target.checked);
    props.handleCheck(e.target.checked);
  };

  const initVolumeList = () => {
    getVolumeList({}).then((res) => { });
  };

  const searchExamName = (e) => {
    clearTimeout(time)
    time = setTimeout(() => {
      props.searchValueChange(e.target.value)
    }, 600);
  }

  return [
    { typeList, activeType, isDefault, resourcesTypeList, value, checkedVal },
    { handleType, changeType, changeInput, changeCheckBox, searchExamName },
  ];
};

export default Presenter;
