/*
 * @Author: c1107 chen1997
 * @Date: 2022-08-31 14:39:06
 * @LastEditors: c1107 chen1997
 * @LastEditTime: 2022-09-16 17:52:59
 * @FilePath: \ZNXT-Student2.0\src\pages\autonomyPractice\components\itemCard\index.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from "react";
import "./index.less";
import { Progress } from "antd";
import { dateFormat } from "@/util/util";

import { DownOutlined, UpOutlined } from "@ant-design/icons";
import Presenter from "./presenter.js";
const ItemCard = (props) => {
  const { itemInfo, bookVolumesVal } = props;
  const [states, events] = Presenter(props);
  const { goAnswer, openChildren, goCardDetail } = events;
  const { childrenShow, childrenList } = states;

  return (
    <div className="ItemCardPage">
      <div className="cardFather">
        <div className="leftBox">
          <div className="topTitle">{itemInfo?.name || ""}</div>
          <div className="bottomBox">
            <span className="NumberBox">
              已做 <span className="jc">{itemInfo?.examCount || 0}</span>
            </span>
            <span className="percentage">
              总正确率
              <span className="jc">{Math.trunc(itemInfo?.accuracy) || 0}%</span>
            </span>
          </div>
        </div>
        {itemInfo?.childSelfLearnDto?.length ? (
          <div className="cardBox">
            {itemInfo?.childSelfLearnDto.map((v, i) => (
              <div
                className="card"
                key={i}
                onClick={() => goCardDetail(v, itemInfo)}
              >
                {/* <div className="percentageBox">
                  {(v?.accuracy === 0 && v?.status === 0) === true ? (
                    <span className="wtj">未提交</span>
                  ) : (
                    <span
                      className={`${v.accuracy < 60 ? "color0" : ""} ${
                        v.accuracy >= 60 && v.accuracy < 80 ? "color1" : ""
                      } ${v.accuracy >= 80 ? "color2" : ""}`}
                    >
                      {Math.trunc(v?.accuracy) || 0}%
                    </span>
                  )}
                </div> */}
                <div className="percentageBox">
                  {v?.status === 0 ? (
                    <span className="wtj">未提交</span>
                  ) : v?.status === 3 || v?.status === 2 ? (
                    <span className="wtj">未核对</span>
                  ) : (
                    <span
                      className={`${v.accuracy < 60 ? "color0" : ""} ${
                        v.accuracy >= 60 && v.accuracy < 80 ? "color1" : ""
                      } ${v.accuracy >= 80 ? "color2" : ""}`}
                    >
                      {Math.trunc(v?.accuracy) || 0}%
                    </span>
                  )}
                </div>
                <div className="timeBox">
                  {dateFormat(v.createdTime, "MM")}月
                  {dateFormat(v.createdTime, "dd")}日
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="cardBox"></div>
        )}

        <div className="rightBox">
          <div className="downUpBox">
            {childrenShow ? (
              <span onClick={() => openChildren(itemInfo, false)}>
                收起章节
                <DownOutlined className="down" />
              </span>
            ) : (
              <span onClick={() => openChildren(itemInfo, true)}>
                展开章节
                <UpOutlined className="up" />
              </span>
            )}
          </div>
          <div
            className="goBtn"
            onClick={() => goAnswer(itemInfo, bookVolumesVal)}
          >
            去练习
          </div>
        </div>
        {childrenShow ? (
          <div className="cardChildrenListCard">
            {childrenList?.length > 0 ? (
              <>
                {childrenList.map((v, i) => (
                  <div className="childrenCard" key={i}>
                    <div className="leftBox">
                      <div className="topTitle">{v?.name || ""}</div>
                      <div className="bottomBox">
                        <span className="NumberBox">
                          已做 <span className="jc">{v?.examCount || 0}</span>
                        </span>
                        <span className="percentage">
                          总正确率
                          <span className="jc">
                            {Math.trunc(v?.accuracy) || 0}%
                          </span>
                        </span>
                      </div>
                    </div>
                    {v?.childSelfLearnDto?.length ? (
                      <div className="cardBox">
                        {v?.childSelfLearnDto.map((item, i) => (
                          <div
                            key={i}
                            className="card"
                            onClick={() => goCardDetail(item, v)}
                          >
                            <div className="percentageBox">
                              {/* {(item?.accuracy === 0 && item?.status === 0) ===
                              true ? (
                                <span className="wtj">未提交</span>
                              ) : (
                                <span
                                  className={`${
                                    item.accuracy < 60 ? "color0" : ""
                                  } ${
                                    item.accuracy >= 60 && item.accuracy < 80
                                      ? "color1"
                                      : ""
                                  } ${item.accuracy >= 80 ? "color2" : ""}`}
                                >
                                  {Math.trunc(item?.accuracy) || 0}%
                                </span>
                              )} */}

                              {item?.status === 0 ? (
                                <span className="wtj">未提交</span>
                              ) : item?.status === 3 || item?.status === 2 ? (
                                <span className="wtj">未核对</span>
                              ) : (
                                <span
                                  className={`${
                                    item.accuracy < 60 ? "color0" : ""
                                  } ${
                                    item.accuracy >= 60 && item.accuracy < 80
                                      ? "color1"
                                      : ""
                                  } ${item.accuracy >= 80 ? "color2" : ""}`}
                                >
                                  {Math.trunc(item?.accuracy) || 0}%
                                </span>
                              )}
                            </div>
                            <div className="timeBox">
                              {dateFormat(item?.createdTime, "MM")}月
                              {dateFormat(item?.createdTime, "dd")}日
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="cardBox"></div>
                    )}

                    <div className="rightBox">
                      <div
                        className="goBtnChild"
                        onClick={() => goAnswer(v, bookVolumesVal)}
                      >
                        {"去练习>"}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className="nullText">该章节下暂无内容</div>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ItemCard;
