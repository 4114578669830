import React from 'react';
import './index.less';
import Presenter from './presenter.js';
import { dateFormat } from '@/util/util';

const GroupsScoreRank = () => {
  const [states, events] = Presenter();

  const { groupsList, scoreAndSort, tabActive, stuGroup, loading } = states;
  const { tabClick } = events;
  let tabs = [
    {
      key: 1,
      name: '学科组排行',
    },
    {
      key: 0,
      name: '行政组排行',
    },
  ];

  return (
    <div className="groups_score_rank" id="groupsScoreRank">
      {loading ? (
        <div className="loading_view">
          <p className="text">立即更新</p>
          <p className="time">
            <i className="icon el-icon-loading"></i> 更新时间：{dateFormat(new Date(), 'MM-dd hh:mm')}
          </p>
        </div>
      ) : null}
      <div className="head_cont">
        <div className="item left">
          <div className="img_cont">
            <img src={require('@/assets/makeScore/frame1.png').default} alt="icon" />
          </div>
          <div className="score_cont">
            <p className="score">{scoreAndSort.score || '-'}分</p>
            <p className="text">我的得分</p>
          </div>
        </div>
        <div className="item right">
          <div className="img_cont">
            <img src={require('@/assets/makeScore/frame2.png').default} alt="icon" />
          </div>
          <div className="score_cont">
            <p className="score">NO.{scoreAndSort.place || '-'}</p>
            <p className="text">我的排名数</p>
          </div>
        </div>
      </div>

      <div className="group_score">
        <div className="tab_text_cont">
          <div className="tab_cont">
            {tabs.map((tab, index) => (
              <div
                className={['tab', tab.key + 1 === tabActive ? 'active' : void 0].join(' ')}
                key={index}
                onClick={() => tabClick(tab.key + 1)}
              >
                {tab.name}
              </div>
            ))}
          </div>
          <p className="txt">
            我的小组：{stuGroup.groupName || '-'} 综合得分{stuGroup.scoreTotal || '-'}分，排名第
            <span className="num">{stuGroup.index + 1 || '-'}</span>
          </p>
        </div>
        <div className="group_rank">
          {groupsList.map((item, index) => (
            <div
              className="group_item"
              style={{
                width: (item.scoreTotal / 2 > 100 ? 100 : item.scoreTotal / 2 < 0 ? 0 : item.scoreTotal / 2) + '%',
              }}
              key={index}
            >
              <div className="title">
                <span className="label">{item.groupName}</span>
                <span className="num">{item.scoreTotal}分</span>
              </div>
              <div className={['progress_bar', index === stuGroup.index ? 'my_group' : void 0].join(' ')}>
                {index < 3 ? <img src={require(`@/assets/makeScore/rank${index + 1}.png`).default} alt="icon" /> : null}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GroupsScoreRank;
