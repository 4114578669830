/*
 * @Author: your name
 * @Date: 2022-03-14 09:44:02
 * @LastEditTime: 2022-04-06 15:48:59
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newGitZnxt2.0\src\components\answerList\components\wordDialog\index.jsx
 */

import "./index.less";
import Presenter from "./presenter";
import { SoundOutlined } from "@ant-design/icons";

const wordDialog = (props) => {
  const { info, wordAnswerData } = props;
  const [states, events] = Presenter(props);
  const { examSteam, title, translateList } = states;

  const { handleNext, playMp3 } = events;
  const url = "https://file.aiznclass.com/word";

  return (
    <div className="dialogPage">
      <div className="wordStem">{info?.word}</div>

      {info?.phAm.length || info?.phEn.length ? (
        <div className="transliterationBox">
          <audio id="musicUSA" src={url + info?.phAmMp3} />
          <audio id="musicUK" src={url + info?.phEnMp3} />
          <div className="usa" onClick={() => playMp3("USA")}>
            <div className="borderBox">美</div>
            {info?.phAm.length ? <div>[{info?.phAm}]</div> : ""}
            {info?.phAmMp3.length ? <SoundOutlined className="iconBox" /> : ""}
          </div>
          <div className="uk" onClick={() => playMp3("UK")}>
            {info?.phEn.length ? <div className="borderBox">英</div> : ""}
            {info?.phEn.length ? <div>[{info?.phEn}]</div> : ""}
            {info?.phEnMp3.length ? <SoundOutlined className="iconBox" /> : ""}
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="contentBox">
        <div className="typesBox">
          <p className="titleBox">
            <span className="line"></span>
            释义
          </p>
          {translateList.map((v, i) => (
            <div key={i}>
              <p>{v}</p>
            </div>
          ))}
        </div>
        <div className="typesBox">
          <p className="titleBox">
            <span className="line"></span>
            例句
          </p>
          {info?.newSentence.slice(0, 3).map((v, i) => (
            <div key={i}>
              <p>
                {v.en}
                <span className="chinese">{v.cn}</span>
              </p>
            </div>
          ))}
        </div>
        {/* <div className="typesBox">
        <p className="titleBox">词根词缀</p>
        <p>
          batter <span className="chinese">牛奶面糊；击球员</span>
        </p>
        <p>
          battle <span className="chinese">战役，战斗；斗争</span>
        </p>
        <p>
          battalion <span className="chinese">常</span>
        </p>
        <p>
          bat <span className="chinese">打，击+er反复做——batter接连重击</span>
        </p>
      </div> */}
      </div>

      {!props?.isStatistics ? (
        <div className="nextBox" onClick={() => handleNext(title)}>
          <>{title}</>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default wordDialog;
