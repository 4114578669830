import { Upload, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Presenter from "./presenter";
import "./index.less";
import { Empty } from "antd";
import { isApp } from "@/util/util";
import {
  CameraOutlined,
  CloseCircleFilled,
  AudioOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Image } from "antd";
import Record from "@/components/answerList/components/record";
import { UploadOutlined } from '@ant-design/icons';

const UploadFile = (props) => {
  const [states, events] = Presenter(props);
  const {
    previewVisible,
    previewImage,
    fileList,
    previewTitle,
    isDisable,
    inputDom,
    info,
    openAudioType,
  } = states;
  const {
    handleCancel,
    handlePreview,
    beforeUpload,
    onRemove,
    handlerCamera,
    closeAudio,
    tiggerPadRecord,
    playAndPauseAudio,
    audioEnded,
    previewImageHandler,
  } = events;
  const maxImageNumber = info?.answerType === 5 ? 2 : 4;
  const uploadFileText = "请上传答案";
  const uploadButton = (
    <div className="uploadBtn">
      {/* <i className="ri-camera-2-line icon"></i> */}
      {/* <UploadOutlined className="icon" /> */}
      <svg t="1683613507309" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7000" ><path d="M829 798.8H239.8c-61.2 0-111-47.1-111-105.1V268.2c0-57.9 49.8-105 111-105h70.1c9.5 0 18.6-3.5 24.9-9.4L410 82h245.1l91.6 72c7.4 5.9 17.1 9.1 27.2 9.1h55c61.2 0 111 47.1 111 105v425.6c0.1 58-49.7 105.1-110.9 105.1zM239.8 211c-36.7 0-66.6 25.7-66.6 57.2v425.6c0 31.6 29.9 57.3 66.6 57.3H829c36.7 0 66.6-25.7 66.6-57.3V268.2c0-31.5-29.9-57.2-66.6-57.2h-55c-19.5 0-38.5-6.5-53.4-18.3l-79.9-62.8H426.8l-62.6 59.6c-14.5 13.9-33.8 21.5-54.3 21.5h-70.1z" fill="#828282" p-id="7001"></path><path d="M534.4 681.7c-106.2 0-192.5-86.3-192.5-192.5s86.4-192.5 192.5-192.5S726.9 383 726.9 489.2s-86.3 192.5-192.5 192.5z m0-340.7c-81.7 0-148.1 66.4-148.1 148.1 0 81.7 66.4 148.1 148.1 148.1s148.1-66.4 148.1-148.1c0-81.7-66.4-148.1-148.1-148.1z" fill="#828282" p-id="7002"></path><path d="M781.4 307.8m-32.5 0a32.5 32.5 0 1 0 65 0 32.5 32.5 0 1 0-65 0Z" fill="#828282" p-id="7003"></path></svg>
      <div className="word">
        {props?.uploadFileText?.length > 0
          ? props.uploadFileText
          : uploadFileText}
      </div>
    </div>
  );

  const style = {
    fontSize: "calc(50vh / 12)",
  };

  const closeStyle = {
    color: "rgb(255,77,79)",
    fontSize: "calc(40vh / 12)",
  };

  return (
    <div className="uploadImgCom">
      {/* answerType：1 键盘， 3拍照，4音频，5视频作答 */}
      {isApp() ? (
        <div className="cameraGrid">
          {/* 3:拍照作答，也默认拍照 */}
          {![1, 4, 5].includes(info?.answerType) &&
            fileList.map((v) => (
              <div className="cameraList" key={v.url}>
                <Image
                  src={v.url}
                  preview={false}
                  onClick={() => previewImageHandler(v.url)}
                />
                {isDisable ? null : (
                  <CloseCircleFilled
                    style={closeStyle}
                    onClick={() => onRemove(v)}
                    className="delImg"
                  />
                )}
              </div>
            ))}
          {/* 4 音频作答 */}
          {info?.answerType === 4 &&
            fileList.map((v, i) => (
              <div className="fileList_cont" key={v.url}>
                <div
                  className="file_cont"
                  onClick={(e) => playAndPauseAudio(e, i)}
                >
                  {v.playType ? (
                    <i className="music_icon ri-pause-circle-fill"></i>
                  ) : (
                    <i className="music_icon ri-play-circle-fill"></i>
                  )}
                  <audio
                    className="stu_audio"
                    src={v.url}
                    controls="controls"
                    controlsList="nodownload"
                    disablePictureInPicture={true}
                    onEnded={(e) => audioEnded(e, i)}
                  ></audio>
                </div>
                {v.playType && (
                  <img
                    className="icon1"
                    src={require("@/assets/record/audio-sheng.gif").default}
                    alt="icon"
                  />
                )}
                {v.playType && (
                  <img
                    className="icon2"
                    src={require("@/assets/record/audio-sheng.gif").default}
                    alt="icon"
                  />
                )}
                {!v.playType && (
                  <img
                    className="icon1"
                    src={require("@/assets/record/audio-sheng2.png").default}
                    alt="icon"
                  />
                )}
                {!v.playType && (
                  <img
                    className="icon2"
                    src={require("@/assets/record/audio-sheng2.png").default}
                    alt="icon"
                  />
                )}
                {isDisable ? null : (
                  <CloseCircleFilled
                    style={closeStyle}
                    onClick={() => onRemove(v)}
                    className="delImg"
                  />
                )}
              </div>
            ))}
          {/* 5视频 视频作答 */}
          {info?.answerType === 5 &&
            fileList.map((v) => (
              <div className="fileList_cont" key={v.url}>
                <video
                  src={v.url}
                  controls="controls"
                  controlsList="nodownload"
                  disablePictureInPicture={true}
                ></video>
                {isDisable ? null : (
                  <CloseCircleFilled
                    style={closeStyle}
                    onClick={() => onRemove(v)}
                    className="delImg"
                  />
                )}
              </div>
            ))}
          {/* 点击上传 */}
          {fileList?.length >= maxImageNumber || isDisable ? null : (
            <div className="cameraDiv" onClick={handlerCamera}>
              {![1, 4, 5].includes(info?.answerType) && (
                <div className="cameraDiv">
                  <CameraOutlined style={style} />
                  <span>请上传图片</span>
                </div>
              )}
              {info?.answerType === 4 && (
                <div className="cameraDiv">
                  <AudioOutlined style={style} />
                  <span>请上传音频</span>
                </div>
              )}
              {info?.answerType === 5 && (
                <div className="cameraDiv">
                  <VideoCameraOutlined style={style} />
                  <span>请上传视频</span>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <Upload
          ref={inputDom}
          // showUploadList={{ showRemoveIcon: false }}
          accept="image/*"
          customRequest={() => { }}
          showUploadList={{
            showRemoveIcon: isDisable ? false : true,
            removeIcon: isDisable ? "" : <i className="ri-close-line"></i>,
          }}
          className={isDisable ? "disbableUploadBtn" : ""}
          listType="picture-card"
          fileList={fileList}
          onPreview={handlePreview}
          beforeUpload={beforeUpload}
          onRemove={onRemove}
        >
          {fileList?.length >= maxImageNumber ? null : uploadButton}
        </Upload>
      )}

      {/* 音频遮框 */}
      {openAudioType && (
        <Record onClose={closeAudio} onTiggerRecord={tiggerPadRecord}></Record>
      )}

      {fileList?.length === 0 && isDisable ? (
        <div className="noData">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="暂无数据" />
        </div>
      ) : (
        ""
      )}

      {/* 图片预览 */}
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
        width="90vw"
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </div>
  );
};

export default UploadFile;
