import "./index.less"
import React from "react"

function DictionaryComment({ word, comment, textCenter, style, state, children, bottom, className, ...props }) {

    const regex = /(n\.|adj\.|adv\.|abbr\.|pron\.|num\.|v\.|vt\.|art\.|prep\.|conj\.|int\.|vi\.|aux.v|int\.|u\.|c\.|pl\.|det\.|interj\.|aux\.)/g;
    let text = comment;
    let processComment = text && text?.replace(regex, '<span>$1</span>')

    return (
        <div className="dictionaryCommentComponent">
            <div
                className={
                    `dictionary-comment${textCenter ? " dictionary-comment-TextCenter " : ""}${' ' + state || ""}${' ' + className || ""}`
                }
                style={{ marginBottom: `calc(${bottom || 0}vh / 12)`, ...style }}
                {...props}
            >
                {
                    word && <span>{word}</span>
                }
                {
                    children && <div>{children}</div>
                }
                {
                    comment && <p className="comment" dangerouslySetInnerHTML={{ __html: processComment }} />
                }
            </div >
        </div>
    )
}

export default React.memo(DictionaryComment)