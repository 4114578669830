import "./index.less"
import Presenter from "./presenter"
import { useHistory } from 'react-router-dom'




function SubjectEntry(props) {
    const history = useHistory()

    const [states, events] = Presenter(props)
    const { subjectIconData } = states
    const { list, obj } = props

    return (
        <>
            <div className="SubjectEntryWarp">
                <div className="title">
                    <div className="text">近一周错题</div>
                    <div className="entery" onClick={() => history.push(`/mistakesBank?studentId=${obj.studentId}`)}>{"进入错题库>"}</div>

                    <img className="errorBook" src={require("@/assets/errorsBook/errorBook.png").default} alt="_" />
                </div>
                <div className="content">
                    {
                        list && list.length !== 0 ? list.map((item, index) => (
                            <div className="card" key={index} onClick={() => {
                                if (item.count === 0) return
                                history.push(
                                    `/mistakesRearrange?userStudentId=${obj.studentId}&baseSubjectId=${item.subjectId}&dateType=${1}&startTime=${''}&endTime=${''}&clear=${2}&examType=${0}&size=${1}&lastId=${0}&isAnswer=0`
                                )
                            }
                            }>
                                <div className="icon">
                                    {
                                        subjectIconData.map((subItem, i) => item.subjectName === subItem.name ? (
                                            <img key={i} src={require(`@/assets/errorsBook/${subItem.icon}_${item.count === 0 ? '1' : '0'}.png`).default} alt="_" />
                                        ) : null) || (<img src={require(`@/assets/errorsBook/other.png`).default} alt="_" />)
                                    }
                                    {
                                        subjectIconData.some((sub) => {
                                            if (sub.name === item.subjectName) {
                                                return true
                                            }
                                            return false
                                        }) ? null : <img src={require(`@/assets/errorsBook/other.png`).default} alt="_" />
                                    }
                                </div>
                                <div className="info">
                                    <div className="name">{item.subjectName}</div>
                                    <div className="wait">待&nbsp;&nbsp;&nbsp;清 : <em style={item.count === 0 ? { color: '#979797', border: 0 } : null}>{item.count}</em></div>
                                    <div className="total">总错题 : {item.totalCount}</div>
                                </div>
                            </div>
                        )) : null
                    }
                </div>
            </div>
        </>
    )
}

export default SubjectEntry