/*
 * @Author: your name
 * @Date: 2022-02-07 11:18:35
 * @LastEditTime: 2022-02-08 10:33:14
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /znxt-student2.0/src/components/answerList/components/record/index.jsx
 */
import React from "react";
import Presenter from "./presenter.js";
import "./index.less";

const Record = (props) => {
  const [states, events] = Presenter(props);
  const { recordType, mp3Time } = states;
  const { changeRecordType } = events;
  const { onClose } = props;

  return (
    <div className="recorder_view_page">
      <div className="masking" onClick={() => onClose(false)}></div>
      <div className="recorder_view">
        {recordType === 1 && (
          <div className="record_cont record_cont1">
            <div className="icon_cont" onClick={() => changeRecordType(2)}>
              <i className="icon ri-mic-fill"></i>
            </div>
            <div className="txt1">点击录音</div>
            <div className="txt2">最长时间为5分钟，请保挂屏幕常亮</div>
          </div>
        )}
        {recordType === 2 && (
          <div className="record_cont record_cont2">
            <div className="timer">
              <img
                className="left"
                src={require("@/assets/record/record_open.gif").default}
                alt="png"
              />
              <div className="time">{mp3Time}</div>
              <img
                className="right"
                src={require("@/assets/record/record_open.gif").default}
                alt="png"
              />
            </div>
            <div className="icons">
              <div className="btn_icon" onClick={() => changeRecordType(3)}>
                <i className="ri-stop-fill"></i>
              </div>
            </div>
          </div>
        )}
        {/* {(recordType === 3 || recordType === 4) && (
          <div className="record_cont record_cont3">
            <div className="timer">
              <img className="left" src={require('@/assets/record/record_close.png').default} alt="png" />
              <div className="time">01:21</div>
              <img className="right" src={require('@/assets/record/record_close.png').default} alt="png" />
            </div>
            <div className="icons">
              <div className="btn_icon" onClick={() => changeRecordType(1)}>
                <div className="btn btn1">
                  <i class="ri-loader-3-fill"></i>
                </div>
                <span>重录</span>
              </div>
              <div className="btn_icon icon2">
                <div className="btn btn2">
                  {recordType === 3 && <i class="ri-play-fill" onClick={() => changeRecordType(4)}></i>}
                  {recordType === 4 && <i class="ri-stop-fill" onClick={() => changeRecordType(3)}></i>}
                </div>
                <span> {recordType === 3 ? '播放' : '暂停'}</span>
              </div>
              <div className="btn_icon icon3" onClick={() => onClose(false)}>
                <div className="btn btn3">
                  <i class="ri-check-line"></i>
                </div>
                <span>完成</span>
              </div>
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default Record;
