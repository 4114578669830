import React from "react";
import Presenter from "./presenter.js";
import "./index.less";
import PadHeader from "@/components/padHeader";
import { Button, Modal } from "antd";
import { dateFormat, isApp } from "@/util/util";

const VideoPlayback = (props) => {
  const [states, events] = Presenter(props);
  const { videosObj } = states;
  //   const {} = events;

  return (
    <div className="video_playback_cont">
      <PadHeader name={"直播回放"}></PadHeader>
      <div className="video_data_cont">
        <div className="video_cont">
          <div className="title">屏幕回放</div>
          <div className="list">
            {videosObj.screenVod.map((url, i) => (
              <div className="video" key={i}>
                <video
                  src={url}
                  controls="controls"
                  controlsList="nodownload"
                  disablePictureInPicture={true}
                ></video>
              </div>
            ))}
          </div>
        </div>
        <div className="video_cont">
          <div className="title">摄像头回放</div>
          <div className="list">
            {videosObj.cameraVod.map((url, i) => (
              <div className="video" key={i}>
                <video
                  src={url}
                  controls="controls"
                  controlsList="nodownload"
                  disablePictureInPicture={true}
                ></video>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayback;
