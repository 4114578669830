import request from "../util/request";

export function getWordHome(params) {
    return request({
        url: "/class/v2/student/word/home/statistics",
        method: "get",
        params
    });
}


// 调整首页学习计划
export function wordSetPlan(params) {

    return request({
        url: "/class/v2/student/word/set/plan",
        method: "get",
        params
    });
}

// 章节 高频 复习 （单词列表） 
export function getWordList(params) {
    return request({
        url: "/class/v2/student/word/list",
        method: "get",
        params,
        meta: {
            notNeedSplit: true
        }
    });
}

export function getExamList(params) {
    return request({
        url: "/class/v2/student/word/exam/list",
        method: "get",
        params
    })
}

export function getWordInfo(params) {
    return request({
        url: "/class/v2/student/word/detail",
        method: "get",
        params
    })
}

export function getExamAnswer(params) {
    return request({
        url: "/class/v2/student/word/exam/answer",
        method: "get",
        params
    })
}

// 学习记录 日历
export function getStudyRecord(params) {
    return request({
        url: "/class/v2/student/word/learn/record",
        method: "get",
        params
    })
}

// 复习单词列表 
export function getReviewWordList(params) {
    return request({
        url: "/class/v2/student/review/word/list",
        method: "get",
        params,
        meta: {
            notNeedSplit: true
        }
    })
}

// 获取所有复习单词的id 来调取作答试题
export function getReviewWordAllIds(params) {
    let url = params?.dayTime ? "/class/v2/student/review/word/exam/list" : '/class/v2/student/word/learn/record/detail/exams'

    return request({
        url,
        method: "get",
        params
    })
}

// 章节列表
export function getChapterList(params) {
    return request({
        url: "/class/v2/student/word/chapter/statistics",
        method: "get",
        params
    })
}

// 年级学册
export function getVolumesList(params) {
    return request({
        url: "/class/v2/customer/school/version/volumes",
        method: "get",
        params
    })
}

// 英语角榜单
export function getStudentWordRank(params) {
    return request({
        url: "/class/v2/student/word/learn/rank",
        method: "get",
        params
    })
}

export function getStudentWordRecord(params) {
    return request({
        url: "/class/v2/student/word/learn/record",
        method: "get",
        params
    })
}

// 日历右侧学习的记录
export function getStudentWordStudyDetail(params) {
    return request({
        url: "/class/v2/student/word/learn/record/detail",
        method: "get",
        params
    })
}

// 英语角 个人数据
export function getStudentWordStatistics(params) {
    return request({
        url: "/class/v2/student/word/statistics",
        method: "get",
        params
    })
}

// 学习动态
export function getLearnDynamics(params) {
    return request({
        url: "/class/v2/student/word/learn/dynamics",
        method: "get",
        params
    })
}
// 点赞
export function getStudentLearnLike(params) {
    return request({
        url: "/class/v2/student/word/learn/like",
        method: "get",
        params
    })
}

// 个性化学习单词配置
export function setWordStyleState(params) {
    return request({
        url: "/class/v2/student/word/style/update",
        method: "get",
        params
    })
}

// 单词收藏
export function addCollectWord(params) {
    return request({
        url: "/class/v2/stu/lesson/student/collection/add",
        method: 'get',
        params
    })
}

// 单词取消收藏
export function delCollectWord(params) {
    return request({
        url: "/class/v2/stu/lesson/student/collection/del",
        method: 'get',
        params
    })
}

// 获取收藏单词列表
export function getCollectWordsList(params) {
    return request({
        url: "/class/v2/stu/lesson/student/collection/list",
        method: "get",
        params
    })
}
