import ChioceExam from "@/components/answerList/components/chioceExam";
import FillBackExam from "@/components/answerList/components/fillBackExam";
import JudgeExam from "@/components/answerList/components/judgeExam";
import CorrectResult from "@/components/answerList/components/correctResult";
import SevenSelectedFive from "@/components/answerList/components/sevenSelectedFive";
import ExamSteam from "../examSteam";
import Presenter from "./presenter";
import UploadFile from "../uploadFile";
import { Switch } from "antd";
import { getParams } from "@/util/util";

import "./index.less";

const Comprehensive = (props) => {
  const { exam, isSubmit, examTypeId, isCorrect, index, isMistakes } = props;

  const [states, events] = Presenter(props);

  const { imgList, defaultChecked } = states;
  const {
    submitAnswerInfo,
    updateAnswer,
    submitAnswerData,
    isHaveSubjective,
    onChange,
  } = events;

  let obj = getParams(window.location.href)
  let isAutonomyPractice = obj.isAutonomyPractice ? JSON.parse(obj.isAutonomyPractice) : false;


  // 综合题的小题：里面没有拍照作答，都是整体拍照作答
  // console.log(exam?.resource?.exam?.children,'-----children');

  return (
    <div className={isMistakes ? "comprehensive mistakes" : "comprehensive"}>
      {/* 综合题题干 */}
      <div className="allChildren">
        {exam?.resource?.exam?.examTypeId !== 18
          ? exam?.resource?.exam?.children?.map((v, i) => (
            <div className="childrenItem" key={i}>
              <p className="childrenSort">{"小题" + (i + 1) + ":"}</p>
              <div className="examArea">
                {v.examTypeId === 1 || v.examTypeId === 2 ? (
                  // 单选、多选
                  <ChioceExam
                    inExamSeq={i + 1}
                    info={v}
                    childrenAnswer={exam?.childrenAnswer}
                    isSubmit={isSubmit}
                    submitAnswerInfo={submitAnswerInfo}
                    isMistakes={isMistakes}
                  ></ChioceExam>
                ) : v.examTypeId === 3 ? (
                  // 判断
                  <JudgeExam
                    inExamSeq={i + 1}
                    info={v}
                    isSubmit={isSubmit}
                    submitAnswerInfo={submitAnswerInfo}
                    isMistakes={isMistakes}
                  ></JudgeExam>
                ) : (
                  // : v.examTypeId === 16 ? ( // 简答题现在不作答了，把所有的主观小题的作答聚合
                  //   <FillBackExam
                  //     inExamSeq={i + 1}
                  //     info={v}
                  //     isSubmit={
                  //       // exam?.resource?.exam?.examTypeId === 16
                  //       //   ? true
                  //       //   : isSubmit
                  //       isSubmit
                  //     }
                  //     submitAnswerInfo={submitAnswerInfo}
                  //   ></FillBackExam>
                  // ) : v.examTypeId === 4 || v.examTypeId === 6 ? (
                  //   // 填空和简答
                  //   <FillBackExam
                  //     inExamSeq={i + 1}
                  //     info={v}
                  //     isSubmit={isSubmit}
                  //     submitAnswerInfo={submitAnswerInfo}
                  //   ></FillBackExam>
                  // ) : (
                  //   ""
                  // )}

                  <ExamSteam info={v?.examStem} isMistakes={isMistakes}></ExamSteam>
                )}

                {/* 批改结果的展示 */}
                {isCorrect ? (
                  <CorrectResult info={v} sort={index} isMistakes={isMistakes}></CorrectResult>
                ) : (
                  ""
                )}
              </div>
            </div>
          ))
          : ""}

        {/* 综合题-拍照作答 */}
        {/* 还需要判断综合题里面是不是有主观题，如果都没有主观题的话，是不需要展示拍照作答的入口的 */}
        {exam?.resource?.exam?.examTypeId === 17 ||
          (exam?.resource?.exam?.examTypeId === 16 &&
            isHaveSubjective(exam?.resource?.exam?.children)) ? (
          <>
            {
              isAutonomyPractice ? null
                :
                <div className="aaa">
                  {isSubmit || isCorrect ? (
                    isMistakes ? null : <div className="switch">
                      <span>批改痕迹</span>
                      <Switch checked={defaultChecked} onChange={onChange} />
                    </div>
                  ) : (
                    ""
                  )}
                  <UploadFile
                    imgList={imgList}
                    isDisable={isSubmit || defaultChecked}
                    info={exam}
                    updateAnswer={updateAnswer}
                    isMistakes={isMistakes}
                    isSubmit={isSubmit}
                  ></UploadFile>
                </div>
            }
          </>

        ) : (
          ""
        )}

        {/* 七选五 */}
        {exam?.resource?.exam?.examTypeId === 18 ? (
          <SevenSelectedFive
            isSubmit={isSubmit}
            info={exam}
            submitAnswerInfo={submitAnswerData}
            isMistakes={isMistakes}
          ></SevenSelectedFive>
        ) : (
          ""
        )}
        {/* 批改结果的展示 */}
        {/* {isCorrect ? <CorrectResult info={v} sort={index}></CorrectResul t> : ""} */}

        <div className="box"></div>
      </div>
    </div>
  );
};

export default Comprehensive;
