import { useEffect, useMemo, useRef, useState } from "react"
import { useHistory } from 'react-router-dom';
import { getParams } from "@/util/util"
import { getWordList, getStudentWordStudyDetail, setWordStyleState, addCollectWord, delCollectWord } from "@/api/memorizeWords"
import store from "@/redux/store"
import { CLEAR_STUDY_RECORDER, SET_STUDY_STYLE } from "@/redux/constant"




const Presenter = (props) => {
    const query = getParams(window.location.href);
    const history = useHistory()
    const [wordMenuList, setWordMenuList] = useState([])
    const [wordMenuActive, setWordMenuActive] = useState(0)
    const [wordModalVisible, setWordModalVisible] = useState(false)
    const [answerList, setAnswerList] = useState([])
    const [studyModalVisible, setStudyModalVisible] = useState(false)
    const [completeModalVisible, setCompleteModalVisible] = useState(false)
    const [schedule, setSchedule] = useState(0)
    const [lastId, setLastId] = useState(0)

    const wordDetail = useMemo(() => {
        return wordMenuList[wordMenuActive]
    }, [wordMenuList, wordMenuActive])

    const translateList = useMemo(() => {
        if (wordDetail?.cn) {
            return wordDetail?.cn?.split(';')
        } else {
            return []
        }
    }, [wordDetail])

    const studySecondRefDOM = useRef(null)

    const examIds = useMemo(() => {
        let whatMode = store.getState().studyWords.model === 0
        console.log(whatMode, store.getState().studyWords.model);
        let examIds = wordMenuList?.map(v => {
            let idlist = []
            // 判断是否是普通模式
            if (whatMode) {
                idlist = v?.examIds?.filter(j => j.examTypeId === 22 || j.examTypeId === 23).map(s => s.ids[0])
            } else {
                let isExam = v?.examIds.some(j => j.examTypeId === 27 || j.examTypeId === 28)
                if (isExam) {
                    idlist = v?.examIds?.filter(j => j.examTypeId === 27 || j.examTypeId === 28).map(s => s.ids[0])
                } else {
                    idlist = v?.examIds?.filter(j => j.examTypeId === 22 || j.examTypeId === 23).map(s => s.ids[0])
                }
            }

            return {
                ids: idlist,
                id: v.id,
                wordId: v.wordId
            }
        })

        return JSON.stringify(examIds)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wordMenuList, store.getState().studyWords.model]);

    const wordMenuChange = (i, e) => {
        const element = e.target
        if (studySecondRefDOM.current) {
            studySecondRefDOM.current.scrollTop = 0
        }
        element.scrollIntoView({
            behavior: "smooth",
            block: "center"
        })
        setWordMenuActive(i)
    }

    // 切换学习方式
    const switchStudyWay = () => {
        let params = {
            studentId: query?.studentId,
            style: store.getState().studyWords.style === 1 ? 2 : 1,
            model: store.getState().studyWords.model
        }
        setWordStyleState(params).then(res => {
            store.dispatch({
                type: SET_STUDY_STYLE,
                data: {
                    style: store.getState().studyWords.style === 1 ? 2 : 1,
                    model: store.getState().studyWords.model
                }
            })
        })
    }

    // 切换测试模式
    const switchTestWay = () => {
        let params = {
            studentId: query?.studentId,
            style: store.getState().studyWords.style,
            model: store.getState().studyWords.model === 0 ? 1 : 0
        }
        setWordStyleState(params).then(res => {
            store.dispatch({
                type: SET_STUDY_STYLE,
                data: {
                    style: store.getState().studyWords.style,
                    model: store.getState().studyWords.model === 0 ? 1 : 0
                }
            })
        })
    }

    // 打开单词详情对话框
    const openWordModal = (i) => {
        setWordMenuActive(i)
        setWordModalVisible(true)
    }

    // 关闭单词详情框
    const offWordModal = () => {
        setWordModalVisible(false)
    }

    const goBack = () => {
        history.replace(`/memorizeWords?studentId=${query.studentId}&studentName=${query.studentName}&schoolId=${query.schoolId}&gradeId=${query.gradeId}&classId=${query.classId}`);
        store.dispatch({
            type: CLEAR_STUDY_RECORDER,
            data: null
        })
    }

    const exitStudy = () => {
        let answer = store.getState()?.studyWords.answeringResults;
        if (answer.length !== 0) {
            setAnswerList(answer)
            setStudyModalVisible(true)
        } else {
            history.goBack();
        }
    }

    const offStudyModal = () => {
        setStudyModalVisible(false)
    }

    const goRecords = () => {
        history.replace(`/studyWordsWay?studentId=${query?.studentId}&studentName=${query?.studentName}&gradeId=${query?.gradeId}&classId=${query?.classId}&schoolId=${query?.schoolId}&chapterId=${query?.chapterId}`);
        history.go(0); //刷新页面
    }

    useEffect(() => {
        if (query?.type) {
            let params = {
                studentId: query?.studentId,
                chapterId: query?.chapterId || 0,
                gradeId: query?.gradeId,
                type: query?.type,
                size: 10,
                dayTime: query?.dayTime || 0
            }

            getWordList(params).then(res => {
                setWordMenuList(res?.data || [])
                setSchedule(res?.meta?.count || 0)

                if (res?.data?.length === 0) {
                    setCompleteModalVisible(true)
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query?.type])

    useEffect(() => {
        if (!query?.type) {
            getStudentWordLearnedList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastId])

    const getStudentWordLearnedList = () => {
        let params = {
            studentId: query?.studentId,
            chapterId: query?.chapterId,
            dayTime: 0,
            size: 12,
            lastId: lastId
        }
        getStudentWordStudyDetail(params).then(data => {
            let res = (data || []).map(v => ({ ...v.esWordDto, id: v.id }))
            if (lastId !== 0) {
                setWordMenuList(list => [...list, ...(res || [])])
            } else {
                setWordMenuList(() => res || [])
            }
        })
    }

    const loadMoreLearnedWordList = () => {
        setLastId(wordMenuList[wordMenuList.length - 1]['id'])
    }

    // 单词收藏事件处理
    const onCollectHandle = (flage, info, callBack) => {
        let paramsObj = getParams(window.location.href)

        let params = {
            studentId: paramsObj.studentId,
            subjectId: 0,
            type: 3,
            examId: info?.word || info?.examId
        }

        if (flage) {
            addCollectWord(params).then(res => {
                callBack && callBack(flage, info)
            })
        } else {
            delCollectWord(params).then(res => {
                callBack && callBack(flage, info)
            })
        }
    }

    const setCollectWordListHandle = (flage, index) => {
        setWordMenuList(wordMenuList.map((v, i) => i === index ? { ...v, collection: flage } : v))
    }

    return [
        { wordMenuList, wordMenuActive, wordDetail, translateList, wordModalVisible, examIds, studyModalVisible, answerList, completeModalVisible, schedule, studySecondRefDOM },
        {
            wordMenuChange,
            switchStudyWay,
            openWordModal,
            offWordModal,
            exitStudy,
            goBack,
            offStudyModal,
            goRecords,
            getStudentWordLearnedList,
            loadMoreLearnedWordList,
            switchTestWay,
            onCollectHandle,
            setCollectWordListHandle
        }
    ]
}

export default Presenter