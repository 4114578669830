/*
 * @Author: your name
 * @Date: 2021-07-22 11:08:17
 * @LastEditTime: 2021-07-28 16:17:20
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /znxt-student2.0/src/components/templateArea/presenter.js
 */

import { answerModel } from "@/api/answer";

import { useState, useEffect } from "react";

const Presenter = (props) => {
  const { courseDetail } = props;

  const [modles, setmodles] = useState([]);

  var timer = null;

  useEffect(() => {
    setmodles(courseDetail?.models || []);
  }, [courseDetail]);

  const onChange = (e, index) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      let modlesCopy = [...modles];
      modlesCopy[index].c = e.target.value;
      setmodles(modlesCopy);
      answerModel({
        id: courseDetail.infoId,
        info: JSON.stringify(modlesCopy),
      }).then((res) => {});
    }, 300);
  };

  return [
    {},
    {
      onChange,
    },
  ];
};

export default Presenter;
