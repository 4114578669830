/*
 * @Description: 电子课本
 * @FilePath: \newGitZnxt2.0\src\pages\keyboardPage\presenter.js
 */

import { useState, useEffect } from 'react';
import { getParams } from '@/util/util';
import { updataBook } from '@/api/groupScoring';

const Presenter = () => {
  const [bookData, setBookData] = useState({});
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(1);

  let urlObj = getParams(window.location.href);

  // pdf数据
  const getElecBookData = () => {
    let obj = {
      file: {
        id: urlObj.id,
        name: urlObj.bookName,
        convertResourceUrl: urlObj.bookUrl,
        resourceUrl: urlObj.bookUrl,
        subjectId: urlObj.subjectId,
      },
      onChangePage(page, pdfDocument) {
        setPage(page);
        setNumPages(pdfDocument?.numPages || 0);
      },
    };
    setBookData(obj);
  };

  // 更新进度
  const updataBookProgress = () => {
    let params = {
      ebookId: urlObj.id,
      type: 1, // 1学生，2老师
      studentId: urlObj.studentId,
      pageIndex: page,
      pageTotal: numPages,
    };
    updataBook(params).then((res) => {
      //
    });
  };

  useEffect(() => {
    getElecBookData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (page || numPages) updataBookProgress();
  }, [page, numPages]); // eslint-disable-line react-hooks/exhaustive-deps

  return [{ bookData }];
};

export default Presenter;
