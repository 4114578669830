/*
 * @Author: your name
 * @Date: 2021-08-05 18:37:00
 * @LastEditTime: 2022-04-29 11:41:11
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \znxt2.0\src\components\subjectSelect\presenter.js
 */

import { useState, useEffect, useRef } from "react";

const Presenter = (props) => {
  const inputValue = useRef("");
  const timerRef = useRef(null);
  const [lowercase, setLowercase] = useState([
    ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"],
    ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p"],
    ["a", "s", "d", "f", "g", "h", "j", "k", "l"],
    [
      "ri-font-size",
      "z",
      "x",
      "c",
      "v",
      "b",
      "n",
      "m",
      "ri-delete-back-2-line",
    ],
    ["'", ",", "?", ";", ".", "!", ":", "ri-space", "ri-check-line"],
  ]);
  const [caseType, setCaseType] = useState(1);
  const keyObj = {
    "ri-font-size": "大小写",
    "ri-delete-back-2-line": "删除",
    "ri-space": "空格",
    "ri-check-line": "对号",
  };

  const clickKey = (el) => {
    if (el === "ri-font-size") {
      // 大小写
      if (caseType === 1) {
        setCaseType(2);
        setLowercase([
          ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"],
          ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"],
          ["A", "S", "D", "F", "G", "H", "J", "K", "L"],
          [
            "ri-font-size",
            "Z",
            "X",
            "C",
            "V",
            "B",
            "N",
            "M",
            "ri-delete-back-2-line",
          ],
          ["'", ",", "?", ";", ".", "!", ":", "ri-space", "ri-check-line"],
        ]);
      } else {
        setCaseType(1);
        setLowercase([
          ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"],
          ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p"],
          ["a", "s", "d", "f", "g", "h", "j", "k", "l"],
          [
            "ri-font-size",
            "z",
            "x",
            "c",
            "v",
            "b",
            "n",
            "m",
            "ri-delete-back-2-line",
          ],
          ["'", ",", "?", ";", ".", "!", ":", "ri-space", "ri-check-line"],
        ]);
      }
    } else if (el === "ri-delete-back-2-line") {
      // 删除
      props?.delInputValue && props?.delInputValue();
    } else if (el === "ri-space") {
      // 空格
      props?.inputOnBlank && props?.inputOnBlank(" ");
    } else if (el === "ri-check-line") {
      // 对号
      props?.inputOnTrue && props?.inputOnTrue();
      props?.complete && props?.complete()
    } else if (el === "close") {
      props?.closeKeyBoard && props?.closeKeyBoard(false);
    } else {
      props?.changeInputValue && props?.changeInputValue(el);
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        props?.inputOnTrue && props?.inputOnTrue();
      }, 500);
    }
  };

  const switchClick = () => {
    props.onSwitch(0);
  };

  useEffect(() => { }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return [
    { lowercase, keyObj, caseType, inputValue },
    { clickKey, switchClick },
  ];
};

export default Presenter;
