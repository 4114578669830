import { Collapse } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import PadHeader from "@/components/padHeader";
import "./index.less"

const { Panel } = Collapse;


function IterationInfor() {

    const [openKeyIndx, setOpenKeyIndx] = useState(0)

    const onChange = (key) => {
        setOpenKeyIndx(key)
    };

    const textIndent = `        `;

    const list = [
        {
            header: (
                <>
                    <span className='vCode'>V3.0.3.0 主要更新</span>
                    <span className='updateTime'>更新时间：2023-10-13</span>
                </>
            ),
            text: `1、新增【背单词】功能，根据单词发音拼写单词，然后选择正确的单词释义。

        2、上传试题新增【听写句子】题型，学生根据音频默写句子，提交后系统自动批改判断对错。
        
        3、修复线上反馈问题

        欢迎师生及时更新体验新功能，并向我们提出改进建议。`
        },
        {
            header: (
                <>
                    <span className='vCode'>V3.0.2.6 主要更新</span>
                    <span className='updateTime'>更新时间：2023-10-07</span>
                </>
            ),
            text: `1、早读上报：学生每日早读后提交各学科早读完成情况，可查看近七天早读数据。

        2、错题打标签：学生可以创建错题本标签，并为错题打上标签。可以根据标签搜索错题。`
        },
        {
            header: (
                <>
                    <span className='vCode'>V3.0.2.0 主要更新</span>
                    <span className='updateTime'>更新时间：2023-08-25</span>
                </>
            ),
            text: `修复线上反馈bug`
        },
        {
            header: (
                <>
                    <span className='vCode'>V3.0.1.9 主要更新</span>
                    <span className='updateTime'>更新时间：2023-08-22</span>
                </>
            ),
            text: `解决记单词已知问题`
        },
        {
            header: (
                <>
                    <span className='vCode'>V3.0.1.8 主要更新</span>
                    <span className='updateTime'>更新时间：2023-08-18</span>
                </>
            ),
            text: `AI智囊学堂学生端上线了“记单词”模块，该模块采用先记忆后检测记忆效果的方式记忆单词，并利用艾宾浩斯记忆遗忘曲线法辅助学生复习单词。该模块共分为四个板块，分别是章节同步练习、高频单词练习、学习记录和英语角。主要内容如下：
     
        1、章节同步练习：该板块的单词与教材同步，学生可自己选择教材章节背诵单词。

        2、高频单词练习：将学生考试和学习中常用且难度较大的单词进行了汇总，学生可在此板块进行单词的专项记忆。

        3、学习记录：本版块根据艾宾浩斯记忆法，提醒学生复习学过的单词，达到巩固单词记忆的效果；学生也可在本模块按天查询自己的学习记录。

        4、英语角：本版块记录学生的单词学习数据和班级的单词学习数据，本版块各种与单词相关的榜单可起到激励学生记忆单词的效果。
    
        请更新客户端，及时体验新功能。`
        },
    ]
    return (
        <div className="IterationInforPage">
            <PadHeader>
                <div className="pageHeaderBar">
                    <div className="title">版本更新介绍</div>
                </div>
            </PadHeader>

            <div className="substanceBox">
                <Collapse accordion defaultActiveKey={['0']} onChange={onChange} ghost expandIcon={({ isActive }) => <CaretDownOutlined rotate={isActive ? 0 : 90} />} expandIconPosition="right" className='Collapselist'>
                    {
                        list.map((v, i) =>
                            <Panel header={v.header} key={i} className={Number(openKeyIndx) !== i && 'line'}>
                                <div className="text">{textIndent + v.text}</div>
                            </Panel>
                        )
                    }
                </Collapse>
            </div>
        </div>
    )
}

export default IterationInfor