import Presenter from "./presenter.js";
import "./index.less";



function TopicHeader({ topicType, sort, wrongRate, iscollect, isNotCollect, onChange, ...props }) {
    const [states, events] = Presenter(props);

    return (
        <div className="TopicHeaderBar">
            <div className={wrongRate * 100 >= 0 && wrongRate * 100 < 20 ? "TopicHeader one" : wrongRate * 100 >= 20 && wrongRate * 100 < 40 ? "TopicHeader second" : "TopicHeader three"} >
                <span className="number">{sort}.</span>
                <span className="type">{topicType}</span>
                <em>/</em>
                <span className="error">错误率: {Math.floor(wrongRate * 100) + '%'}</span>
            </div>
            {
                isNotCollect &&
                <div className="collect" onClick={() => onChange(!iscollect)}>
                    {
                        iscollect ?
                            <>
                                <i className="ri-heart-3-fill"></i>
                                <span>已收藏</span>
                            </>
                            :
                            <>
                                <i className="ri-heart-3-line"></i>
                                <span>收藏</span>
                            </>
                    }
                </div>
            }
        </ div>
    )
}
// collection
export default TopicHeader