import Presenter from "./presenter.js";
import "./index.less";
import PadHeader from "@/components/padHeader";
import { Button, Modal, Empty } from "antd";
import LiveList from "@/pages/liveList/index";
import { dateFormat } from "@/util/util";
import listPng from "@/assets/newSence/list.png";

const MyCourse = (props) => {
  const [states, events] = Presenter(props);
  const {
    tabActiveIndex,
    todayLiveList,
    buyCourses,
    buyCourseItems,
    orderList,
    isCourseVisible,
  } = states;
  const { tabClick, goLiveRoom, goVideoPlayback, openLessonModal } = events;
  let tabs = ["我的课程", "所有课程", "我的订单"];
  let status = [
    "未支付",
    "已支付",
    "已取消",
    "已退款",
    "退款审核中",
    "退款审核被拒绝",
  ];
  let gradeList = [
    "",
    "一年级",
    "二年级",
    "三年级",
    "四年级",
    "五年级",
    "六年级",
    "七年级",
    "八年级",
    "九年级",
    "高一",
    "高二",
    "高三",
  ];

  return (
    <div className="my_course_cont">
      <PadHeader name={""}>
        <div className="tabs">
          {tabs.map((tab, i) => (
            <div
              className={`tab ${tabActiveIndex === i ? "active" : ""}`}
              key={i}
              onClick={() => tabClick(i)}
            >
              {tab}
            </div>
          ))}
        </div>
      </PadHeader>
      <div className="data_content">
        {tabActiveIndex === 0 && (
          <div className="course_cont course_cont1">
            {true && (
              <div className="live_today_left">
                <div className="title_cont">
                  <img
                    src={require("@/assets/live/ladder_shaped.png").default}
                    alt="icon"
                  />
                  <span>今日直播</span>
                </div>
                <div className="list_cont">
                  {/* todayLiveList */}
                  {/* {[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map((item, i) => ( */}
                  {todayLiveList.map((item, i) => (
                    <div className="course" key={i}>
                      <div className="logo_data">
                        <div className="logo">
                          <img
                            src={
                              item?.ext ||
                              require("@/assets/live/activeBg.jpg").default
                            }
                            alt=""
                          />
                        </div>
                        <div className="data">
                          <div className="h1">{item.lessonTeacherName}</div>
                          <div className="tags">
                            <div className="subject">{item.subjectName}</div>
                            <div className="name">{item.teacherName}</div>
                          </div>
                        </div>
                      </div>
                      <div className="btn_cont">
                        <Button size="large" onClick={() => goLiveRoom(item)}>
                          进入直播
                        </Button>

                        <div className="time">
                          {dateFormat(item.taskTime, "MM-dd hh:mm")}—
                          {dateFormat(item.endTime, "MM-dd hh:mm")}
                        </div>
                      </div>
                    </div>
                  ))}
                  {todayLiveList.length === 0 ? (
                    <Empty
                      image={listPng}
                      description={""}
                      imageStyle={{
                        height: 280,
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
            <div className="buyed_course_right">
              <div className="title_cont">
                <img
                  src={require("@/assets/live/ladder_shaped.png").default}
                  alt="icon"
                />
                <span>已购课程</span>
              </div>
              <div className="list_cont">
                {buyCourses.map((item, i) => (
                  <div
                    className="course_card"
                    key={i}
                    onClick={() => openLessonModal(true, item)}
                  >
                    <div
                      className="img_bg"
                      style={{
                        backgroundImage: "url(" + item.logo + ")",
                      }}
                    >
                      <div className="num">共{item.lessonNum}课次</div>
                    </div>
                    <div className="title">{item.title}</div>
                    {/* <div className="teacher_name">
                      <span className="name">孙小龙</span>
                      <span className="name">王小丽</span>
                      <span className="name">张大友</span>
                    </div> */}
                    <div className="subject">
                      <span className="txt">{gradeList[item.gradeId]}</span>
                      <span className="txt">{item.subjectName}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {tabActiveIndex === 1 && (
          <div className="course_cont course_cont2">
            <LiveList></LiveList>
          </div>
        )}
        {tabActiveIndex === 2 && (
          <div className="course_cont course_cont3">
            {orderList.map((item, i) => (
              <div className="my_order_cont" key={i}>
                {/* <div>

                </div> */}
                <div className="title_top">
                  <div className="title_left">
                    <p className="orderNumline">
                      订单号：
                      <span className="orderNum">
                        {item.orderNo || item.id}
                      </span>
                    </p>
                    <p>下单时间： {dateFormat(item.updateTime - 0)}</p>
                  </div>
                </div>
                <div className="course">
                  <div className="btn_cont">
                    <span>金额：</span>
                    <span className="num">￥{item.orderAmount}元</span>
                  </div>
                  <div className="logo_data">
                    <div className="state">{status[item.state]}</div>

                    {/* <Button type="primary">商品详情</Button> */}
                    {/* <div className="data">
                      <div className="h1">{item.courseName}</div>
                      <div className="tags">
                        <div className="subject">{gradeList[item.gradeId]}</div>
                        <div className="name">
                          {decodeURIComponent(item.userName)}
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="goodsList">
                  <p className="goodsListtitle">商品列表:</p>
                  <div>
                    {item?.goods.map((v) => (
                      <p className="itemGoods">{v.goodsName}</p>
                    ))}
                  </div>
                </div>
              </div>
            ))}
            {orderList.length === 0 && <div className="no_data">暂无订单</div>}
          </div>
        )}
      </div>
      <Modal
        title="课次"
        visible={isCourseVisible}
        width="100%"
        onCancel={() => openLessonModal(false)}
        footer={null}
      >
        <div className="course_list_cont">
          {buyCourseItems.map((item, i) => (
            <div className="course" key={i}>
              <div className="logo_data">
                {/* <div className="logo"></div> */}
                <div className="data">
                  <div className="h1">{item.name}</div>
                  <div className="chapter_name">{item.chapterName}</div>
                  <div className="time">
                    {dateFormat(item.attendClassTime - 0, "yyyy-MM-dd hh:mm")} -{" "}
                    {dateFormat(item.endClassTime - 0, "yyyy-MM-dd hh:mm")}
                  </div>
                  {/* <div className="tags">
                    <div className="subject">{item.name}</div>
                    <div className="name">李连杰</div>
                  </div> */}
                </div>
              </div>
              <div className="btn_cont">
                <Button size="large" onClick={() => goLiveRoom(item)}>
                  进入直播
                </Button>
                {(JSON.parse(item?.roomInfo)?.cameraVod ||
                  JSON.parse(item?.roomInfo)?.screenVod) && (
                  <Button size="large" onClick={() => goVideoPlayback(item)}>
                    观看回放
                  </Button>
                )}
              </div>
            </div>
          ))}
          {buyCourseItems.length === 0 && (
            <div className="no_data">暂无课次</div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default MyCourse;
