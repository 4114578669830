import "./index.less"

function EmptyBg({ text, ...props }) {

    return (
        <>
            {
                props?.visible ?

                    <div className={`emptyBgComponent`}>
                        <div className={`center ${props?.className || ''}`}>
                            {props?.children}
                            {text ? <p className="textWriting">{text}</p> : null}
                        </div>
                    </div>
                    : null
            }
        </>
    )
}

export default EmptyBg