/*
 * @Author: your name
 * @Date: 2022-03-28 14:41:48
 * @LastEditTime: 2022-03-29 15:44:08
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newGitZnxt2.0\src\pages\wordPK\components\presenter.js
 */

import { useState, useEffect, useRef } from "react";

const Presenter = (props) => {
  // 区分一个表还是两个表
  const [oneOrTwo, setOneOrTwo] = useState(1);
  // 单表
  const [wordlist, setWordList] = useState([]);
  // 两个表
  const [oneTable, setoneTable] = useState([]);
  const [twoTable, settwoTable] = useState([]);

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = () => {
    let dataList = localStorage.getItem("PKList");
    dataList = JSON.parse(dataList || "[]");

    if (dataList.length > 10) {
      let num = Math.ceil(dataList.length / 2);
      let onelist = dataList.slice(0, num);
      let twolist = dataList.slice(num, dataList.length);
      setoneTable(onelist);
      settwoTable(twolist);
      setOneOrTwo(2);
    } else {
      setOneOrTwo(1);
      setWordList(dataList);
    }
  };

  return [{ wordlist, oneOrTwo, oneTable, twoTable }, {}];
};

export default Presenter;
