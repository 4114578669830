import "./index.less";
import Presenter from "./presenter"
import { DatePicker, Radio } from 'antd';
import 'moment/locale/zh-cn';
import locale from 'antd/lib/date-picker/locale/zh_CN';

const { RangePicker } = DatePicker;

function ScreenTopic(props) {
    const { subjectListData, useLabelList } = props
    const [states, events] = Presenter(props)

    const { subjectActive, timerList, timerActive, timerDate, questionBank, questionTypes, labelName } = states
    const { subjectListChange, timerListChange, rangePickerChange, onChangeQuestionBank, onChangeQuestionType, updateSearchLabelName } = events

    return (
        <>
            <div className="ScreenTopicWarp">
                <div className="item">

                    <div className="chunk">
                        <div className="text">科目 :</div>
                        {
                            subjectListData.map((item, index) =>
                                <div className={`button${subjectActive === index ? " active" : ''}`} key={index}
                                    onClick={() => subjectListChange(index, item.baseSubjectId)}>
                                    {item.baseSubjectName}
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className="item">

                    <div className="chunk">
                        <div className="text">时间 :</div>
                        {
                            timerList.map((item, index) =>
                                <div className={`button${timerActive === index ? " active" : ''}`} key={index}
                                    onClick={() => timerListChange(index)}>
                                    {item}
                                </div>
                            )
                        }
                        <div className="m">
                            <RangePicker locale={locale} onChange={rangePickerChange} style={{ width: 'calc(280vh / 12)' }} value={timerDate} size="small" />
                        </div>
                    </div>

                    <div className="chunk">
                        <div className="text">题库 :</div>
                        <Radio.Group onChange={onChangeQuestionBank} value={questionBank} size="small">
                            <Radio value={2}>未清</Radio>
                            <Radio value={1}>已清</Radio>
                        </Radio.Group>
                    </div>

                    <div className="chunk">
                        <div className="text">题型 :</div>
                        <Radio.Group onChange={onChangeQuestionType} value={questionTypes} size="small">
                            <Radio value={0}>全部</Radio>
                            <Radio value={1}>客观题</Radio>
                            {/* <Radio value={2}>主观题</Radio> */}
                        </Radio.Group>
                    </div>
                </div>

                <div className="item">
                    <div className="chunk">
                        <div className="text">搜索标签 :</div>
                        <input type="text" className="labelInput" placeholder="输入关键词搜索标签" value={labelName} onChange={(e) => updateSearchLabelName(e.target.value)} />
                    </div>

                    <div className="chunk">
                        <div className="text">常用标签 :</div>
                        {
                            useLabelList?.map((item, index) =>
                                <div className={`button${item.labelName === labelName ? ' active' : ''}`} key={index}
                                    onClick={() => updateSearchLabelName(item.labelName)}>
                                    {item.labelName || '全部'}
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default ScreenTopic