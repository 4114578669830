import { useEffect, useRef, useState } from "react"
import mathJax from "@/util/globalVariable";
import { createlabel, getLabelList, updateWorngTopicLabel, deleteCurrentWrongLabel } from "@/api/mistakesBooks"
import { getParams } from "@/util/util"

const Presenter = (props) => {

    const { removePresentTopic } = props

    const [isShowAll, setIsShowAll] = useState(false)
    const [optionsBoxScrollY, setOptionsBoxScrollY] = useState(0)
    const [removeInfo, setRemoveInfo] = useState(null)
    const [isShowRemoveModal, setIsShowRemoveModal] = useState(false)
    const [isShowChangeLabelModal, setIsShowChangeModal] = useState(false)
    const [isShowEditLabelInput, setIsShowEditLabelInput] = useState(false)
    const createLabelInputDom = useRef(null)
    const searchLabelInputDom = useRef(null)
    const [allLabelList, setAllLabelList] = useState([])
    const [checkedLabelList, setCheckedLabelList] = useState(props.info?.labelRpcDtos || [])


    // 展开全部
    const changeIsShowAll = (flage) => {
        setIsShowAll(flage)
        if (flage) {
            setOptionsBoxScrollY(document.querySelector('#topListContent').scrollTop)
        } else {
            document.querySelector('#topListContent').scrollTop = optionsBoxScrollY
        }
    }

    // 删除此题 打开对话框
    const setRemoveInfoChange = (info) => {
        setRemoveInfo(info)
        setIsShowRemoveModal(true)
    }

    // 确认删除此题
    const setRemoveInfoOK = () => {
        if (removePresentTopic) { removePresentTopic(removeInfo) }
    }

    // 取消此题
    const setRemoveCancel = () => {
        setIsShowRemoveModal(false)
    }

    const changeIsShowLabel = (flage) => {
        setIsShowChangeModal(flage)
    }

    useEffect(() => {
        isShowEditLabelInput && createLabelInputDom.current.focus();
    }, [isShowEditLabelInput]) // eslint-disable-next-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isShowChangeLabelModal) {
            getWantLabelList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShowChangeLabelModal])

    const changeIsShowLabelInput = (flage) => {
        setIsShowEditLabelInput(flage)
    }

    const createNewLabelHandle = () => {
        const { studentId } = getParams(window.location.href);

        if (createLabelInputDom?.current.value.trim()) {
            createlabel({
                studentId,
                labelName: createLabelInputDom?.current.value
            }).then(res => {
                getWantLabelList(searchLabelInputDom.current.value)
            })
        }
    }

    const getWantLabelList = (name) => {
        const { studentId } = getParams(window.location.href);
        getLabelList({
            studentId,
            name
        }).then(res => {
            setAllLabelList(res || [])
        })
    }

    const changeCheckedLabelHandle = (info) => {
        const index = checkedLabelList.findIndex(v => v.id === info.id)

        let params = {
            studentBookId: props.info.id,
            examTemplateId: props.info.examTemplateId,
            clearTime: props.info.clearTime,
            baseSubjectId: props.info.baseSubjectId,
            createdTime: props.info.timeCreate,
            ...info,
            labelId: info.id
        }

        if (index === -1) {
            setCheckedLabelList([info, ...checkedLabelList])
            updateWorngTopicLabel(params)
        } else {
            setCheckedLabelList(checkedLabelList.filter(v => v.id !== info.id))
            deleteCurrentWrongLabel(params)
        }
    }



    useEffect(() => {
        mathJax()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return [
        { isShowAll, isShowRemoveModal, isShowChangeLabelModal, isShowEditLabelInput, createLabelInputDom, allLabelList, searchLabelInputDom, checkedLabelList },
        { changeIsShowAll, setRemoveInfoChange, setRemoveInfoOK, setRemoveCancel, changeIsShowLabel, changeIsShowLabelInput, createNewLabelHandle, getWantLabelList, changeCheckedLabelHandle }
    ]
}

export default Presenter