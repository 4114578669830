import { useEffect, useMemo, useState } from "react"
import { getParams, isSameDay } from "@/util/util"
import { getStudyRecord, getStudentWordStudyDetail, addCollectWord, delCollectWord } from "@/api/memorizeWords"

const Label = ({ className, children, props }) => (<div className={`label-calendar ${className || ''}`} {...props}>{children}</div>)

const Presenter = (props) => {
    const query = getParams(window.location.href);
    const [calendar, setCalendar] = useState([])
    const [dateSolt, setDateSolt] = useState(new Date())
    const [wordMenuList, setWordMenuList] = useState([])
    const [wordActive, setWordActive] = useState(null)
    const [lastId, setLastId] = useState(0)
    const currentDayInfo = useMemo(() => {
        return calendar.filter(v => isSameDay(v.date, dateSolt))[0]
    }, [calendar, dateSolt])
    const wordDetail = useMemo(() => wordMenuList?.[wordActive]?.['esWordDto'] || null, [wordMenuList, wordActive])
    const [wordModalVisible, setWordModalVisible] = useState(false)

    useEffect(() => {
        getRangeStudyRecord()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getEverydayStudyRecordDetail(dateSolt)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastId, dateSolt])

    const getRangeStudyRecord = (date) => {
        const currentDate = date || new Date()
        const currentYear = currentDate.getFullYear(); // 获取当前年份
        const currentMonth = currentDate.getMonth(); // 获取当前月份
        const firstDayOfMonth = new Date(currentYear, currentMonth, 1); // 创建一个指向当前月份第一天的Date对象        
        const firstDayOfWeek = firstDayOfMonth.getDay(); // 获取当前月份第一天是星期几 (0为星期日，1为星期一，依此类推)
        const startTime = new Date(firstDayOfMonth.getTime() - 24 * 60 * 60 * 1000 * firstDayOfWeek).setHours(0, 0, 0, 0)
        const endTime = new Date(startTime + 24 * 60 * 60 * 1000 * 41).setHours(23, 59, 59, 999)

        getStudyRecord({
            studentId: query?.studentId,
            startTime,
            endTime
        }).then(res => {
            let arr = res.map((v) => {
                let date = new Date(v.dayTime)
                let nowDate = new Date()
                let type = isSameDay(date, nowDate) ? "type-today" : v.reviewCount > 0 ? "type-review" : undefined

                let obj = {
                    date,
                    className: type,
                    memorizedCount: v?.memorizedCount,
                    keyCount: v?.keyCount,
                    children: (<>
                        {type ? <Label className="title-label">{type === "type-today" ? "今日" : type === "type-review" ? "复习" : null}</Label> : null}
                        <Label className="recorded-label"><span>已记：</span><span>{v?.memorizedCount}</span></Label>
                        <Label className="keyCount-label"><span>重点：</span><span>{v?.keyCount}</span></Label>
                    </>)
                }

                return obj
            })

            let isToday = arr.find(v => v.className === 'type-today');
            if (!isToday) {
                arr.push({
                    date: new Date(),
                    className: "type-today",
                    children: (<Label className="title-label">今日</Label>)
                })
            }
            setCalendar(arr)
        })
    }

    const getEverydayStudyRecordDetail = (date) => {
        let params = {
            studentId: query?.studentId,
            dayTime: date?.setHours(0, 0, 0, 0),
            size: 10,
            lastId: lastId
        }
        getStudentWordStudyDetail(params).then(res => {
            if (lastId !== 0) {
                setWordMenuList(list => [...list, ...(res || [])])
            } else {
                setWordMenuList(() => res || [])
            }
        })
    }

    const dayActiveChange = (date) => {
        if (isSameDay(dateSolt, date)) return
        setDateSolt(() => date || new Date())
        setLastId(0)
        setWordMenuList(() => [])
    }

    const addWordMenuList = (e) => {
        setLastId(wordMenuList[wordMenuList.length - 1]['id'])
    }

    const openWordModal = (i) => {
        setWordModalVisible(true)
        setWordActive(i)
    }

    const offWordModal = () => {
        setWordModalVisible(false)
    }

    // 单词收藏事件处理
    const onCollectHandle = (flage, info, callBack) => {
        let paramsObj = getParams(window.location.href)

        let params = {
            studentId: paramsObj.studentId,
            subjectId: 0,
            type: 3,
            examId: info?.word || info?.examId
        }

        if (flage) {
            addCollectWord(params).then(res => {
                callBack && callBack(flage, info)
            })
        } else {
            delCollectWord(params).then(res => {
                callBack && callBack(flage, info)
            })
        }
    }

    const setCollectWordListHandle = (flage, index) => {
        setWordMenuList(wordMenuList.map((v, i) => i === index ? { ...v, esWordDto: { ...v.esWordDto, collection: flage } } : v))
    }

    return [
        { calendar, wordMenuList, dateSolt, currentDayInfo, wordDetail, wordModalVisible, wordActive },
        { getRangeStudyRecord, getEverydayStudyRecordDetail, addWordMenuList, dayActiveChange, openWordModal, offWordModal, onCollectHandle, setCollectWordListHandle }
    ]
}

export default Presenter