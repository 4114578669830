import React from "react";
import "./index.less";
import { Switch } from "antd";
import TypeList from "@/components/typeList";
import SubjectSelect from "@/components/subjectSelect";

import Presenter from "./presenter.js";
const HeadOperation = (props) => {
  const [states, events] = Presenter(props);
  const { isShowSubject } = states;

  return (
    <div className="headOperation">
      <div className="leftTypeBox"></div>
      <div className="rightBox">
        <div>
          <div className="twoSwitch">
            <Switch defaultChecked={false} onChange={props.changeSwitch} />
          </div>
          <div className="onetext">只看已回复</div>
        </div>
        <div className="rightMinBox">
          <TypeList changeType={props.changeType} />
        </div>
        {isShowSubject ? (
          <div className="rightMinBox">
            <SubjectSelect changeSubject={props.changeSubject} />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default HeadOperation;
