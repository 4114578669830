import { useState, useEffect, useMemo } from 'react';
import { getParams, isSameDay } from '@/util/util';
import { getSubmitReading, getAllReadingData } from "@/api/earlyReading"
import { message } from "antd"

const middleSubjectConfigList = [
    { subjectName: "语文", subjectId: 2, icon: "yuwen", color: 'red' },
    { subjectName: "英语", subjectId: 8, icon: "yingyu", color: 'orange' },
    { subjectName: "政治", subjectId: 35, icon: "zhengzi", color: 'brown' },
    { subjectName: "历史", subjectId: 16, icon: "lishi", color: 'green' },
    { subjectName: "地理", subjectId: 18, icon: "dili", color: 'cyan' },
    { subjectName: "化学", subjectId: 12, icon: "huaxue", color: 'blue' },
    { subjectName: "生物", subjectId: 14, icon: "shengwu", color: 'purple' },
];

const highSubjectConfigList = [
    { subjectName: "语文", subjectId: 3, icon: "yuwen", color: 'red' },
    { subjectName: "英语", subjectId: 9, icon: "yingyu", color: 'orange' },
    { subjectName: "政治", subjectId: 20, icon: "zhengzi", color: 'brown' },
    { subjectName: "历史", subjectId: 17, icon: "lishi", color: 'green' },
    { subjectName: "地理", subjectId: 19, icon: "dili", color: 'cyan' },
    { subjectName: "化学", subjectId: 13, icon: "huaxue", color: 'blue' },
    { subjectName: "生物", subjectId: 15, icon: "shengwu", color: 'purple' },
];

const Presenter = () => {
    const subjectConfigList = useMemo(() => {
        const { levelId } = getParams(window.location.href);
        if (Number(levelId) === 2) {
            return middleSubjectConfigList
        } else if (Number(levelId) === 3) {
            return highSubjectConfigList
        } else {
            return middleSubjectConfigList
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const ReadingScoreList = [100, 80, 60];
    const [subjectReadingDetails, setSubjectReadingDetails] = useState([]); // 今日早读数据
    const [todayIsSubmitReading, setTodayIsSubmitReading] = useState(false); // 今日是否提交过早读
    const [otherDayReadingList, setOtherDayReadingList] = useState([]); // 它日早读数据
    const [isOpenCard, setIsOpenCard] = useState(true); // 卡片展开收起状态

    useEffect(() => {
        init();
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    const init = () => {
        const { studentId } = getParams(window.location.href);
        getAllReadingData({
            startTime: new Date(+new Date() - (24 * 60 * 60 * 1000 * 7)).setHours(0, 0, 0, 0), // 七日前凌晨0点
            endTime: new Date().setHours(23, 59, 59, 999), //今日晚上12点
            studentId
        }).then(res => {
            let isExistToday = isSameDay(res[0]?.createTime, +new Date()); //判断今日早读是否提交过
            let readingDetailsList = subjectConfigList.map(v => {
                let isSubmit = isExistToday ? (!!res[0]?.morningReadingScoreRpcDtoList?.some(j => j.subjectId === v.subjectId)) : false
                let score = isExistToday ? res[0]?.morningReadingScoreRpcDtoList.find(j => j.subjectId === v.subjectId)?.score : null
                let teacherScore = isExistToday ? res[0]?.morningReadingScoreRpcDtoList.find(j => j.subjectId === v.subjectId)?.teacherScore : null

                return {
                    ...v,
                    isSubmit,
                    score,
                    teacherScore
                }
            })
            setTodayIsSubmitReading(isExistToday); // 今日是否提交过早读
            setSubjectReadingDetails(readingDetailsList); // 今日早读数据
            setOtherDayReadingList(isExistToday ? res?.slice(1) : res || []); // 它日早读数据
        });
    }

    const changeSubjectReadingRate = (index, score) => {
        let arr = subjectReadingDetails
        if (arr[index].isSubmit) return
        if (arr[index].score === score) {
            arr[index].score = null 
        } else {
            arr[index].score = score
        }
        setSubjectReadingDetails([...arr])
    }

    const submitReadingDetails = () => {
        const { studentId, studentName, classId, className } = getParams(window.location.href);
        // 提交时过滤出 （已打分）且 （未提交）
        let optionList = subjectReadingDetails.filter(v => v.score && !v.isSubmit).map(v => ({
            studentId,
            classId,
            studentName: decodeURIComponent(studentName),
            className: decodeURIComponent(className),
            subjectName: v.subjectName,
            subjectId: v.subjectId,
            score: v.score
        }))

        if (optionList.length !== 0) {
            getSubmitReading({ optionList }).then(res => {
                init();
                message.success('提交成功');
            })
        } else {
            message.error('请先添加早读数据后提交');
        }
    }

    const changeIsOpen = () => {
        setIsOpenCard(!isOpenCard)
    }

    return [
        { ReadingScoreList, subjectReadingDetails, todayIsSubmitReading, isOpenCard, otherDayReadingList, subjectConfigList },
        { changeSubjectReadingRate, submitReadingDetails, changeIsOpen },
    ];
};

export default Presenter;
