/*
 * @Author: your name
 * @Date: 2021-12-03 09:53:09
 * @LastEditTime: 2023-02-23 16:22:45
 * @LastEditors: c1107 chen1997
 * @Description: 打开koroFileHeader查看配置 进行设置: https:github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newGitZnxt2.0\src\pages\indexPage\presenter.js
 */

import { useState, useEffect, useRef } from "react";

import { getTaskList } from "@/api/login";
import { getParams } from "@/util/util";

const Presenter = (props) => {
  const [indexData, setindexData] = useState([]);
  const [lastMaxId, setLastMaxId] = useState(0);
  const [showMore, setShowMore] = useState(true);

  let obj = localStorage.getItem("sdzn-userInfo");
  let token = localStorage.getItem("sdzn-token");
  obj = JSON.parse(obj || "{}");
  const endTime =
    new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000;

  const toolPlate = [
    {
      img: require("@/assets/index/ziyuan.png").default,
      name: "我的直播",
      info: "优质的学习资源",
      path: `/myCourse?userId=${obj.id}&account=${obj.account}&userName=${obj.name}&schoolId=${obj.schoolId}&schoolName=${obj.schoolName}&gradeId=${obj.gradeId}&gradeName=${obj.gradeName}&className=${obj.className}&classId=${obj.classInfoId}`,
    },
    {
      img: require("@/assets/index/cuotiben.png").default,
      name: "错题本",
      info: "同步练习、知识点练习、智能练习",
      // path: `/errorBook?studentId=${obj.id}&token=${token}&levelId=${obj.levelId}&schoolId=${obj.schoolId}&classId=${obj.classId}&gradeId=${obj.gradeId}`,
      path: `/mistakesBooks?studentId=${obj.id}&token=${token}&levelId=${obj.levelId}&schoolId=${obj.schoolId}&classId=${obj.classInfoId}&gradeId=${obj.gradeId}`,
    },
    // {
    //   img: require('@/assets/index/biji.png').default,
    //   name: '问题记录',
    //   info: '与同学们一起成组讨论，共同进步',
    //   path: `/listQuestionSheet?studentId=${obj.id}&token=${token}&levelId=${obj.levelId}&schoolId=${obj.schoolId}&classId=${obj.classId}&gradeId=${obj.gradeId}`,
    // },
    {
      // img: require("@/assets/index/ziyuan.png").default,
      img: require("@/assets/index/biji.png").default,

      name: "我的收藏",
      info: "查看以前收藏过的学习资源",
      path: `/myCollect?studentId=${obj.id}&levelId=${obj.levelId}&schoolId=${obj.schoolId}&classId=${obj.classInfoId}&gradeId=${obj.gradeId}`,
    },
    {
      // img: require("@/assets/index/ziyuan.png").default,
      img: require("@/assets/index/fenxi.png").default,
      name: "综合统计",
      info: "查看综合统计和学科成绩",
      path: `/analysis?studentId=${obj.id}&levelId=${obj.levelId}&schoolId=${obj.schoolId}&classId=${obj.classInfoId}&gradeId=${obj.gradeId}`,
    },
    {
      // img: require("@/assets/index/ziyuan.png").default,
      img: require("@/assets/index/fenxi.png").default,
      name: "记单词",
      info: "记单词记单词",
      path: `/memorizeWords?studentId=${obj.id}&studentName=${encodeURIComponent(obj.name)}&schoolId=${obj.schoolId}&gradeId=${obj.gradeId}&classId=${obj.classInfoId}`,
    },
    {
      // img: require("@/assets/index/ziyuan.png").default,
      img: require("@/assets/index/fenxi.png").default,
      name: "早读",
      info: "早读上报",
      path: `/earlyReading?studentId=${obj.id}&studentName=${encodeURIComponent(obj.name)}&classId=${obj.classInfoId}&className=${encodeURIComponent(obj.className)}`,
    },
    {
      // img: require("@/assets/index/ziyuan.png").default,
      img: require("@/assets/index/biji.png").default,
      name: "词汇表",
      info: "词汇表 单词收藏",
      path: `/glossary?studentId=${obj.id}`,
    },
    {
      // img: require("@/assets/index/ziyuan.png").default,
      img: require("@/assets/index/biji.png").default,
      name: "班级德育",
      info: "班级德育",
      path: `/morality?studentId=${obj.id}&classId=${obj.classInfoId}&studentName=${encodeURIComponent(obj.name)}&className=${encodeURIComponent(obj.className)}&schoolId=${obj.schoolId}&gradeId=${obj.gradeId}`,
    }
  ];

  const allTaskHandle = () => {
    props.history.push(
      `/myCollect?token=${token}&schoolId=${obj.schoolId}&classId=${obj.classInfoId}&gradeId=${obj.gradeId}`
    );
  };
  const lookHandle = (item) => {
    let obj = JSON.parse(localStorage.getItem("sdzn-userInfo"));
    const markIndex = item.path.indexOf("?");
    props.history.push(
      `${item.path}${markIndex === -1 ? "?" : "&"}levelId=${obj.levelId
      }&studentId=${obj.id}&`
    );
  };
  const bindCard = (item) => {
    console.log(item, 'itemitemitem-----------');
    if (item.resourceType === 5) {
      if (item.status) {
        // 已作答去统计页面
        props.history.push(
          `/wordStatistics?id=${item.id}&lessonTeacherId=${item.lessonTeacherId}&type=${item.type}&studentId=${obj.id}&name=${item.lessonTeacherName}&token=${token}&levelId=${obj.levelId}&schoolId=${obj.schoolId}&classId=${obj.classId}&gradeId=${obj.gradeId}&isPc=true`
        );
      } else {
        // 记单词
        props.history.push(
          `/wordsRecite?id=${item.id}&lessonTeacherId=${item.lessonTeacherId
          }&type=${item.type}&studentId=${obj.id}&name=${item.lessonTeacherName
          }&token=${token}&levelId=${obj.levelId}&schoolId=${obj.schoolId
          }&classId=${obj.classId}&gradeId=${obj.gradeId}&isPc=true&status=${item.status
          }&review=${item?.ext > 1 ? "true" : "false"}&extNum=${item?.ext
          }&countExam=${item?.countExam}`
        );
      }
    } else {
      props.history.push(
        `/answer?id=${item.id}&lessonTeacherId=${item.lessonTeacherId}&type=${item.type}&studentId=${obj.id}&name=${item.lessonTeacherName}&token=${token}&levelId=${obj.levelId}&schoolId=${obj.schoolId}&classId=${obj.classId}&gradeId=${obj.gradeId}&subjectId=${item.subjectId}`
      );
    }
  };

  const [examList, setExamList] = useState([]);

  const getSubjectName = (name) => {
    let s = name.substr(0, 1);
    return s;
  };

  const moreLearn = (name) => {
    let length = indexData.length;
    let lastId = indexData[length - 1]?.id;
    setLastMaxId(lastId);
  };

  useEffect(() => {
    let pageObj = getParams(window.location.href) || {};
    if (pageObj?.id) {
      localStorage.setItem("sdzn-userInfo", JSON.stringify(pageObj));
    }
    let obj = localStorage.getItem("sdzn-userInfo") || JSON.stringify(pageObj);
    if (obj) {
      obj = JSON.parse(obj);
      getTaskList({
        studentId: pageObj.id || obj.id,
        size: 10,
        lastMaxId: lastMaxId,
        endTime: endTime,
      }).then((res) => {
        if (res.length < 10) {
          setShowMore(false);
        }
        let arr = indexData.concat(res);
        console.log(arr, 'pppppeeeee');
        setindexData(arr);
      });
    } else {
    }
  }, [lastMaxId]); // eslint-disable-line react-hooks/exhaustive-deps

  return [
    { toolPlate, indexData, showMore },
    { allTaskHandle, lookHandle, bindCard, getSubjectName, moreLearn },
  ];
};

export default Presenter;
