/*
 * @Author: your name
 * @Date: 2021-09-17 14:40:59
 * @LastEditTime: 2022-09-13 10:11:14
 * @LastEditors: c1107 chen1997
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newGitZnxt2.0\src\pages\studentAnswer\index.jsx
 */
import React from "react";
import "./index.less";

import PadHeader from "@/components/padHeader";
import AnswerList from "@/components/answerList";
import Presenter from "./presenter.js";
import ExamFeature from "./components/examFeature";

import { connect } from "react-redux";

const AnswerWrapper = () => {
  const [states, events] = Presenter();
  const { courseDetail, activeInfo, name, weekEnFiles } = states;
  const { changeComponents } = events;
  return (
    <div className="AnswerWrapper">
      {/* pad顶部 */}
      <PadHeader name={name}>
        {activeInfo.key === 2 ? (
          <ExamFeature weekEnFiles={weekEnFiles}></ExamFeature>
        ) : (
          ""
        )}
      </PadHeader>
      {/* 中间的卡片区域 */}
      {courseDetail && (
        <div className="card">
          {/* // 学生的作答区域 */}
          <AnswerList courseDetail={courseDetail} />
        </div>
      )}
      {courseDetail === undefined && <div className="no_data">暂无数据</div>}
    </div>
  );
};

connect();

export default connect((state) => ({}), {})(AnswerWrapper);
